import React, { useEffect } from 'react';
import Card from '@material-ui/core/Card';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { AdHeader } from './AdHeader';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import VizSensor from 'react-visibility-sensor';
import ReactGA from 'react-ga';
import { trackEvent, eventTracker, generateSubId, getFullPlatformUrl } from '../../utils';
import { routeNames } from '../../data/Data';
import { useSelector, shallowEqual } from 'react-redux';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles((theme) => ({
	card: {
		'&:hover': { cursor: 'pointer' },

		//margin: 'auto',
		margin: '0 12px 0 12px',
		marginBottom: '12px',
		boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
		position: 'relative',
		borderRadius: '8px',
	},
	premiumCard: {
		border: '1px solid #F3B81F',
	},
	expandIcon: {
		position: 'absolute',
		top: '12px',
		right: '12px',
		background: '#5056E7',
		'&:hover': {
			backgroundColor: '#646AF0',
		},
		borderRadius: '100px',
		color: '#fff',
		padding: '6px 16px',
		textTransform: 'none',

		[theme.breakpoints.down('519')]: {
			padding: '6px',
			minWidth: '30px',
		},
		[theme.breakpoints.down('445')]: {
			padding: '6px 16px',
			minWidth: '64px',
		},
		[theme.breakpoints.down('310')]: {
			padding: '6px',
			minWidth: '30px',
		},
	},
}));

export const AdCard = (props) => {
	const { investmentPlatform, embedOnclick, expandable, w, h } = props;
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const classes = useStyles();
	const [source, setSource] = React.useState(state.itemSource || 'MoneyMade');
	const platformUrl = `${routeNames.customWidget}/${investmentPlatform.id}`;
	const fullPlatformUrl = getFullPlatformUrl(`${platformUrl}`);
	const siteUrl = getFullPlatformUrl(`${routeNames.discover}`);
	function stopPropagation(event) {
		trackEvent('ExternalClick', 'platform', investmentPlatform.name);
		eventTracker("ExternalClick", investmentPlatform.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
		event.stopPropagation();
	}
	const onCardClick = (event) => {
		ReactGA.pageview(siteUrl);
		eventTracker("pageview", siteUrl, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
		window.open(siteUrl);
	};
	useEffect(() => {});

	return (
		<VizSensor
			onChange={(isVisible) => {
				trackEvent('PlatformImpression', 'platform', investmentPlatform.name);
				eventTracker("PlatformImpression", investmentPlatform.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
			}}
		>
			<Card
				className={`mainCard ${classes.card} ${
					investmentPlatform.premium > 0 ? classes.premiumCard : null
				}`}
				onClick={onCardClick}
			>
				<AdHeader
					investmentPlatform={investmentPlatform}
					platformUrl={fullPlatformUrl}
					w={w}
				/>
				<Button
					color="primary"
					className={classes.expandIcon}
					target="_blank"
					rel="noopener noreferrer"
					href={
						investmentPlatform.website
							? investmentPlatform.website.replace('%SUBID%', generateSubId(state.itemSource, state.itemMedium))
							: `/${routeNames.discover}`
					}
					onClick={stopPropagation}
				>
					{w > 519 || (w <= 444 && w >= 310) ? 'More' : <KeyboardArrowRightIcon />}
				</Button>
			</Card>
		</VizSensor>
	);
};
