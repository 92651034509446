import React from 'react';
import ReactGA from 'react-ga';
import makeStyles from '@material-ui/core/styles/makeStyles';

ReactGA.initialize('UA-148517382-4');

const useStyles = makeStyles((theme) => ({
    link: {
        cursor: 'pointer',
    }
}));

export const LinkBlank = (props) => {
	const {
		stopPropagation,
        href,
        children,
        style: styleProp,
    } = props;

    const classes = useStyles();

	return (
        <a
            target="_blank"
            rel="noopener noreferrer"
            style={styleProp}
            href={href}
            onClick={stopPropagation}
            className={classes.link}
        >
            {children}
        </a>
	);
}
