import { REACT_APP_EVENT_TRACKING_URL } from "./ConstAPI";
import { cookiesEnabled, setStorage, getStorage } from '../utils';


const makeURL = () => `${REACT_APP_EVENT_TRACKING_URL}`;

const defaultHeaders = {
  "Content-Type" : "application/json",
  //"Accept": "application/json"
};

const makeRequestData = (data) => ({
    method: "POST",
    headers: defaultHeaders,
    body: JSON.stringify(data),
});

const makeRequest = async (data) => {
    const url = makeURL();
    const requestData = makeRequestData(data);

    return await fetch(url, requestData);
};
/**
 * @param {string} category
 * @param {string} name
 * @param {string} source
 * @param {string} medium
 * @param {string} campaign
 * @param {string} userId
 */
export const eventTrackingAPI = async (category, name, source, medium, campaign, userId) => {
    try {
		const response = await makeRequest(
            {
                category,
                name,
                source,
                medium,
                campaign,
                userId
            }
        );
        const responseStatus = await response.text();
        if (response.status === 200) {
            return {
                success: true,
                result: responseStatus,
                message: '',
            }
        } else {
            return {
                success: false,
                result: [],
                message: responseStatus.error.message,
            };
        }
	} catch (error) {
        console.log(error);
		return {
			success: false,
			result: [],
			message: error,
        };
	}
};
