import negative from "../../img/icons/negative.png";
import neutral from "../../img/icons/neutral.png";
import positive from "../../img/icons/positive.png";

const sentiments = {
    negative: { icon: negative, text: "Negative" },
    neutral: { icon: neutral, text: "Neutral" },
    positive: { icon: positive, text: "Positive" },
};

export const getSentiment = sentimentScore => {
    if (sentimentScore < 0) {
        return sentiments.negative;
    }

    if ((sentimentScore >= 0 && sentimentScore < 0.1) || !sentimentScore) {
        return sentiments.neutral;
    }

    if (sentimentScore >= 0.1) {
        return sentiments.positive;
    }
};

export const getSentimentIcon = sentimentScore => getSentiment(sentimentScore).icon;
export const getSentimentText = sentimentScore => getSentiment(sentimentScore).text;
