import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useSelector, shallowEqual } from 'react-redux';
import First from '../../img/bestfor/First.svg';
import Second from '../../img/bestfor/Second.svg';
import Third from '../../img/bestfor/Third.svg';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { cookiesEnabled, getStorage, trackEvent } from '../../utils';
import { deepDiveDelimiters } from '../../data/Data';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles((theme) => ({
	root: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '10px',
        },
        [theme.breakpoints.down('800')]: {
            flexDirection: 'column',
        },
	},
	title: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#73757A',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    cardRight: {
        marginLeft: '20px',
        [theme.breakpoints.down('800')]: {
            marginLeft: '0px',
        },
    },
    container: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        width: '253px',
        background: '#FFFFFF',
        border: '1px solid #F2F5F8',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
        borderRadius: '8px',
        minHeight: '80px',
        paddingLeft: '64px',
        paddingRight: '10px',
        paddingTop: '16px',
        paddingBottom: '16px',
        display: 'flex',
        alignItems: 'center',
        marginTop: '12px',
        [theme.breakpoints.down('800')]: {
            width: '100%',
        },
    },
    image1: {
        backgroundImage: `url(${First})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '12px center',
    },
    image2: {
        backgroundImage: `url(${Second})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '12px center',
    },
    image3: {
        backgroundImage: `url(${Third})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '12px center',
    },
}));

export const BestFor = (props) => {
    const {
        investmentAdvanced,
    } = props;
	const classes = useStyles();
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);

	return (
        <div>
            {investmentAdvanced !== null && investmentAdvanced.map((i, index) => {
                if (i['type'] === deepDiveDelimiters.bestFor) {
                    return (
                    <div className={classes.root} key={`bestFor_${index}`}>
                        {i['Content'][0] &&
                            <div className={`${classes.container} ${classes.image1}`}>
                                {i['Content'][0]}
                            </div>}
                        {i['Content'][1] &&
                            <div className={`${classes.container} ${classes.image2} ${classes.cardRight}`}>
                                {i['Content'][1]}
                            </div>}
                        {i['Content'][2] &&
                            <div className={`${classes.container} ${classes.image3} ${classes.cardRight}`}>
                                {i['Content'][2]}
                            </div>}
                    </div>);
                }
            })}
        </div>
	)
}
