import React, { useState, useEffect, useMemo } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, useTheme, withStyles, fade } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import VizSensor from 'react-visibility-sensor';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Spinner } from '../../Spinner/Spinner';
import Close from '../../../img/dashboard/Close.svg';
import * as actions from "../../../redux/actions";
import LazyLoad from 'react-lazyload';
import ExpandedPlatform from '../../InvestmentPlatform/ExpandedPlatform';
import { trackEvent, catchError, eventTracker, getFullPlatformUrl } from '../../../utils';
import { routeNames } from '../../../data/Data';
import { deleteFavorite } from '../../../Services/DeleteFavoriteAPI';
import { CollapsedCard } from './CollapsedCard';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
    },
    expandIcon: {
		position: 'absolute',
		top: '12px',
		right: '12px',
		background: '#5056E7',
		'&:hover': {
			backgroundColor: '#646AF0',
		},
		color: '#fff',
		borderRadius: '100px',
		textTransform: 'none',
		padding: '6px 16px',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
    favoritesTitle: {
        fontFamily: 'Red Hat Display',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '28px',
        lineHeight: '40px',
        color: '#30333E',
        padding: '40px 0 0 40px',
    },
    favoritesCount: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#73757A',
        padding: '4px 0 24px 40px',
    },
    investCard: {
		'&:hover  $expandIcon': {
			display: 'block',
        },
        position: 'relative',
        width: '96%',
        padding: '4px 0 24px 40px',
        marginRight: '70px',
        [theme.breakpoints.down('770')]: {
            width: '100%',
        },
    },
    [theme.breakpoints.down('1495')]: {
        favoritesTitle: {
            padding: '40px 0 0 0px',
        },
        favoritesCount: {
            padding: '4px 0 24px 0px',
        },
        investCard: {
            padding: '4px 0 24px 0px',
            marginRight: '0',
        },
    },
    starContainer: {
        position: 'relative',
        float: 'right',
        [theme.breakpoints.down('770')]: {
            display: 'none',
        },
	},
	starImg: {
		position: 'absolute',
		top: '-100px',
        left: '0px',
        paddingLeft: '16px',
        cursor: 'pointer',
		[theme.breakpoints.down('445')]: {
			position: 'absolute',
			top: '-20px',
			left: '0px',
			cursor: 'pointer',
        },
    },

    card: {
		'&:hover': { cursor: 'pointer' },
	},
	cardAction: {
		borderTop: '1px solid #F2F5F8',
		padding: '0px',
    },
    premiumCard: {
		border: '1px solid #F3B81F',
	},
}));

export const SelectedPlatforms = (props) => {
    const { displaySpinner } = props;
    const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
    const dispatch = useDispatch();
    const classes = useStyles();
    const theme = useTheme();

	const [investments, setInvestments] = useState(state.investmentsTotal);
    const [source, setSource] = React.useState(state.itemSource || 'MoneyMade');
    const [activePlatform, setActivePlatform] = useState(null);

    const areInvestmentsPresent = Object.values(state.investmentsTotal).length > 0;

    useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
    useEffect(() => {
        if (Object.values(state.investmentsTotal).length > 0) {
            setInvestments(state.investmentsTotal);
        }
    }, [state.investmentsTotal]);

    //scroll to active platform
    useEffect(() => {
        if (activePlatform !== null && activePlatform !== undefined) {
            try{
                let element = document.getElementById(`platform_ex_null${activePlatform.id}`);
                element.scrollIntoView({
                    behavior: 'smooth',
                });
            } catch(err) {
                catchError(err);
            }
        }
    }, [activePlatform]);

    const deleteFavoriteAsync = (id) => {
		deleteFavorite(state.token, id)
		.then((data) => {
			if (data.success) {
				dispatch(actions.addInvesmentsToFavorites(data.message));
			}
		}).catch((error) => {
			console.log(error);
		});
	};
	const handleCardClick = async (id, investmentPlatform) => {
        const platformUrl = `/${routeNames.discover}/${id}`;
        ReactGA.pageview(platformUrl);
        eventTracker("pageview", platformUrl, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);

        setActivePlatform(investmentPlatform);
    };
    const handleMouseHover = (id) => {
        document.getElementById(`id_starContainer_${id}`).style.display = "block";
    };

    const handleMouseOut = (id) => {
        document.getElementById(`id_starContainer_${id}`).style.display = "none";
    };

    const handleCloseClick = (id) => {
        dispatch(actions.removeInvestmentFromFavorites(id));
        deleteFavoriteAsync(id);
    };

    const stopPropagation = (event, investmentPlatform) => {
        trackEvent('ExternalClick', 'platform', investmentPlatform.name);
        eventTracker("ExternalClick", investmentPlatform.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);

		event.stopPropagation();
	};

	return (
		<div className={`respCont ${classes.root}`}>
            <CssBaseline />
            <div className={classes.toolbar} />
			<main className={`${classes.content} mainPrinc`}>
                <div className={classes.favoritesTitle}>
                    Selected platforms
                </div>
                <div className={classes.favoritesCount}>
                    {`${state.favoritesInvestments.length}`} platforms
                </div>
				{areInvestmentsPresent && state.favoritesInvestments.length > 0 &&
                    Object.values(investments)
                    .filter(i => i !== true)
                    .map(function (investment, index) {
                        if (state.favoritesInvestments.filter((i) => i === investment.id).length > 0) {
                            let investmentComponent = null;
                            const fullPlatformUrl = getFullPlatformUrl(`discover/${investment.slug}`);

                            investmentComponent = (
                                <div
                                    id={`platform_${investment.id}`}
                                    key={`platform_${investment.id}`}
                                    className={classes.investCard}
                                    onMouseOver={() => {handleMouseHover(investment.id)}}
                                    onMouseOut={() => {handleMouseOut(investment.id)}}
                                >
                                    <LazyLoad
                                        height={230}
                                        offset={500}
                                        once
                                        key={`lazy_${investment.id}`}
                                    >
                                        <VizSensor
                                            onChange={(isVisible) => {
                                                trackEvent('PlatformImpression', 'platform', investment.name);
                                                eventTracker("PlatformImpression", investment.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
                                            }}
                                        >
                                            <div key={`cardContainer_${investment.id}`}>
                                                <CollapsedCard
                                                    fullPlatformUrl={fullPlatformUrl}
                                                    investment={investment}
                                                    stopPropagation={stopPropagation}
                                                    handleCardClick={handleCardClick}
                                                    itemSource={state.itemSource}
                                                    itemMedium={state.itemMedium}
                                                />
                                                <div
                                                    className={classes.starContainer}
                                                    id={`id_starContainer_${investment.id}`}
                                                    style={{display: 'none'}}
                                                    key={`id_starContainer_${investment.id}`}
                                                >
                                                    <img
                                                        src={Close}
                                                        alt={investment.name}
                                                        onClick={() => {handleCloseClick(investment.id)}}
                                                        className={classes.starImg}
                                                    />
                                                </div>
                                            </div>
                                        </VizSensor>
                                    </LazyLoad>
                                </div>
                            );
                            if (activePlatform === investment) {
                                const similarInvestments = activePlatform.similars.map((similar) => {
                                    const similarInvestment = Object.values(investments).find(
                                        (investment) => investment.id === similar
                                    );
                                    return similarInvestment;
                                });
                                investmentComponent = (
                                    <div
                                        className={classes.investCard}
                                    >
                                        <ExpandedPlatform
                                            key={investment.id}
                                            investmentPlatform={investment}
                                            similarInvestments={similarInvestments}
                                            setActivePlatform={setActivePlatform}
                                            expandable={false}
                                            embed={true}
                                            favorites={true}
                                        />
                                    </div>
                                );
                            }
                            return investmentComponent;
                        } else {
                            return null;
                        }
				    })
                }
                {displaySpinner && (
                    <center>
                        <Spinner displaySpinner={displaySpinner} />
                    </center>)}
			</main>
		</div>
	);
};
