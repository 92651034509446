import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { TextField, Button, RadioGroup } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Radio from '@material-ui/core/Radio';
import Slider from '@material-ui/core/Slider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import clsx from 'clsx';
import Stepper from '../Stepper/Stepper';
import EnterIcon from '../../img/enter.svg';
import chevronLeftRight from '../../img/chevron-left-right.svg';
import LowRiskActiveIcon from '../../img/icons/lowRisk_selected.png';
import LowRiskIcon from '../../img/icons/lowRisk.png';
import HighRiskActiveIcon from '../../img/icons/highRisk_selected.png';
import HighRiskIcon from '../../img/icons/highRisk.png';
import MediumRiskActiveIcon from '../../img/icons/mediumRisk_selected.png';
import MediumRiskIcon from '../../img/icons/mediumRisk.png';
import oneYearActiveIcon from '../../img/icons/1yearSelected.png';
import oneYearIcon from '../../img/icons/1year.png';
import fiveYearActiveIcon from '../../img/icons/5yearsSelected.png';
import fiveYearIcon from '../../img/icons/5years.png';
import fivePlusYearActiveIcon from '../../img/icons/5plusYearsSelected.png';
import fivePlusYearIcon from '../../img/icons/5plusYears.png';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import { Spinner } from '../Spinner/Spinner.js';
import { getFilterResult } from '../../Services/BaseAPI.js';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { eventTracker, filter, isPaidOnly, trackEvent, GASendEvent, FBSendEvents, generateQuizAnswersObject } from '../../utils';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as actions from "../../redux/actions";
import { InlineSlider } from '../Slider/InlineSlider';

const StyledRadio = (props) => {
    const classes = useStyles();
    return (
        <Radio
            classes={{ root: classes.rootRadio, checked: classes.checked }}
            disableRipple
            color="default"
            checkedIcon={
                <span className={clsx(props.iconstyle, props.checkediconstyle)} />
            }
            icon={<span className={props.iconstyle} />}
            {...props}
        />
    );
};
const useStyles = makeStyles((theme) => ({
    tooltip: {
		zIndex: 10001, // to be visible inside the modal
	},
    root: {
        width: '800px',
        maxWidth: '100%',
        flexGrow: 1,
        $checked: {
			backgroundColor: '#ECF5FF',
		},
    },
    rootRadio: {
        display: 'flex',
    },
    wizard: {
        [theme.breakpoints.up('830')]: {
            paddingLeft: '95px',
		},
        [theme.breakpoints.down('830')]: {
            paddingLeft: '95px',
        },
        [theme.breakpoints.down('780')]: {
            paddingLeft: '44px',
        },
        [theme.breakpoints.down('600')]: {
            paddingLeft: '20px',
        },
    },
    h4: {
		fontSize: '12px',
		lineHeight: '20px',
		letterSpacing: '0.04em',
		textTransform: 'uppercase',
		color: '#73757A',
		fontWeight: 'normal',
		marginTop: '30px',
        marginBottom: '2px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
	},
	h3: {
		fontSize: '28px',
		lineHeight: '40px',
		fontFamily: 'Red Hat Display',
		color: '#30333E',
		fontWeight: 'normal',
		marginTop: '5px',
        marginBottom: '5px',
        maxWidth: '600px',
	},
	h2: {
		fontSize: '28px',
		lineHeight: '40px',
		fontFamily: 'Red Hat Display',
		color: '#30333E',
		fontWeight: 'normal',
		marginTop: '5px',
		marginBottom: '5px',
	},
	h5: {
		fontSize: '14px',
		lineHeight: '20px',
		fontFamily: 'Inter',
		color: '#73757A',
        fontWeight: 'normal',
        fontStyle: 'normal',
        marginTop: '10px',
        maxWidth: '600px',
    },
    slider: {
		[theme.breakpoints.up('830')]: {
            width: '306px',
            marginLeft: '6px',
		},
        [theme.breakpoints.down('830')]: {
            width: '306px',
            marginLeft: '6px',
        },
        [theme.breakpoints.down('360')]: {
            width: '236px',
            marginLeft: '6px',
        },
	},
    sliderText: {
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        background: '#FFFFFF',
        border: '1px solid #C5C8D1',
        boxSizing: 'border-box',
        borderRadius: '8px',
        fontFamily: 'Inter',
        lineHeight: '32px',
        color: '#30333E',
        padding: '10px',
        position: 'relative',
        top: '21px',
        [theme.breakpoints.up('830')]: {
            width: '320px',
		},
        [theme.breakpoints.down('830')]: {
            width: '320px',
        },
        [theme.breakpoints.down('360')]: {
            width: '250px',
        },
    },
    radioLabel: {
		width: '322px',
		border: '1px solid #F2F5F8',
		borderRadius: '8px',
		boxSizing: 'border-box',
		boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
		'&:hover': {
			backgroundColor: '#ECF5FF',
		},
		'&:active': {
			backgroundColor: '#ECF5FF',
		},
	},
	radioLabel2: {
		maxWidth: '437px',
		minHeight: '48px',
		border: '1px solid #F2F5F8',
		borderRadius: '8px',
		boxSizing: 'border-box',
		boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
    },
    extendedLabel: {
		maxWidth: '400px !important',
	},
    iconLow: {
		borderRadius: '8px',
		width: '52px',
		height: '52px',
		backgroundImage: `url(${LowRiskIcon})`,
	},
	checkedIconLow: {
		borderRadius: '8px',
		width: 52,
		height: 52,
		backgroundImage: `url(${LowRiskActiveIcon})`,
	},
	iconMedium: {
		borderRadius: '8px',
		width: 52,
		height: 52,
		backgroundImage: `url(${MediumRiskIcon})`,
    },
    checkedIconMedium: {
		borderRadius: '8px',
		width: 52,
		height: 52,
		backgroundImage: `url(${MediumRiskActiveIcon})`,
	},
    iconHigh: {
		borderRadius: '8px',
		width: 52,
		height: 52,
		backgroundImage: `url(${HighRiskIcon})`,
	},
	checkedIconHigh: {
		borderRadius: '8px',
		width: 52,
		height: 52,
		backgroundImage: `url(${HighRiskActiveIcon})`,
	},
	icon1Year: {
		borderRadius: '8px',
		width: 52,
		height: 52,
		backgroundImage: `url(${oneYearIcon})`,
	},
	checkedIcon1Year: {
		borderRadius: '8px',
		width: 52,
		height: 52,
		backgroundImage: `url(${oneYearActiveIcon})`,
	},

	icon5Years: {
		borderRadius: '8px',
		width: 52,
		height: 52,
		backgroundImage: `url(${fiveYearIcon})`,
	},
	checkedIcon5Years: {
		borderRadius: '8px',
		width: 52,
		height: 52,
		backgroundImage: `url(${fiveYearActiveIcon})`,
	},
	icon5PlusYears: {
		borderRadius: '8px',
		width: 52,
		height: 52,
		backgroundImage: `url(${fivePlusYearIcon})`,
	},
	checkedIcon5PlusYears: {
		borderRadius: '8px',
		width: 52,
		height: 52,
		backgroundImage: `url(${fivePlusYearActiveIcon})`,
	},
    nextButtons: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '20px',
        paddingTop: '30px',
        [theme.breakpoints.up('830')]: {
            paddingLeft: '95px',
		},
        [theme.breakpoints.down('830')]: {
            paddingLeft: '95px',
        },
        [theme.breakpoints.down('780')]: {
            paddingLeft: '44px',
        },
        [theme.breakpoints.down('600')]: {
            paddingLeft: '20px',
        },
    },
    enterText: {
        //display: 'flex',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        textAlign: 'center',
        color: '#73757A',
        marginLeft: '16px',
    },
    iconButton: {
        color: '#5056E7',
        marginLeft: '6px',
    },
    required: {
        color: '#ff726f',
        marginTop: '10px',
    },
    visible: {
        visibility: 'visible',
    },
    hidden: {
        visibility: 'hidden',
    },
}));
export const Wizard = (props) => {
    const { onFinish, open } = props;
    const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
    const dispatch = useDispatch();

	const risk = useRef();
	const cash = useRef();
	const timeframe = useRef();
	const cashout = useRef();
	const accredited = useRef();
	const form = useRef();
    const classes = useStyles();
    const theme = useTheme();

    const steps = {
        step0: false,
        step1: true,
        step2: true,
        step3: true,
        step4: true,
        step5: true,
    }
    const requiredText = 'Please choose the relevant choice for you';
    const buttonWizard = `button_wizard`;

    const [paidOnly, setPaidOnly] = useState(state.itemCompaign);
    const [activeStep, setActiveStep] = useState(0);
    const [maxStep, setMaxStep] = useState(0);
    const [sliderValue, setSliderValue] = useState(3000);
    const [riskValue, setRiskValue] = useState('');
    const [cashValue, setCashValue] = useState('');
    const [timeframeValue, setTimeframeValue] = useState('');
    const [cashOutValue, setCashOutValue] = useState('');
    const [accreditedValue, setAccreditedValue] = useState('');
    const [displaySpinner, setDisplaySpinner] = useState(false);
    const [openTooltip1, setOpenTooltip1] = useState(false);
    const [openTooltip2, setOpenTooltip2] = useState(false);
    const [openTooltip3, setOpenTooltip3] = useState(false);
    const [openTooltip4, setOpenTooltip4] = useState(false);
    const [openTooltip5, setOpenTooltip5] = useState(false);
    const [openTooltip6, setOpenTooltip6] = useState(false);
    const [required, setRequired] = useState(steps);
    const [nextAction, setNextAction] = useState(false);

    useEffect(() => {
        setMaxStep(Math.max(maxStep, activeStep));
    }, [activeStep]);
    useEffect(() => {
		if (state.itemCompaign) {
			setPaidOnly(isPaidOnly(state.itemCompaign));
		}
    }, [state.itemCompaign]);
    useEffect(() => {
        window.addEventListener("keyup", detectEnter);
        return () =>
          window.removeEventListener("keyup", detectEnter);
    }, []);

    const handleSliderChange = (value) => {
        setSliderValue(value);
    }
    const handleRiskChange = (e, value) => {
        setRiskValue(value);
        setRequired({...required, step1: false});
    }
    const handleCashChange = (e, value) => {
        setCashValue(value);
        setRequired({...required, step2: false});
    }
    const handleTimeframeChange = (e, value) => {
        setTimeframeValue(value);
        setRequired({...required, step3: false});
    }
    const handleCashOutChange = (e, value) => {
        setCashOutValue(value);
        setRequired({...required, step4: false});
    }
    const handleAccreditedChange = (e, value) => {
        setAccreditedValue(value);
        setRequired({...required, step5: false});
    }
    const handleNext = () => {
        if (!required[`step${activeStep}`]) {
            setNextAction(false);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
            setNextAction(true);
        }
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleFinish = () => {
        setActiveStep(0);
        window.scrollTo(0, 0);

        trackEvent('QuizSubmit', 'platform', 'Calculator');
		GASendEvent('QuizSubmit', 'Calculator');
		GASendEvent('QuizData_amount', String(sliderValue));
		GASendEvent('QuizData_risk', riskValue);
		GASendEvent('QuizData_cashpayments', cashValue);
		GASendEvent('QuizData_timeframe', timeframeValue);
		GASendEvent('QuizData_liquidity', cashOutValue);
		GASendEvent('QuizData_accredited', accreditedValue);
		FBSendEvents('QuizData', {
			amount: String(sliderValue),
			risk: riskValue,
			cashpayments: cashValue,
			timeframe: timeframeValue,
			liquidity: cashOutValue,
			accredited: accreditedValue,
        });
        eventTracker("QuizSubmit", 'Calculator', state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
		eventTracker("QuizData_amount", String(sliderValue), state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
		eventTracker("QuizData_risk", riskValue, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
		eventTracker("QuizData_cashpayments", cashValue, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
		eventTracker("QuizData_timeframe",  timeframeValue, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
		eventTracker("QuizData_liquidity", cashOutValue, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
		eventTracker("QuizData_accredited", accreditedValue, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);

        setDisplaySpinner(true);
		getFilterResult()
		.then((data) => {
            let investmentsData = data;

			if (paidOnly) {
				investmentsData = filter(investmentsData, (o) => o.paid);
            }
			let filterredList = filter(investmentsData, (investment) => {
				return investment.minInvestment <= sliderValue;
			});
			filterredList = filter(filterredList, (investment) => {
				return investment.newRisks.includes(riskValue);
            });

            //'Dividends' or 'Interest'
            //'Value'
            //'Dividends + Value' or 'Value + Dividends '
            switch(Number(cashValue)) {
                case 1:
                    filterredList = filter(filterredList, (investment) => {
                        return (
                            investment.howMoney === 'Dividends' ||
                            investment.howMoney === 'Interest'
                        );
                    });
                    break;
                case 2:
                    filterredList = filter(filterredList, (investment) => {
                        return (
                            investment.howMoney === 'Value'
                        );
                    });
                    break;
                case 3:
                    filterredList = filter(filterredList, (investment) => {
                        return (
                            investment.howMoney === 'Dividends + Value'
                        );
                    });
                    break;
                default:
                    filterredList = filter(filterredList, (investment) => {
                        return (
                            investment.howMoney === 'Dividends' ||
                            investment.howMoney === 'Interest'
                        );
                    });
            }
			filterredList = filter(filterredList, (investment) => {
				return investment.newRisks.includes(riskValue);
			});
			filterredList = filter(filterredList, (investment) => {
				return investment.timeframeNumber <= Number(timeframeValue);
            });
			if (Number(cashOutValue) === 1) {
				filterredList = filter(filterredList, (investment) => {
					return (
						investment.liquidity === 'Easy' || investment.liquidity === 'Moderate'
					);
				});
            }
			if (Number(accreditedValue) !== 1) {
				filterredList = filter(filterredList, (investment) => {
					return investment.investors === 'All';
				});
			}
            setDisplaySpinner(false);
            dispatch(actions.setInvestments(filterredList));
            dispatch(actions.setQuizResults(true));
            dispatch(actions.setQuizAnswers(
                generateQuizAnswersObject(
                    String(sliderValue),
                    riskValue,
                    cashValue,
                    timeframeValue,
                    cashOutValue,
                    accreditedValue,
            )));
            onFinish();
		});
    };
    const detectEnter = (event) => {
        if (event.keyCode === 13 && open) {
            event.preventDefault();
            const button = document.getElementById(buttonWizard);
            if (button) {
                button.click();
            }
        }
    };
    const handleTooltip1Close = () => {
        setOpenTooltip1(false);
    };
    const handleToolti1pOpen = () => {
        setOpenTooltip1(true);
    };
    const handleTooltip2Close = () => {
        setOpenTooltip2(false);
    };
    const handleToolti2pOpen = () => {
        setOpenTooltip2(true);
    };
    const handleTooltip3Close = () => {
        setOpenTooltip3(false);
    };
    const handleToolti3pOpen = () => {
        setOpenTooltip3(true);
    };
    const handleTooltip4Close = () => {
        setOpenTooltip4(false);
    };
    const handleToolti4pOpen = () => {
        setOpenTooltip4(true);
    };
    const handleTooltip5Close = () => {
        setOpenTooltip5(false);
    };
    const handleToolti5pOpen = () => {
        setOpenTooltip5(true);
    };
    const handleTooltip6Close = () => {
        setOpenTooltip6(false);
    };
    const handleToolti6pOpen = () => {
        setOpenTooltip6(true);
    };

    return (
        <>
            {displaySpinner ?
                (<center>
                    <Spinner displaySpinner={displaySpinner} />
                </center>) :
                (<div>
                <Stepper
                    variant="progress"
                    steps={6}
                    position="static"
                    activeStep={activeStep}
                    className={classes.root}
                    nextButton={
                    <Button size="large" onClick={handleNext} disabled={(activeStep === 5) || (activeStep >= maxStep)}>
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </Button>
                    }
                    backButton={
                    <Button size="large" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                    </Button>
                    }
                    onFinish={handleFinish}
                />
                <div className={classes.wizard}>
                    <form
                        ref={form}>
                        {(activeStep === 0) &&
                            (
                                <div>
                                    <h4 className={classes.h4}>1. Money</h4>
                                    <ClickAwayListener onClickAway={handleTooltip1Close}>
                                        <Tooltip
                                            //title="Enter your value in US dollars"
                                            title="Enter your value in US dollars"
                                            classes={{ popper: classes.tooltip }}
                                            arrow
                                            onClose={handleTooltip1Close}
                                            open={openTooltip1}
                                            placement="top-start"
                                        >
                                            <h3 className={classes.h3}>
                                                Select Investment Amount
                                                <IconButton
                                                    className={classes.iconButton}
                                                    onClick={handleToolti1pOpen}
                                                    //onMouseOver={handleToolti1pOpen}
                                                    aria-label="Help"
                                                    size="small"
                                                >
                                                    <HelpOutlineIcon />
					                            </IconButton>
                                            </h3>
                                        </Tooltip>
                                    </ClickAwayListener>
                                    <InlineSlider
                                        sliderValue={sliderValue}
                                        handleSliderChange={handleSliderChange}
                                    />
                                </div>
                            )}
                        {(activeStep === 1) &&
                            (
                                <div>
                                    <h4 className={classes.h4}>2. Risk</h4>
                                    <ClickAwayListener onClickAway={handleTooltip2Close}>
                                        <Tooltip
                                            title="Typically, the higher the risk the higher the return but also a higher chance for loss."
                                            classes={{ popper: classes.tooltip }}
                                            arrow
                                            onClose={handleTooltip2Close}
                                            open={openTooltip2}
                                            placement="top-start"
                                        >
                                            <h3 className={classes.h3}>
                                                What level of risk are you comfortable investing at?
                                                <IconButton
                                                    className={classes.iconButton}
                                                    onClick={handleToolti2pOpen}
                                                    //onMouseOver={handleToolti2pOpen}
                                                    aria-label="Help"
                                                    size="small"
                                                >
                                                    <HelpOutlineIcon />
					                            </IconButton>
                                            </h3>
                                        </Tooltip>
                                    </ClickAwayListener>
                                    <RadioGroup aria-label="risk" name="risk" value={riskValue} onChange={handleRiskChange}>
                                        <FormControlLabel
                                            value="1"
                                            inputRef={risk}
                                            classes={{root: classes.radioLabel, checked: classes.checked }}
                                            control={
                                                <StyledRadio
                                                    iconstyle={classes.iconLow}
                                                    checkediconstyle={classes.checkedIconLow}
                                                />
                                            }
                                            label="Low risk"
                                        />
                                        <FormControlLabel
                                            value="2"
                                            inputRef={risk}
                                            className={classes.radioLabel}
                                            control={
                                                <StyledRadio
                                                    iconstyle={classes.iconMedium}
                                                    checkediconstyle={classes.checkedIconMedium}
                                                />
                                            }
                                            label="Moderate risk"
                                        />
                                        <FormControlLabel
                                            value="3"
                                            inputRef={risk}
                                            className={classes.radioLabel}
                                            control={
                                                <StyledRadio
                                                    iconstyle={classes.iconHigh}
                                                    checkediconstyle={classes.checkedIconHigh}
                                                />
                                            }
                                            label="High risk"
                                        />
                                    </RadioGroup>
                                    <div className={`${classes.required} ${required.step1 && nextAction ? classes.visible : classes.hidden}`}>
                                        {requiredText}
                                    </div>
                                </div>
                            )}
                        {(activeStep === 2) &&
                            (
                                <div>
                                    <h4 className={classes.h4}>3. Cash Payments</h4>
                                    <ClickAwayListener onClickAway={handleTooltip3Close}>
                                        <Tooltip
                                                title=""
                                                classes={{ popper: classes.tooltip }}
                                                arrow
                                                open={openTooltip3}
                                                placement="top-start"
                                            >
                                            <h3 className={classes.h3}>
                                                What is your primary investment objective?
                                                <IconButton
                                                    className={classes.iconButton}
                                                    onClick={handleToolti3pOpen}
                                                    aria-label="Help"
                                                    size="small"
                                                >
                                                    <HelpOutlineIcon />
					                            </IconButton>
                                            </h3>
                                        </Tooltip>
                                    </ClickAwayListener>
                                    <RadioGroup aria-label="cash" name="cash" value={cashValue} onChange={handleCashChange}>
                                        <FormControlLabel
                                            value="1"
                                            inputRef={cash}
                                            classes={{
                                                root: classes.radioLabel2,
                                                label: classes.extendedLabel,
                                            }}
                                            control={<Radio color="primary" />}
                                            label="Passive income"
                                        />
                                        <FormControlLabel
                                            value="2"
                                            inputRef={cash}
                                            classes={{
                                                root: classes.radioLabel2,
                                                label: classes.extendedLabel,
                                            }}
                                            control={<Radio color="primary" />}
                                            label="Long term growth"
                                        />
                                        <FormControlLabel
                                            value="3"
                                            inputRef={cash}
                                            classes={{
                                                root: classes.radioLabel2,
                                                label: classes.extendedLabel,
                                            }}
                                            control={<Radio color="primary" />}
                                            label="Balanced"
                                        />
                                    </RadioGroup>
                                    <div className={`${classes.required} ${required.step2 && nextAction ? classes.visible : classes.hidden}`}>
                                        {requiredText}
                                    </div>
                                </div>
                            )}
                        {(activeStep === 3) &&
                            (
                                <div>
                                    <h4 className={classes.h4}>4. Timeframe</h4>
                                    <ClickAwayListener onClickAway={handleTooltip4Close}>
                                        <Tooltip
                                            title="Many investments take time to see returns. The length of time
                                                doesn’t guarantee you’ll see returns, however the longer you’re
                                                willing to invest the more options you will have to invest in."
                                            classes={{ popper: classes.tooltip }}
                                            arrow
                                            open={openTooltip4}
                                            placement="top-start"
                                        >
                                            <h3 className={classes.h3}>
                                                How long are you comfortable investing this money for?
                                                <IconButton
                                                    className={classes.iconButton}
                                                    onClick={handleToolti4pOpen}
                                                    aria-label="Help"
                                                    size="small"
                                                >
                                                    <HelpOutlineIcon />
					                            </IconButton>
                                        </h3>
                                        </Tooltip>
                                    </ClickAwayListener>
                                    <RadioGroup aria-label="timeframe" name="timeframe" value={timeframeValue} onChange={handleTimeframeChange}>
                                        <FormControlLabel
                                            value="12"
                                            inputRef={timeframe}
                                            className={classes.radioLabel}
                                            control={
                                                <StyledRadio
                                                    iconstyle={classes.icon1Year}
                                                    checkediconstyle={classes.checkedIcon1Year}
                                                />
                                            }
                                            label="Less than 1 year"
                                        />
                                        <FormControlLabel
                                            value="60"
                                            inputRef={timeframe}
                                            className={classes.radioLabel}
                                            control={
                                                <StyledRadio
                                                    iconstyle={classes.icon5Years}
                                                    checkediconstyle={classes.checkedIcon5Years}
                                                />
                                            }
                                            label="1-5 years"
                                        />
                                        <FormControlLabel
                                            value="72"
                                            inputRef={timeframe}
                                            className={classes.radioLabel}
                                            control={
                                                <StyledRadio
                                                    iconstyle={classes.icon5PlusYears}
                                                    checkediconstyle={classes.checkedIcon5PlusYears}
                                                />
                                            }
                                            label="5+ years"
                                        />
                                    </RadioGroup>
                                    <div className={`${classes.required} ${required.step3 && nextAction ? classes.visible : classes.hidden}`}>
                                        {requiredText}
                                    </div>
                                </div>
                            )}
                        {(activeStep === 4) &&
                            (
                                <div>
                                    <h4 className={classes.h4}>5. Cash Out / Sell</h4>
                                    <ClickAwayListener onClickAway={handleTooltip5Close}>
                                        <Tooltip
                                            title="This is known as liquidity. For some, it may be important that you
                                                can access the cash you put towards your investment quickly. While
                                                for others, the ability to quickly sell for cash is less important."
                                            classes={{ popper: classes.tooltip }}
                                            arrow
                                            open={openTooltip5}
                                            placement="top-start"
                                        >
                                            <h3 className={classes.h3}>
                                                Is it important that you can sell your investment quickly if you need to?
                                                <IconButton
                                                    className={classes.iconButton}
                                                    onClick={handleToolti5pOpen}
                                                   // onMouseOver={handleToolti5pOpen}
                                                    aria-label="Help"
                                                    size="small"
                                                >
                                                    <HelpOutlineIcon />
					                            </IconButton>
                                            </h3>
                                        </Tooltip>
                                    </ClickAwayListener>
                                    <RadioGroup aria-label="cashout" name="cashout" value={cashOutValue} onChange={handleCashOutChange}>
                                        <FormControlLabel
                                            value="1"
                                            inputRef={cashout}
                                            classes={{
                                                root: classes.radioLabel2,
                                                label: classes.extendedLabel,
                                            }}
                                            control={<Radio color="primary" />}
                                            label="Yes, I need to be able to sell my investment quickly"
                                        />
                                        <FormControlLabel
                                            value="2"
                                            inputRef={cashout}
                                            classes={{
                                                root: classes.radioLabel2,
                                                label: classes.extendedLabel,
                                            }}
                                            control={<Radio color="primary" />}
                                            label="No, I won’t need to sell my investment"
                                        />
                                    </RadioGroup>
                                    <div className={`${classes.required} ${required.step4 && nextAction ? classes.visible : classes.hidden}`}>
                                        {requiredText}
                                    </div>
                                </div>
                            )}
                        {(activeStep === 5) &&
                            (
                                <div>
                                    <h4 className={classes.h4}>6. Accredited</h4>
                                    <ClickAwayListener onClickAway={handleTooltip6Close}>
                                        <Tooltip
                                            title="To be considered an accredited investor, one must have a net worth
                                                of at least $1,000,000, excluding the value of one's primary
                                                residence, or have income at least $200,000 each year for the last
                                                two years (or $300,000 combined income if married)"
                                            classes={{ popper: classes.tooltip }}
                                            arrow
                                            open={openTooltip6}
                                            placement="top-start"
                                        >
                                            <h3 className={classes.h3}>
                                                Are you an accredited investor?
                                                <IconButton
                                                    className={classes.iconButton}
                                                    onClick={handleToolti6pOpen}
                                                    //onMouseOver={handleToolti6pOpen}
                                                    aria-label="Help"
                                                    size="small"
                                                >
                                                    <HelpOutlineIcon />
					                            </IconButton>
                                            </h3>
                                        </Tooltip>
                                    </ClickAwayListener>
                                    <RadioGroup aria-label="accredited" name="accredited" value={accreditedValue} onChange={handleAccreditedChange}>
                                        <FormControlLabel
                                            value="1"
                                            inputRef={accredited}
                                            className={classes.radioLabel2}
                                            control={<Radio color="primary" />}
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            value="2"
                                            inputRef={accredited}
                                            className={classes.radioLabel2}
                                            control={<Radio color="primary" />}
                                            label="No"
                                        />
                                        <FormControlLabel
                                            value="3"
                                            inputRef={accredited}
                                            className={classes.radioLabel2}
                                            control={<Radio color="primary" />}
                                            label="I don't know"
                                        />
                                    </RadioGroup>
                                    <div className={`${classes.required} ${required.step5 && nextAction ? classes.visible : classes.hidden}`}>
                                        {requiredText}
                                    </div>
                                </div>
                            )}
                    </form>
                </div>
                <div className={classes.nextButtons}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        disabled={(activeStep === 5) && required.step5}
                        id={buttonWizard}
                    >
                        {(activeStep === 5) ? 'Get results' : 'Next'}
                    </Button>
                    <div className={classes.enterText}>
                        or press Enter
                    </div>
                    <img
                        src={EnterIcon}
                        alt='enter'
                    />
                </div>
            </div>
            )}
        </>
    );
};
