import React, { useEffect, useState, useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import { firstNameAndLastNameCombination } from '../../utils';
import { BumpButton } from './BumpButton';
import { CommentsButton } from './CommentsButton';
import { ReplyButton } from './ReplyButton';
import { ShareButton } from './ShareButton';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});
const useStyles = makeStyles((theme) => ({
    postsAction: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '12px',
    },
    postsActionLeft: {
        display: 'flex',
        alignItems: 'center',
    },
    postsActionRight: {
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center',
    },
}));
export const PostActions = (props) => {
    const {
		likes,
        id,
        replies,
        loggedIn,
        displayName,
        handleCommentClick,
        handleReplyClick,
        showComments,
        showShare,
        index,
        isBumped,
        modal,
        postLoading,
	} = props;
    const classes = useStyles();

	return (
        <div className={`${classes.postsAction}`}>
            <div className={`${classes.postsActionLeft}`}>
                <BumpButton
                    isBumped={isBumped}
                    counter={likes}
                    postId={id}
                    index={index}
                />
                {(showShare) &&
                    <ShareButton
                        postId={id}
                        modal={modal}
                    />}
            </div>
            <div className={`${classes.postsActionRight}`}>
                {(showComments) &&
                    <CommentsButton
                        replies={replies}
                        handleCommentClick={handleCommentClick}
                        postId={id}
                        modal={modal}
                        postLoading={postLoading}
                    />}
                <ReplyButton
                    handleReplyClick={handleReplyClick}
                    postId={id}
                    loggedIn={loggedIn}
                    userName={firstNameAndLastNameCombination(displayName)}
                    modal={modal}
                />
            </div>
        </div>
	);
};
