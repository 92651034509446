import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowDown';
import Header from '../../InvestmentPlatform/Header';
import { generateSubId } from '../../../utils';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles((theme) => ({
	investCard: {
		'&:hover  $expandIcon': {
			display: 'block',
        },
        position: 'relative',
        width: '96%',
        padding: '4px 0 0px 40px',
        marginRight: '70px',
        marginBottom: '12px',
        [theme.breakpoints.down('770')]: {
            width: '100%',
        },
    },
	container: {
	},
	headerContainer: {
		display: 'flex',
	},
	headerTitle: {
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '16px',
		lineHeight: '24px',
		color: '#30333E',
		margin: '20px',
	},
	headerClose: {
		marginLeft: 'auto',
	},
	bodyContainer: {
		display: 'flex',
		alignItems: 'flex-end',
		flexDirection: 'row',
		padding: '0 0 32px 20px',
		[theme.breakpoints.down('1460')]: {
			display: 'block',
		},
		[theme.breakpoints.down('530')]: {
			display: 'flex',
			justifyСontent: 'center',
			flexDirection: 'column',
			alignItems: 'center',
		},
	},
	formControl: {
		minWidth: 220,
		background: '#FFFFFF',
		borderRadius: '8px',
		[theme.breakpoints.down('530')]: {
			maxWidth: '400px',
			width: '100%',
		},
	},
	selectEmpty: {
		padding: 0,
		height: '48px',
	},
	inputTitle: {
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '14px',
		lineHeight: '20px',
		color: '#73757A',
		marginBottom: '8px',
	},
	bodyItems: {
		marginRight: '20px',
		[theme.breakpoints.down('1460')]: {
			marginBottom: '20px',
			float: 'left',
		},
		[theme.breakpoints.down('530')]: {
			marginBottom: '20px',
			clear: 'left',
			maxWidth: '400px',
			width: '100%',
		},
	},
	bodyItemsButton: {
		marginRight: '20px',
		[theme.breakpoints.down('1460')]: {
			marginBottom: '20px',
			clear: 'left',
		},
		[theme.breakpoints.down('530')]: {
			marginBottom: '20px',
			clear: 'left',
			maxWidth: '400px',
			width: '100%',
        },
	},
	buttonContainer: {
        [theme.breakpoints.down('530')]: {
			textAlign: 'center',
			margin: '0',
        },
    },
    button: {
        width: '128px',
        height: '48px',
		background: '#5056E7',
		'&:hover': {
			backgroundColor: '#646AF0',
		},
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
		cursor: 'pointer',
		[theme.breakpoints.down('1460')]: {
			width: '220px',
		},
		[theme.breakpoints.down('530')]: {
			maxWidth: '400px',
			width: '100%',
			marginTop: '10px',
        },
    },
    buttonText: {
        fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 500,
		fontSize: '16px',
		lineHeight: '24px',
        color: '#FFFFFF',
	},
	disabled: {
		opacity: '0.4',
		cursor: 'default',
	},
	moreButton: {
		textTransform: 'none',
		color: '#73757A',
		fontFamily: 'Inter',
		fontSize: '14px',
    },
	card: {
		'&:hover': { cursor: 'pointer' },
	},
	cardAction: {
		borderTop: '1px solid #F2F5F8',
		padding: '0px',
    },
    premiumCard: {
		border: '1px solid #F3B81F',
	},
	expandIcon: {
		position: 'absolute',
		top: '12px',
		right: '12px',
		background: '#5056E7',
		'&:hover': {
			backgroundColor: '#646AF0',
		},
		color: '#fff',
		borderRadius: '100px',
		textTransform: 'none',
		padding: '6px 16px',
	},
	[theme.breakpoints.down('1495')]: {
		investCard: {
			padding: '4px 0 24px 0px',
			marginRight: '0',
		},
	},
}));

export const CollapsedCard = (props) => {
    const {
        fullPlatformUrl,
        investment,
        stopPropagation,
        handleCardClick,
        itemSource,
        itemMedium,
    } = props;

	const classes = useStyles();

	return (
        <Card
            className={`mainCard ${classes.card} ${
                investment.premium > 0 ? classes.premiumCard : null
            }`}
            onClick={() => {handleCardClick(investment.id, investment)}}
        >
            <Header
                investmentPlatform={investment}
                platformUrl={fullPlatformUrl}
            />
            <CardActions className={classes.cardAction}>
                <Button
                    endIcon={<KeyboardArrowRightIcon />}
                    fullWidth={true}
                    className={classes.moreButton}
                >
                    See details
                </Button>
            </CardActions>
            <Button
                className={classes.expandIcon}
                target="_blank"
                rel="noopener noreferrer"
                href={
                    investment.website
                        ? investment.website.replace('%SUBID%', generateSubId(itemSource, itemMedium))
                        : '/discover'
                }
                onClick={(e) => {stopPropagation(e, investment)}}
            >
                More
            </Button>
        </Card>
	);
};
