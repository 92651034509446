import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Header from './Header';
import { BodyPlatform } from './BodyPlatform';
import { HeaderBodyMobile } from '../InvestmentPlatform/HeaderBodyMobile';
import ClosableModal from '../ClosableModal';
import CreateReviewForm from './CreateReviewForm';
import { createReview } from '../../Services/ReviewsAPI';
import { getDiscoveryPageName, getFullPlatformUrl } from '../../utils';
import { HelmetCard } from '../Helmet/HelmetCard';

const useStyles = makeStyles((theme) => ({
	mainModal: {
		paddingBottom: '56px',
	},
	premium: {
		border: '1px solid #F3B81F',
	},

	createReviewModal: {
		maxWidth: '553px',
		width: '100%',
		backgroundColor: '#FFF',
	},
	innerCards: {
		display: 'flex',
		alignItems: 'flex-start',
		padding: '0 8px 0 62px',
	},
	innerSimilarCards: {
		display: 'flex',
		alignItems: 'flex-start',
		padding: '0 0 0 62px',
		width: '70%',
	},
	innerCard: {
		border: '1px solid #F2F5F8',
		borderRadius: '8px',
		backgroundColor: '#fff',
		padding: 0,
		margin: '0 8px',

		'& .MuiCardContent-root:last-child': {
			padding: '0 !important',
		},
	},
	tabsCard: {
		width: '70%',
	},
	similarHeader: {
		fontWeight: '500',
		fontSize: '16px',
		lineHeight: '24px',
		marginLeft: '1em',
		padding: '0 8px 0 62px',
	},
	sidebarCard: {
		width: '30%',
		padding: '24px',
	},
	[theme.breakpoints.down('sm')]: {
		innerCards: {
			flexDirection: 'column',
			padding: '0 16px',
		},
		innerCard: {
			margin: '0 0 20px 0',
		},
		innerSimilarCards: { width: '100%', flexDirection: 'column', padding: '0 16px' },
		tabsCard: { width: '100%' },
		sidebarCard: { width: '100%' },
	},
	cardContainerDesktop: {
		[theme.breakpoints.up('750')]: {
			display: 'inherit',
		},
		[theme.breakpoints.down('750')]: {
			display: 'none',
		},
	},
	cardContainerMobile: {
		[theme.breakpoints.up('750')]: {
			display: 'none',
		},
		[theme.breakpoints.down('750')]: {
			display: 'inherit',
		},
	},
}));

const modals = {
	MAIN: 'main',
	CREATE_REVIEW: 'create_review',
};

export default function InvestmentPlatformModal({
	investmentPlatform,
	similarInvestments,
	onClose,
	embed,
	expandable,
	resetTotal,
	search,
}) {
	const classes = useStyles();
	const [visibleModal, setVisibleModal] = React.useState(modals.MAIN);
	const [isWaitingForReview, setIsWaitingForReview] = React.useState(false);

	const openCreateReviewForm = () => setVisibleModal(modals.CREATE_REVIEW);
	const openMainModal = () => setVisibleModal(modals.MAIN);

	const platformUrl = getDiscoveryPageName(expandable, investmentPlatform.slug);
	const fullPlatformUrl = getFullPlatformUrl(`${platformUrl}`);

	const onCreateReviewFormSubmit = async (data) => {
		setIsWaitingForReview(true);

		await createReview(investmentPlatform.id, {
			reviewerName: data.name,
			reviewerEmail: data.email,
			isVerified: data.isInvesting,
			comment: data.comment,
			rating: data.rating,
		});

		setIsWaitingForReview(false);
		setVisibleModal(modals.MAIN);
	};

	return (
		<React.Fragment>
			<HelmetCard
                title={`${investmentPlatform.name} - MoneyMade.io`}
                desc={investmentPlatform.description}
            />
			<ClosableModal
				className={`${classes.mainModal} ${
					investmentPlatform.premium > 0 ? classes.premium : null
				}`}
				open={visibleModal === modals.MAIN}
				onClose={onClose}
				embed={embed}
			>
				{' '}
				<div id="modal" />
				<div className={classes.cardContainerDesktop}>
					<Header
						investmentPlatform={investmentPlatform}
						platformUrl={fullPlatformUrl}
						expandable={expandable}
						expanded={true}
					/>
					<BodyPlatform
						investmentPlatform={investmentPlatform}
						similarInvestments={similarInvestments}
						embed={embed}
						cluster={true}
						openCreateReviewForm={openCreateReviewForm}
						resetTotal={resetTotal}
						search={search}
						expandable={expandable}
					/>
				</div>
				<div className={classes.cardContainerMobile}>
					<HeaderBodyMobile
						investmentPlatform={investmentPlatform}
						platformUrl={fullPlatformUrl}
						expandable={expandable}
						expanded={true}
						similarInvestments={similarInvestments}
						embed={embed}
						cluster={true}
						openCreateReviewForm={openCreateReviewForm}
						modal={true}
						resetTotal={resetTotal}
						search={search}
					/>
				</div>
			</ClosableModal>

			<ClosableModal
				className={classes.createReviewModal}
				open={visibleModal === modals.CREATE_REVIEW}
				onClose={openMainModal}
			>
				<CreateReviewForm
					isLoading={isWaitingForReview}
					investmentPlatform={investmentPlatform}
					onSubmit={onCreateReviewFormSubmit}
				/>
			</ClosableModal>
		</React.Fragment>
	);
}
