import React, { useEffect, useState, useRef } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import { useSelector, shallowEqual } from "react-redux";
import { trackEvent, isPaidOnly, checkPhoneNumber, eventTracker, filterDigits, removeCountryCode } from '../../utils';
import { AppBarComponent } from '../AppBar/AppBarComponent';
import Grid from '@material-ui/core/Grid';
import iphoneCircle from '../../img/iphoneCircle1.png';
import IconInvestment from '../../img/IconInvestment.svg';
import IconShortSweet from '../../img/IconShortSweet.svg';
import IconDiversification from '../../img/IconDiversification.svg';
import { TextField, Button } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { isMobile } from 'react-device-detect';
import { Spinner } from '../Spinner/Spinner';
import { sendSubscribeTipsData } from '../../Services/SubscribeTipsAPI';
import InputMask from "react-input-mask";
import { ModalSignUp } from '../Modal/ModalSignUp';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const CustomCheckbox = withStyles({
	root: {
		color: '#DBE0E6',
		'&$checked': {
			color: '#5056e7',
		},
	},
	checked: {},
})((props) => <Checkbox color="default" {...props} />);
const CustomTextField = withStyles((theme) => ({
	root: {
        width: '100%',
        [theme.breakpoints.up('1100')]: {
            maxWidth: '360px',
        },
        [theme.breakpoints.down('1100')]: {
            maxWidth: '300px',
        },
        [theme.breakpoints.down('1000')]: {
            maxWidth: '280px',
        },
        [theme.breakpoints.down('850')]: {
            maxWidth: '360px',
        },
        [theme.breakpoints.down('560')]: {
            maxWidth: '300px',
        },
        [theme.breakpoints.down('520')]: {
            maxWidth: '280px',
        },
        [theme.breakpoints.down('500')]: {
            maxWidth: '360px',
        },
    },
}))(TextField);
const CustomButton = withStyles((theme) => ({
	root: {
        height: '40px',
        marginLeft: '10px',
        marginTop: '10px',
        [theme.breakpoints.down('500')]: {
            maxWidth: '360px',
            width: '100%',
            marginBottom: '10px',
            marginLeft: '0px',
        },
	},
}))(Button);

const TextMaskCustom = (props) => {
    const { ...other } = props;
    return (
        <InputMask
            alwaysShowMask={false}
            mask="+1 (999) 999-9999"
            maskChar=" "
            {...other}
        />
    );
}
const useStyles = makeStyles((theme) => ({
	appBar: {
		[theme.breakpoints.up('sm')]: {
			zIndex: 9999,
		},
	},
	toolbar: {
        marginTop: '100px',
        [theme.breakpoints.down('500')]: {
			marginTop: '60px',
        },
    },
    container: {
        maxWidth: '1130px',
        width: '100%',
        margin: '0 auto',
        justifyContent: 'center',
        [theme.breakpoints.up('850')]: {
			display: 'flex',
        },
        [theme.breakpoints.down('850')]: {
			display: 'block',
		},
    },
    item1: {
        [theme.breakpoints.down('850')]: {
			maxWidth: '100%',
        },
    },
    item2: {
        [theme.breakpoints.down('850')]: {
			maxWidth: '100%',
        },
    },
    containerImage: {
        margin: 'auto',
        textAlign: 'left',
        [theme.breakpoints.down('850')]: {
			textAlign: 'center',
        },
    },
    image: {
        background: '#fbfcfd',
        [theme.breakpoints.up('1100')]: {
			width: '462px',
        },
        [theme.breakpoints.down('1100')]: {
			width: '440px',
        },
        [theme.breakpoints.down('1000')]: {
			width: '390px',
        },
        [theme.breakpoints.down('1000')]: {
			width: '380px',
        },
        [theme.breakpoints.down('900')]: {
			width: '370px',
        },
        [theme.breakpoints.down('850')]: {
			width: '360px',
        },
        [theme.breakpoints.down('500')]: {
			width: '278px',
        },
        [theme.breakpoints.down('340')]: {
			width: '250px',
        },
    },
    containerText: {
        maxWidth: '500px',
        [theme.breakpoints.up('850')]: {
			marginTop: '100px',
        },
        [theme.breakpoints.down('1100')]: {
			marginTop: '90px',
        },
        [theme.breakpoints.down('1000')]: {
			marginTop: '80px',
        },
        [theme.breakpoints.down('1000')]: {
			marginTop: '70px',
        },
        [theme.breakpoints.down('900')]: {
			marginTop: '60px',
        },
        [theme.breakpoints.down('850')]: {
            marginTop: '58px',
            margin: 'auto',
        },
        [theme.breakpoints.down('560')]: {
            padding: '30px',
            marginTop: '0px',
        },
        [theme.breakpoints.down('400')]: {
            padding: '20px',
        },
    },
    title: {
        fontFamily: 'Red Hat Display',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '44px',
        lineHeight: '56px',
        color: '#000',
        marginBottom: '16px',
        [theme.breakpoints.down('500')]: {
            textAlign: 'center',
            fontSize: '32px',
            lineHeight: '44px',
        },
    },
    description1: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '20px',
        lineHeight: '32px',
        color: '#73757A',
        marginBottom: '20px',
        [theme.breakpoints.down('500')]: {
            textAlign: 'center',
        },
    },
    description2: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#000',
        marginBottom: '32px',
        [theme.breakpoints.down('500')]: {
            textAlign: 'center',
        },
    },
    subscribeContainer: {
        position: 'relative',
        [theme.breakpoints.down('500')]: {
            textAlign: 'center',
        },
    },
    agreementContainer: {
        display: 'block',
        alignItems: 'center',
        marginBottom: '32px',
    },
    agreementText: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#73757A',
    },
    bottomContainer: {
        display: 'flex',
        background: '#FFFFFF',
        border: '1px solid #F2F5F8',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
        borderRadius: '8px',
        marginTop: '130px',
        marginBottom: '130px',
        maxWidth: '1130px',
        width: '100%',
        margin: '0 auto',
        [theme.breakpoints.up('1160')]: {
			padding: '56px 40px 52px 40px',
		},
        [theme.breakpoints.down('1160')]: {
			padding: '56px 20px 52px 20px',
        },
        [theme.breakpoints.down('1120')]: {
			padding: '56px 10px 52px 10px',
        },
        [theme.breakpoints.down('850')]: {
            padding: 0,
            marginTop: '100px',
            marginBottom: '100px',
            border: 0,
            background: "none",
            display: 'block',
            boxSizing: 'none',
            boxShadow: 'none',
            borderRadius: 0,
        },
    },
    bottomItem: {
        width: '33.33vw',
        margin: '0 40px 0 40px',
        [theme.breakpoints.up('1120')]: {
            margin: '0 40px 0 40px',
        },
        [theme.breakpoints.down('1120')]: {
            margin: '0 20px 0 20px',
        },
        [theme.breakpoints.down('880')]: {
            margin: '0 10px 0 10px',
        },
        [theme.breakpoints.down('850')]: {
            maxWidth: '510px',
            width: '100%',
            padding: '56px 40px 52px 40px',
            margin: 'auto',
            background: '#FFFFFF',
            border: '1px solid #F2F5F8',
            boxSizing: 'border-box',
            boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
            borderRadius: '8px',
            marginBottom: '44px',
        },
    },
    bottomImageContainer: {
        position: 'relative',
    },
    bottomImage: {
        textAlign: 'center',
        bottom: '0px',
        position: 'absolute',
        left: '30%',
        [theme.breakpoints.down('850')]: {
            left: '38%',
        },
        [theme.breakpoints.down('500')]: {
            left: '35%',
        },
        [theme.breakpoints.down('400')]: {
            left: '30%',
        },
        [theme.breakpoints.down('320')]: {
            left: '25%',
        },
    },
    bottomTitle: {
        fontFamily: 'Red Hat Display',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '24px',
        lineHeight: '36px',
        textAlign: 'center',
        color: '#000000',
        marginBottom: '16px',
    },
    bottomDescription: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center',
        color: '#000000',
    },
    success: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '20px',
        lineHeight: '32px',
        color: 'rgb(80, 86, 231)',
        marginBottom: '20px',
        [theme.breakpoints.down('500')]: {
            textAlign: 'center',
        },
    }
}));
export const Tips = (props) => {
    const { utmParams, expandable, expandableBannerShow } = props;
    const classes = useStyles();
    const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
    const [paidOnly, setPaidOnly] = useState(isPaidOnly(state.itemCompaign));
    const [medium, setMedium] = useState(state.itemMedium);
    const [email, setEmail] = useState("");
    const [showMenu, setshowMenu] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [displaySpinner, setDisplaySpinner] = useState(false);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [number, setNumber] = useState('');
    const [checked, setChecked] = useState(false);
    const [failedNumber, setFailedNumber] = useState(false);
    const [sendingNumber, setSendingNumber] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [openModalSignUp, setOpenModalSignUp] = useState(state.modalSignUpOpen);

    const error = "Wrong phone number format";
    const success = "Thank you! You are now subscribed";

    const input = useRef();
    useEffect(() => {
		if (state.modalSignUpOpen) {
			setOpenModalSignUp(true);
		} else {
			setOpenModalSignUp(false);
		}
	}, [state.modalSignUpOpen]);
    useEffect(() => {
        window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		if (state.itemCompaign) {
			setPaidOnly(isPaidOnly(state.itemCompaign));
		}
    });
    useEffect(() => {
		if (state.itemMedium) {
			setMedium(state.itemMedium);
		}
    }, [state.itemMedium]);
    const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
    };
    const handleTextChange = (event) => {
		setNumber(event.target.value);
    };
    const handleCheckboxChange = (event) => {
		setChecked(event.target.checked);
    };
    const handleSubmit = (event) => {
        if(!checkPhoneNumber(removeCountryCode(number))) {
            setFailedNumber(true);
            setSendingNumber(false);
            setErrorText(error);
        } else {
            setFailedNumber(false);
            setSendingNumber(true);
            setErrorText("");
        }
    }

    useEffect(() => {
        if(!failedNumber && sendingNumber) {
            setDisplaySpinner(true);
            trackEvent('User', 'SubcribeNumber', 'SubcribeNumber');
            eventTracker("User", 'SubcribeNumber', state.itemSource, state.itemMedium, state.itemCompaign, state.userId);

            const userId = state.userId;
            const filteredNumber = filterDigits(removeCountryCode(number));
            const accredited = checked ? true : false;
            sendSubscribeTipsData(email, filteredNumber, userId, accredited)
            .then((data) => {
                setDisplaySpinner(false);
                setSendingNumber(false);
                if (data.status === "OK") {
                    setShowMessage(true);
                    setFailedNumber(false);
                } else {
                    setFailedNumber(true);
                    setErrorText(data.status || error);
                }
            }).catch((error) => {
                setErrorText(error);
            });
        }
    }, [failedNumber, sendingNumber]);

	return (
        <div className={`respCont ${classes.root}`}>
            <CssBaseline />
            <AppBarComponent
                utmParams={utmParams}
                medium={medium}
                showMenu={showMenu}
                paidOnly={paidOnly}
                expandable={expandable}
                handleDrawerToggle={handleDrawerToggle}
                expandableBannerShow={expandableBannerShow}
            />
            <main className={`${classes.content} mainPrinc`}>
				<div className={classes.toolbar} />
                <Grid className={classes.container}>
                    <Grid item xs={6} lg={6} className={classes.item1}>
                        <div className={classes.containerImage}>
                            <img
                                src={iphoneCircle}
                                className={classes.image}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6} lg={6} className={classes.item2}>
                        <div className={classes.containerText}>
                            <div className={classes.title}>
                                Do more with your money
                            </div>
                            <div className={classes.description1}>
                                Simple. Smart. Investing. For Everyone.
                            </div>
                            <div className={classes.description2}>
                                Get a personalized investment opportunity sent to you by text each week. The quickest way to learn about new investment platforms to help you grow your wealth.
                            </div>
                            {displaySpinner ?
                                <center>
                                    <Spinner displaySpinner={displaySpinner} />
                                </center> :
                                showMessage ? (
                                    <div className={classes.success}>
                                        {success}
                                    </div>) :
                                    <>
                                        <div id='SUBSCRIBE_ID' className={classes.subscribeContainer}>
                                            <CustomTextField
                                                name="SUBSCRIBE"
                                                className="mailInput"
                                                placeholder="+1 (406) 555-0120"
                                                variant="outlined"
                                                onChange={handleTextChange}
                                                value={number}
                                                error={failedNumber}
                                                InputProps={{
                                                    inputComponent: TextMaskCustom,
                                                }}
                                                inputRef={input}
                                                type={isMobile ? "tel" : "text"}
                                                helperText={errorText}
                                            />
                                            <CustomButton
                                                type="submit"
                                                className={classes.button}
                                                variant="contained"
                                                color="primary"
                                                onClick={handleSubmit}
                                            >
                                                Subscribe
                                            </CustomButton>
                                        </div>
                                        <div className={classes.agreementContainer}>
                                            <CustomCheckbox
                                                checked={checked}
                                                onChange={handleCheckboxChange}
                                            />
                                            <span className={classes.agreementText}>if Accredited</span>
                                        </div>
                                    </>
                            }
                        </div>
                    </Grid>
			    </Grid>
                <div className={classes.bottomContainer}>
                    <div className={classes.bottomItem}>
                        <div className={classes.bottomImageContainer}>
                            <img src={IconInvestment} className={classes.bottomImage} />
                        </div>
                        <div className={classes.bottomTitle}>
                            Investment focussed
                        </div>
                        <div className={classes.bottomDescription}>
                            We send a text a week on an investment opportunity you may never have heard of. Where are you going to invest next?
                        </div>
                    </div>
                    <div className={classes.bottomItem}>
                        <div className={classes.bottomImageContainer}>
                            <img src={IconShortSweet} className={classes.bottomImage} />
                        </div>
                        <div className={classes.bottomTitle}>
                            Short and sweet
                        </div>
                        <div className={classes.bottomDescription}>
                            We highlight the key points so you can quickly understand what the opportunity is and if it works for you.
                        </div>
                    </div>
                    <div className={classes.bottomItem}>
                        <div className={classes.bottomImageContainer}>
                            <img src={IconDiversification} className={classes.bottomImage} />
                        </div>
                        <div className={classes.bottomTitle}>
                            Diversification
                        </div>
                        <div className={classes.bottomDescription}>
                            We share a differnet investment category each week to help you diversify your investments.
                        </div>
                    </div>
                </div>
            </main>
            <ModalSignUp
				open={openModalSignUp}
			/>
        </div>
	);
};
