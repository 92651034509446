import React, { useEffect, useState, useCallback, useRef } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import Skeleton from '@material-ui/lab/Skeleton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Button } from '@material-ui/core';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
	isPaidOnly,
	paste,
	detectPlatform,
	detectActiveStar,
	convertToAutocompleteObject,
	detectRouteUrl,
	updateStream,
	getLastId,
	getFirstId,
	isStreamUrl,
	getTribute,
	addObserver,
	isValueinArray,
	isValuesinArray,
	toggleActviePostClass,
	updatePosts,
	openPostOrHide,
	detectPlatformById,
	detectPlatformBySlug,
} from '../../utils';
import { AppBarComponent } from '../AppBar/AppBarComponent';
import starOutline from '../../img/dashboard/starOutline.svg';
import star from '../../img/dashboard/star.svg';
import { ModalSignUp } from '../Modal/ModalSignUp';
import * as actions from '../../redux/actions';
import { getFavorite } from '../../Services/GetFavoriteAPI';
import { addFavorite } from '../../Services/AddFavoriteAPI';
import { deleteFavorite } from '../../Services/DeleteFavoriteAPI';
import { checkUser } from '../../Services/CheckUserAPI';
import { getMentions } from '../../Services/MetionsAPI';
import { getStream } from '../../Services/GetStreamAPI';
import { getLatestStream } from '../../Services/GetLatestStreamAPI';
import { getUserLikes } from '../../Services/GetUserLikesAPI';
import { TextWithTags } from './TextWithTags';
import { SkeletonPost } from './SkeletonPost';
import { SkeletonMakePost } from './SkeletonMakePost';
import { ResultCard } from './ResultCard';
import { MakePostCard } from './MakePostCard';
import { AutocompleteCard } from './AutocompleteCard';
import { PostHeader } from './PostHeader';
import { PostActions } from './PostActions';
import { NewPostsButton } from '../Buttons/NewPostsButton';
import { routeNames } from '../../data/Data';
import { useTimeout } from '../../hooks/useTimeout';
import { OnePostModalRoute } from './OnePostModalRoute';
import { BackToButton } from '../Buttons/BackToButton';
//import { RepliesComments } from './RepliesComments';
import { loginUser, checkAuth, getToken } from '../../Firebase/firebase-service';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});
const useStyles = makeStyles((theme) => ({
	toolbar: theme.mixins.toolbar,
	toolbar4Banner: {
		minHeight: 50,
		[theme.breakpoints.up('xs')]: {
			minHeight: 50,
		},
		[theme.breakpoints.up('sm')]: {
			minHeight: 50,
		},
	},
	container: {
		maxWidth: '800px',
		width: '100%',
		margin: '0 auto',
	},
	card: {
		position: 'relative',
		background: '#FFFFFF',
		border: '1px solid #F2F5F8',
		boxSizing: 'border-box',
		boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
		borderRadius: '8px',
		padding: '18px',
		[theme.breakpoints.down('460')]: {
			padding: '12px',
		},
	},
	topCard: {
		marginTop: '32px',
		marginBottom: '20px',
	},
	searchContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	backButton: {
		fontSize: '14px',
		lineHeight: '20px',
		color: '#73757A',
		textTransform: 'none',
		marginBottom: '24px',
	},
	title: {
		fontFamily: 'Red Hat Display',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '28px',
		lineHeight: '40px',
		color: '#30333E',
		marginLeft: '12px',
		[theme.breakpoints.down('500')]: {
			textAlign: 'center',
			fontSize: '32px',
			lineHeight: '44px',
		},
	},
	favorites: {
		display: 'flex',
		alignItems: 'center',
		background: '#F2F5F8',
		border: '1px solid #DBE0E6',
		boxSizing: 'border-box',
		borderRadius: '100px',
		height: '32px',
		cursor: 'pointer',
		paddingRight: '10px',
	},
	favoritesText: {
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '12px',
		lineHeight: '20px',
		color: '#30333E',
		paddingRight: '4px',
		paddingLeft: '4px',
	},
	hl: {
		width: '744px',
		maxWidth: '100%',
		height: '1px',
		background: '#F2F5F8',
		marginTop: '0px',
		marginBottom: '0px',
	},
	marginStyle: {
		marginTop: '18px',
	},
	hidden: {
		display: 'none',
	},
	postsContainer: {
		//marginBottom: '10px',
		padding: '18px 10px',
	},
	grey: {
		//background: '#fafbfe',
	},
	commentsContainer: {
		//marginBottom: '10px',
		marginLeft: '40px',
	},
	like: {
		marginLeft: '8px',
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 500,
		fontSize: '14px',
		lineHeight: '20px',
		color: '#73757A',
		cursor: 'pointer',
	},
	sticky: {
		display: 'none',
	},
	hide: {
		display: 'none',
	},
	show: {
		display: 'inherit',
	},
}));
export const StreamPage = (props) => {
	const { utmParams, expandable, expandableBannerShow } = props;
	const classes = useStyles();
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const dispatch = useDispatch();
	const history = useHistory();
	const { url: currentUrl } = useRouteMatch();
	const match = useRouteMatch(`${currentUrl}/:platformId`);

	const [paidOnly, setPaidOnly] = useState(isPaidOnly(state.itemCompaign));
	const [medium, setMedium] = useState(state.itemMedium);
	const [showMenu, setshowMenu] = useState(false);
	const [displaySpinner, setDisplaySpinner] = useState(false);
	const [mobileOpen, setMobileOpen] = useState(false);
	const [openModalSignUp, setOpenModalSignUp] = useState(state.modalSignUpOpen);
	const [investmentTitles, setInvestmentTitles] = useState([]);
	const [value, setValue] = useState('');
	const [investment, setInvestment] = useState(null);
	const [favoritesInvestments, setFavoritesInvestments] = useState(
		state.favoritesInvestments
	);
	const [starActive, setStarOutline] = useState(false);
	const [mentions, setMentions] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [stream, setStream] = useState([]);
	const [streaming, setStreaming] = useState(false);
	const [addingComment, setAddingComment] = useState(false);
	const [individualStream, setIndividualStream] = useState(false);
	const [individualStreamName, setIndividualStreamName] = useState('');
	const [userNameReply, setUserNameReply] = useState('');
	const [postId, setPostId] = useState('');
	const [replying, setReplying] = useState(false);
	const [replyed, setReplyed] = useState(false);
	const [replyResult, setReplyResult] = useState({});
	const [userLikes, setUserLikes] = useState([]);
	const [noResults, setNoResults] = useState(false);
	const [loadByRoute, setLoadByRoute] = useState(false);
	const [firstId, setFirstId] = useState(null);
	const [newPosts, setNewPosts] = useState([]);
	const [postLoading, setPostLoading] = useState(null);

	let posts = [];
	const sharePostId = `share-post-id`;
	const limitId = `limitId`;
	const lastReplyId = `lastReplyId`;
	const lastPostedReplyId = `lastPostedReplyId`;
	const commentArea = `commentArea`;
	const replyArea = `replyArea`;
	const containerId = `container_`;
	const buttonId = `button_stream`;
	const stickyButtonId = `stickyButton_stream`;
	const buttonLimitId = `buttonLimitId_stickyButton_stream`;
	const delay = 45000;

	const [timerOn, setTimerOn] = useState(false);
	const { reset } = useTimeout(getNewPostsAsync, delay, timerOn);
	const startTimer = () => setTimerOn(true);
	const stopTimer = () => setTimerOn(false);

	// get stream
	useEffect(() => {
		if (streaming) {
			if (detectRouteUrl(match)) {
				return;
			}
			getCorrectStreamAsync();
		}
	}, [streaming]);

	// get mentions
	useEffect(() => {
		if (investment !== null) {
			setIsLoading(true);
			getMentionsAsync(investment);
		}
	}, [investment]);

	// setup tag autocomplete
	useEffect(() => {
		if (investmentTitles.length > 0) {
			attachTributeToElement(investmentTitles, sharePostId);
			setLoadByRoute(true);
		}
	}, [investmentTitles]);

	// get stream by url matching
	useEffect(() => {
		if (loadByRoute) {
			if (detectRoute()) {
				handleRoute();
			} else {
				getCorrectStreamAsync();
			}
		}
	}, [loadByRoute]);

	// get user likes
	useEffect(() => {
		if (state.loggedIn) {
			getUserLikesAsync();
		}
	}, [state.loggedIn]);

	// get favorites
	useEffect(() => {
		if (state.loggedIn) {
			getFavorite(state.token)
				.then((data) => {
					dispatch(actions.addInvesmentsToFavorites(data.result));
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [state.loggedIn]);

	// check is user logged in
	useEffect(() => {
		checkAuth(callbackAuth);
	}, []);

	// eventListener on paste
	useEffect(() => {
		const el = document.getElementById(sharePostId);
		if (el) {
			el.addEventListener('paste', paste);
		}
		return () => {
			const el = document.getElementById(sharePostId);
			if (el) {
				window.removeEventListener('paste', paste);
			}
		};
	});

	// set autocomplete titles
	useEffect(() => {
		if (Object.values(state.investmentsTotal).length > 0) {
			const titles = convertToAutocompleteObject(state.investmentsTotal);
			setInvestmentTitles(titles);
		}
	}, [state.investmentsTotal]);

	// detect active star
	useEffect(() => {
		if (investment !== null) {
			const starOutline = detectActiveStar(state.favoritesInvestments, investment);
			setStarOutline(starOutline);
		} else {
			setStarOutline(false);
		}
	}, [investment, state.favoritesInvestments]);

	// set favorites
	useEffect(() => {
		setFavoritesInvestments(state.favoritesInvestments);
	}, [state.favoritesInvestments]);

	// toggle modal
	useEffect(() => {
		if (state.modalSignUpOpen) {
			setOpenModalSignUp(true);
		} else {
			setOpenModalSignUp(false);
		}
	}, [state.modalSignUpOpen]);

	// scroll top on mount
	useEffect(() => {
		scrollTop();
	}, []);

	// attach Observer when data is loaded
	useEffect(() => {
		if (stream.length > 0) {
			addObserver(limitId, observerCallback);
		}
	}, [stream]);

	// handle comment click
	useEffect(() => {
		if (replyed && postId !== '') {
			if (replyResult.replies !== undefined) {
				handleCommentClick(postId, true);
			}
		}
	}, [replyed, replyResult]);

	// setup timeout to get new posts
	useEffect(() => {
		if (firstId) {
			startTimer();
		}
	}, [firstId]);

	// handle sroll
	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return function cleanup() {
			window.removeEventListener('scroll', handleScroll);
		};
	});

	const callbackAuth = async (currentUser) => {
		if (currentUser !== null) {
			const token = await currentUser.getIdToken();
			dispatch(actions.setToken(token));
			dispatch(actions.setLoggedInStatus(true));
		} else {
			dispatch(actions.setLoggedInStatus(false));
		}
	};
	const getCorrectStreamAsync = () => {
		if (individualStreamName !== '' && individualStreamName !== undefined) {
			getStreamAsync('', value.id);
		} else {
			getStreamAsync();
		}
	};
	const getPlatforms = () => {
		if (!detectRouteUrl(match)) {
			return [];
		}
		const id = match.params.platformId;
		return detectPlatformBySlug(state.investmentsTotal, id);
	};
	const detectRoute = () => {
		const platforms = getPlatforms();
		if (platforms.length > 0) {
			return true;
		} else {
			return false;
		}
	};
	const getUserLikesAsync = async () => {
		getUserLikes(state.token)
			.then((data) => {
				setUserLikes(data.result);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const getStreamAsync = async (commentId = '', platformId = '') => {
		const response = await getStream(commentId, platformId);
		const data = response.message.stream;
		setAddingComment(false);
		if (data.length > 0) {
			const firstId = getFirstId(data);
			const lastId = getLastId(data);
			document.getElementById(lastReplyId).innerHTML = lastId;
			setStream([]);
			setStream(data);
			setNoResults(false);
			setFirstId(null);
			setFirstId(firstId);
		} else {
			setStream([]);
			setNoResults(true);
			setFirstId(null);
		}
		setNewPosts([]);
		setStreaming(false);
		setDisplaySpinner(false);
	};
	const getLazyStreamAsync = async (commentId = '', platformId = '') => {
		const response = await getStream(commentId, platformId);
		const data = response.message.stream;
		if (data.length > 0) {
			const lastId = getLastId(data);
			document.getElementById(lastReplyId).innerHTML = lastId;
			setStream([...stream, ...data]);
		}
		setDisplaySpinner(false);
		setStreaming(false);
	};
	const getMentionsAsync = async (investment) => {
		const mentions = await getMentions(investment.id);
		setMentions(mentions);
		setIsLoading(false);
	};
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	const handleRoute = () => {
		const platformLimited = getPlatforms();
		const newValue = platformLimited[0];
		setValue(newValue);
		const platform = detectPlatform(state.investmentsTotal, newValue);
		setInvestment(platform);
		const starActive = detectActiveStar(favoritesInvestments, platform);
		setStarOutline(starActive);
		scrollTop();
		clearCounters();
		setStream([]);
		getStreamAsync('', platform.id);
		setIndividualStream(true);
		setIndividualStreamName(newValue.name.trim());

		setStreaming(false);
		setDisplaySpinner(false);
	};
	const handleAutocomplete = (event, newValue) => {
		stopTimer();
		if (newValue === null) {
			setValue('');
			clearCounters();
			setStream([]);
			getStreamAsync();
			setIndividualStream(false);
			setIndividualStreamName('');
			getUserLikesAsync();

			redirect();
			return;
		}
		setIndividualStream(true);
		setIndividualStreamName(newValue.title.trim());
		setValue(newValue);
		const platform = detectPlatform(state.investmentsTotal, newValue);
		setInvestment(platform);
		const starActive = detectActiveStar(favoritesInvestments, platform);
		setStarOutline(starActive);

		clearCounters();
		getStreamAsync('', platform.id);
		getUserLikesAsync();

		redirect(platform);
	};
	const handleBack = (event, newValue) => {
		stopTimer();
		setValue('');
		setInvestment(null);
		setStarOutline(false);
		clearCounters();
		setStream([]);
		getStreamAsync();
		setIndividualStream(false);
		setIndividualStreamName('');
		getUserLikesAsync();

		redirect();
	};
	const handleTextWithTagClick = (platformLimited) => {
		stopTimer();
		const newValue = platformLimited;
		setValue(newValue);
		const platform = detectPlatform(state.investmentsTotal, newValue);
		setInvestment(platform);
		const starActive = detectActiveStar(favoritesInvestments, platform);
		setStarOutline(starActive);
		scrollTop();
		clearCounters();
		setStream([]);
		getStreamAsync('', platform.id);
		setIndividualStream(true);
		setIndividualStreamName(newValue.title.trim());

		redirect(platform);
	};
	const handleStarClick = (event) => {
		event.stopPropagation();
		if (!state.loggedIn) {
			dispatch(actions.setModalSignUpOpen(true));
			return;
		}
		if (!starActive) {
			dispatch(actions.addInvesmentToFavorites(investment.id));
			addFavoriteAsync();
		} else {
			dispatch(actions.removeInvestmentFromFavorites(investment.id));
			deleteFavoriteAsync();
		}
	};
	const redirect = (platform = '') => {
		let url = '';
		if (platform) {
			url = `${currentUrl}/${platform.slug}`;
		} else {
			url = `${currentUrl}`;
		}
		history.push(`${url}`);
	};
	const popUp = (postId) => {
		setPostLoading(postId);
		let url = ``;
		if (individualStreamName) {
			url = `${currentUrl}/${individualStreamName}/${postId}`;
		} else {
			url = `${currentUrl}/${postId}`;
		}
		history.push(`${url}`);
	};
	const addFavoriteAsync = () => {
		addFavorite(state.token, investment.id)
			.then((data) => {
				if (data.success) {
					dispatch(actions.addInvesmentsToFavorites(data.message));
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const deleteFavoriteAsync = () => {
		deleteFavorite(state.token, investment.id)
			.then((data) => {
				if (data.success) {
					dispatch(actions.addInvesmentsToFavorites(data.message));
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const isStarActive = (value) => {
		return value ? `${star}` : `${starOutline}`;
	};
	const scrollTop = () => {
		window.scrollTo(0, 0);
	};
	const lazyLoadComments = async () => {
		const lastId = document.getElementById(lastReplyId).innerText;
		const postedId = document.getElementById(lastPostedReplyId).innerText;
		if (lastId !== postedId) {
			document.getElementById(lastPostedReplyId).innerHTML = lastId;
			setDisplaySpinner(true);
			if (individualStream) {
				getLazyStreamAsync(lastId, investment.id);
			} else {
				getLazyStreamAsync(lastId);
			}
		}
	};
	const observerCallback = (entries, observer, el) => {
		entries.forEach((entry, i) => {
			if (entry.isIntersecting) {
				lazyLoadComments();
			}
		});
	};
	const clearCounters = () => {
		document.getElementById(lastReplyId).innerHTML = '';
		document.getElementById(lastPostedReplyId).innerHTML = '';
	};
	const attachTributeToElement = (investmentTitles, sharePosiId) => {
		const tribute = getTribute(investmentTitles);
		tribute.attach(document.getElementById(sharePosiId));
	};
	const handlePostedComment = (result, postId) => {
		setReplyResult(result);
		if (postId !== '') {
			const elId = `${replyArea}_${postId}`;
			const element = document.getElementById(elId);
			if (element) {
				element.style.display = 'none';

				const newStream = updateStream(stream, postId, result);
				setStream(newStream);
			}
		}
	};
	const handleCommentClick = (id, toggle) => {
		popUp(id);
		// commentClickAction();
	};
	const handleReplyClick = (id, toggle, userName) => {
		popUp(id);
		// replyClickAction();
	};
	const handleScroll = () => {
		const button = document.getElementById(buttonId);
		const stickyButton = document.getElementById(stickyButtonId);
		const buttonLimit = document.getElementById(buttonLimitId);

		if (buttonLimit.getBoundingClientRect().top < 60) {
			button.style.visibility = 'hidden';
			stickyButton.style.display = 'inherit';
		} else {
			button.style.visibility = 'visible';
			stickyButton.style.display = 'none';
		}
	};
	async function getNewPostsAsync() {
		stopTimer();
		if (!isStreamUrl(window.location.pathname, routeNames.stream)) {
			setFirstId(null);
			return;
		}
		const platformId = investment !== null ? investment.id : ``;
		const response = await getLatestStream(firstId, platformId);
		const data = response.message.stream;
		//const data = stream.slice(Math.floor(Math.random() * Math.floor(10)), 10);
		if (data.length > 0) {
			const firstId = getFirstId(data);
			setFirstId(firstId);
			setNewPosts(data);
		} else {
			startTimer();
		}
	}
	const onNewPostsClick = () => {
		setStream([...newPosts, ...stream]);
		setNewPosts([]);
		scrollTop();
	};
	const handleNewPost = (timestamp) => {
		setNewPosts([]);
		stopTimer();
		getCorrectStreamAsync();
	};
	const handlePostLoaded = () => {
		setPostLoading(null);
	};
	/*const replyClickAction = (id, toggle, userName) => {
		const elId = `${replyArea}_${id}`;
		const shareId = `${sharePostId}_${id}`;
		const element = document.getElementById(elId);
		if (toggle) {
			if (postId !== '') {
				const elId = `${replyArea}_${postId}`;
				const element = document.getElementById(elId);
				if (element !== null) {
					element.style.display = 'none';
				}
			}
			element.style.display = 'block';
			element.scrollIntoView({ behavior: 'smooth', block: 'center' });
			attachTributeToElement(investmentTitles, shareId);
			setUserNameReply(userName);
			setPostId(id);
		}
	};
	const commentClickAction = (id, toggle) => {
		const elId = `${commentArea}_${id}`;
		const element = document.getElementById(elId);
		posts = updatePosts(posts, id, toggle);
		openPostOrHide(toggle, element);
		toggleActviePostClass(posts, containerId);
	};*/

	return (
		<div className={`respCont ${classes.root}`}>
			<CssBaseline />
			<AppBarComponent
				utmParams={utmParams}
				medium={medium}
				showMenu={showMenu}
				paidOnly={paidOnly}
				expandable={expandable}
				handleDrawerToggle={handleDrawerToggle}
				expandableBannerShow={expandableBannerShow}
			/>
			{
				<OnePostModalRoute
					handleCloseModal={handleTextWithTagClick}
					handlePostedComment={handlePostedComment}
					handlePostLoaded={handlePostLoaded}
				/>
			}
			<main className={`${classes.container} mainPrinc`}>
				<div className={expandable ? classes.toolbar4Banner : classes.toolbar} />
				{value === '' ? (
					Object.values(state.investmentsTotal).length > 0 ? (
						<AutocompleteCard
							value={value}
							investmentTitles={investmentTitles}
							handleAutocomplete={handleAutocomplete}
						/>
					) : (
						<div className={`${classes.searchContainer} ${classes.topCard}`}>
							<Skeleton
								animation="wave"
								variant="rect"
								width={320}
								height={56}
								style={{ borderRadius: '8px' }}
							/>
						</div>
					)
				) : (
					<div className={`${classes.topCard}`}>
						<BackToButton handleBack={handleBack} text={`Back to Stream page`} />
						{investment !== null && (
							<ResultCard
								investment={investment}
								handleStarClick={handleStarClick}
								mentions={mentions}
								isLoading={isLoading}
								starActive={starActive}
								isStarActive={isStarActive}
							/>
						)}
					</div>
				)}
				<div className={`${classes.card}`}>
					<div id={buttonLimitId}></div>

					<div className={`${newPosts.length > 0 ? classes.show : classes.hide}`}>
						<div id={buttonId}>
							<NewPostsButton
								count={newPosts.length}
								onNewPostsClick={onNewPostsClick}
							/>
						</div>
						<div
							id={stickyButtonId}
							className={`${classes.sticky} stickyButtonStream`}
						>
							<NewPostsButton
								count={newPosts.length}
								onNewPostsClick={onNewPostsClick}
							/>
						</div>
					</div>

					{Object.values(state.investmentsTotal).length > 0 ? (
						<div>
							<MakePostCard
								userName={state.userName}
								sharePostId={sharePostId}
								token={state.token}
								reply={false}
								investmentsTotal={state.investmentsTotal}
								setAddingComment={setAddingComment}
								setStreaming={setStreaming}
								postId={postId}
								individualStreamName={individualStreamName}
								loggedIn={state.loggedIn}
								handleNewPost={handleNewPost}
							/>
							<div className={classes.hl}></div>
						</div>
					) : (
						<SkeletonMakePost />
					)}
					<div className={`${classes.posts}`}>
						{(streaming || addingComment) && <SkeletonPost />}
						{stream.length === 0 ? (
							!noResults ? (
								<div>
									<SkeletonPost />
									<SkeletonPost />
									<SkeletonPost />
									<SkeletonPost />
								</div>
							) : null
						) : (
							stream.map((i, index) => {
								const id = i.id;
								const isBumped = isValueinArray(userLikes, id);
								return (
									<div key={`container_${index}_${id}`}>
										<div
											key={`${index}_${id}`}
											id={`${containerId}${id}`}
											className={`${classes.postsContainer}`}
										>
											<PostHeader
												displayName={i.user.displayName}
												avatar={i.user.avatar}
												timestamp={i.timestamp._seconds}
											/>
											<TextWithTags
												text={i.text}
												investmentTitles={investmentTitles}
												platforms={i.platforms}
												handleClick={handleTextWithTagClick}
												imgUrl={i.imgUrl}
												id={`${id}`}
											/>
											<PostActions
												likes={i.likes}
												id={i.id}
												replies={i.replies}
												loggedIn={state.loggedIn}
												displayName={i.user.displayName}
												handleCommentClick={handleCommentClick}
												handleReplyClick={handleReplyClick}
												showComments={true}
												showShare={true}
												index={null}
												isBumped={isBumped}
												postLoading={postLoading}
											/>
										</div>
										<div className={classes.hl}></div>
										{/*<RepliesComments
                                        i={i}
                                        handleTextWithTagClick={handleTextWithTagClick}
                                        handleCommentClick={handleCommentClick}
                                        handleReplyClick={handleReplyClick}
                                        commentArea={commentArea}
                                        replyArea={replyArea}
                                        userLikes={userLikes}
                                        replying={replying}
                                        id={id}
                                        investmentTitles={investmentTitles}
                                        sharePostId={sharePostId}
                                        loggedIn={state.loggedIn}
                                        userName={state.userName}
                                        token={state.token}
                                        investmentsTotal={state.investmentsTotal}
                                        setAddingComment={setAddingComment}
                                        setStreaming={setStreaming}
                                        userNameReply={userNameReply}
                                        postId={postId}
                                        setReplying={setReplying}
                                        handlePostedComment={handlePostedComment}
                                        setReplyed={setReplyed}
                                    />*/}
									</div>
								);
							})
						)}
						{displaySpinner && (
							<div>
								<SkeletonPost />
								<SkeletonPost />
								<SkeletonPost />
							</div>
						)}
						{stream.length > 0 && <div id={limitId}></div>}
						<div id={lastReplyId} style={{ display: 'none' }}></div>
						<div id={lastPostedReplyId} style={{ display: 'none' }}></div>
						<div id={lastPostedReplyId} style={{ display: 'none' }}></div>
					</div>
				</div>
			</main>
			<ModalSignUp open={openModalSignUp} />
		</div>
	);
};
