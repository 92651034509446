import React, { useEffect, useState } from 'react';

import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	modal: {
		overflow: 'scroll',
		zIndex: '10000 !important', // to appear before the header
		display: 'flex',
		alignItems: 'flex-start',
	},
	card: {
		width: 1200,
		maxWidth: '100%',
		margin: '90px auto',
		boxShadow: '0px 4px 24px rgba(6, 7, 23, 0.06)',
		position: 'relative',
		borderRadius: '8px',
		backgroundColor: '#FBFCFD',
	},
	closeButton: {
		position: 'absolute',
		top: '12px',
		right: '12px',
		left: 'auto !important',
	},
	cardEmbed: {
		width: 1200,
		maxWidth: '100%',

		boxShadow: '0px 4px 24px rgba(6, 7, 23, 0.06)',
		position: 'relative',
		borderRadius: '8px',
		backgroundColor: '#FBFCFD',
	},
	closeButton: {
		position: 'absolute',
		top: '12px',
		right: '12px',
		left: 'auto !important',
	},
});

export default function ClosableModal(
	{ onClose, className, open, children, embed },
	props
) {
	const classes = useStyles();
	const [medium, setMedium] = useState('');

	//console.log(medium);
	return (
		<Modal
			disableAutoFocus
			disableEnforceFocus
			hideBackdrop={embed}
			open={open}
			className={classes.modal}
			onClose={onClose}
			id="modalPlatform"
		>
			<Card
				className={`mainCard ${
					embed ? classes.cardEmbed : classes.card
				} ${className}`}
			>
				{!embed ? (
					<IconButton
						className={`expansionIcon ${classes.closeButton}`}
						onClick={onClose}
						aria-label="Close"
					>
						<CloseIcon />
					</IconButton>
				) : null}

				{children}
			</Card>
		</Modal>
	);
}
