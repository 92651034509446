import React, { useState, useEffect } from 'react';
import { TextField, Button } from '@material-ui/core';
import InputBase from "@material-ui/core/InputBase";
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Spinner } from '../Spinner/Spinner';
import { trackEvent, checkMail, eventTracker } from '../../utils';
import { sendQuizData } from '../../Services/QuizMailAPI';
import { useSelector, shallowEqual } from 'react-redux';

const useStyles = makeStyles((theme) => ({

	mailCard: {
		margin: '40px 0 40px 0',
		background: '#ffffff',
		border: '1px solid #f2f5f8',
		boxSizing: 'border-box',
		boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
		padding: '20px 0 20px 0',
		textAlign: 'center',
		maxWidth: '668px',
    }
}));

export const MailCard = (props) => {
    const { investments } = props;
    const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
    const [mail, setMail] = useState('');
    const [displaySpinner, setDisplaySpinner] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [failedMail, setFailedMail] = useState(false);
    const [sendingMail, setSendingMail] = useState(false);

    const classes = useStyles();
    const handleChange = (event) => {
        setMail(event.target.value);
    }
    const handleSubmit = (event) => {

        if(!checkMail(mail.trim())) {
            setFailedMail(true);
            setSendingMail(false);
        } else {
            setFailedMail(false);
            setSendingMail(true);
        }
    }
    useEffect(() => {
        if(!failedMail && sendingMail) {
            trackEvent('User', 'QuizLetter', 'QuizLetter');
            eventTracker("User", 'QuizLetter', state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
            setFailedMail(false);
            setDisplaySpinner(true);
            const investmentIds = investments.map(i => i.id);
            const userId = state.userId;
            const quizAnswers = state.quizAnswers;
            sendQuizData(mail, investmentIds, userId, quizAnswers)
            .then((data) => {
                if (data.success) {
                    trackEvent('QuizLetter', 'Email', `${mail}`);
                    eventTracker("QuizLetter", `${mail}`, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
                    setFailedMail(false);
                } else {
                    trackEvent('QuizLetterNotSent', 'Email', `${mail}`);
                    eventTracker("QuizLetterNotSent", `${mail}`, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
                }
                setDisplaySpinner(false);
                setShowMessage(true);
                setSendingMail(false);
            })
            .catch((error) => {
                console.log(error.toString());
            });
        }}, [failedMail, sendingMail]);

    return (
        <>
        {displaySpinner ?
            <Spinner displaySpinner={displaySpinner} />
            :
            (<div className={classes.mailCard}>
                {!showMessage ? (<Typography
                    style={{
                        fontSize: '16px',
                        lineHeight: '24px',
                        color: '#30333E',
                        marginBottom: '8px'
                    }}
                    className={classes.heading}
                >
                    Email my results
                </Typography>)
                :
                (<Typography
                    style={{
                        fontSize: '16px',
                        lineHeight: '24px',
                        color: '#5056E7',
                        marginBottom: '8px'
                    }}
                    className={classes.heading}
                >
                    Your results have been sent
                </Typography>)}
                <Typography
                    className={classes.heading}
                    style={{
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#73757A',
                        marginBottom: '8px'
                    }}>
                    {!showMessage ?
                        'Get your results via Email to have an ability to refer back to them' :
                        'Get your personalized results below sent to your inbox.'}
                </Typography>
			<div>
                <div>
                    <TextField
                        name="EMAIL"
                        style={{
                            maxWidth: '264px',
                            width: '100%'
                        }}
                        className="mailInput"
                        placeholder="Email"
                        variant="outlined"
                        onChange={handleChange}
                        value={mail}
                        error={failedMail}
                    />
					<Button
						type="submit"
						className=""
						style={{
                            height: '40px',
                            marginLeft: '10px',
                            marginTop: '10px'
                        }}
						variant="contained"
                        color="primary"
                        onClick={handleSubmit}
					>
						Send results
					</Button>
				</div>
			</div>
		</div>
            )}
        </>
    )
}
