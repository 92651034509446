import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	convertToAutocompleteObject,
	getTribute,
	isValueinArray,
	isValuesinArray,
	updatePosts,
	openPostOrHide,
} from '../../utils';
import { getUserLikes } from '../../Services/GetUserLikesAPI';
import { SkeletonPost } from './SkeletonPost';
import { MakePostCard } from './MakePostCard';
import { TextWithTags } from './TextWithTags';
import { PostHeader } from './PostHeader';
import { PostActions } from './PostActions';

const useStyles = makeStyles((theme) => ({
	toolbar: theme.mixins.toolbar,
	toolbar4Banner: {
		minHeight: 50,
		[theme.breakpoints.up('xs')]: {
			minHeight: 50,
		},
		[theme.breakpoints.up('sm')]: {
			minHeight: 50,
		},
	},
	container: {
		maxWidth: '800px',
		width: '100%',
		margin: '0 auto',
	},
	card: {
		position: 'relative',
		background: '#FFFFFF',
		border: '1px solid #F2F5F8',
		boxSizing: 'border-box',
		boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
		borderRadius: '8px',
		padding: '18px',
		[theme.breakpoints.down('460')]: {
			padding: '12px',
		},
	},
	topCard: {
		marginTop: '32px',
		marginBottom: '20px',
	},
	searchContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	backButton: {
		fontSize: '14px',
		lineHeight: '20px',
		color: '#73757A',
		textTransform: 'none',
		marginBottom: '24px',
	},
	title: {
		fontFamily: 'Red Hat Display',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '28px',
		lineHeight: '40px',
		color: '#30333E',
		marginLeft: '12px',
		[theme.breakpoints.down('500')]: {
			textAlign: 'center',
			fontSize: '32px',
			lineHeight: '44px',
		},
	},
	favorites: {
		display: 'flex',
		alignItems: 'center',
		background: '#F2F5F8',
		border: '1px solid #DBE0E6',
		boxSizing: 'border-box',
		borderRadius: '100px',
		height: '32px',
		cursor: 'pointer',
		paddingRight: '10px',
	},
	favoritesText: {
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '12px',
		lineHeight: '20px',
		color: '#30333E',
		paddingRight: '4px',
		paddingLeft: '4px',
	},
	hl: {
		width: '744px',
		maxWidth: '100%',
		height: '1px',
		background: '#F2F5F8',
		marginTop: '0px',
		marginBottom: '0px',
	},
	marginStyle: {
		marginTop: '18px',
	},
	hidden: {
		display: 'none',
	},
	postsContainer: {
		//marginBottom: '10px',
		padding: '18px 10px',
	},
	grey: {
		//background: '#fafbfe',
	},
	commentsContainer: {
		[theme.breakpoints.up('400')]: {
			marginLeft: '40px',
		},
		[theme.breakpoints.down('400')]: {
			marginLeft: '20px',
		},
	},
	like: {
		marginLeft: '8px',
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 500,
		fontSize: '14px',
		lineHeight: '20px',
		color: '#73757A',
		cursor: 'pointer',
	},
	sticky: {
		display: 'none',
	},
	hide: {
		display: 'none',
	},
	show: {
		display: 'inherit',
	},
}));

export const OnePostCard = (props) => {
	const {
		post: postProp,
		postId,
		currentUrl,
		onClose: onCloseProps,
		handleCloseModal,
		handlePostedComment: handlePostedCommentProp,
	} = props;
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const classes = useStyles();
	const history = useHistory();

	const [userLikes, setUserLikes] = useState([]);
	const [bumped, setBumped] = useState(false);
	const [investmentTitles, setInvestmentTitles] = useState([]);
	const [userNameReply, setUserNameReply] = useState('');
	const [id, setId] = useState('');
	const [addingComment, setAddingComment] = useState(false);
	const [streaming, setStreaming] = useState(false);
	const [replying, setReplying] = useState(false);
	const [replyed, setReplyed] = useState(false);
	const [replyResult, setReplyResult] = useState(null);
	const [post, setPost] = useState(postProp);

	let posts = [];
	const sharePostId = `modal-share-post-id`;
	const commentArea = `modal_commentArea`;
	const replyArea = `modal_replyArea`;

	// get user likes
	useEffect(() => {
		if (state.loggedIn) {
			getUserLikesAsync();
		}
	}, [state.loggedIn]);

	// set autocomplete titles
	useEffect(() => {
		if (Object.values(state.investmentsTotal).length > 0) {
			const titles = convertToAutocompleteObject(state.investmentsTotal);
			setInvestmentTitles(titles);
		}
	}, [state.investmentsTotal]);

	// setup tag autocomplete
	useEffect(() => {
		if (investmentTitles.length > 0) {
			attachTributeToElement(investmentTitles, sharePostId, postId);
		}
	}, [investmentTitles]);

	// update post data after post comment
	useEffect(() => {
		if (replyResult) {
			const newPost = post;
			newPost['replies'] = replyResult.replies;
			setPost(newPost);
			setReplyResult(null);
			handlePostedCommentProp(replyResult, postId);
		}
	}, [replyResult]);

	const attachTributeToElement = (investmentTitles, sharePostId, postId) => {
		const tribute = getTribute(investmentTitles);
		tribute.attach(document.getElementById(`${sharePostId}_${postId}`));
	};
	const getUserLikesAsync = async () => {
		getUserLikes(state.token)
			.then((data) => {
				setUserLikes(data.result);
				const bumped = isValueinArray(data.result, postId);
				setBumped(bumped);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const handleTextWithTagClick = (platformLimited) => {
		onCloseProps(`${currentUrl}`);
		handleCloseModal(platformLimited);
	};
	const handleCommentClick = (id, toggle) => {
		const elId = `${commentArea}_${id}`;
		const element = document.getElementById(elId);
		posts = updatePosts(posts, id, toggle);
		openPostOrHide(toggle, element);
	};
	const handleReplyClick = (id, toggle, userName) => {
		const elId = `${replyArea}_${id}`;
		const shareId = `${sharePostId}_${id}`;
		const element = document.getElementById(elId);
		if (toggle) {
			if (postId !== '') {
				const elId = `${replyArea}_${postId}`;
				const element = document.getElementById(elId);
				if (element !== null) {
					element.style.display = 'none';
				}
			}
			element.style.display = 'block';
			element.scrollIntoView({ behavior: 'smooth', block: 'center' });
			setUserNameReply(userName);
			setId(id);
		}
	};
	const handlePostedComment = (result, postId) => {
		setReplyResult(result);
		/*if (postId !== "") {
            const elId = `${replyArea}_${postId}`;
            const element = document.getElementById(elId);
            element.style.display = "none";
            const newStream = updateStream(stream, postId, result);
            setStream(newStream);
        }*/
	};

	//	console.log(post);

	return (
		<div className={`${classes.container} mainPrinc`}>
			<div className={`${classes.card}`}>
				<div className={`${classes.posts}`}>
					{post !== null && (
						<div key={`modal_${postId}`}>
							<div
								key={`modal_post_${postId}`}
								id={`${postId}`}
								className={`${classes.postsContainer}`}
							>
								<PostHeader
									displayName={post.user.displayName}
									avatar={post.user.avatar}
									timestamp={post.timestamp._seconds}
								/>
								<TextWithTags
									text={post.text}
									investmentTitles={investmentTitles}
									platforms={post.platforms}
									handleClick={handleTextWithTagClick}
									imgUrl={post.imgUrl}
									id={`${postId}`}
								/>
								<PostActions
									likes={post.likes}
									id={postId}
									replies={post.replies}
									loggedIn={state.loggedIn}
									displayName={post.user.displayName}
									handleCommentClick={handleCommentClick}
									handleReplyClick={handleReplyClick}
									showComments={true}
									showShare={true}
									index={null}
									isBumped={bumped}
									modal={true}
								/>
							</div>
							<div className={classes.hl}></div>
							<div
								className={classes.commentArea}
								//style={{display: 'none'}}
								id={`${commentArea}_${postId}`}
							>
								{post.replies
									? post.replies.map((i, index) => {
											const isBumped = isValuesinArray(userLikes, postId, index);

											return (
												<div
													key={index}
													className={`${classes.postsContainer} ${classes.commentsContainer}`}
												>
													<PostHeader
														displayName={i.user.displayName}
														avatar={i.user.avatar}
														timestamp={i.timestamp._seconds}
													/>
													<TextWithTags
														text={i.text}
														investmentTitles={investmentTitles}
														platforms={i.platforms}
														handleClick={handleTextWithTagClick}
														imgUrl={i.imgUrl}
														id={`${postId}_${index}_modal`}
													/>
													<PostActions
														likes={i.likes}
														id={postId}
														replies={i.replies}
														loggedIn={state.loggedIn}
														displayName={i.user.displayName}
														handleCommentClick={handleCommentClick}
														handleReplyClick={handleReplyClick}
														showComments={false}
														showShare={false}
														index={index}
														isBumped={isBumped}
													/>
													<div
														className={`${classes.hl} ${classes.marginStyle}`}
													></div>
												</div>
											);
									  })
									: ``}
							</div>
							<div
								className={`${classes.postsContainer} ${classes.commentsContainer}`}
								//style={{display: 'none'}}
								id={`${replyArea}_${postId}`}
							>
								{replying === false ? (
									<MakePostCard
										userName={state.userName}
										sharePostId={`${sharePostId}_${postId}`}
										token={state.token}
										investmentsTotal={state.investmentsTotal}
										setAddingComment={setAddingComment}
										setStreaming={setStreaming}
										reply={true}
										userNameReply={userNameReply}
										postId={postId}
										id={postId}
										setReplying={setReplying}
										handlePostedComment={handlePostedComment}
										setReplyed={setReplyed}
										loggedIn={state.loggedIn}
										modal={true}
										key={`${sharePostId}_${postId}`}
									/>
								) : (
									<SkeletonPost />
								)}
								<div className={classes.hl}></div>
							</div>
						</div>
					)}
					{!post && (
						<div>
							<SkeletonPost />
							<SkeletonPost />
							<SkeletonPost />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
