import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

export const BleakButton = withStyles({
    root: {
        fontSize: "14px",
        lineHeight: "20px",
        color: "#73757A",
        textTransform: "none",
        backgroundColor: "#FBFCFD",
    }
})(Button);
