import React, { useEffect, useState, useMemo } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { getPlatformsById, openModalScreen, closeModalScreen } from '../../utils';
import { TagUrl } from './TagUrl';

const useStyles = makeStyles((theme) => ({
    postsText: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#30333E',
        wordBreak: 'normal',
        overflowWrap: 'break-word',
    },
    img: {
        maxWidth: '100%',
        height: 'auto',
        //maxHeight: '300px',
        marginTop: '10px',
        cursor: 'pointer',
    },
    imgContainer: {
        maxHeight: '300px',
        overflow: 'hidden',
    },
}));

export const TextWithTags = (props) => {
    const {
        text,
        investmentTitles,
        platforms,
        handleClick: handleClickProp,
        imgUrl,
        id,
    } = props;
    const classes = useStyles();

    const [platformTagged, setPlatformTagged] = useState([]);
    const [textWithTags, setTextWithTags] = useState(text);

    const modalImgContainer = `modalImgContainer_${id}`;
    const modalImgTemplate = `modalImgTemplate_${id}`;

    const modal = document.querySelector(`#${modalImgContainer}`);
    const modalImg = document.querySelector(`#${modalImgTemplate}`);

    useEffect(() => {
        const tagged = getPlatformsById(platforms, investmentTitles);
        if (investmentTitles.length > 0 && tagged.length > 0) {
            setPlatformTagged(tagged);
        }
    }, [platforms, investmentTitles]);

    useEffect(() => {
        if (platformTagged) {
            if (platformTagged.length > 0) {
                replaceText(platformTagged, text);
             }
        }
    }, [platformTagged]);

    const replaceText = (platformTagged, textWithTags) => {
        const ar = textWithTags.split("$");
        if (ar.length === 1) {
            return textWithTags;
        }
        const newAr = [];
        newAr.push(ar[0]);
        for (let i = 1; i < ar.length; i++) {
            for (let j = 0; j < platformTagged.length; j++) {
                if (ar[i].toLowerCase().indexOf(platformTagged[j].title.trim().toLowerCase()) === 0) {
                    const text = ar[i].substr(platformTagged[j].title.trim().length);
                    newAr.push((
                        <TagUrl
                            text={platformTagged[j].title}
                            platform={platformTagged[j]}
                            handleClick={handleClickProp}
                            key={i}
                        />));
                    newAr.push(text);
                    break;
                }
                if (j === platformTagged.length-1) {
                    newAr.push(`$${ar[i]}`);
                }
            }
        }
        setTextWithTags(newAr);
    }

    const handleImgClick = (imgUrl) => {
        openModalScreen(modal, imgUrl, modalImg);
    }
    const handleCloseModal = () => {
        closeModalScreen(modal);
    }

    const imageElement = (
        <div className={`${classes.imgContainer}`}>
            <img
                src={imgUrl}
                alt='attachment'
                className={`${classes.img}`}
                onClick={() => {handleImgClick(imgUrl)}}
            />
        </div>
    )
    const modalElement = (
        <div className={`modal`} id={modalImgContainer}>
            <span
                className={`iconModal closeModal `}
                onClick={handleCloseModal}
            >
                &times;
            </span>
            <img
                className={`modal-content`}
                id={modalImgTemplate}
                alt="modal img"
            />
        </div>
    )

    return (
        <div className={`${classes.postsText}`}>
            {textWithTags}
            {imgUrl && imageElement}
            {modalElement}
        </div>
    )
}
