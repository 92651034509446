import React, { useState, useEffect } from 'react';
import {Menu, MenuItem} from "@material-ui/core";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Button, RadioGroup } from '@material-ui/core';
import SortIcon from '@material-ui/icons/Sort';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import ListIcon from '@material-ui/icons/List';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import ListItemText from '@material-ui/core/ListItemText';
import InputBase from '@material-ui/core/InputBase';
import Skeleton from '@material-ui/lab/Skeleton';
//import { StyledMenu, StyledMenuItem } from '../StyledMenu';
import { sortAsc, sortDesc, sortTargetAsc, sortTargetDesc, sortRatingDesc } from '../../utils';
import { ViewMenu } from './ViewMenu';
import { CollectionItemInfo } from './CollectionItemInfo';

export const StyledMenu = withStyles({
    paper: {
		border: '1px solid #F2F5F8',
		boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
    },
})(props => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));
export const StyledMenuItem = withStyles(theme => ({
    root: {
        '&:focus': {
            backgroundColor: '#F2F5F8',
        },
    },
}))(MenuItem);
const CustomButton = withStyles((theme) => ({
	root: {
		color: '#73757A',
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '14px',
		lineHeight: '20px',
		textTransform: 'none',
		padding: '4px',
		marginLeft: '14px',
		[theme.breakpoints.down('500')]: {
			marginLeft: '0px',
		},
	},
}))(Button);
const CustomTextField = withStyles((theme) => ({
	root: {
		'label + &': {},
		[theme.breakpoints.between('600', '660')]: {
			width: '180px',
		},
		[theme.breakpoints.down('480')]: {
			width: '200px',
		},
		[theme.breakpoints.down('430')]: {
			width: '160px',
		},
		[theme.breakpoints.down('390')]: {
			width: '140px',
		},
		[theme.breakpoints.down('370')]: {
			maxWidth: '360px',
		},
	},
	input: {
		borderRadius: 25,
		position: 'relative',
		backgroundColor: theme.palette.common.white,
		border: '1px solid #DBE0E6',
		fontSize: 14,
		maxWidth: '280px',
		width: '100%',
		padding: '6px 12px',
		boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		fontFamily: ['Inter'].join(','),
		'&:focus': {
			borderColor: 'rgba(0, 0, 0, 0.87)',
		},
	},
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    investPlatformHeader: {
		padding: '0px 0px 0px 0px',
		marginBottom: '2px',
		boxShadow: 'unset',
		background: 'transparent',
		maxWidth: '1200px',
		width: '100%',
		margin: 'auto',
		display: 'flex',
		[theme.breakpoints.down('450')]: {
			display: 'flex',
			justifyContent: 'center',
		},
	},
	investPlatformHeaderCollection: {
		padding: '0px 0px 0px 0px',
		marginBottom: '2px',
		boxShadow: 'unset',
		background: 'transparent',
		maxWidth: '1200px',
		width: '100%',
		margin: 'auto',
		display: 'flex',
		[theme.breakpoints.down('1145')]: {
			display: 'block',
		},
    },
    investPlatformText: {
		float: 'left',
		[theme.breakpoints.down('580')]: {
			display: 'none',
		},
	},
	investPlatformTextWithSearch: {
		float: 'left',
		[theme.breakpoints.down('700')]: {
			display: 'none',
		},
	},
	collectionActive: {
		//float: 'left',
		maxWidth: '800px',
		[theme.breakpoints.down('1145')]: {
			maxWidth: '100%',
		},
	},
    leftSectionButtons: {
		position: 'relative',
		//float: 'right',
		alignSelf: 'flex-end',
		marginTop: '10px',
		marginBottom: '10px',
		marginLeft: 'auto',
		[theme.breakpoints.down('370')]: {
			//clear: 'right',
			//display: 'block',
		},
	},
	blue: {
		color: '#5056e7 !important',
	},
	activeFilter: {
		color: '#5056e7 !important',
		width: '20px',
		height: '20px',
		marginLeft: '6px',
	},
	searchContainer: {
		position: 'relative',
	},
	clearSearch: {
		position: 'absolute',
		right: '10px',
		bottom: '-3px',
		cursor: 'pointer',
	}
}));

export const InvestPlatformHeader = (props) => {
    const {
        areInvestmentsPresent,
		investments,
		search: searchProp,
        searchResultsCount,
        handleSearch: handleSearchProp,
        isListView,
        hanldeSetIsListView,
        setInvestments,
		handleResetSort,
		setShowDrawer,
		showDrawer,
		handleResetFiltering,
		filtering,
		quizValue,
		isCollectionActive,
		collectionItem,
    } = props;
	const classes = useStyles();
	const [anchorElSort, setAnchorElSort] = useState(null);
	const [showSearch, setshowSearch] = useState(false);
	const [value, setValue] = useState('None');
	const [search, setSearch] = useState(searchProp);

	const textInput = React.createRef();

	useEffect(() => {
		if (showSearch) {
			textInput.current.focus();
		}
	}, [showSearch]);

	useEffect(() => {
		setSearch(searchProp);
	}, [searchProp]);

	const handleMenuOpen = (event) => {
		setAnchorElSort(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorElSort(null);
	};
	const handleSort = (event) => {
		setValue(event.target.value);
	};
	const handleSearch = (event) => {
		setSearch(event.target.value);
		handleSearchProp(event.target.value.toLowerCase());
	};
	const clearSearch = (event) => {
		setSearch('');
		handleSearchProp('');
	};
	const handleDrawerIconClick = (event) => {
		if (filtering) {
			handleResetFiltering();
		}
		setShowDrawer(!showDrawer);
	};

    return (
		<div
			className={
				!isCollectionActive ? classes.investPlatformHeader : classes.investPlatformHeaderCollection}
			id="investPlatformHeader">
			<div
				className={
					!isCollectionActive
						? showSearch
							? classes.investPlatformTextWithSearch
							: classes.investPlatformText
						: classes.collectionActive
				}
			>
				{!isCollectionActive
					? areInvestmentsPresent
						? search === ''
							?
								<p>
									{Object.values(investments).length} investment platforms
								</p>
							:
								<p
									style={{
										textAlign: 'left',
									}}
								>
									{searchResultsCount} investment platforms
								</p>
						: <Skeleton variant="text" width={165} height={48} id={`skeleton-investments-count`} />
					: null}

				{areInvestmentsPresent && isCollectionActive && collectionItem !== null
					?
						<CollectionItemInfo
							collectionItem={collectionItem}
						/>
					: null
				}
			</div>
			<div className={classes.leftSectionButtons}>
				{!showSearch ? (
					<CustomButton
						size="small"
						onClick={() => setshowSearch(!showSearch)}
						startIcon={<SearchIcon />}
					>
						Search
					</CustomButton>
				) : (
					<span className={`${classes.searchContainer}`}>
						<CustomTextField
							size="small"
							onChange={handleSearch}
							id="search"
							placeholder="Search"
							variant="standard"
							inputRef={textInput}
							value={search}
						/>
						{search !== '' &&
							<CloseIcon
								size="small"
								onClick={clearSearch}
								className={`${classes.clearSearch}`}
							/>}
					</span>
				)}
				<CustomButton
					size="small"
					onClick={handleDrawerIconClick}
					startIcon={<FilterListIcon />}
				>
					Filter {' '}
					{filtering && (
						<CloseIcon
							size="small"
							//onClick={handleResetFiltering}
							className={`${classes.activeFilter}`}
						/>
					)}
				</CustomButton>
				<CustomButton
					size="small"
					onClick={handleMenuOpen}
					startIcon={<SortIcon />}
				>
					Sort
				</CustomButton>
				{!quizValue && (
					<ViewMenu
						isListView={isListView}
						hanldeSetIsListView={hanldeSetIsListView}
					/>)
				}
			</div>
			<div style={{ clear: 'both' }}></div>
			<StyledMenu
				id="customized-menu"
				anchorEl={anchorElSort}
				keepMounted
				open={Boolean(anchorElSort)}
				onClose={handleClose}
			>
				<RadioGroup
					aria-label="gender"
					name="gender2"
					value={value}
					onChange={handleSort}
				>
					<StyledMenuItem>
						<ListItemText
							primary={
								<FormControlLabel
									value="Category"
									control={<Radio color="primary" />}
									label="Category"
									labelPlacement="start"
									onChange={() => {
										setInvestments(
											Object.values(investments).sort(sortAsc('industry'))
										);
									}}
								/>
							}
						/>
					</StyledMenuItem>
					<StyledMenuItem>
						<ListItemText
							primary={
								<FormControlLabel
									value="feesort"
									control={<Radio color="primary" />}
									label="Fees"
									labelPlacement="start"
									onChange={() => {
										setInvestments(
											Object.values(investments).sort(sortAsc('fees'))
										);
									}}
								/>
							}
						/>
					</StyledMenuItem>
					<StyledMenuItem>
						<ListItemText
							primary={
								<FormControlLabel
									value="mintarget"
									control={<Radio color="primary" />}
									label="Target return (low)"
									labelPlacement="start"
									onChange={() => {
										setInvestments(
											Object.values(investments).sort(sortTargetAsc)
										);
									}}
								/>
							}
						/>
					</StyledMenuItem>
					<StyledMenuItem>
						<ListItemText
							primary={
								<FormControlLabel
									value="maxtarget"
									control={<Radio color="primary" />}
									label="Target return (high)"
									labelPlacement="start"
									onChange={() => {
										setInvestments(
											Object.values(investments).sort(sortTargetDesc)
										);
									}}
								/>
							}
						/>
					</StyledMenuItem>
					<StyledMenuItem>
						<ListItemText
							primary={
								<FormControlLabel
									value="mininvest"
									control={<Radio color="primary" />}
									label="Min investment (low)"
									labelPlacement="start"
									onChange={() => {
										setInvestments(
											Object.values(investments).sort(sortAsc('minInvestment'))
										);
									}}
								/>
							}
						/>
					</StyledMenuItem>
					<StyledMenuItem>
						<ListItemText
							primary={
								<FormControlLabel
									value="maxinvest"
									control={<Radio color="primary" />}
									label="Min investment (high)"
									labelPlacement="start"
									onChange={() => {
										setInvestments(
											Object.values(investments).sort(sortDesc('minInvestment'))
										);
									}}
								/>
							}
						/>
					</StyledMenuItem>
					<StyledMenuItem>
						<ListItemText
							primary={
								<FormControlLabel
									value="rating"
									control={<Radio color="primary" />}
									label="Rating"
									labelPlacement="start"
									onChange={() => {
										setInvestments(
											Object.values(investments).sort(sortRatingDesc)
										);
									}}
								/>
							}
						/>
					</StyledMenuItem>
					<StyledMenuItem>
						<ListItemText
							primary={
								<FormControlLabel
									value="reset"
									control={<Radio color="primary" style={{ visibility: 'hidden' }} />}
									label="Reset"
									labelPlacement="start"
									onChange={() => {
										handleResetSort();
									}}
								/>
							}
						/>
					</StyledMenuItem>
				</RadioGroup>
			</StyledMenu>
		</div>
    )
}
