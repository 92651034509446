import React from 'react';
import Typography from '@material-ui/core/Typography';

export const OverviewText =({ investmentPlatform }) => {
	return (
	    <>
    		<Typography paragraph>
				<b>How you make money</b>
			</Typography>
			<Typography paragraph>{investmentPlatform.howMoneyText}</Typography>

			<Typography paragraph>
				<b>How {investmentPlatform.name} makes money</b>
			</Typography>
			<Typography paragraph>{investmentPlatform.platformMakesMoney}</Typography>
			<Typography paragraph>
				<b>Risks</b>
			</Typography>
			<Typography paragraph>{investmentPlatform.risks}</Typography>
        </>
	);
}
