import React, { useEffect } from 'react';

import First from '../../img/bestfor/First.svg';
import Second from '../../img/bestfor/Second.svg';
import Third from '../../img/bestfor/Third.svg';
import makeStyles from '@material-ui/core/styles/makeStyles';

import ReactGA from 'react-ga';

import { cookiesEnabled, getStorage, trackEvent } from '../../utils';
import { useSelector, shallowEqual } from 'react-redux';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles((theme) => ({
	root: {
        paddingTop: '32px',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '10px',
        },
	},
	title: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#30333E',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    container: {
        background: '#FFFFFF',
        border: '1px solid #F2F5F8',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
        borderRadius: '8px',
        minHeight: '80px',
        paddingLeft: '60px',
        paddingRight: '10px',
        display: 'flex',
        alignItems: 'center',
        marginTop: '12px',
    },
    image1: {
        backgroundImage: `url(${First})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '12px center',
    },
    image2: {
        backgroundImage: `url(${Second})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '12px center',
    },
    image3: {
        backgroundImage: `url(${Third})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '12px center',
    },
}));

export const BestFor = (props) => {
    const {
        bestFor,
    } = props;
	const classes = useStyles();
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);

	return (
        bestFor ? (<div className={classes.root}>
            <div className={classes.title}>
                Best for
            </div>
            {bestFor[0] &&
                <div className={`${classes.container} ${classes.image1}`}>
                    {bestFor[0]}
                </div>}
            {bestFor[1] &&
                <div className={`${classes.container} ${classes.image2}`}>
                    {bestFor[1]}
                </div>}
            {bestFor[2] &&
                <div className={`${classes.container} ${classes.image3}`}>
                    {bestFor[2]}
                </div>}
        </div>) : null
	)
}
