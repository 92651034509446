import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import appIosImg from '../../img/icons/appIosImg.png';
import appAndroidImg from '../../img/icons/appAndroidImg.png';

import ReactGA from 'react-ga';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

export const SidebarMore = ({ investmentPlatform }) => {
	return (
        <>
            <Typography style={{ marginBottom: '5px' }} paragraph>
				Established
			</Typography>
			<Typography style={{ color: 'black' }} paragraph>
				{investmentPlatform.yearEstablished}
			</Typography>

			<Typography style={{ marginBottom: '5px' }} paragraph>
				Assets under management
			</Typography>
			<Typography style={{ color: 'black' }} paragraph>
				{investmentPlatform.assetsUnderManagement
					? `$ ${investmentPlatform.assetsUnderManagement
							.toString()
							.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
					: 'Unknown'}
			</Typography>

			{/*
			<Typography style={{ marginBottom: '5px' }} paragraph>
				Insurance
			</Typography>
			<Typography style={{ color: 'black' }} paragraph>
				{investmentPlatform.insurance ? 'Yes' : 'No'}
			</Typography>
			*/}

			<Typography style={{ marginBottom: '5px' }} paragraph>
				APP
			</Typography>
			<Typography style={{ color: 'black' }} paragraph>
				{investmentPlatform.appIos ? (
					<img alt="appIosImg" style={{ display: 'block' }} src={appIosImg} />
				) : null}
				{investmentPlatform.appAndroid ? (
					<img
						alt="appAndroidImg"
						style={{ display: 'block', marginTop: '10px' }}
						src={appAndroidImg}
					/>
				) : null}
				{!investmentPlatform.appIos && !investmentPlatform.appAndroid ? 'No' : null}
			</Typography>
        </>
	);
}
