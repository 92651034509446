import React, { useEffect, useState, lazy } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import { deepDiveDelimiters } from '../../data/Data';
import { createMarkup } from '../../utils';
import { VisitWebsite } from '../Buttons/VisitWebsite';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});
const useStyles = makeStyles((theme) => ({
    card: {
        marginTop: '32px',
        background: '#FFFFFF',
        border: '1px solid #F2F5F8',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
        borderRadius: '8px',
        padding: '18px 18px 18px 18px',
        [theme.breakpoints.down('460')]: {
            padding: '12px',
        },
    },
    descriptionTitle: {
        fontFamily: 'Red Hat Display',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '36px',
        lineHeight: '48px',
        color: '#30333E',
        marginLeft: '10px',
        marginRight: '10px',
        marginTop: '36px',
        marginBottom: '16px',
    },
    descriptionText: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '28px',
        color: '#30333E',
        marginTop: '16px',
        marginLeft: '10px',
        marginRight: '10px',
    },
    img: {
        width: '100%',
        padding: '10px',
    },
    typeNumberTitle: {
        fontFamily: 'Red Hat Display',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '28px',
        lineHeight: '40px',
        color: '#30333E',
        marginLeft: '10px',
        marginRight: '10px',
        marginTop: '16px',
        marginBottom: '16px',
    },
    typeStepContainer: {
        display: 'flex',
        margin: '16px 10px',
    },
    typeStepTitle: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#30333E',
        //minWidth: '60px',
        whiteSpace: 'nowrap',
    },
    typeStepDesc: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#30333E',
        marginLeft: '10px',
        //marginBottom: '16px',
    },
    button: {
        width: '160px',
        padding: '20px 0 0 10px',
        [theme.breakpoints.down('460')]: {
            width: '100%',
        },
    },
}));
export const ResultAdvanced = (props) => {
    const {
        investments,
        investment,
	} = props;
    const classes = useStyles();

    const button = (
        <div className={`${classes.button}`}>
            <VisitWebsite
                investmentPlatform={investment}
                color={1}
            />
        </div>
    );

	return (
        <div className={`${classes.card}`}>
            {investments.map((i, index) => {
                const result = [];
                if (i['type'] === `${deepDiveDelimiters.mainType}`) {

                    const val = (
                        <div
                            className={`${classes.descriptionTitle}`}
                            style={index === 1 ? {marginTop: '0px'} : {} }
                            key={`mainTitle_${index}_${i}`}
                            id={i['id']}
                        >
                            {i['Title']}
                        </div>
                    );
                    result.push(val);

                    if (i['Contents'] !== 0) {
                        const limit = i['Descriptions'] || i['Contents'];

                        for (let j = 1; j <= limit; j++) {
                            // check are descriptions exist
                            if (i['Descriptions'] > 0) {
                                // detect the current description (Description1, Description1 etc.)
                                if (i['Descriptions'] >= j) {
                                    const tempArray = [];
                                    // enum description content
                                    i[`Description${j}`].forEach((z, index) => {
                                        let val = '';
                                        if (z.indexOf(deepDiveDelimiters.delimetrImg) === 0) {
                                            val = (
                                                <img
                                                    src={z.replace(deepDiveDelimiters.delimetrImg, '')}
                                                    alt={`advanced`}
                                                    className={`${classes.img}`}
                                                    key={`Description_img_${index}_${i}_${j}`}
                                                />
                                            );
                                        } else {
                                            val = (
                                                <div className={`${classes.descriptionText}`} key={`Description_${index}_${i}_${j}`}>
                                                    <span dangerouslySetInnerHTML={createMarkup(z)} />
                                                </div>
                                            );
                                        }
                                        tempArray.push(val);
                                    });
                                    result.push(tempArray);
                                }
                            }
                            if (i['Contents'] > 0) {
                                if (i['Contents'] >= j) {
                                    const tempArray = [];
                                    i[`Content${j}`].forEach((z, index) => {
                                        let val = '';
                                        if (z.indexOf(deepDiveDelimiters.delimetrImg) === 0) {
                                            val = (
                                                <img
                                                    src={z.replace(deepDiveDelimiters.delimetrImg, '')}
                                                    alt={`advanced`}
                                                    className={`${classes.img}`}
                                                    key={`Content_img_${index}_${i}_${j}`}
                                                />
                                            );
                                        } else {
                                            if (i['order'] === deepDiveDelimiters.orderBullet) {
                                                if (Array.isArray(z)) {
                                                    const tempArray = [];
                                                    z.forEach((i, index) => {
                                                        tempArray.push((
                                                            <li key={`Content_${deepDiveDelimiters.orderBullet}_${index}_${i}_${j}`}>
                                                                <span dangerouslySetInnerHTML={createMarkup(i)} />
                                                            </li>
                                                        ));
                                                    })
                                                    val = (
                                                        <ul className={`deepDiveBullet`}>
                                                            {tempArray}
                                                        </ul>
                                                    );
                                                } else {
                                                    val = (
                                                        <li key={`Content_${deepDiveDelimiters.orderBullet}_${index}_${i}_${j}`}>
                                                            <span dangerouslySetInnerHTML={createMarkup(z)} />
                                                        </li>
                                                    );
                                                }
                                            } else {
                                                if (i['order'] === deepDiveDelimiters.orderNumber) {
                                                    if (z.indexOf(deepDiveDelimiters.delimetr) > 0) {
                                                        const title = z.split(deepDiveDelimiters.delimetr)[0];
                                                        const desc = z.split(deepDiveDelimiters.delimetr)[1];

                                                        if (Array.isArray(desc)) {
                                                            const tempArray = [];
                                                            desc.forEach((i, index) => {
                                                                tempArray.push((
                                                                    <li key={`Content_${deepDiveDelimiters.orderBullet}_${index}_${i}_${j}`}>
                                                                        <span dangerouslySetInnerHTML={createMarkup(i)} />
                                                                    </li>
                                                                ));
                                                            })
                                                            val = (
                                                                <ul className={`deepDiveBullet`}>
                                                                    {tempArray}
                                                                </ul>
                                                            );
                                                        } else {
                                                            val = (
                                                                <div key={`Content_${deepDiveDelimiters.orderNumber}_${index}_${i}_${j}`}>
                                                                    <div className={`${classes.typeNumberTitle}`}>
                                                                        {title}
                                                                    </div>
                                                                    <div className={`${classes.descriptionText}`}>
                                                                        <span dangerouslySetInnerHTML={createMarkup(desc)} />
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    } else {
                                                        if (Array.isArray(z)) {
                                                            const tempArray = [];
                                                            z.forEach((i, index) => {
                                                                tempArray.push((
                                                                    <li key={`Content_${deepDiveDelimiters.orderBullet}_${index}_${i}_${j}`}>
                                                                        <span dangerouslySetInnerHTML={createMarkup(i)} />
                                                                    </li>
                                                                ));
                                                            });
                                                            val = (
                                                                <ul className={`deepDiveBullet`}>
                                                                    {tempArray}
                                                                </ul>
                                                            );
                                                        } else {
                                                            val = (
                                                                <div
                                                                    className={`${classes.descriptionText}`}
                                                                    key={`Content_${deepDiveDelimiters.orderNumber}_${index}_${i}_${j}`}
                                                                >
                                                                    <span dangerouslySetInnerHTML={createMarkup(z)} />
                                                                </div>
                                                            );
                                                        }
                                                    }
                                                } else {
                                                    if (i['order'] === deepDiveDelimiters.orderStep) {
                                                        if (z.indexOf(deepDiveDelimiters.delimetr) > 0) {
                                                            const title = z.split(deepDiveDelimiters.delimetr)[0];
                                                            const desc = z.split(deepDiveDelimiters.delimetr)[1];
                                                            if (Array.isArray(desc)) {
                                                                const tempArray = [];
                                                                desc.forEach((i, index) => {
                                                                    tempArray.push((
                                                                        <li key={`Content_${deepDiveDelimiters.orderStep}_${index}_${i}_${j}`}>
                                                                            <span dangerouslySetInnerHTML={createMarkup(i)} />
                                                                        </li>
                                                                    ));
                                                                })
                                                                val = (
                                                                    <ul className={`deepDiveBullet`}>
                                                                        {tempArray}
                                                                    </ul>
                                                                );
                                                            } else {
                                                                val = (
                                                                    <div
                                                                        className={`${classes.typeStepContainer}`}
                                                                        key={`Content_${deepDiveDelimiters.orderStep}_${index}_${i}_${j}`}
                                                                    >
                                                                        <div className={`${classes.typeStepTitle}`}>
                                                                            {title}
                                                                        </div>
                                                                        <div className={`${classes.typeStepDesc}`}>
                                                                            <span dangerouslySetInnerHTML={createMarkup(desc)} />
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        } else {
                                                            if (Array.isArray(z)) {
                                                                const tempArray = [];
                                                                z.forEach((i, index) => {
                                                                    tempArray.push((
                                                                        <li key={`Content_${deepDiveDelimiters.orderStep}_${index}_${i}_${j}`}>
                                                                            <span dangerouslySetInnerHTML={createMarkup(i)} />
                                                                        </li>
                                                                    ));
                                                                })
                                                                val = (
                                                                    <ul className={`deepDiveBullet`}>
                                                                        {tempArray}
                                                                    </ul>
                                                                );
                                                            } else {
                                                                val = (
                                                                    <div
                                                                        className={`${classes.descriptionText}`}
                                                                        key={`Content_${deepDiveDelimiters.orderStep}_${index}_${i}_${j}`}
                                                                    >
                                                                        <span dangerouslySetInnerHTML={createMarkup(z)} />
                                                                    </div>
                                                                )
                                                            }
                                                        }
                                                    } else {
                                                        if (Array.isArray(z)) {
                                                            const tempArray = [];
                                                            z.forEach((i, index) => {
                                                                tempArray.push((
                                                                    <li key={`Content_${deepDiveDelimiters.orderNone}_${index}_${i}_${j}`}>
                                                                        <span dangerouslySetInnerHTML={createMarkup(i)} />
                                                                    </li>
                                                                ));
                                                            })
                                                            val = (
                                                                <ul className={`deepDiveBullet`}>
                                                                    {tempArray}
                                                                </ul>
                                                            );
                                                        } else {
                                                            val = (
                                                                <div
                                                                    className={`${classes.descriptionText}`}
                                                                    key={`Content_${deepDiveDelimiters.orderStep}_${index}_${i}_${j}`}
                                                                >
                                                                    <span dangerouslySetInnerHTML={createMarkup(z)} />
                                                                </div>
                                                            )
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        tempArray.push(val);
                                    });
                                    if (i['order'] === deepDiveDelimiters.orderBullet) {
                                        const val = (
                                            <div className={`deepDiveBullet`} key={`Contents_${deepDiveDelimiters.orderBullet}_${index}_${i}_${j}`}>
                                                <ul>
                                                    {tempArray}
                                                </ul>
                                            </div>
                                        );
                                        result.push(val);
                                    } else {
                                        result.push(tempArray);
                                    }
                                    if (i["showButton"]) {
                                        result.push(button);
                                    }
                                }
                            }
                        }
                        //result.push(i['Content1'][0]);
                    }
                }
                return result;
            })}
        </div>
	);
};
