import Avatar from '@material-ui/core/Avatar';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { userAbbr } from '../../utils/index';
import { routeNames } from '../../data/Data';

const useStyles = makeStyles({
	avatar: {
		fontSize: '16px',
		cursor: 'pointer',
	},
	avatarStyle: {
		marginRight: '10px',
		marginTop: '16px',
		marginBottom: '16px',
	},
	purpleAvatar: {
		backgroundColor: '#EEEFFD',
		color: '#5056E7',
	},
	greenAvatar: {
		backgroundColor: '#EBFAF1',
		color: '#33CD71',
	},
	blueAvatar: {
		backgroundColor: '#ECF5FF',
		color: '#3E94F9',
	},
});

const randomArrayEntry = (array) => array[Math.floor(Math.random() * array.length)];

export const UserAvatar = (props) => {
	const { name, clickIgnore, styleignore, size, randomColor, avatarUrl } = props;
	const history = useHistory();
	const classes = useStyles();
	const { avatar, purpleAvatar, greenAvatar, blueAvatar } = useStyles();
	const availableColors = [purpleAvatar, greenAvatar, blueAvatar];

	const randomColorClass = useMemo(() => randomArrayEntry(availableColors), [size]);

	const handleClick = () => {
		if (clickIgnore) {
			return;
		}
		history.push(`/${routeNames.dashboard}`);
	};
	//console.log(avatarUrl);

	return (
		<Avatar
			className={`${styleignore ? classes.avatarStyle : ''} ${avatar} ${
				randomColor ? randomColorClass : classes.blueAvatar
			}`}
			onClick={handleClick}
			style={{ width: `${size}px`, height: `${size}px` }}
			src={avatarUrl || null}
		>
			{name ? userAbbr(name) : 'A'}
		</Avatar>
	);
};
