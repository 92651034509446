import React, { useState, useEffect } from 'react';
import { getReviews } from '../../Services/ReviewsAPITrade';
import { Spinner } from '../Spinner/Spinner';
import InvestmentPlatformReview from './Review';
import ReviewsStats from './ReviewsStats';
import LazyLoad from 'react-lazyload';
import ReviewsActions from './ReviewsActions';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
	loader: {
		width: '100%',
		height: '200px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
});

const reviewOrderings = [
	{
		name: 'rating-high-to-low',
		label: 'Rating (high to low)',
		comparator: (review, otherReview) => otherReview.rating - review.rating,
	},
	{
		name: 'rating-low-to-high',
		label: 'Rating (low to high)',
		comparator: (review, otherReview) => review.rating - otherReview.rating,
	},
	{
		name: 'date-newest-first',
		label: 'Date (newest first)',
		comparator: (review, otherReview) => otherReview.timestamp - review.timestamp,
	},
	{
		name: 'date-oldest-first',
		label: 'Date (oldest first)',
		comparator: (review, otherReview) => review.timestamp - otherReview.timestamp,
	},
];

const sortReviews = ({ comparator }, reviews) => [...reviews].sort(comparator);

export default function InvestmentPlatformReviews({
	investmentPlatform,
	onCreateReview,
}) {
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(true);
	const [currentOrdering, setCurrentOrdering] = useState(reviewOrderings[0]);
	const [reviews, setReviews] = useState([]);

	useEffect(() => {
		const effect = async () => {
			const reviews = await getReviews(investmentPlatform.id);
			setReviews(sortReviews(currentOrdering, reviews));
			setIsLoading(false);
		};

		setIsLoading(true);
		effect();
	}, [investmentPlatform.id]);

	const applyOrdering = (ordering) => {
		const sortedReviews = sortReviews(ordering, reviews);
		setReviews(sortedReviews);
		setCurrentOrdering(ordering);
	};

	const onReviewUpdate = (updatedReview) => {
		const oldReviewIndex = reviews.findIndex(
			(review) => review.id === updatedReview.id
		);
		const updatedReviews = [...reviews];
		updatedReviews[oldReviewIndex] = {
			...reviews[oldReviewIndex],
			...updatedReview,
		};
		setReviews(updatedReviews);
	};

	return (
		<React.Fragment>
			{isLoading ? (
				<div className={classes.loader}>
					<Spinner displaySpinner />
				</div>
			) : (
				<React.Fragment>
					<ReviewsStats investmentPlatform={investmentPlatform} reviews={reviews} />

					<ReviewsActions
						onCreateReview={onCreateReview}
						orderings={reviewOrderings}
						selectedOrdering={currentOrdering}
						onOrderingUpdate={applyOrdering}
					/>

					{reviews.map((review) => (
						<LazyLoad height={100} once>
							<InvestmentPlatformReview
								key={review.id}
								platformId={investmentPlatform.id}
								review={review}
								onUpdate={onReviewUpdate}
							/>
						</LazyLoad>
					))}
				</React.Fragment>
			)}
		</React.Fragment>
	);
}
