import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { makeStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import { CustomWidgetCard } from '../components/CustomWidget/CustomWidgetCard';
import { eventTracker } from '../utils';
import { routeNames } from '../data/Data';
import * as actions from '../redux/actions';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles((theme) => ({
	expandIcon: {
		position: 'absolute',
		top: '12px',
		right: '12px',
	},
	embedContent: {
		justifyContent: 'center',
		margin: 'auto',
	},
}));

const CustomWidget = (props) => {
	const { utm_campaign, utm_medium, utm_source, w, h, i } = queryString.parse(
		props.location.search
	);
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const [paidOnly, setPaidOnly] = useState(false);
	const dispatch = useDispatch();
	const classes = useStyles();
	useEffect(() => {
		if (state.userId) {
			ReactGA.pageview(`/${routeNames.customWidget}`);
			eventTracker("pageview", `/${routeNames.customWidget}`, utm_source, utm_medium, utm_campaign, state.userId);
		}
		if (utm_campaign === 'paidOnly') {
			setPaidOnly(true);
		}
		if (utm_source) {
			dispatch(actions.setItemSource(utm_source));
		}
		if (utm_campaign) {
			dispatch(actions.setItemCompaign(utm_campaign));
		}
		if (utm_medium) {
			dispatch(actions.setItemMedium(utm_medium));
		}
	}, [utm_campaign, utm_medium, utm_source, w, h, i, state.userId]);
	return (
		<div className={classes.embedContent}>
			<CustomWidgetCard
				utmParams={{ utm_campaign, utm_medium, utm_source, w, h, i }}
				paidOnly={paidOnly}
			/>
		</div>
	);
};
export default CustomWidget;
