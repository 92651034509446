import { REMOTEAPI_BASE_URL } from './ConstAPI';

const makeURL = path => `${REMOTEAPI_BASE_URL}/${path}`;

const defaultHeaders = {
	'Content-Type': 'application/json; charset=utf-8'
};

const makeRequestData = data => ({
	method: 'POST',
	headers: defaultHeaders,
	body: JSON.stringify(data)
});

const makeRequest = async (path, data) => {
	const url = makeURL(path);
	const requestData = makeRequestData(data);

	return await fetch(url, requestData);
};

const fetchJSON = async (path, data) => {
	const response = await makeRequest(path, data);
	return await response.json();
};

export const getMentions = async (platformId, rating = null) => {
	const response = await makeRequest('getMentions', {
		platformType: 1,
		platformId,

		...(rating && { rating })
	});
	const mentions = await response.json();

	return mentions;
};
