import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	root: { padding: '0 24px 16px 24px' }
});

export default function InvestmentPlatformOverview({ investmentPlatform }) {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Typography paragraph>
				<b>What you can trade</b>
			</Typography>
			<Typography paragraph>
				<ul>
					{investmentPlatform.trades.map(platform => (
						<li key={platform}>{platform}</li>
					))}
				</ul>
			</Typography>

			<Typography paragraph>
				<b>Promotions</b>
			</Typography>
			<Typography paragraph>{investmentPlatform.promotion}</Typography>
		</div>
	);
}
