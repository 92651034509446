import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SimilarPlatforms from './SimilarPlatforms';

const useStyles = makeStyles((theme) => ({
	innerSimilarCards: {
		display: 'flex',
		alignItems: 'flex-start',
		padding: '0 0 0 62px',
		width: '70%',
	},
	similarHeader: {
		fontWeight: '500',
		fontSize: '16px',
		lineHeight: '24px',
		marginLeft: '1em',
		padding: '0 8px 0 62px',
	},
	[theme.breakpoints.down('sm')]: {
		innerSimilarCards: { width: '100%', flexDirection: 'column', padding: '0 16px' },
	},
}));

export const SimilarPlatformsCard = (props) => {
    const {
        similarPlatforms,
        setActivePlatform,
        expanded,
        resetTotal,
        search,
        expandable,
        deepDive,
        handleMoveToListView,
		isListView,
    } = props;
    const classes = useStyles();
    return (
        <>
            <h3 className={classes.similarHeader}>Similar investment platforms</h3>
            <div className={classes.innerSimilarCards}>
                <SimilarPlatforms
                    setActivePlatform={setActivePlatform}
                    expanded={expanded}
                    similarPlatforms={similarPlatforms}
                    resetTotal={resetTotal}
                    search={search}
                    expandable={expandable}
                    deepDive={deepDive}
                    handleMoveToListView={handleMoveToListView}
					isListView={isListView}
                />
            </div>
        </>
    )
}
