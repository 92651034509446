import React from 'react';

import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

import Rating from '../Rating';
import { useReviewStyles } from './review-styles';
import { Collapse } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { BleakButton } from '../BleakButton';

const ratings = [5, 4, 3, 2, 1];
const sentimentByRating = {
	1: { text: 'Bad', color: 'red' },
	2: { text: 'Poor', color: 'red' },
	3: { text: 'Average', color: 'blue' },
	4: { text: 'Good', color: 'green' },
	5: { text: 'Excellent', color: 'green' }
};

const useStyles = makeStyles(theme => ({
	root: {
		paddingBottom: '20px'
	},
	shortStats: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginTop: '-4px'
	},
	aggregatedReviews: {
		display: 'flex',
		alignItems: 'center'
	},
	heading: {
		color: '#30333E !important',
		fontSize: '14px',
		fontWeight: 'bold',
		margin: 0
	},
	rating: {
		marginLeft: '14px',
		verticalAlign: 'text-bottom'
	},
	aggregatedScoreText: {
		margin: '0 0 0 14px !important'
	},
	fullStatsSwitch: {
		width: '126px'
	},
	ratingRow: {
		display: 'flex',
		width: '100%',
		alignItems: 'center'
	},
	ratingBarsBlock: {
		paddingTop: '10px'
	},
	ratingBar: {
		flexGrow: 1,
		borderRadius: '10px'
	},
	ratingBarSentiment: {
		width: '96px'
	},
	reviewsPercentage: {
		width: '44px',
		textAlign: 'right'
	},
	[theme.breakpoints.down('xs')]: {
		shortStats: {
			flexWrap: 'wrap'
		},
		fullStatsSwitch: {
			marginTop: '20px'
		}
	}
}));

export default function ReviewsStats({ reviews, investmentPlatform }) {
	const classes = useStyles();
	const commonClasses = useReviewStyles();
	const [fullStatsVisible, setFullStatsVisible] = React.useState(true);

	const aggregatedRating = investmentPlatform.averageRating || null;
	const aggregatedSentiment = sentimentByRating[Math.round(aggregatedRating)];
	//console.log(aggregatedRating);

	const toggleFullStats = () => setFullStatsVisible(!fullStatsVisible);

	return (
		<div className={`${classes.root} ${commonClasses.reviewsBlockRow}`}>
			<div className={classes.shortStats}>
				<div className={classes.aggregatedReviews}>
					<span className={classes.heading}>Reviews: {reviews.length}</span>

					{aggregatedRating && (
						<React.Fragment>
							<Rating className={classes.rating} value={aggregatedRating} />

							<Chip
								label={aggregatedSentiment.text}
								className={`ctag ${aggregatedSentiment.color} ${classes.aggregatedScoreText}`}
							/>
						</React.Fragment>
					)}
				</div>

				<BleakButton className={classes.fullStatsSwitch} onClick={toggleFullStats}>
					{fullStatsVisible ? 'Hide' : 'Show'} statistics
				</BleakButton>
			</div>

			<Collapse in={fullStatsVisible}>
				<div className={classes.ratingBarsBlock}>
					{ratings.map(rating => {
						const sentiment = sentimentByRating[rating];
						const reviewsWithRating = reviews.filter(
							review => review.rating === rating
						);
						const reviewsPercentage =
							reviews.length !== 0
								? Math.round((reviewsWithRating.length / reviews.length) * 100)
								: 0;

						return (
							<div key={rating} className={classes.ratingRow}>
								<div className={classes.ratingBarSentiment}>{sentiment.text}</div>

								<LinearProgress
									className={classes.ratingBar}
									variant="determinate"
									value={reviewsPercentage}
								/>

								<div className={classes.reviewsPercentage}>{reviewsPercentage}%</div>
							</div>
						);
					})}
				</div>
			</Collapse>
		</div>
	);
}
