import React from 'react';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import expandIcon from '../../img/icons/external-link.svg';
import Tooltip from '@material-ui/core/Tooltip';
import TextTruncate from 'react-text-truncate';
import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import InvestmentPlatformAvatar from './Avatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Rating from '../Rating';
import { getSentimentText } from './helpers';
import { trackEvent, eventTracker } from '../../utils';
import { useSelector, shallowEqual } from 'react-redux';

const useStyles = makeStyles({
	borderedColumn: {
		position: 'relative',

		'& .pseudoborder': {
			height: 'calc(100% - 40px) !important',
			top: '28px',
			left: '-24px',
			margin: '0 !important',
		},
	},
	secondColumn: {
		paddingRight: '32px',
	},
	subheader: {
		//display: 'flex',
		alignItems: 'center',
		maxWidth: '220px',
		marginTop: '5px',
	},
	rating: {
		marginLeft: '8px',
	},
	tooltip: {
		zIndex: 10001, // to be visible inside the modal
	},
});

export default function Header(props) {
	const { investmentPlatform } = props;
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const [copySuccess, setCopySuccess] = React.useState(false);
	const classes = useStyles();

	function clickCopy(url) {
		//console.log(url);
		copyToClipboard(url);
		setCopySuccess(true);
		setTimeout(() => setCopySuccess(false), 1200);
	}

	function copyToClipboard(copyText) {
		var textField = document.createElement('textarea');
		textField.innerText = copyText;
		document.body.appendChild(textField);
		textField.select();
		document.execCommand('copy');
		textField.remove();
	}

	function stopPropagation(event) {
		trackEvent('ExternalClick', 'platform', investmentPlatform.name);
		eventTracker("ExternalClick", investmentPlatform.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
		event.stopPropagation();
	}

	return (
		<Grid container alignItems="flex-start">
			<Grid className="firstgrid" item xs={12}>
				<CardHeader
					avatar={
						<Tooltip
							disableFocusListener
							disableTouchListener
							classes={{ popper: classes.tooltip }}
							placement="top"
							title={getSentimentText(investmentPlatform.sentiment)}
							arrow
						>
							<span>
								<InvestmentPlatformAvatar investmentPlatform={investmentPlatform} />
							</span>
						</Tooltip>
					}
					title={
						<div>
							<Tooltip
								disableFocusListener
								disableTouchListener
								classes={{ popper: classes.tooltip }}
								placement="top"
								title={`Visit site`}
								arrow
							>
								<a
									target="_blank"
									rel="noopener noreferrer"
									style={{ cursor: 'pointer' }}
									href={investmentPlatform.website}
									onClick={stopPropagation}
								>
									{investmentPlatform.name}
									<img
										alt="expandIcon"
										style={{ marginBottom: '-2px', marginLeft: '5px' }}
										src={expandIcon}
									/>
								</a>
							</Tooltip>
						</div>
					}
					subheader={
						<div className={classes.subheader}>
							<Chip
								style={{marginRight: '0px'}}
								label={investmentPlatform.industry}
								className="ctag gray"
							/>

							{(investmentPlatform.averageRating || null) && (
								<Rating
									className={classes.rating}
									value={investmentPlatform.averageRating}
								/>
							)}
						</div>
					}
				/>

				<CardContent style={{ paddingTop: '0px', paddingRight: '24px' }}>
					<Typography
						variant="body2"
						color="textSecondary"
						component="p"
						style={{
							maxWidth: '335px',
							marginLeft: '56px',
							color: '#73757A',
							textOverflow: 'ellipsis',
							wordWrap: 'break-word',
							overflow: 'hidden',
							maxHeight: '5.6em',
							lineHeight: '1.4em',
						}}
					>
						<TextTruncate
                            line={3}
                            element="span"
                            truncateText="…"
                            text={investmentPlatform.description}
                            textTruncateChild={<a style={{ color: '#5056e7' }}>more</a>}
                        />
					</Typography>
				</CardContent>
				<CardActions disableSpacing></CardActions>
			</Grid>
		</Grid>
	);
}
