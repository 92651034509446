import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ReactGA from 'react-ga';
import { Button } from '@material-ui/core';
import { eventTracker, trackEvent, generateSubId } from '../../utils';
import { useSelector, shallowEqual } from 'react-redux';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles({
	button: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '20px',
		textTransform: 'none',
		margin: 'auto',
		maxWidth: '1200px',
        color: '#5056E7',
        border: '1px solid #5056E7',
        boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
        boxSizing: 'border-box',
        //borderRadius: '8px',
        '&:hover': {
			backgroundColor: '#F2F5F8',
		},
	},
});

export const DeepDiveButton = (props) => {
	const {
        href,
		name,
		text: textProp,
    } = props;
	const classes = useStyles();
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const history = useHistory();

	const text = textProp ? textProp : `Deep Dive Review`;

	const stopPropagation = (event) => {
		trackEvent('DeepDive', 'platform', name);
		eventTracker(
			'DeepDive',
			name,
			state.itemSource,
			state.itemMedium,
			state.itemCompaign,
			state.userId
		);
		event.stopPropagation();
		history.push(href);
	};

	return (
		<Button
			fullWidth={true}
			//href={href}
			className={`${classes.button}`}
			rel="noopener noreferrer"
            onClick={stopPropagation}
            variant="outlined"
		>
			{text}
		</Button>
	);
};
