import React, { useEffect, useState, lazy } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from 'react-router-dom';
import { isPaidOnly, detectRouteUrl, detectPlatformBySlug, detectPlatform, generateSubId, changeUrls } from '../../utils';
import { AppBarComponent } from '../AppBar/AppBarComponent';
import { ModalSignUp } from '../Modal/ModalSignUp';
import * as actions from "../../redux/actions";
import { getFavorite } from '../../Services/GetFavoriteAPI';
// import { checkUser } from '../../Services/CheckUserAPI';
import { routeNames, deepDiveDelimiters } from '../../data/Data';
import SimilarPlatforms from '../InvestmentPlatform/SimilarPlatforms';
import { BestFor } from './BestFor';
import { ResultCard } from './ResultCard';
import { ResultAdvanced } from './ResultAdvanced';
import { ProsCons } from './ProsCons';
import { SkeletonPost } from '../Stream/SkeletonPost';
import { TableOfContents } from './TableOfContents';
import { IntroCard } from './IntroCard';
import { BackToButton } from '../Buttons/BackToButton';
import { loginUser, checkAuth, getToken } from '../../Firebase/firebase-service';
import { HelmetCard } from '../Helmet/HelmetCard';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});
const useStyles = makeStyles((theme) => ({
	toolbar: theme.mixins.toolbar,
	toolbar4Banner: {
		minHeight: 50,
		[theme.breakpoints.up('xs')]: {
			minHeight: 50,
		},
		[theme.breakpoints.up('sm')]: {
			minHeight: 50,
		},
	},
    container: {
        maxWidth: '800px',
        width: '100%',
        margin: '0 auto',
    },
    result: {},
    card: {
        marginTop: '32px',
        background: '#FFFFFF',
        border: '1px solid #F2F5F8',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
        borderRadius: '8px',
        padding: '18px 0 18px 18px',
        [theme.breakpoints.down('460')]: {
            padding: '12px',
        },
    },
    cardPadding: {
    },
    cardProsContainer: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('960')]: {
            flexDirection: 'column',
        },
    },
    cardPros: {
        maxWidth: '390px',
        width: '100%',
    },
    cardRight: {
        marginLeft: '20px',
        [theme.breakpoints.down('960')]: {
            marginLeft: '0px',
        },
    },
    prosConsTitle: {
        fontFamily: 'Red Hat Display',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '28px',
        lineHeight: '40px',
        marginLeft: '10px',
    },
    green: {
        color: '#33CD71',
    },
    red: {
        color: '#F04362',
    },
    titleSmall: {
        paddingTop: '24px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#73757A',
    },
    hl: {
        width: '334px',
        maxWidth: '100%',
        height: '1px',
        background: '#F2F5F8',
        marginTop: '8px',
        marginBottom: '19px',
        marginLeft: '10px',
    },
    topCard: {
        marginTop: '32px',
        marginBottom: '20px',
    },
    searchContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    backButton: {
		fontSize: '14px',
		lineHeight: '20px',
		color: '#73757A',
        textTransform: 'none',
        marginBottom: '24px',
    },
    title: {
        fontFamily: 'Red Hat Display',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '28px',
        lineHeight: '40px',
        color: '#30333E',
        marginLeft: '12px',
        [theme.breakpoints.down('500')]: {
            textAlign: 'center',
            fontSize: '32px',
            lineHeight: '44px',
        },
    },
    favorites: {
        display: 'flex',
        alignItems: 'center',
        background: '#F2F5F8',
        border: '1px solid #DBE0E6',
        boxSizing: 'border-box',
        borderRadius: '100px',
        height: '32px',
        cursor: 'pointer',
        paddingRight: '10px',
    },
    favoritesText: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '20px',
        color: '#30333E',
        paddingRight: '4px',
        paddingLeft: '4px',
    },
    marginStyle: {
        marginTop: '18px',
    },
    hidden: {
        display: 'none',
    },
    postsContainer: {
        //marginBottom: '10px',
        padding: '18px 10px',
    },
    grey: {
        //background: '#fafbfe',
    },
    commentsContainer: {
        //marginBottom: '10px',
        marginLeft: '40px',
    },
    like: {
        marginLeft: '8px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '20px',
        color: '#73757A',
        cursor: 'pointer',
    },
    innerSimilarCards: {
		display: 'flex',
		alignItems: 'flex-start',
		//padding: '0 0 0 62px',
		//width: '70%',
	},
	similarHeader: {
		fontWeight: '500',
		fontSize: '16px',
		lineHeight: '24px',
		marginLeft: '1em',
		//padding: '0 8px 0 62px',
	},
	[theme.breakpoints.down('sm')]: {
		innerSimilarCards: { width: '100%', flexDirection: 'column', padding: '0 16px' },
	},
}));
export const DeepdivePage = (props) => {
    const {
		utmParams,
		expandable,
		expandableBannerShow,
	} = props;
    const classes = useStyles();
    const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
    const dispatch = useDispatch();
    const history = useHistory();
    const { url: currentUrl } = useRouteMatch();
    const match = useRouteMatch(`${currentUrl}/:platformId`);

    const [paidOnly, setPaidOnly] = useState(isPaidOnly(state.itemCompaign));
    const [medium, setMedium] = useState(state.itemMedium);
    const [showMenu, setshowMenu] = useState(false);
    const [displaySpinner, setDisplaySpinner] = useState(true);
    const [mobileOpen, setMobileOpen] = useState(false)
    const [openModalSignUp, setOpenModalSignUp] = useState(state.modalSignUpOpen);
    const [investment, setInvestment] = useState(null);
    const [investmentAdvanced, setInvestmentAdvanced] = useState(null);
    const [loadByRoute, setLoadByRoute] = useState(false);
    const [similarInvestments, setSimilarInvestments] = useState([]);
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDesc, setMetaDesc] = useState('');

    // get favorites
	useEffect(() => {
		if (state.loggedIn) {
			getFavorite(state.token)
				.then((data) => {
					dispatch(actions.addInvesmentsToFavorites(data.result));
				})
				.catch((error) => {
					console.log(error);
				});
		}
    }, [state.loggedIn]);

    // check is user logged in
    useEffect(() => {
		checkAuth(callbackAuth);
    }, []);

    // toggle modal
    useEffect(() => {
		if (state.modalSignUpOpen) {
			setOpenModalSignUp(true);
		} else {
			setOpenModalSignUp(false);
		}
    }, [state.modalSignUpOpen]);

    // wait until all platforms are loaded
    useEffect(() => {
		if (Object.values(state.investmentsTotal).length > 0) {
			setLoadByRoute(true);
		}
    }, [state.investmentsTotal]);

    // check url string for match
    useEffect(() => {
        if (loadByRoute) {
            if (detectRoute()) {
                handleRoute();
            } else {
                setDisplaySpinner(false);
            }
        }
    }, [loadByRoute]);

    // get similar platforms
    useEffect(() => {
        if (investment !== null) {
            getSimilar(investment);
        }
    }, [investment])

    // update urls
    useEffect(() => {
        if (investmentAdvanced !== null) {
            if (investment !== null) {
                const url = investment.website.replace('%SUBID%', generateSubId(state.itemSource, state.itemMedium));
                changeUrls(url);
            }
        }
    }, [investmentAdvanced]);

    useEffect(() => {
        if (investmentAdvanced !== null) {
            const data = investmentAdvanced.filter((i) => i.type === deepDiveDelimiters.meta);
            if (data.length > 0) {

                setMetaTitle(data[0].title);
                setMetaDesc(data[0].desc);
            }
        }
    }, [investmentAdvanced]);

	const callbackAuth = async (currentUser) => {
		if (currentUser !== null) {
			const token = await currentUser.getIdToken();
			dispatch(actions.setToken(token));
			dispatch(actions.setLoggedInStatus(true));
		} else {
			dispatch(actions.setLoggedInStatus(false));
		}
	};
    // get platform by slug
    const getPlatforms = () => {
        if (!detectRouteUrl(match)) {
            return [];
        }
        const id = match.params.platformId;
        //const slug = id.split("-")[0];
        const slug = id.substr(0,id.lastIndexOf("-"));
        return detectPlatformBySlug(state.investmentsTotal, slug);
    }
    const detectRoute = () => {
        const platforms = getPlatforms();
        if (platforms.length > 0) {
            return true;
        } else {
            return false;
        }
    }
    const handleRoute = async () => {
        const platformLimited = getPlatforms();
        const investPlatform = detectPlatform(state.investmentsTotal, platformLimited[0]);
        setInvestment(investPlatform);
        try {
            const { platform } = await import(`../../data/deepdive/${investPlatform.slug.toLowerCase()}`);
            setInvestmentAdvanced(platform);
        } catch (e) {
            setInvestmentAdvanced(null);
        }
        setDisplaySpinner(false);
    }
    const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
    };
    const handleBack = () => {
        history.push(`/${routeNames.discover}/${investment.slug}`);
    }
    const getSimilar = (investment) => {
        const similarInvestments = investment.similars.map((similar) => {
            const similarInvestment = Object.values(state.investmentsTotal).find(
                (investment) => investment.id === similar
            );
            return similarInvestment;
        });
        setSimilarInvestments(similarInvestments);
    }
    const setActivePlatform = (investment) => {
        history.push(`/${routeNames.discover}/${investment.slug}`);
    }
	return (
        <>
        {investmentAdvanced && (
            <HelmetCard
                title={`${metaTitle} by MoneyMade.io`}
                desc={metaDesc}
            />)}
        <div className={`respCont ${classes.root}`}>
            <CssBaseline />
            <AppBarComponent
                utmParams={utmParams}
                medium={medium}
                showMenu={showMenu}
                paidOnly={paidOnly}
                expandable={expandable}
                handleDrawerToggle={handleDrawerToggle}
                expandableBannerShow={expandableBannerShow}
            />
            <main className={`${classes.container} mainPrinc`}>
                <div className={expandable ? classes.toolbar4Banner : classes.toolbar} />
                {displaySpinner ?
               <div>
                    <SkeletonPost />
                    <SkeletonPost />
                    <SkeletonPost />
                    <SkeletonPost />
                    <SkeletonPost />
                    <SkeletonPost />
               </div> :
                <div>
                    <div className={`${classes.topCard}`}>
                        <BackToButton
                            handleBack={handleBack}
                            text={`Back to Investing page`}
                        />
                    </div>
                    <div className={`${classes.result}`}>
                        {investment &&
                        <ResultCard
                            investment={investment}
                            investmentAdvanced={investmentAdvanced}
                        />}
                    </div>
                    {investmentAdvanced !== null &&
                        <IntroCard
                            investmentAdvanced={investmentAdvanced}
                        />}
                    {investmentAdvanced !== null && <div className={`${classes.cardProsContainer} prosCons`}>
                        {investmentAdvanced !== null &&
                        <ProsCons
                            investmentAdvanced={investmentAdvanced}
                        />}
                    </div>}
                    <div className={`${classes.titleSmall}`}>
                        {investmentAdvanced !== null && <div>Best for</div>}
                    </div>
                    {investmentAdvanced !== null &&
                        <BestFor
                            investmentAdvanced={investmentAdvanced}
                        />}
                    {investmentAdvanced !== null &&
                        <TableOfContents
                            investments={investmentAdvanced}
                        />}
                    {investmentAdvanced !== null &&
                        <ResultAdvanced
                            investment={investment}
                            investments={investmentAdvanced}
                        />}
                    {similarInvestments.length > 0 &&
                    <div className={`${classes.card}`}>
                        <h3 className={classes.similarHeader}>Similar investment platforms</h3>
                        <div className={classes.innerSimilarCards}>
                            <SimilarPlatforms
                                setActivePlatform={setActivePlatform}
                                expanded={true}
                                similarPlatforms={similarInvestments}
                                resetTotal={() => {}}
                                search={``}
                                expandable={expandable}
                                deepDive={true}
                            />
                        </div>
                    </div>}
                </div>
            }
            </main>
            <ModalSignUp
				open={openModalSignUp}
			/>
        </div>
        </>
	);
};
