import React from 'react';
import moment from 'moment';

import CheckIcon from '@material-ui/icons/Check';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { useReviewStyles } from './review-styles';

const useStyles = makeStyles(theme => ({
	root: {
		paddingBottom: '24px',
		marginTop: '24px',

		'& p': {
			marginTop: 0
		}
	},
	reviewHeading: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	headingSubsection: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '12px'
	},
	reviewerName: {
		color: '#30333E',
		fontSize: '14px',
		fontWeight: 'bold',
		margin: '0 0 0 16px'
	},
	rating: {
		marginLeft: '10px'
	},
	verifiedBadge: {
		fontSize: '14px',
		color: '#33CD71',
		margin: 0,

		'& span': { verticalAlign: 'middle' }
	},
	verifiedIcon: {
		fontSize: '16px',
		verticalAlign: 'middle'
	},
	bump: {
		marginLeft: '16px'
	},
	twitterLink: {
		color: '#5056E7',
		fontSize: '14px'
	},
	comment: {
		fontSize: '14px',
		lineHeight: '20px',
		color: '#30333E',
		marginBottom: '12px'
	},
	date: {
		fontSize: '14px',
		color: '#73757A',
		textAlign: 'left',
		marginBottom: 0
	},
	[theme.breakpoints.down('xs')]: {
		reviewHeading: {
			flexWrap: 'wrap'
		},
		headingSubsection: {
			width: '100%'
		},
		rightSubsection: {
			justifyContent: 'space-between'
		},
		bump: { marginLeft: 0 }
	}
}));

const shortenName = name => {
	const [firstName, lastName] = name.split(' ');
	return lastName ? `${firstName} ${lastName[0]}.` : name;
};

export default function InvestmentPlatformMention({ platformId, mention }) {
	const classes = useStyles();
	const commonClasses = useReviewStyles();
	//console.log(mention);
	return (
		<div className={`${classes.root} ${commonClasses.reviewsBlockRow}`}>
			<div className={classes.reviewHeading}>
				<div
					className={`${classes.headingSubsection} ${classes.rightSubsection}`}
				></div>
			</div>

			<p className={classes.comment}>{mention.text}</p>

			<p className={classes.date}>
				Discovered:{' '}
				<a
					href={`https://twitter.com/${mention.author}`}
					target="_blank"
					rel="noopener noreferrer"
					className={classes.twitterLink}
				>
					{mention.author}
				</a>{' '}
				sent {moment.unix(mention.timestamp).fromNow()}
			</p>
		</div>
	);
}
