import { REACT_APP_ADD_USER_INFO_URL } from "./ConstAPI";

const makeURL = () => `${REACT_APP_ADD_USER_INFO_URL}`;

const makeRequestData = (token, platform) => ({
    method: "POST",
    headers: {
        "Content-Type" : "application/json",
        "Authorization" : `Bearer ${token}`,
    },
    body: JSON.stringify(platform),
});

const makeRequest = async (token, platform) => {
    const url = makeURL();
    const requestData = makeRequestData(token, platform);

    return await fetch(url, requestData);
};
/**
 * @param {string} token
 * @param {number} age
 * @param {string} zip
 * @param {string} income
 * @param {string} accredited
 */
export const addUserInfo = async (token, age, zip, income, accredited) => {
    try {
        const response = await makeRequest(token, {age, zip, income, accredited});
        const responseStatus = await response.json();
        if (response.status === 200) {
            return {
                success: true,
                result: [],
                message: responseStatus.platforms,
            }
        } else {
            return {
                success: false,
                result: [],
                message: responseStatus.platforms,
            };
        }
	} catch (error) {
        console.log(error);
		return {
			success: false,
			result: [],
			message: error,
        };
	}
};
