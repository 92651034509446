import React, { useState } from 'react';
import { Button, MenuItem } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Popper from '@material-ui/core/Popper';
import ExpandMoreIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core/styles';
import FilterIcon from '../../img/icons/filter.svg';
import { filterArrayKeyByValue, detectAccreditedValue } from '../../utils';
import { view } from '../../data/Data';

const drawerWidth = 247;
const useStyles = makeStyles((theme) => ({
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		width: drawerWidth,
	},
	toolbarMd: {
		maxWidth: '1200px',
		width: '100%',
		overflowY: 'hidden',
		overflowX: 'auto',
		margin: 'auto',
		display: 'flex',
		justifyContent: 'flex-end',
		[theme.breakpoints.down('md')]: {
			justifyContent: 'start',
		},
		marginBottom: '20px',

		'& button': {
			borderRadius: '100px',
			border: '1px solid #DBE0E6 !important',
			boxSizing: 'border-box',
			alignSelf: 'center !important',
			background: '#fff',
			textTransform: 'none',
			height: '48px',
			color: '#73757A',
			marginRight: '15px',
			minWidth: '140px',
			padding: '0px 20px',
			'&:last-child ': {
				marginRight: '0px',
			},
		},
	},
	activeFilter: {
		color: '#5056e7 !important',
	},
	hiddenToolbar: {
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	popper: {
		maxWidth: '320px',
		background: '#fff',
		padding: '20px',
		border: '1px solid #F2F5F8',
		borderRadius: '8px',
		zIndex: '9999',
		'& label': {
			display: 'flex',
		},
		'& span': {
			maxWidth: '420px !important',
		},
		'& a': {
			textTransform: 'none',
		},
	},
	filterIcon: {
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
		marginRight: '10px',
	},
}));
export const Drawer = (props) => {
	const {
		paidOnly,
		clusters,
		investments,

		open,
		setOpen,
		anchorEl,
		setAnchorEl,
		type,
		setType,
		filter,
		setfilter,
		liquidity,
		setLiquidity,
		setReturnsButtonName,
		liqudityButtonName,
		setLiquidityButtonName,
		payoutButtonName,
		setPayoutButtonName,
		payoutfreq,
		setPayoutfreq,

		handleClick,
		handleClickAway,
		handleFilterCategory,
		resetTypes,
		resetReturns,
		resetLiquidity,
		resetPayout,
		resetRating,
		resetAccreditation,

		setIsListView,
		setClusterInView,
		clusterInView,
		typeButtonName,
		categoriesValues,
		returnsButtonName,
		ratingButtonName,
		ratingFilter,
		accreditationButtonName,
		setAccreditedFilter,
		accreditedFilter,
		setAccreditationButtonName,
		setRatingFilter,
		setRatingButtonName,
		quizValue,
		isCollectionActive,
	} = props;

	const classes = useStyles();

	return (
		<div className={`${classes.toolbarMd}`} id="drawerContainer">
			<img alt="filter" src={FilterIcon} className={classes.filterIcon} />

			{!paidOnly && !quizValue && !isCollectionActive ? (
				<>
					<Button
						aria-describedby="7"
						onClick={(event) => handleClick(7, event)}
						disabled={open === 7}
						endIcon={<ExpandMoreIcon />}
					>
						Categories
					</Button>
					<Popper
						id={open === 7 ? 'simple-popper' : undefined}
						open={open === 7 || false}
						anchorEl={open === 7 ? anchorEl : null}
						className={classes.popper}
						placement={'top-start'}
					>
						<ClickAwayListener onClickAway={handleClickAway}>
							<div className="noPadding">
								{clusters.map((cluster) => (
									<Button
										key={cluster.id}
										value={cluster.id}
										onClick={() => {
											//setIsListView(view.collection);
											setClusterInView(cluster.id);
											setOpen(null);
											setAnchorEl(null);
										}}
										href={`#cluster_${cluster.id}`}
									>
										<Typography
											variant="body1"
											gutterBottom
											color={cluster.id === clusterInView ? 'primary' : 'initial'}
											className='Typography-categories'
										>
											{cluster.name}
										</Typography>
									</Button>
								))}

							</div>
						</ClickAwayListener>
					</Popper>
				</>
			) : null}

			<Button
				aria-describedby={open === 1 ? 'simple-popper' : undefined}
				onClick={(event) => {
					!typeButtonName ? handleClick(1, event) : resetTypes();
				}}
				endIcon={!typeButtonName ? <ExpandMoreIcon /> : <ClearIcon />}
				className={typeButtonName ? classes.activeFilter : null}
			>
				{typeButtonName || 'Type'}
			</Button>
			<Popper
				id={open === 1 ? 'simple-popper' : undefined}
				open={open === 1 || false}
				anchorEl={open === 1 ? anchorEl : null}
				className={classes.popper}
			>
				<ClickAwayListener onClickAway={handleClickAway}>
					<div className="noPadding">
						{[
							'Collectibles',
							'Commodities',
							'Farmland',
							'Loans',
							'Real Estate',
							'Trading',
							'Crypto',
							'Robo Invest',
							'Startups',
						].map((propx) => (
							<FormControlLabel
								key={propx}
								refX={propx}
								label={propx}
								control={
									<MenuItem>
										<Radio
											checked={categoriesValues.includes(propx)}
											value={propx}
											onChange={handleFilterCategory(propx)}
											color="primary"
											disabled={filterArrayKeyByValue(investments, "industry", propx).length > 0 ? false : true}
										/>
									</MenuItem>
								}
								disabled={filterArrayKeyByValue(investments, "industry", propx).length > 0 ? false : true}
							/>
						))}
					</div>
				</ClickAwayListener>
			</Popper>

			<Button
				aria-describedby="2"
				onClick={(event) => {
					!returnsButtonName ? handleClick(2, event) : resetReturns();
				}}
				endIcon={!returnsButtonName ? <ExpandMoreIcon /> : <ClearIcon />}
				className={returnsButtonName ? classes.activeFilter : null}
			>
				{returnsButtonName || 'Returns'}
			</Button>
			<Popper
				id={open === 2 ? 'simple-popper' : undefined}
				open={open === 2 || false}
				anchorEl={open === 2 ? anchorEl : null}
				className={classes.popper}
			>
				<ClickAwayListener onClickAway={handleClickAway}>
					<div className="noPadding">
						{['Interest', 'Dividends + Value', 'Value', 'Dividends'].map((propx) => (
							<FormControlLabel
								key={propx}
								label={propx}
								control={
									<MenuItem>
										<Radio
											key={propx}
											checked={type === propx}
											value={propx}
											onChange={(e) => {
												setType(e.target.value);
												setIsListView(view.list);
												setfilter({ ...filter, types: e.target.value });
												setReturnsButtonName(e.target.value);
											}}
											color="primary"
											disabled={filterArrayKeyByValue(investments, "howMoney", propx).length > 0 ? false : true}
										/>
									</MenuItem>
								}
								disabled={filterArrayKeyByValue(investments, "howMoney", propx).length > 0 ? false : true}
							/>
						))}
					</div>
				</ClickAwayListener>
			</Popper>

			<Button
				aria-describedby="3"
				onClick={(event) => {
					!liqudityButtonName ? handleClick(3, event) : resetLiquidity();
				}}
				endIcon={!liqudityButtonName ? <ExpandMoreIcon /> : <ClearIcon />}
				className={liqudityButtonName ? classes.activeFilter : null}
			>
				{liqudityButtonName || 'Liquidity'}
			</Button>
			<Popper
				id={open === 3 ? 'simple-popper' : undefined}
				open={open === 3 || false}
				anchorEl={open === 3 ? anchorEl : null}
				className={classes.popper}
			>
				<ClickAwayListener onClickAway={handleClickAway}>
					<div className="noPadding">
						{['Easy', 'Moderate', 'Hard'].map((propx) => (
							<FormControlLabel
								key={propx}
								label={propx}
								control={
									<MenuItem>
										<Radio
											checked={liquidity === propx}
											value={propx}
											onChange={(e) => {
												setIsListView(view.list);
												setLiquidity(e.target.value);
												setfilter({ ...filter, liquidity: e.target.value });
												setLiquidityButtonName(e.target.value);
											}}
											color="primary"
											disabled={filterArrayKeyByValue(investments, "liquidity", propx).length > 0 ? false : true}
										/>
									</MenuItem>
								}
								disabled={filterArrayKeyByValue(investments, "liquidity", propx).length > 0 ? false : true}
							/>
						))}
					</div>
				</ClickAwayListener>
			</Popper>

			<Button
				aria-describedby="4"
				onClick={(event) => {
					!payoutButtonName ? handleClick(4, event) : resetPayout();
				}}
				endIcon={!payoutButtonName ? <ExpandMoreIcon /> : <ClearIcon />}
				className={payoutButtonName ? classes.activeFilter : null}
			>
				{payoutButtonName || 'Payout'}
			</Button>
			<Popper
				id={open === 4 ? 'simple-popper' : undefined}
				open={open === 4 || false}
				anchorEl={open === 4 ? anchorEl : null}
				className={classes.popper}
				transition
			>
				<ClickAwayListener onClickAway={handleClickAway}>
					<div className="noPadding">
						{['Daily', 'Monthly', 'Quarterly', 'Annually', 'Asset sold'].map(
							(propx) => (
								<FormControlLabel
									key={propx}
									label={propx}
									control={
										<MenuItem>
											<Radio
												checked={payoutfreq === propx}
												value={propx}
												onChange={(e) => {
													setIsListView(view.list);
													setPayoutfreq(e.target.value);
													setfilter({ ...filter, payout: e.target.value });
													setPayoutButtonName(e.target.value);
												}}
												color="primary"
												disabled={filterArrayKeyByValue(investments, "payoutFrequency", propx).length > 0 ? false : true}
											/>
										</MenuItem>
									}
									disabled={filterArrayKeyByValue(investments, "payoutFrequency", propx).length > 0 ? false : true}
								/>
							)
						)}
					</div>
				</ClickAwayListener>
			</Popper>

			<Button
				aria-describedby="5"
				onClick={(event) => {
					!ratingButtonName ? handleClick(5, event) : resetRating();
				}}
				endIcon={!ratingButtonName ? <ExpandMoreIcon /> : <ClearIcon />}
				className={ratingButtonName ? classes.activeFilter : null}
			>
				{ratingButtonName || 'Rating'}
			</Button>
			<Popper
				id={open === 5 ? 'simple-popper' : undefined}
				open={open === 5 || false}
				anchorEl={open === 5 ? anchorEl : null}
				className={classes.popper}
			>
				<ClickAwayListener onClickAway={handleClickAway}>
					<div className="noPadding">
						{['1 star', '2 stars', '3 stars', '4 stars', '5 stars', 'Unrated'].map(
							(propx) => (
								<FormControlLabel
									key={propx}
									label={propx}
									control={
										<MenuItem>
											<Radio
												checked={ratingFilter === propx}
												value={propx}
												onChange={(e) => {
													setIsListView(view.list);
													setRatingFilter(e.target.value);
													setfilter({ ...filter, rating: e.target.value });
													setRatingButtonName(e.target.value);
												}}
												color="primary"
												disabled={
													Object.values(investments).filter(i => parseInt(i["averageRating"]) === (parseInt(propx) || 0)).length > 0 ? false : true
												}
											/>
										</MenuItem>
									}
									disabled={
										Object.values(investments).filter(i => parseInt(i["averageRating"]) === (parseInt(propx) || 0)).length > 0 ? false : true
									}
								/>
							)
						)}
					</div>
				</ClickAwayListener>
			</Popper>

			<Button
				aria-describedby="6"
				onClick={(event) => {
					!accreditationButtonName ? handleClick(6, event) : resetAccreditation();
				}}
				endIcon={!accreditationButtonName ? <ExpandMoreIcon /> : <ClearIcon />}
				className={accreditationButtonName ? classes.activeFilter : null}
			>
				{accreditationButtonName || 'Accreditation'}
			</Button>
			<Popper
				id={open === 6 ? 'simple-popper' : undefined}
				open={open === 6 || false}
				anchorEl={open === 6 ? anchorEl : null}
				className={classes.popper}
			>
				<ClickAwayListener onClickAway={handleClickAway}>
					<div className="noPadding">
						{['Accredited only', 'Non Accred only'].map(
								(propx) => (
									<FormControlLabel
										key={propx}
										label={propx}
										control={
											<MenuItem>
												<Radio
													checked={accreditedFilter === propx}
													value={propx}
													onChange={(e) => {
														setIsListView(view.list);
														setAccreditedFilter(e.target.value);
														setfilter({ ...filter, accredited: e.target.value });
														setAccreditationButtonName(e.target.value);
													}}
													color="primary"
													disabled={filterArrayKeyByValue(investments, "investors", detectAccreditedValue(propx)).length > 0 ? false : true}
												/>
											</MenuItem>
										}
										disabled={filterArrayKeyByValue(investments, "investors", detectAccreditedValue(propx)).length > 0 ? false : true}
									/>
								)
							)}
					</div>
				</ClickAwayListener>
			</Popper>
		</div>
	);
};
