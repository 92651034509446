import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowDown';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
	card: {
		'&:hover': { cursor: 'pointer' },
		'&:hover  $expandIcon': {
			display: 'block',
		},
		width: 1200,
		maxWidth: '100%',
		margin: 'auto',
		marginBottom: '12px',
		boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
		position: 'relative',
		borderRadius: '8px',
	},
	premiumCard: {
		border: '1px solid #F3B81F',
	},
	expandIcon: {
		position: 'absolute',
		top: '12px',
		right: '12px',
		background: '#5056E7',
		'&:hover': {
			backgroundColor: '#646AF0',
		},
		color: '#fff',
		borderRadius: '100px',
		textTransform: 'none',
		padding: '6px 16px',
	},
	moreButton: {
		textTransform: 'none',
		color: '#73757A',
		fontFamily: 'Inter',
		fontSize: '14px',
	},
	cardAction: {
		borderTop: '1px solid #F2F5F8',
		padding: '0px',
	},
	subheader: {
		maxWidth: '220px',
		[theme.breakpoints.down('369')]: {
			maxWidth: '190px',
		},
		alignItems: 'center',
	},
	borderedColumn: {
		position: 'relative',

		'& .pseudoborder': {
			height: 'calc(100% - 40px) !important',
			top: '28px',
			left: '-24px',
			margin: '0 !important',
		},
	},
	secondColumn: {
		paddingRight: '32px',
	},
}));

export default function CardCollapsedSkeleton(props) {
	const classes = useStyles();
	return (
		<Card className={`mainCard ${classes.card}`}>
			<Grid container alignItems="flex-start">
				<Grid className="firstgrid" item sm={5} xs={5}>
					<CardHeader
						avatar={
							<Skeleton animation="wave" variant="circle" width={48} height={48} />
						}
						title={
							<Typography component="div"  variant={`h5`}>
								<Skeleton />
							</Typography>
						}
						subheader={
							<Typography component="div"  variant={`h6`}>
								<Skeleton />
							</Typography>
						}
					/>
					<CardContent
						style={{
							paddingTop: '0px',
							paddingRight: '24px',
							paddingBottom: '10px',
						}}
					>
						<Skeleton animation="wave" height={15} />
					</CardContent>
				</Grid>
				<Grid
					className={`${classes.borderedColumn} ${classes.secondColumn}`}
					item
					sm={3}
					xs={3}
				>
					<Typography component="div"  variant={`h4`}>
						<Skeleton />
					</Typography>
					<Typography component="div"  variant={`h4`}>
						<Skeleton />
					</Typography>
					<Typography component="div"  variant={`h4`}>
						<Skeleton />
					</Typography>
				</Grid>
				<Grid className={`thirdgrid ${classes.borderedColumn}`} item sm={3} xs={3}>
					<Typography component="div"  variant={`h4`}>
						<Skeleton />
					</Typography>
					<Typography component="div"  variant={`h4`}>
						<Skeleton />
					</Typography>
					<Typography component="div"  variant={`h4`}>
						<Skeleton />
					</Typography>
				</Grid>
			</Grid>
			<CardActions className={classes.cardAction}>
				<Button
					endIcon={<KeyboardArrowRightIcon />}
					fullWidth={true}
					className={classes.moreButton}
				>
					See details
				</Button>
			</CardActions>
		</Card>
	);
}
