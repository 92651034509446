import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import CreateReviewFormFirstPart from "./FirstPart";
import CreateReviewFormSecondPart from "./SecondPart";
import Loader from "react-loader-spinner";

const formParts = [
    CreateReviewFormFirstPart,
    CreateReviewFormSecondPart,
];

const useStyles = makeStyles({
    loader: {
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
    },
});

export default function CreateReviewForm({ investmentPlatform, onSubmit, isLoading }) {
    const classes = useStyles();
    const [ data, setData ] = React.useState({});
    const [ currentFormPart, setCurrentFormPart ] = React.useState(0);
    const FormPart = formParts[Math.min(currentFormPart, formParts.length - 1)];

    React.useEffect(() => {
        if (currentFormPart === formParts.length) {
            onSubmit(data);
        }
    }, [currentFormPart, data]);

    return (
        <div>
            <FormPart
                investmentPlatform={investmentPlatform}
                onSubmit={newData => {
                    setData({...data, ...newData});
                    setCurrentFormPart(currentFormPart + 1);
                }}
            />

            {isLoading && (
                <div className={classes.loader}>
                    <Loader
                        type="TailSpin"
                        color="#5056E7"
                        height={60}
                        width={60}
                    />
                </div>
            )}
        </div>
    );
}
