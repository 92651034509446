import React from 'react';
import ReactGA from 'react-ga';
import TextTruncate from 'react-text-truncate';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Avatar from '@material-ui/core/Avatar';
import { useSelector, shallowEqual } from 'react-redux';
import { eventTracker, trackEvent } from '../../utils';
import { LinkBlank } from '../Buttons/LinkBlank';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles((theme) => ({
    container: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'flex-start',
        //width: '400px',
        //minWidth: '400px',
        height: 172,
        background: '#FFFFFF',
        border: '1px solid #F2F5F8',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
        borderRadius: '8px',
        minHeight: '80px',
        //paddingLeft: '54px',
        paddingRight: '10px',
        marginTop: '12px',
        overflow: 'hidden',
        //marginBottom: '32px',
        //marginRight: '20px',

        width: '100%',
        marginRight: '0px',
        /*[theme.breakpoints.down('750')]: {
            width: '100%',
            marginRight: '0px',
            minWidth: 'inherit',
        },*/
    },
    containerText: {
        display: 'flex',
        flexDirection: 'column',
    },
	title: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#30333E',
    },
    description: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#73757A',
        marginBottom: '16px',
        cursor: 'pointer',
        //textOverflow: 'ellipsis',
        //overflow: 'hidden',
    },
    marginStyle: {
        marginTop: '12px',
        marginBottom: '8px',
    },
    marginStyle2: {
        marginLeft: '20px',
    },
    avatarContainer: {
        paddingTop: '18px',
        paddingLeft: '12px',
        paddingRight: '12px',
    },
    avatar: {
		width: '28px',
		height: '28px',
    },
}));

export const PressItems = (props) => {
    const {
        data,
    } = props;
	const classes = useStyles();
    const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);

    const stopPropagation = (event, name) => {
		trackEvent('ExternalClick', 'News', name);
		eventTracker("ExternalClick", name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
		event.stopPropagation();
    }
    const handleClick = (event, url) => {
        window.open(url, '_blank');
        stopPropagation(event, url);
    }

	return (
        data.map((i, index) => (
                <div
                    //className={`${classes.container} ${index === 1 ? window.innerWidth > 600 ? classes.marginStyle2 : '' : ''}`}
                    className={`${classes.container}`}
                    key={`press_${index}`}
                    onClick={(event) => {handleClick(event, i.url)}}
                >
                    <LinkBlank
                        stopPropagation={(event) => stopPropagation(event, i.url)}
                        href={i.url}
                    >
                        <div className={`${classes.avatarContainer}`}>
                            <Avatar aria-label="avatar" className={`${classes.avatar}`}>
                                <img
                                    alt={i.title}
                                    style={{maxWidth: '28px'}}
                                    src={i.logo}
                                />
                            </Avatar>
                        </div>
                    </LinkBlank>
                    <div className={`${classes.containerText}`}>
                        <div
                            className={`${classes.title}
                            ${classes.marginStyle}`}
                        >
                            <TextTruncate
                                line={3}
                                element="span"
                                truncateText="…"
                                text={i.title}
                            />
                        </div>
                        <div
                            className={classes.description}
                        >
                            <TextTruncate
                                line={3}
                                element="span"
                                truncateText="…"
                                text={i.description}
                                textTruncateChild={
                                    <LinkBlank
                                        stopPropagation={(event) => stopPropagation(event, i.url)}
                                        href={i.url}
                                        style={{ color: '#5056e7'}}
                                    >
                                        more
                                    </LinkBlank>
                                }
                            />
                        </div>
                    </div>
                </div>
        ))
	)
}
