import React from 'react';
import { Link } from 'react-router-dom';
import imgLogo from '../../img/logo.svg';
import imgLogoWhite from '../../img/logowhite.svg';
import imgLogoDashboard from '../../img/dashboard/Logo.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    img: {
        marginRight: 0,
    },

}));

export const LogoComponent = (props) => {
	const {
        color,
        clearSpace,
    } = props;
    const classes = useStyles();
    const clearSpaceProps = clearSpace ? classes.img : "Logo";
    let colorSrc;
    switch (color) {
        case "white":
            colorSrc = imgLogoWhite
            break;
        case "whiteGreen":
            colorSrc = imgLogoDashboard
            break;
        default:
            colorSrc = imgLogo;
            break;
    }
	return (
		<Link to="/discover">
			<img
                className={clearSpaceProps}
                src={colorSrc}
                alt="logo"
            />
		</Link>
	);
};
