import React, { useState } from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import InvestmentPlatformOverview from './Overview';
import InvestmentPlatformReviews from './Reviews';
import InvestmentPlatformMentions from './Mentions';
import { CardContent } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

import { getSentimentIcon } from './helpers';
import { StreamTab } from '../Stream/StreamTab';
import { Press } from './Press';

export const StylizedTabs = withStyles({
	root: {
		borderBottom: '1px solid #DBE0E6',
		marginBottom: '24px',
	},
	indicator: {
		height: '2px',
		backgroundColor: '#5056E7',
	},
})(Tabs);

export const StylizedTab = withStyles({
	root: {
		textTransform: 'none',
		fontSize: '16px',

		color: '#73757A',
		'&$selected': {
			color: '#30333E',
		},
		'& img': {
			position: 'absolute',
			right: '5px',
			width: '17px',
		},
	},
})(Tab);

const tabs = {
	overview: { index: 0, label: 'Overview', path: '' },
	reviews: { index: 1, label: 'Reviews', path: '/reviews' },
	stream: { index: 2, label: 'Conversation', path: '/conversation' },
	mentions: { index: 3, label: 'Mentions', path: '/mentions' },
	press: { index: 4, label: 'Press', path: '/press' },
};

const ReplaceLink = (props) => <Link replace {...props} />;

export const TabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			key={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index ? children : null}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

export const a11yProps = (index) => {
	return {
		id: `wrapped-tab-${index}`,
		'aria-controls': `wrapped-tabpanel-${index}`,
	};
}

export default function InvestmentPlatformTabs({
	investmentPlatform,
	onCreateReview,
	embed,
}) {
	let { url: currentUrl } = useRouteMatch();
	const [tabIndex, setTabIndex] = useState(0);

	const handleChange = (event, newValue) => {
		setTabIndex(newValue);
	};
	//let currentUrl = `/discover/${investmentPlatform.id}`
	//console.log(investmentPlatform);
	/*return (
		<Route
			exact
			path={`${currentUrl}/:tab?`}
			render={({ match }) => {

				const tabIndex = tabs[tab].index;

				return (
					<CardContent>
						<StylizedTabs value={tabIndex}>
							{Object.values(tabs).map((tab) => (
								<StylizedTab
									label={tab.label}
									key={tab.index}
									component={ReplaceLink}
									to={currentUrl + tab.path}
								/>
							))}
						</StylizedTabs>

						<Switch>
							<Route exact path={`${currentUrl}`}>
								<InvestmentPlatformOverview
									investmentPlatform={investmentPlatform}
								/>
							</Route>

							<Route exact path={`${currentUrl}/${investmentPlatform.id}/reviews`}>
								<InvestmentPlatformReviews
									investmentPlatform={investmentPlatform}
									onCreateReview={onCreateReview}
									embed={embed}
								/>
							</Route>

							<Route exact path={`${currentUrl}/${investmentPlatform.id}/mentions`}>
								<InvestmentPlatformMentions
									investmentPlatform={investmentPlatform}
								/>
							</Route>
						</Switch>
					</CardContent>
				);
			}}
		/>
	); */

	return (
		<CardContent>
			<StylizedTabs value={tabIndex} onChange={handleChange}>
				{Object.values(tabs).map((tab) => (
					<StylizedTab
						label={tab.label}
						key={tab.index}
						icon={
							tab.index === 3 ? (
								<img src={getSentimentIcon(investmentPlatform.sentiment)} />
							) : null
						}
						{...a11yProps(tab.index)}
					/>
				))}
			</StylizedTabs>

			<TabPanel value={tabIndex} index={0}>
				<InvestmentPlatformOverview investmentPlatform={investmentPlatform} />
			</TabPanel>
			<TabPanel value={tabIndex} index={1}>
				<InvestmentPlatformReviews
					investmentPlatform={investmentPlatform}
					onCreateReview={onCreateReview}
					embed={embed}
				/>
			</TabPanel>
			<TabPanel value={tabIndex} index={2}>
				<StreamTab
					slug={investmentPlatform.slug}
					name={investmentPlatform.name}
					id={investmentPlatform.id}
					count={10}
				/>
			</TabPanel>
			<TabPanel value={tabIndex} index={3}>
				<InvestmentPlatformMentions investmentPlatform={investmentPlatform} />
			</TabPanel>
			<TabPanel value={tabIndex} index={4}>
				<Press
					platformId={investmentPlatform.id}
				/>
			</TabPanel>
		</CardContent>
	);
}
