import React, { useState, useEffect } from 'react';

import { getAds } from '../Services/AdsAPI.js';

import CssBaseline from '@material-ui/core/CssBaseline';
import { getMore, getSearch, getFilterResult } from '../Services/BaseAPITrade.js';
import { Spinner } from '../components/Spinner/Spinner.js';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import { useHistory, useRouteMatch, Route } from 'react-router-dom';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import { TextField, Button, MenuItem, RadioGroup } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Drawer from '@material-ui/core/Drawer';
import Chip from '@material-ui/core/Chip';
import Hidden from '@material-ui/core/Hidden';
import SortIcon from '@material-ui/icons/Sort';
import FilterIcon from '../img/icons/filter.svg';
import ExpandMoreIcon from '@material-ui/icons/ArrowDropDown';
import InputAdornment from '@material-ui/core/InputAdornment';

import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Search from '@material-ui/icons/Search';
import ListItemText from '@material-ui/core/ListItemText';

import AdUnit from '../components/InvestmentPlatform/AdUnit';

import ReactGA from 'react-ga';
import TradingPlatformCollapsed from './TradingPlatform/Collapsed';
import TradingPlatformModal from './TradingPlatform/Modal';
import { StyledMenu, StyledMenuItem } from './StyledMenu';
import { isEmbeded, trackEvent, isPaidOnly, eventTracker } from '../utils';
import { useSelector, shallowEqual } from "react-redux";
import { AppBarComponent } from './AppBar/AppBarComponent';
import { ModalSignUp } from './Modal/ModalSignUp';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const drawerWidth = 247;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	appBar: {
		[theme.breakpoints.up('sm')]: {
			zIndex: 9999,
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		width: drawerWidth,
	},
	staticDrawerPaper: {
		position: 'relative',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		marginTop: '5px',
	},
}));

const LeftNavbarWrapper = (props) => {
	const { container, expandableBannerShow } = props;
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const history = useHistory();
	const { url: currentUrl } = useRouteMatch();
	const [openModalSignUp, setOpenModalSignUp] = useState(state.modalSignUpOpen);

	const [displaySpinner, setDisplaySpinner] = useState(false);

	const [investments, setInvestments] = useState({});
	const [investmentsMoreArray, setInvestmentsMoreArray] = useState([]);
	const [search, setSearch] = useState('');
	const [searchResultsCount, setSearchResultsCount] = useState('');

	const [value, setValue] = React.useState('None');

	const [liquidity, setLiquidity] = React.useState('');
	const [type, setType] = React.useState('');
	const [payoutfreq, setPayoutfreq] = React.useState('');
	const [ratingFilter, setRatingFilter] = React.useState({});

	const classes = useStyles();
	const theme = useTheme();
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const [nextUrl, setNextUrl] = useState(null);
	const [showSearch, setshowSearch] = useState(false);
	const [showMenu, setshowMenu] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [filter, setfilter] = React.useState({});
	const [paidOnly, setPaidOnly] = useState(isPaidOnly(state.itemCompaign));
	const [medium, setMedium] = useState(state.itemMedium);
	const [ads, setAds] = useState([]);
	const [filterCategories, setfilterCategories] = React.useState({});

	let merged = { ...filter };

	const categoriesValues = Object.keys(filterCategories).filter(
		(k) => filterCategories[k]
	);
	if (categoriesValues.length) {
		merged.trades = categoriesValues;
	}

	const ratingValues = Object.keys(ratingFilter).filter((k) => ratingFilter[k]);
	if (ratingValues.length > 0) {
		merged.rating = ratingValues;
	}

	useEffect(() => {
		if (state.modalSignUpOpen) {
			setOpenModalSignUp(true);
		} else {
			setOpenModalSignUp(false);
		}
	}, [state.modalSignUpOpen]);

	const PseudoMailCard = () => {
		function handleSubmit(event) {
			trackEvent('User', 'Newsletter', 'Newsletter');
			eventTracker("User", 'Newsletter', state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
		}

		return (
			<div className="pseudoMailCard">
				<Typography
					style={{ color: '#30333E', marginBottom: '8px' }}
					className={classes.heading}
				>
					Grow your wealth
				</Typography>
				<Typography className={classes.heading}>
					Get notified of new investment opportunities and platforms
				</Typography>

				<form
					action="https://moneymade.us4.list-manage.com/subscribe/post?u=9922df745a85edbac4d1eef0b&amp;id=360bd75c7c"
					method="post"
					name="mc-embedded-subscribe-form"
					className="validate"
					noValidate
					onSubmit={handleSubmit}
				>
					<TextField
						name="FNAME"
						type="hidden"
						style={{ margin: '10px', display: 'none' }}
						className="searchInput "
						placeholder="Name"
						variant="outlined"
					/>

					<TextField
						name="EMAIL"
						style={{ maxWidth: '288px', margin: 0, marginRight: '12px' }}
						className="mailInput toFullLength"
						placeholder="Email"
						variant="outlined"
					/>

					<TextField
						multiline
						rows="4"
						name="MESSAGE"
						type="hidden"
						style={{ margin: '10px', display: 'none' }}
						className="searchInput"
						placeholder="Your message"
						variant="outlined"
					/>

					<div style={{ textAlign: 'right', marginRight: '-10px' }}>
						<Button
							type="submit"
							className="toFullLength"
							style={{ marginRight: '8px' }}
							variant="contained"
							color="primary"
						>
							Subscribe
						</Button>
					</div>
				</form>
			</div>
		);
	};

	const handleFilterCategory = (name) => (event) => {
		if (event.target.checked)
			setfilterCategories({ ...filterCategories, [name]: event.target.checked });
		else setfilterCategories({ ...filterCategories, [name]: false });
	};

	const handleFilterRating = (name) => (event) => {
		setRatingFilter({ ...ratingFilter, [name]: event.target.checked });
	};

	const handleFilterNonAcred = (name) => (event) => {
		if (event.target.checked) {
			setfilter({ ...filter, accredited: false });
		} else {
			setfilter({ ...filter, accredited: undefined });
		}
	};

	const handleFilterAcred = (name) => (event) => {
		if (event.target.checked) {
			setfilter({ ...filter, accredited: true });
		} else {
			setfilter({ ...filter, accredited: undefined });
		}
	};

	useEffect(() => {
		if (state.itemMedium) {
			setMedium(state.itemMedium);
		}
	}, [state.itemMedium]);

	useEffect(() => {
		const requestParams = { ...merged };
		if (requestParams.rating) {
			requestParams.rating = requestParams.rating.map(
				(textRating) => parseInt(textRating) || 0
			);
		}

		setDisplaySpinner(true);
		setInvestments([]);
		getFilterResult(JSON.stringify(requestParams)).then((data) => {
			setInvestments(data);
			setNextUrl(data.next);
			setDisplaySpinner(false);
		});
		setAds([]);
		getAds().then((data) => {
			setAds(data);
		});
	}, [filter, filterCategories, ratingFilter]);

	const handleMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSearch = (event) => {
		let scoop = event.target.value;

		setTimeout(() => {
			setSearch(scoop);
			setSearchResultsCount(document.querySelectorAll('.MuiCard-root').length);
		}, 101);
	};

	const fetchMore = async (event) => {
		await getMore(event).then((data) => {
			setDisplaySpinner(true);
			setInvestmentsMoreArray((oldArr) => [...oldArr, data.data]);
			setNextUrl(data.next);
			setDisplaySpinner(false);
		});
	};

	const fetchAsync = async () => {
		getSearch().then((data) => {
			setInvestments(data);
			setNextUrl(data.next);
			setDisplaySpinner(false);
		});
	};

	useEffect(() => {
		setDisplaySpinner(true);
		fetchAsync();
	}, []);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	const handleShowSearch = () => {
		setshowSearch(!showSearch);
	};

	const handleSort = (event) => {
		setValue(event.target.value);
	};

	const countFilters =
		Object.values(filter).filter((v) => v).length +
		Object.values(filterCategories).filter((v) => v).length +
		Object.values(ratingFilter).filter((v) => v).length;

	const drawer = (
		<div className="toolbarMd">
			<div className={classes.toolbar} />

			<ExpansionPanel>
				<Typography
					style={{
						marginLeft: '25px',
						paddingTop: '10px',
						color: '#30333E',
						marginBottom: '8px',
					}}
					className={classes.heading}
				>
					Filters
				</Typography>
			</ExpansionPanel>

			<ExpansionPanel>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography className={classes.heading}>Trade</Typography>
				</ExpansionPanelSummary>

				<ExpansionPanelDetails>
					<Typography className="noPadding">
						{[
							'Stocks',
							'Fractional Stocks',
							'ETFs',
							'Mutual Funds',
							'Bonds',
							'Options',
							'Futures',
							'Forex',
							'Crypto',
						].map((propx) => (
							<FormControlLabel
								key={propx}
								refX={propx}
								label={propx}
								control={
									<MenuItem>
										<Checkbox
											value="oo"
											onChange={handleFilterCategory(propx)}
											color="primary"
										/>
									</MenuItem>
								}
							/>
						))}
					</Typography>
				</ExpansionPanelDetails>
			</ExpansionPanel>

			<ExpansionPanel>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel2a-content"
					id="panel2a-header"
				>
					<Typography className={classes.heading}>Expertise</Typography>
				</ExpansionPanelSummary>

				<ExpansionPanelDetails>
					<div className="noPadding">
						{['Beginner', 'Moderate'].map((propx) => (
							<FormControlLabel
								key={propx}
								label={propx}
								control={
									<MenuItem>
										<Radio
											key={propx}
											checked={type === propx}
											value={propx}
											onChange={(e) => {
												setType(e.target.value);
												setfilter({ ...filter, types: e.target.value });
											}}
											color="primary"
										/>
									</MenuItem>
								}
							/>
						))}
					</div>
				</ExpansionPanelDetails>
			</ExpansionPanel>

			<ExpansionPanel>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel2a-content"
					id="panel2a-header"
				>
					<Typography className={classes.heading}>Rating</Typography>
				</ExpansionPanelSummary>

				<ExpansionPanelDetails>
					<div className="noPadding">
						{['1 star', '2 stars', '3 stars', '4 stars', '5 stars', 'Unrated'].map(
							(ratingOption) => (
								<FormControlLabel
									key={ratingOption}
									label={ratingOption}
									control={
										<MenuItem>
											<Checkbox
												checked={ratingFilter[ratingOption] || false}
												onChange={handleFilterRating(ratingOption)}
												color="primary"
											/>
										</MenuItem>
									}
								/>
							)
						)}
					</div>
				</ExpansionPanelDetails>
			</ExpansionPanel>

			<div style={{ paddingRight: '10px', paddingLeft: '10px', marginTop: '10px' }}>
				<Button
					style={{
						background: '#FBFCFD',
						boxShadow: 'unset',
						marginTop: '10px',
						display: countFilters > 0 ? 'block' : 'none',
					}}
					onClick={() => {
						history.push('/n');
						setTimeout(() => {
							history.push('/trading');
						}, 10);
					}}
					fullWidth
					variant="contained"
				>
					Clear all{' '}
					{countFilters > 0 ? (
						<font style={{ marginLeft: '2px' }}>({countFilters})</font>
					) : null}
				</Button>
			</div>
			{window.innerWidth >= 768 && !isEmbeded(medium, 'embed') ? <PseudoMailCard /> : null}
		</div>
	);

	const areInvestmentsPresent = Object.values(investments).length !== 0;

	return (
		<div className={`respCont ${classes.root}`}>
			<CssBaseline />
			<AppBarComponent
                medium={medium}
                showMenu={showMenu}
                paidOnly={paidOnly}
                handleDrawerToggle={handleDrawerToggle}
				expandableBannerShow={expandableBannerShow}
				handleShowSearch={handleShowSearch}
            />

			{areInvestmentsPresent && (
				<Route
					path={`${currentUrl}/:platformId`}
					render={({ match }) => {
						const selectedId = match.params.platformId;
						const selectedInvestment = Object.values(investments).find(
							(investment) => investment.id === selectedId
						);

						if (!selectedInvestment) {
							history.push(currentUrl);
							return null;
						}

						return (
							<TradingPlatformModal
								investmentPlatform={selectedInvestment}
								onClose={() => history.push(currentUrl)}
							/>
						);
					}}
				/>
			)}

			<nav className={`leftNav ${classes.drawer}`} aria-label="mailbox folders">
				<Hidden smUp implementation="css">
					<Drawer
						container={container}
						variant="temporary"
						anchor={theme.direction === 'rtl' ? 'right' : 'left'}
						open={mobileOpen}
						onClose={handleDrawerToggle}
						classes={{
							paper: classes.drawerPaper,
						}}
						ModalProps={{
							keepMounted: true,
						}}
					>
						{drawer}
					</Drawer>
				</Hidden>

				<Hidden xsDown implementation="css">
					<Drawer
						classes={{
							paper: `${classes.drawerPaper} ${classes.staticDrawerPaper}`,
						}}
						variant="permanent"
						open
					>
						{drawer}
					</Drawer>
				</Hidden>
			</nav>
			<main className={`${classes.content} mainPrinc`}>
				<div className={classes.toolbar} />
				<div className="searchInputWrapper">
					<div
						className="hideWeb searchMob"
						style={{ fontSize: '24px', marginBottom: '7px', textAlign: 'left' }}
					>
						Results
					</div>
					<TextField
						style={{
							display:
								window.innerWidth <= 768 && !showSearch && !isEmbeded(medium, 'embed')
									? 'none'
									: 'flex',
						}}
						onChange={handleSearch}
						id="search"
						className="searchInput sortWrapperTrading"
						placeholder="Search these results..."
						variant="outlined"
						InputProps={{
							startAdornment: (
								<InputAdornment style={{ marginLeft: '8px' }} position="start">
									<Search />
								</InputAdornment>
							),
						}}
					/>
				</div>

				<center>
					<div
						style={{
							display: 'flex',
							width: 1128,
							flexFlow: 'wrap',
							maxWidth: '90%',
						}}
					>
						{Object.entries(filter) &&
							Object.entries(filter).map(([key, value]) => (
								<div>
									{value && (
										<Chip
											onClick={() => {
												setfilter({ ...filter, [key]: false });
											}}
											label={`${key} : ${value} x`}
											className="filtertag ctag gray"
										/>
									)}
								</div>
							))}

						{Object.entries(filterCategories) &&
							Object.entries(filterCategories).map(([key, value]) => (
								<div>
									{value && (
										<Chip
											onClick={() => {
												document.querySelector(`[refX="${key}"]`).click();
											}}
											label={`Trades: ${key} x`}
											className="filtertag ctag gray"
										/>
									)}
								</div>
							))}

						{Object.entries(ratingFilter) &&
							Object.entries(ratingFilter).map(([key, value]) => (
								<div>
									{value && (
										<Chip
											onClick={() =>
												setRatingFilter({ ...ratingFilter, [key]: false })
											}
											label={`Rating: ${key} x`}
											className="filtertag ctag gray"
										/>
									)}
								</div>
							))}
					</div>
				</center>

				<div className="sortWrapper sortWrapperTrading">
					{areInvestmentsPresent && search === '' ? (
						<p style={{ textAlign: 'left', position: 'absolute' }}>
							{Object.values(investments).length - 1} trading platforms
						</p>
					) : null}
					{areInvestmentsPresent && search !== '' ? (
						<p style={{ textAlign: 'left', position: 'absolute' }}>
							{searchResultsCount} trading platforms
						</p>
					) : null}

					<Button
						className="filterMargin"
						onClick={handleDrawerToggle}
						startIcon={<img alt="filter" src={FilterIcon} />}
					>
						Filter
					</Button>
					<Button
						className="sortMargin"
						onClick={handleMenuOpen}
						startIcon={<SortIcon />}
					>
						Sort
					</Button>

					<StyledMenu
						id="customized-menu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
					>
						<RadioGroup
							aria-label="gender"
							name="gender2"
							value={value}
							onChange={handleSort}
						>
							<StyledMenuItem>
								<ListItemText
									primary={
										<FormControlLabel
											value="Fees"
											control={<Radio color="primary" />}
											label="Fees"
											labelPlacement="start"
											onChange={() => {
												setInvestments(
													Object.values(investments).sort((a, b) =>
														a.fees > b.fees ? 1 : b.fees > a.fees ? -1 : 0
													)
												);
											}}
										/>
									}
								/>
							</StyledMenuItem>

							<StyledMenuItem>
								<ListItemText
									primary={
										<FormControlLabel
											value="accountmin"
											control={<Radio color="primary" />}
											label="Account min"
											labelPlacement="start"
											onChange={() => {
												setInvestments(
													Object.values(investments).sort((a, b) =>
														a.minInvestment > b.minInvestment
															? 1
															: b.minInvestment > a.minInvestment
															? -1
															: 0
													)
												);
											}}
										/>
									}
								/>
							</StyledMenuItem>

							<StyledMenuItem>
								<ListItemText
									primary={
										<FormControlLabel
											value="rating"
											control={<Radio color="primary" />}
											label="Rating"
											labelPlacement="start"
											onChange={() => {
												setInvestments(
													Object.values(investments).sort((a, b) =>
														a.averageRating > b.averageRating
															? 1
															: b.averageRating > a.averageRating
															? -1
															: 0
													)
												);
											}}
										/>
									}
								/>
							</StyledMenuItem>

							<StyledMenuItem>
								<ListItemText
									primary={
										<FormControlLabel
											value="reset"
											control={<Radio color="primary" style={{ display: 'none' }} />}
											label="Reset"
											labelPlacement="start"
											onChange={() => {
												fetchAsync();
											}}
										/>
									}
								/>
							</StyledMenuItem>
						</RadioGroup>
					</StyledMenu>
				</div>
				<center>
					<Spinner displaySpinner={displaySpinner} />
				</center>
				{areInvestmentsPresent &&
					Object.values(investments).map(function (investment, index) {
						if (investment === true) {
							return null;
						}

						let investmentComponent = null;

						const name = (investment.name || '').toLowerCase();
						const description = (investment.description || '').toLowerCase();
						const query = search.toLowerCase();

						if (
							query === '' ||
							description.includes(query) ||
							name.includes(query)
						) {
							investmentComponent = (
								<TradingPlatformCollapsed
									key={investment.id}
									investmentPlatform={investment}
								/>
							);
						}

						if (index === 3 && window.innerWidth <= 768) {
							return (
								<div key={investment.id}>
									<PseudoMailCard />
									{investmentComponent}
								</div>
							);
						}

						if (
							(index === 5 && ads.length > 0) ||
							(Object.keys(investments).length < 6 &&
								index === Object.keys(investments).length - 2)
						) {
							return (
								<div key={investment.id}>
									<AdUnit key={ads[0].name} ad={ads[0]} trading={true} />
									{investmentComponent}
								</div>
							);
						}

						return investmentComponent;
					})}

				{!areInvestmentsPresent && (
					<div>
						<p style={{ textAlign: 'center' }}>Loading results...</p>
						<Button
							style={{
								background: '#FBFCFD',
								boxShadow: 'unset',
								marginTop: '10px',
								display: countFilters > 0 ? 'block' : 'none',
							}}
							onClick={() => {
								setLiquidity('');
								setType('');
								setPayoutfreq('');
								setfilter({});
								setfilterCategories();
								setRatingFilter({});
							}}
							fullWidth
							variant="contained"
							color="none"
						>
							Reset filters ({countFilters})
						</Button>
					</div>
				)}

				{investmentsMoreArray &&
					investmentsMoreArray.map((thes, index) => {
						return thes.map((properties) => (
							<TradingPlatformCollapsed
								key={properties.id}
								investmentPlatform={properties}
							/>
						));
					})}
				{investments.total > 25 && (
					<div className="expandSearch">
						<p
							onClick={() =>
								fetchMore(`https://cors-anywhere.herokuapp.com/${nextUrl}`)
							}
						>
							MORE
						</p>{' '}
					</div>
				)}
				<ModalSignUp
					open={openModalSignUp}
				/>
			</main>
		</div>
	);
};

export default LeftNavbarWrapper;
