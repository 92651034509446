import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import expandIcon from '../../img/icons/external-link.svg';
import star from '../../img/dashboard/star.svg';
import starOutline from '../../img/dashboard/starOutline.svg';
import Tooltip from '@material-ui/core/Tooltip';
import TextTruncate from 'react-text-truncate';
import ShareIcon from '@material-ui/icons/Share';
import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import InvestmentPlatformAvatar from './Avatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Rating from '../Rating';
import { getSentimentText } from './helpers';
import ReactGA from 'react-ga';
import { eventTracker, trackEvent, generateSubId } from '../../utils';
import * as actions from "../../redux/actions";
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { addFavorite } from '../../Services/AddFavoriteAPI';
import { deleteFavorite } from '../../Services/DeleteFavoriteAPI';

ReactGA.initialize('UA-148517382-4');

const useStyles = makeStyles((theme) => ({
	subheader: {
		maxWidth: '220px',
		[theme.breakpoints.down('369')]: {
			maxWidth: '190px',
		},
		alignItems: 'center',
	},
	rating: {
		marginLeft: '8px',
		[theme.breakpoints.down('xs')]: {
			marginLeft: '0px',
		},
	},
	tooltip: {
		zIndex: 10001, // to be visible inside the modal
	},
	starContainer: {
		position: 'relative',
	},
	starImg: {
		position: 'absolute',
		top: '15px',
		left: '8px',
		cursor: 'pointer',
		[theme.breakpoints.down('445')]: {
			position: 'absolute',
			top: '-20px',
			left: '0px',
			cursor: 'pointer',
        },
	},
}));

export const HeaderFirstGrid = (props) => {
	const {
		investmentPlatform,
		expanded,
        expandable,
        platformUrl,
	} = props;
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const dispatch = useDispatch();
	const classes = useStyles();

	const expandedProp = /* expanded */false;
	const [copySuccess, setCopySuccess] = React.useState(false);
	const [source, setSource] = React.useState(state.itemSource || 'MoneyMade');
	const starDefault = !expandedProp ? true : false;
	const [starVisibility] = React.useState(starDefault);

	const starActiveDefault = state.favoritesInvestments.filter((i) => i === investmentPlatform.id).length > 0 ? true : false;
	const [starActive, setStarOutline] = React.useState(starActiveDefault);

	function isStarActive(value) {
		return value ? `${star}` : `${starOutline}`;
	}
	function clickCopy(url) {
		copyToClipboard(url);
		setCopySuccess(true);
		setTimeout(() => setCopySuccess(false), 1200);
	}
	function copyToClipboard(copyText) {
		var textField = document.createElement('textarea');
		textField.innerText = copyText;
		document.body.appendChild(textField);
		textField.select();
		document.execCommand('copy');
		textField.remove();
	}
	function stopPropagation(event) {
		trackEvent('ExternalClick', 'platform', investmentPlatform.name);
		eventTracker("ExternalClick", investmentPlatform.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
		event.stopPropagation();
	}

	const addFavoriteAsync = () => {
		addFavorite(state.token, investmentPlatform.id)
		.then((data) => {
			if (data.success) {
				dispatch(actions.addInvesmentsToFavorites(data.message));
			}
		}).catch((error) => {
			console.log(error);
		});
	}
	const deleteFavoriteAsync = () => {
		deleteFavorite(state.token, investmentPlatform.id)
		.then((data) => {
			if (data.success) {
				dispatch(actions.addInvesmentsToFavorites(data.message));
			}
		}).catch((error) => {
			console.log(error);
		});
	}

	const handleStarClick = (event) => {
		event.stopPropagation();
		if (!state.loggedIn) {
			dispatch(actions.setModalSignUpOpen(true));
			return;
		}
		if (!starActive) {
			dispatch(actions.addInvesmentToFavorites(investmentPlatform.id));
			addFavoriteAsync();
		} else {
			dispatch(actions.removeInvestmentFromFavorites(investmentPlatform.id));
			deleteFavoriteAsync();
		}
	}
	useEffect(() => {
		const starOutline = state.favoritesInvestments.filter((i) => i === investmentPlatform.id).length > 0 ? true : false;
		setStarOutline(starOutline);
    }, [state.favoritesInvestments]);

	const paddingStyle = {paddingTop: '0px', paddingRight: '24px', paddingBottom: '10px'};

	return (
		<>
            <CardHeader
                avatar={
                    <>
                        <Tooltip
                            disableFocusListener
                            disableTouchListener
                            classes={{ popper: classes.tooltip }}
                            placement="top"
                            title={`${getSentimentText(investmentPlatform.sentiment)} sentiment`}
                            arrow
                        >
                            <span>
                                <InvestmentPlatformAvatar
                                    investmentPlatform={investmentPlatform}
                                    starVisibility={starVisibility}
                                />
                            </span>
                        </Tooltip>
                        {starVisibility &&
                            (<div className={classes.starContainer}>
                                <img
                                    src={isStarActive(starActive)}
                                    className={classes.starImg}
                                    onClick={handleStarClick}
                                    alt={investmentPlatform.name}
                                />
                            </div>)}
                    </>
                }
                title={
                    <div>
                        <Tooltip
                            disableFocusListener
                            disableTouchListener
                            classes={{ popper: classes.tooltip }}
                            placement="top"
                            title={`Visit site`}
                            arrow
                        >
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ cursor: 'pointer' }}
                                href={
                                    investmentPlatform.website
                                        ? investmentPlatform.website.replace('%SUBID%', generateSubId(state.itemSource, state.itemMedium))
                                        : '/discover'
                                }
                                onClick={stopPropagation}
                            >
                                {investmentPlatform.name}
                                <img
                                    alt="expandIcon"
                                    style={{ marginBottom: '-2px', marginLeft: '5px' }}
                                    src={expandIcon}
                                />
                            </a>
                        </Tooltip>
                        <Tooltip
                            disableFocusListener
                            disableTouchListener
                            classes={{ popper: classes.tooltip }}
                            placement="top"
                            title={`Click to copy profile URL to share`}
                            arrow
                        >
                            <a
                                onClick={(event) => {
                                    event.stopPropagation();
                                    platformUrl && clickCopy(platformUrl);
                                }}
                            >
                                <Tooltip
                                    disableFocusListener={true}
                                    disableHoverListener={true}
                                    className=""
                                    placement="bottom"
                                    title={`Copied!`}
                                    open={copySuccess}
                                    arrow
                                >
                                    <ShareIcon
                                        style={{
                                            cursor: 'pointer',
                                            color: '#cacbce',
                                            marginBottom: '-5px',
                                            marginLeft: '7px',
                                        }}
                                        fontSize="small"
                                    />
                                </Tooltip>
                            </a>
                        </Tooltip>
                    </div>
                }
                subheader={
                    <div className={classes.subheader}>
                        <Chip
                            style={{ marginTop: '0px', marginRight: '0px' }}
                            label={investmentPlatform.industry}
                            className="ctag gray"
                        />

                        {(investmentPlatform.averageRating || null) && (
                            <Rating
                                className={classes.rating}
                                value={investmentPlatform.averageRating}
                            />
                        )}
                    </div>
                }
            />
            <CardContent
                style={paddingStyle}
            >
                <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    style={{ maxWidth: '450px', marginLeft: '16px', color: '#73757A' }}
                >
                    {!expanded ? (
                        <TextTruncate
                            line={3}
                            element="span"
                            truncateText="…"
                            text={investmentPlatform.description}
                            textTruncateChild={<a style={{ color: '#5056e7' }}>more</a>}
                        />
                    ) : (
                        investmentPlatform.description
                    )}
                </Typography>
            </CardContent>
		</>
	);
}
