import React, { useEffect, useRef, useState } from 'react';
import Card from '@material-ui/core/Card';
import Header from './Header';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import VizSensor from 'react-visibility-sensor';
import { ObserverSensor } from '../ObserverSensor/ObserverSensor';
import Sidebar from './Sidebar';
import InvestmentPlatformTabs from './Tabs';
import ReactGA from 'react-ga';
import { trackEvent, eventTracker, getFullPlatformUrl } from '../../utils';
import { useSelector, shallowEqual } from 'react-redux';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});
//cd/cd test
const useStyles = makeStyles((theme) => ({
	card: {
		'&:hover': { cursor: 'pointer' },
		'&:hover  $expandIcon': {
			display: 'block',
		},
		width: 1200,
		maxWidth: '98%',
		margin: 'auto',
		marginBottom: '12px',
		boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
		position: 'relative',
		borderRadius: '8px',
	},
	premiumCard: {
		border: '1px solid #F3B81F',
	},
	expandIcon: {
		position: 'absolute',
		top: '12px',
		right: '12px',
	},
	mainModal: {
		paddingBottom: '56px',
	},
	premium: {
		border: '1px solid #F3B81F',
	},

	createReviewModal: {
		maxWidth: '553px',
		width: '100%',
		backgroundColor: '#FFF',
	},
	innerCards: {
		display: 'flex',
		alignItems: 'flex-start',
		padding: '0 8px 0 62px',
	},
	innerCard: {
		border: '1px solid #F2F5F8',
		borderRadius: '8px',
		backgroundColor: '#fff',
		padding: 0,
		margin: '0 8px',

		'& .MuiCardContent-root:last-child': {
			padding: '0 !important',
		},
	},
	tabsCard: {
		width: '70%',
	},
	sidebarCard: {
		width: '30%',
		padding: '24px',
	},
	[theme.breakpoints.down('sm')]: {
		innerCards: {
			flexDirection: 'column',
			padding: '0 16px',
		},
		innerCard: {
			margin: '0 0 20px 0',
		},
		tabsCard: { width: '100%' },
		sidebarCard: { width: '100%' },
	},
}));

export default function InvestmentPlatformCollapsed(props) {
	const { investmentPlatform, frameId, expandHandler, embedOnclick } = props;

	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const classes = useStyles();
	const [collapsed, setCollapsed] = React.useState(true);

	const fullPlatformUrl =  getFullPlatformUrl(`discover/${investmentPlatform.slug}`);

	useEffect(() => {
		expandHandler();
	});

	function onCardClick() {
		if (embedOnclick) {
			embedOnclick();
		} else {
			setCollapsed(!collapsed);

			trackEvent(
				'PlatformImpression',
				investmentPlatform.name,
				investmentPlatform.name
			);
			eventTracker(
				'PlatformImpression',
				investmentPlatform.name,
				state.itemSource,
				state.itemMedium,
				state.itemCompaign,
				state.userId
			);
		}
	}
	//console.log(height);

	return (
		<div>
			{collapsed ? (
				<VizSensor
					onChange={(isVisible) => {
						trackEvent(
							'PlatformImpression',
							investmentPlatform.name,
							investmentPlatform.name
						);
						eventTracker(
							'PlatformImpression',
							investmentPlatform.name,
							state.itemSource,
							state.itemMedium,
							state.itemCompaign,
							state.userId
						);
					}}
				>
					<ObserverSensor
						id={`cardCollapsed_${investmentPlatform.id}`}
						onMouseOver={() => {
							trackEvent('PlatformMouseOver', 'platform', investmentPlatform.name);
							eventTracker(
								'PlatformMouseOver',
								investmentPlatform.name,
								state.itemSource,
								state.itemMedium,
								state.itemCompaign,
								state.userId
							);
						}}
						onScroll={() => {
							trackEvent('PlatformScroll', 'platform', investmentPlatform.name);
							eventTracker(
								'PlatformScroll',
								investmentPlatform.name,
								state.itemSource,
								state.itemMedium,
								state.itemCompaign,
								state.userId
							);
						}}
					>
						<Card
							className={`mainCard ${classes.card} ${
								investmentPlatform.premium > 0 ? classes.premiumCard : null
							}`}
							onClick={onCardClick}
							key={investmentPlatform.id}
						>
							<IconButton className={classes.expandIcon} aria-label="Expand">
								<KeyboardArrowRightIcon />
							</IconButton>

							<Header
								investmentPlatform={investmentPlatform}
								platformUrl={fullPlatformUrl}
							/>
						</Card>
					</ObserverSensor>
				</VizSensor>
			) : (
				<React.Fragment>
					<Card
						className={`mainCard ${classes.card} ${
							investmentPlatform.premium > 0 ? classes.premiumCard : null
						}`}
					>
						<div id="modal" />
						<IconButton
							className={classes.expandIcon}
							aria-label="Expand"
							onClick={onCardClick}
						>
							<KeyboardArrowUpIcon />
						</IconButton>
						<Header
							investmentPlatform={investmentPlatform}
							platformUrl={window.location.href}
						/>
						<div className={classes.innerCards}>
							<div className={`${classes.innerCard} ${classes.tabsCard}`}>
								<InvestmentPlatformTabs
									investmentPlatform={investmentPlatform}
									embed={true}
								/>
							</div>

							<div className={`${classes.innerCard} ${classes.sidebarCard}`}>
								<Sidebar investmentPlatform={investmentPlatform} color="1" />
							</div>
						</div>
					</Card>
				</React.Fragment>
			)}
		</div>
	);
}
