import React from "react";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { useCommonStyles } from "./common-styles";

const useStyles = makeStyles({
    textField: {
        marginTop: "16px",
    },
    gratitude: {
        color: "#73757A",
        marginBottom: "8px",
    },
});

export default function CreateReviewFormSecondPart({ onSubmit }) {
    const commonClasses = useCommonStyles();
    const classes = useStyles();
    const textFieldClassName = `${commonClasses.textField} ${classes.textField}`;

    const [ name, setName ] = React.useState("");
    const [ email, setEmail ] = React.useState("");

    return (
        <div className={commonClasses.root}>
            <div className={commonClasses.heading}>One last thing</div>

            <p className={commonClasses.text}>
                To confirm your review, please enter a valid email address below.
                Click the link in the confirmation email you will receive and - all done.
            </p>

            <p className={`${commonClasses.text} ${classes.gratitude}`}>
                Thank you for helping others by reviewing this platform!
            </p>

            <TextField
                variant="outlined"
                size="small"
                name="name"
                className={textFieldClassName}
                placeholder="Name"
                value={name}
                onChange={event => setName(event.target.value)}
            />

            <TextField
                variant="outlined"
                size="small"
                name="email"
                className={textFieldClassName}
                placeholder="Email address"
                value={email}
                onChange={event => setEmail(event.target.value)}
            />

            <div className={commonClasses.submitButtonContainer}>
                <Button
                    variant="contained"
                    className="homeBtn alt"
                    color="primary"
                    disabled={name.length === 0 || email.length === 0}
                    onClick={() => onSubmit({ name, email })}
                >
                    Submit
                </Button>
            </div>
        </div>
    )
}