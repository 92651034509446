import React, { useEffect } from 'react';
import Card from '@material-ui/core/Card';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { AdHeader } from '../CustomWidget/AdHeader';
import CardActions from '@material-ui/core/CardActions';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import VizSensor from 'react-visibility-sensor';
import { ObserverSensor } from '../ObserverSensor/ObserverSensor';
import ReactGA from 'react-ga';
import { eventTracker, trackEvent, generateSubId, getFullPlatformUrl } from '../../utils';
import { routeNames } from '../../data/Data';
import { useSelector, shallowEqual } from 'react-redux';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles((theme) => ({
	card: {
		'&:hover': { cursor: 'pointer' },

		//margin: 'auto',
		margin: '0 12px 0 12px',
		marginBottom: '12px',
		boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
		position: 'relative',
		borderRadius: '8px',
		padding: '0px 12px',
	},
	premiumCard: {
		border: '1px solid #F3B81F',
	},
	expandIcon: {
		position: 'absolute',
		top: '12px',
		right: '12px',
		background: '#5056E7',
		'&:hover': {
			backgroundColor: '#646AF0',
		},
		borderRadius: '100px',
		color: '#fff',
		padding: '6px 16px',
		textTransform: 'none',

		[theme.breakpoints.down('519')]: {
			padding: '6px',
			minWidth: '30px',
		},
		[theme.breakpoints.down('445')]: {
			padding: '6px 16px',
			minWidth: '64px',
		},
		[theme.breakpoints.down('310')]: {
			padding: '6px',
			minWidth: '30px',
		},
	},
	moreButton: {
		textTransform: 'none',
		color: '#73757A',
		fontFamily: 'Inter',
		fontSize: '14px',
	},
	cardAction: {
		borderTop: '1px solid #F2F5F8',
		padding: '0px',
	},
}));

export const InvestCard = (props) => {
	const {
		investmentPlatform,
		embedOnclick,
		expandable,
		w,
		h,
		setActivePlatform,
		cluster,
	} = props;
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const classes = useStyles();
	const [source, setSource] = React.useState(state.itemSource || 'MoneyMade');

	const platformUrl = `${routeNames.customWidget}/${investmentPlatform.id}`;
	const fullPlatformUrl = getFullPlatformUrl(platformUrl);

	function stopPropagation(event) {
		trackEvent('ExternalClick', 'platform', investmentPlatform.name);
		eventTracker("ExternalClick", investmentPlatform.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
		event.stopPropagation();
	}
	const onCardClick = async () => {
		if (embedOnclick) {
			embedOnclick();
		} else {
			ReactGA.pageview(platformUrl);
			eventTracker("pageview", platformUrl, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);

			await setActivePlatform(investmentPlatform);
			let element = document.getElementById(
				`platform_ex_${cluster ? `${cluster}_` : null}${investmentPlatform.id}`
			);
			element.scrollIntoView({
				behavior: 'smooth',
			});
		}
	};
	useEffect(() => {});

	return (
		<VizSensor
			onChange={(isVisible) => {
				trackEvent('PlatformImpression', 'platform', investmentPlatform.name);
				eventTracker("PlatformImpression", investmentPlatform.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
			}}
		>
			<ObserverSensor
				 id={`cardCollapsed_${investmentPlatform.id}`}
				 onMouseOver={() => {
					trackEvent('PlatformMouseOver', 'platform', investmentPlatform.name);
					eventTracker("PlatformMouseOver", investmentPlatform.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
				 }}
				 onScroll={() => {
					trackEvent('PlatformScroll', 'platform', investmentPlatform.name);
					eventTracker("PlatformScroll", investmentPlatform.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
				 }}
			>
				<Card
					className={`mainCard ${classes.card} ${
						investmentPlatform.premium > 0 ? classes.premiumCard : null
					}`}
					onClick={onCardClick}
				>
					<Button
						color="primary"
						className={classes.expandIcon}
						target="_blank"
						rel="noopener noreferrer"
						href={
							investmentPlatform.website
								? investmentPlatform.website.replace('%SUBID%', generateSubId(state.itemSource, state.itemMedium))
								: `/${routeNames.discover}`
						}
						onClick={stopPropagation}
					>
						{w > 519 || (w <= 444 && w >= 310) ? 'More' : <KeyboardArrowRightIcon />}
					</Button>

					<AdHeader
						investmentPlatform={investmentPlatform}
						platformUrl={fullPlatformUrl}
						w={w}
					/>
					<CardActions className={classes.cardAction}>
						<Button
							endIcon={<KeyboardArrowRightIcon />}
							fullWidth={true}
							className={classes.moreButton}
						>
							See details
						</Button>
					</CardActions>
				</Card>
			</ObserverSensor>
		</VizSensor>
	);
};
