import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import Grid from '@material-ui/core/Grid';
import { trackEvent, eventTracker, hideMedia, isStringExistAndNotEmpty } from '../../utils';
import { useSelector, shallowEqual } from 'react-redux';
import { BestFor } from './BestFor';
import { HeaderFirstGrid } from './HeaderFirstGrid';
import { HeaderSecondGrid } from './HeaderSecondGrid';
import { HeaderThirdGrid } from './HeaderThirdGrid';
import { SimilarPlatformsCard } from './SimilarPlatformsCard';
import { ParallelMarkets } from '../ParallelMarkets/ParallelMarkets';
import { MediaCard } from '../Media/MediaCard';
import { OverviewText } from './OverviewText';
import InvestmentPlatformReviews from './Reviews';
import InvestmentPlatformMentions from './Mentions';
import { VisitWebsite } from '../Buttons/VisitWebsite';
import { SidebarCountry } from './SidebarCountry';
import { SidebarMore } from './SidebarMore';
import { TabPanel, a11yProps } from './Tabs';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import withStyles from '@material-ui/core/styles/withStyles';
import { LearnMoreButton } from '../Buttons/LearnMore';
import { StreamTab } from '../Stream/StreamTab';
import { Press } from './Press';
import { DeepDiveButton } from '../Buttons/DeepDiveButton';
import { routeNames } from '../../data/Data';
import { isDeepDiveAllowed, genereateDeepdiveReviewPage } from '../../utils';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

export const StylizedTabs = withStyles({
	root: {
		borderBottom: '1px solid #DBE0E6',
		marginBottom: '24px',
	},
	indicator: {
		height: '2px',
		backgroundColor: '#5056E7',
    },
    fixed: {
        overflowX: 'auto !important',
    }
})(Tabs);

export const StylizedTab = withStyles({
	root: {
        textTransform: 'none',
        '&:link': {
            color: '#73757A',
          },
		'&:hover': {
            color: '#5056E7',
          },
        '&$selected': {
            color: '#5056E7',
        },
        '&:focus': {
            color: '#5056E7',
        },
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '20px',
        minWidth: '92px',
	},
})(Tab);
const useStyles = makeStyles((theme) => ({
	headerItem: {
        minWidth: '100%',
        paddingLeft: '16px',
        paddingRight: '16px',
    },
    item: {
        padding: '10px 10px 0 10px',
    },
    button: {
        padding: '10px 10px 10px 10px',
    },
    card: {
		border: '1px solid #F2F5F8',
		borderRadius: '8px',
		backgroundColor: '#fff',
		padding: '24px',
        margin: '12px',
		'& .MuiCardContent-root:last-child': {
			padding: '0 !important',
		},
    },
    borderedColumn: {
		position: 'relative',
		'& .pseudoborder': {
			height: 'calc(100% - 40px) !important',
			top: '28px',
			left: '-24px',
			margin: '0 !important',
		},
	},
	secondColumn: {
		paddingRight: '32px',
    },
    sticky: {
        display: 'none',
    }
}));

export const HeaderBodyMobile = (props) => {
    const {
        investmentPlatform,
        platformUrl,
        expandable,
        expanded,
        similarInvestments,
        setActivePlatform,
        embed,
        cluster,
        openCreateReviewForm,
        modal,
        resetTotal,
        search,
        handleMoveToListView,
		isListView,
    } = props;
    const classes = useStyles();
    const [tabIndex, setTabIndex] = useState(0);
    const hideMediaValue = useMemo(() => hideMedia(investmentPlatform.promoVideo, investmentPlatform.media), [investmentPlatform.promoVideo, investmentPlatform.media]);
    const tabsId = `tabs_${investmentPlatform.id}`;
    const stickyTabsId = `stickyTabs_${investmentPlatform.id}`;
    const stickyTabsLimitId = `stickyTabsLimit_${investmentPlatform.id}`;
    const platformLimitId = `platformLimit_${investmentPlatform.id}`;
    const buttonLimitId = `buttonLimitId_${investmentPlatform.id}`;
    const buttonId = `button_${investmentPlatform.id}`;
    const stickyButtonId = `stickyButton_${investmentPlatform.id}`;
    const modalId = `modalPlatform`;
    const modalElement = document.getElementById(modalId);

    const modalStickyButton = modal ? `stickyButtonModal` : `stickyButton`;
    const modalStickyTabs = modal ? `stickyTabsModal` : `stickyTabs`;

    const href = `/${routeNames.deepdive}/${genereateDeepdiveReviewPage(investmentPlatform.slug)}`;

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
		return function cleanup () {
            window.removeEventListener('scroll', handleScroll)
		};
    });
    useEffect(() => {
        if (!modal) {
            return;
        }
        if (!modalElement) {
            return;
        }
        modalElement.addEventListener('scroll', handleFixScroll);
		return function cleanup () {
            modalElement.removeEventListener('scroll', handleFixScroll)
		};
    });

    const handleScroll = () => {
        const tabs = document.getElementById(tabsId);
        const stickyTabs = document.getElementById(stickyTabsId);
        const button = document.getElementById(buttonId);
        const stickyButton = document.getElementById(stickyButtonId);

        const buttonLimit = document.getElementById(buttonLimitId);
        const tabsLimit = document.getElementById(stickyTabsLimitId);
        const platformLimit = document.getElementById(platformLimitId);

        if (buttonLimit.getBoundingClientRect().top < 60) {
            tabs.style.visibility = 'hidden';
            stickyTabs.style.display = 'inherit';

            button.style.visibility = 'hidden';
            stickyButton.style.display = 'inherit';
        } else {
            tabs.style.visibility = 'visible';
            stickyTabs.style.display = 'none';

            button.style.visibility = 'visible';
            stickyButton.style.display = 'none';
        }
        if (platformLimit.getBoundingClientRect().top < 130) {
            tabs.style.visibility = 'visible';
            stickyTabs.style.display = 'none';

            button.style.visibility = 'visible';
            stickyButton.style.display = 'none';
        }
    }
	const handleChange = (event, newValue) => {
        setTabIndex(newValue);
        const tabsLimit = document.getElementById(buttonLimitId);
        tabsLimit.scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
    };
    const handleFixScroll = () => {
        const tabs = document.getElementById(tabsId);
        const stickyTabs = document.getElementById(stickyTabsId);
        const button = document.getElementById(buttonId);
        const stickyButton = document.getElementById(stickyButtonId);

        const buttonLimit = document.getElementById(buttonLimitId);
        const tabsLimit = document.getElementById(stickyTabsLimitId);
        const platformLimit = document.getElementById(platformLimitId);

        if (buttonLimit.getBoundingClientRect().top < 0) {
            tabs.style.visibility = 'hidden';
            stickyTabs.style.display = 'inherit';

            button.style.visibility = 'hidden';
            stickyButton.style.display = 'inherit';
        } else {
            tabs.style.visibility = 'visible';
            stickyTabs.style.display = 'none';

            button.style.visibility = 'visible';
            stickyButton.style.display = 'none';
        }
    }
    const tabs = {
        general: { index: 0, label: 'General', selected: true },
        overview: { index: 1, label: 'Overview' },
        reviews: { index: 2, label: 'Reviews' },
        stream: { index: 3, label: 'Conversation' },
        mentions: { index: 4, label: 'Mentions' },
        press: { index: 5, label: 'Press' },
        more: { index: 6, label: 'More' },
    };
    const header = (
        <>
            <div className={classes.headerItem}>
                <HeaderFirstGrid
                    investmentPlatform={investmentPlatform}
                    expanded={expanded}
                    expandable={expandable}
                    platformUrl={platformUrl}
                />
            </div>
            <div id={buttonLimitId}></div>
            <div className={classes.button}>

            {(investmentPlatform.deepDive || isDeepDiveAllowed(investmentPlatform.slug)) && <div style={{marginBottom: '10px'}}>
				<DeepDiveButton
					name={investmentPlatform.name}
					href={href}
				/>
			</div>}
                <div id={buttonId}>
                    <VisitWebsite
                        investmentPlatform={investmentPlatform}
                        color={`1`}
                    />
                </div>
                <div id={stickyButtonId} className={`${classes.sticky} ${modalStickyButton}`}>
                    <VisitWebsite
                        investmentPlatform={investmentPlatform}
                        color={`1`}
                    />
                </div>
            </div>
        </>
    );
    const tabGeneral = (
        <>
            <Grid container alignItems="flex-start" id={`MuiGrid-item-Mobile`}>
				<Grid className={`${classes.borderedColumn} ${classes.secondColumn}`} item sm={6} xs={6}>
					<HeaderSecondGrid
						investmentPlatform={investmentPlatform}
						expanded={expanded}
						expandable={expandable}
					/>
				</Grid>
				<Grid className={`${classes.borderedColumn}`} item sm={6} xs={6} id={`MuiGrid-item-ThirdGrid`}>
					<HeaderThirdGrid
						investmentPlatform={investmentPlatform}
						expanded={expanded}
						expandable={expandable}
					/>
				</Grid>
			</Grid>
            {expandable && expanded &&
				<LearnMoreButton
					investmentPlatform={investmentPlatform}
					color="1"
				/>}
            <div className={classes.item}>
                {!hideMediaValue && (
                    <MediaCard
                        id={investmentPlatform.id}
                        logo={investmentPlatform.logo}
                        promoVideo={investmentPlatform.promoVideo}
                        media={investmentPlatform.media}
                        mediaArray={isStringExistAndNotEmpty(investmentPlatform.promoVideo) ?
                            [investmentPlatform.promoVideo, ...investmentPlatform.media] : investmentPlatform.media }
                />)}
            </div>
            <div className={classes.item}>
                <BestFor
                    bestFor={investmentPlatform.bestFor}
                />
            </div>
        </>
    );
    const tabOverview = (
        <>
            <div className={classes.card}>
                <SidebarCountry
                    investmentPlatform={investmentPlatform}
                />
            </div>
            <div className={classes.card}>
                <OverviewText
                    investmentPlatform={investmentPlatform}
                />
            </div>
            {!embed && similarInvestments.length > 0 ? (
                <>
                    <SimilarPlatformsCard
                        setActivePlatform={setActivePlatform}
                        expanded={cluster ? false : true}
                        similarPlatforms={similarInvestments}
                        resetTotal={resetTotal}
                        handleMoveToListView={handleMoveToListView}
						isListView={isListView}
                    />
                </>
            ) : null}
        </>
    );
    const tabReviews = (
        <>
            <div className={classes.card}>
                <InvestmentPlatformReviews
					investmentPlatform={investmentPlatform}
					onCreateReview={openCreateReviewForm}
					embed={embed}
				/>
            </div>
        </>
    );
    const tabStream = (
        <StreamTab
            slug={investmentPlatform.slug}
            name={investmentPlatform.name}
            id={investmentPlatform.id}
        />
    )
    const tabMentions = (
        <>
            <div className={classes.card}>
                <InvestmentPlatformMentions
                    investmentPlatform={investmentPlatform}
                />
            </div>
        </>
    );
    const tabPress = (
        <div className={classes.item}>
            <Press
                platformId={investmentPlatform.id}
            />
        </div>
    );
    const tabMore = (
        <>
            <div className={classes.card}>
                <SidebarMore
                    investmentPlatform={investmentPlatform}
                />
            </div>
            {investmentPlatform.investors === 'Accredited' && (
                <div className={classes.item}>
                    <ParallelMarkets
                        id={investmentPlatform.id}
                    />
                </div>
            )}
            {!embed && similarInvestments.length > 0 ? (
                <>
                    <SimilarPlatformsCard
                        setActivePlatform={setActivePlatform}
                        expanded={cluster ? false : true}
                        similarPlatforms={similarInvestments}
                        search={search}
                    />
                </>
            ) : null}
        </>
    )

    return (
        <>
            {header}
            <div id={stickyTabsLimitId}></div>
            <div>
                <StylizedTabs value={tabIndex} onChange={handleChange} id={tabsId}>
                    {Object.values(tabs).map((tab) => (
                        <StylizedTab
                            label={tab.label}
                            key={tab.index}
                            selected={tab.selected}
                            {...a11yProps(tab.index)}
                        />
                    ))}
                </StylizedTabs>
                <StylizedTabs value={tabIndex} onChange={handleChange} id={stickyTabsId} className={`${classes.sticky} ${modalStickyTabs}`}>
                    {Object.values(tabs).map((tab) => (
                        <StylizedTab
                            label={tab.label}
                            key={tab.index}
                            selected={tab.selected}
                            {...a11yProps(tab.index)}
                        />
                    ))}
                </StylizedTabs>
                <TabPanel value={tabIndex} index={0} selected={true}>
                    {tabGeneral}
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    {tabOverview}
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                    {tabReviews}
                </TabPanel>
                <TabPanel value={tabIndex} index={3}>
                    {tabStream}
                </TabPanel>
                <TabPanel value={tabIndex} index={4}>
                    {tabMentions}
                </TabPanel>
                <TabPanel value={tabIndex} index={5}>
                    {tabPress}
                </TabPanel>
                <TabPanel value={tabIndex} index={6}>
                    {tabMore}
                </TabPanel>
            </div>
            <div id={platformLimitId}></div>
        </>
    )
}
