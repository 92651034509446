import React from 'react';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import expandIcon from '../../img/icons/external-link.svg';
import Tooltip from '@material-ui/core/Tooltip';
import ShareIcon from '@material-ui/icons/Share';
import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import InvestmentPlatformAvatar from './Avatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Rating from '../Rating';
import { getSentimentText } from './helpers';

function stopPropagation(event) {
	event.stopPropagation();
}

const useStyles = makeStyles({
	borderedColumn: {
		position: 'relative',

		'& .pseudoborder': {
			height: 'calc(100% + 40px) !important',
			top: '-20px',
			left: '-24px',
			margin: '0 !important',
		},
	},
	secondColumn: {
		paddingRight: '32px',
		alignSelf: 'center',
	},
	thirdgrid: {
		alignSelf: 'center',
	},
	subheader: {
		maxWidth: '220px',
		alignItems: 'center',
	},
	rating: {
		marginLeft: '8px',
	},
	tooltip: {
		zIndex: 10001, // to be visible inside the modal
	},
});

export default function Header(props) {
	const { investmentPlatform } = props;
	const [copySuccess, setCopySuccess] = React.useState(false);
	const classes = useStyles();

	function clickCopy(url) {
		//console.log(url);
		copyToClipboard(url);
		setCopySuccess(true);
		setTimeout(() => setCopySuccess(false), 1200);
	}

	function copyToClipboard(copyText) {
		var textField = document.createElement('textarea');
		textField.innerText = copyText;
		document.body.appendChild(textField);
		textField.select();
		document.execCommand('copy');
		textField.remove();
	}
	//console.log(investmentPlatform.features);
	return (
		<Grid container alignItems="flex-start">
			<Grid className="firstgrid" item xs={5}>
				<CardHeader
					avatar={
						<Tooltip
							disableFocusListener
							disableTouchListener
							classes={{ popper: classes.tooltip }}
							placement="top"
							title={getSentimentText(investmentPlatform.sentiment)}
							arrow
						>
							<span>
								<InvestmentPlatformAvatar investmentPlatform={investmentPlatform} />
							</span>
						</Tooltip>
					}
					title={
						<div>
							<Tooltip
								disableFocusListener
								disableTouchListener
								classes={{ popper: classes.tooltip }}
								placement="top"
								title={`Visit site`}
								arrow
							>
								<a
									target="_blank"
									rel="noopener noreferrer"
									style={{ cursor: 'pointer' }}
									href={investmentPlatform.website}
									onClick={stopPropagation}
								>
									{investmentPlatform.name}
								</a>
							</Tooltip>
						</div>
					}
					subheader={
						<div className={classes.subheader}>
							<Chip
								style={{ marginTop: '0px' }}
								label={investmentPlatform.industry}
								className="ctag gray"
							/>

							{(investmentPlatform.rating || null) && (
								<Rating
									className={classes.rating}
									value={investmentPlatform.rating}
								/>
							)}
						</div>
					}
				/>

				<CardContent style={{ paddingTop: '0px', paddingRight: '24px' }}>
					<Typography
						variant="body2"
						color="textSecondary"
						component="p"
						style={{ maxWidth: '450px', marginLeft: '56px', color: '#73757A' }}
					>
						{investmentPlatform.description}
					</Typography>
				</CardContent>
				<CardActions disableSpacing></CardActions>
			</Grid>

			<Grid
				className={`${classes.borderedColumn} ${classes.secondColumn}`}
				item
				xs={2}
			>
				<p
					style={{
						fontSize: '14px',
						margin: '0',
						marginTop: '0px',
						color: '#73757A',
					}}
				>
					Account min
				</p>
				<h4
					style={{
						fontWeight: 'normal',
						marginTop: '0',
						marginBottom: '10px',
						fontSize: '20px',
					}}
				>
					${' '}
					{investmentPlatform.fees.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
				</h4>

				<div className="pseudoborder"></div>
			</Grid>

			<Grid
				className={` ${classes.thirdgrid} ${classes.borderedColumn}`}
				item
				xs={4}
			>
				<div>
					<ul>
						{investmentPlatform.features.map((feature) => (
							<li key={feature}>{feature}</li>
						))}
					</ul>
				</div>
			</Grid>
		</Grid>
	);
}
