import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { OverviewText } from './OverviewText';

const useStyles = makeStyles({
	root: { padding: '0 24px 16px 24px' },
});

export default function InvestmentPlatformOverview({ investmentPlatform }) {
	const classes = useStyles();
	//console.log(investmentPlatform);
	return (
		<div className={classes.root}>
			<OverviewText
				investmentPlatform={investmentPlatform}
			/>
		</div>
	);
}
