import React from 'react';

import HeaderAppBar from '../components/HeaderAppBar.jsx';
import { Grid, Button, TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer/Footer.jsx';
//assets
import bg1Web from '../img/lpbgs/bg1Web.svg';
import bg1Tablet from '../img/lpbgs/bg1Tablet.svg';
import bg1Phone from '../img/lpbgs/bg1Phone.svg';

import bottom1web from '../img/lpbgs/bottomsection.svg';
import bottom1tablet from '../img/lpbgs/bgBottomTablet.svg';
import bottom1phone from '../img/lpbgs/bgBottomPhone.svg';

import groupsvg from '../img/lpbgs/Group 59.svg';
import groupsvgtablet from '../img/lpbgs/Group 59tablet.svg';
import groupsvgphone from '../img/lpbgs/Group 59phone.svg';

import phoneImg from '../img/lpbgs/iphone 1.png';
import phoneImgMobile from '../img/lpbgs/iphone 2.png';

import mailSvg from '../img/lpbgs/Group 60.png';
import mailSvgMobile from '../img/lpbgs/Group 60Mobile.png';
import reviewimg from '../img/lpbgs/reviews illustration.png';
import reviewimgTablet from '../img/lpbgs/reviews illustrationTablet.png';
import reviewimgMobile from '../img/lpbgs/reviews illustrationMobile.png';
import SVG from 'react-inlinesvg';
import ReactGA from 'react-ga';
import { trackEvent, eventTracker } from '../utils';
import { useSelector, shallowEqual } from 'react-redux';
//import { useBrowserEffect } from '../shared';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const Home = () => {
	let homeStyles = {};
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);

	React.useEffect(() => {
		if (state.userId) {
			ReactGA.pageview('/');
			eventTracker("pageview", '/', state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
		}
	}, [state.userId]);

	/*
	useBrowserEffect(() => {
		if (state.userId) {
			ReactGA.pageview('/');
			eventTracker("pageview", '/', state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
		}
	}, [state.userId]);
	*/

	function handleSubmit(event) {
		ReactGA.pageview('/thankyou');
		eventTracker("pageview", '/thankyou', state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
		trackEvent('User', 'Newsletter', 'Newsletter');
	}

	if (window.innerWidth > 768) {
		homeStyles = {
			bg1: {
				background: `url(${bg1Web})`,
				height: '1007px',
				backgroundRepeat: 'no-repeat',
			},
			bg4: {
				background: `url(${bottom1web})`,
				height: '1027px',
				marginBottom: '-56px',
			},

			grid1: { height: '924px', width: '1280px', maxWidth: '90%', margin: 'auto' },
			grid1noH: {
				height: 'unset',
				width: '1280px',
				maxWidth: '90%',
				margin: 'auto',
				marginBottom: '100px',
			},
			grid2: { height: '924px', maxWidth: '90%', margin: 'auto' },
			grid3: { height: '100%', maxWidth: '90%', margin: 'auto' },
			gridnoH: {
				height: 'unset',
				maxWidth: '90%',
				margin: 'auto',
				marginTop: '0px',
				marginBottom: '0px',
			},
			SvgMail: groupsvg,
			PhoneImage: phoneImg,
			ImgReview: reviewimg,
			ImgMail: mailSvg,
		};
	}
	if (window.innerWidth <= 768 && window.innerWidth > 440) {
		homeStyles = {
			bg1: {
				background: `url(${bg1Tablet})`,
				height: 1124,
				backgroundRepeat: 'no-repeat',
			},
			grid1: { height: '1124px' },
			bg4: {
				background: `url(${bottom1tablet})`,
				height: '1027px',
				marginBottom: '-56px',
			},
			SvgMail: groupsvgtablet,
			PhoneImage: phoneImg,
			ImgReview: reviewimgTablet,
			ImgMail: mailSvgMobile,
		};
	}
	if (window.innerWidth <= 440) {
		homeStyles = {
			bg1: {
				background: `url(${bg1Phone})`,
				height: 834,
				backgroundRepeat: 'no-repeat',
			},
			grid1: { height: '834px' },
			bg4: {
				background: `url(${bottom1phone})`,
				height: '1027px',
				marginBottom: '-96px',
			},
			SvgMail: groupsvgphone,
			PhoneImage: phoneImgMobile,
			ImgReview: reviewimgMobile,
			ImgMail: mailSvgMobile,
		};
	}
	return (
		<div>
			<div style={homeStyles.bg1} className="transparentFixedHeader">
				<HeaderAppBar />

				<Grid style={homeStyles.grid1} className="reverse" container spacing={1}>
					<Grid xs={6} item>
						<div className="textGrid1">
							<h1 className="mobileDifGrid1">
								{' '}
								Find and compare <b>investment opportunities</b>{' '}
							</h1>
							<p>
								Discover online investments from Real Estate, Collectibles, Loans,
								Farmland, Stocks and more.{' '}
							</p>

							<Link to="/discover">
								<Button variant="contained" className="homeBtn one" color="primary">
									Discover investments
								</Button>
							</Link>
						</div>
					</Grid>
					<Grid xs={6} className="imgGrid1 exceptionImg" item>
						<img alt="phoneimg" src={phoneImg} />
					</Grid>
				</Grid>
			</div>

			<div className="transparentFixedHeader">
				<Grid style={homeStyles.gridnoH} container spacing={1}>
					<Grid xs={6} className="imgLimit" item>
						<SVG style={{ maxWidth: '100%' }} src={homeStyles.SvgMail} />
					</Grid>
					<Grid xs={6} item>
						<div className="textGrid2">
							<h1>
								{' '}
								There’s more ways than ever to <b>invest online today</b>{' '}
							</h1>
							<p>Easily discover investment opportunities that work best for you.</p>
							<p className="alt">
								Robo investing, Real Estate, Collectibles, Loans, Farmland, Gold,
								Startups, Digital Currencies, and more…{' '}
							</p>

							<Link to="/discover">
								<Button variant="contained" className="homeBtn alt" color="primary">
									Discover investments
								</Button>
							</Link>
						</div>
					</Grid>
				</Grid>
			</div>

			<div /* style={homeStyles.bg1} */ className="transparentFixedHeader spacer">
				<Grid style={homeStyles.grid1noH} className="reverse " container spacing={1}>
					<Grid xs={6} item>
						<div className="textGrid2">
							<h1>
								{' '}
								Reviews from the <b>Professionals & the People</b>{' '}
							</h1>
							<p>
								Read reviews and engage with Financial Professionals that have
								assessed the investment as well as the People that have invested.{' '}
							</p>

							<Button
								variant="contained"
								className="homeBtn alt"
								style={{ background: '#C5C8D1' }}
								color="primary"
								disabled
							>
								Coming soon
							</Button>
						</div>
					</Grid>
					<Grid xs={6} className="imgGrid2" item>
						<img className="imgLimit" alt="review" src={homeStyles.ImgReview} />
					</Grid>
				</Grid>
			</div>
			<div /* style={homeStyles.bg1} */ className="transparentFixedHeader">
				<Grid style={homeStyles.grid3} container spacing={1}>
					<Grid xs={6} style={{ textAlign: 'center' }} item>
						<img alt="imgMail" src={homeStyles.ImgMail} />
					</Grid>
					<Grid xs={6} item>
						<div className="textGrid2">
							<h1> Grow your wealth </h1>
							<p>
								Get notified of the latest investment opportunities, platforms, tips
								and updates.
							</p>
							<iframe
								width="0"
								height="0"
								border="0"
								title="dummyframe"
								name="dummyframe"
								id="dummyframe"
							></iframe>
							<form
								onSubmit={handleSubmit}
								style={{ display: 'flex' }}
								target="dummyframe"
								action="https://moneymade.us4.list-manage.com/subscribe/post?u=9922df745a85edbac4d1eef0b&amp;id=360bd75c7c"
								method="post"
								id="mc-embedded-subscribe-form"
								name="mc-embedded-subscribe-form"
								className="validate"
								/* target="_blank" */ noValidate
							>
								<TextField
									name="FNAME"
									type="hidden"
									id="mce-FNAME"
									style={{ margin: '10px', display: 'none' }}
									className="searchInput"
									placeholder="Name"
									variant="outlined"
								/>

								<TextField
									name="EMAIL"
									id="mce-EMAIL"
									style={{ maxWidth: '288px', margin: 0, marginRight: '12px' }}
									className="searchInput"
									placeholder="Email"
									variant="outlined"
								/>

								<TextField
									multiline
									rows="4"
									name="MESSAGE"
									type="hidden"
									id="mce-MESSAGE"
									style={{ margin: '10px', display: 'none' }}
									className="searchInput"
									placeholder="Your message"
									variant="outlined"
								/>
								<div style={{ textAlign: 'right', marginRight: '-10px' }}>
									<Button
										type="submit"
										style={{ height: '56px', borderRadius: '8px' }}
										variant="contained"
										className="homeBtn alt"
										color="primary"
									>
										Submit
									</Button>
								</div>
							</form>
						</div>
					</Grid>
				</Grid>
			</div>

			<div style={homeStyles.bg4} className="transparentFixedHeader">
				<Grid style={homeStyles.grid3} container spacing={1}>
					<Grid xs={12} item>
						<div className="textGrid1" style={{ textAlign: 'center', height: 1000 }}>
							<h1 style={{ marginTop: '130px' }}>Get in touch</h1>
							<p>
								Got questions, thoughts or know of another investment platform you
								don’t see listed? Drop us an email
							</p>

							<form
								action="https://www.enformed.io/5ixr2g1s"
								method="post"
								name="mc-embedded-subscribe-form"
								className="validate"
								/* target="_blank" */ noValidate
							>
								<TextField
									name="FNAME"
									style={{ margin: '10px' }}
									className="searchInput"
									placeholder="Name"
									variant="outlined"
								/>
								<TextField
									name="EMAIL"
									style={{ margin: '10px' }}
									className="searchInput"
									placeholder="Email"
									variant="outlined"
								/>
								<TextField
									multiline
									rows="4"
									name="MESSAGE"
									style={{ margin: '10px' }}
									className="searchInput"
									placeholder="Your message"
									variant="outlined"
								/>
								<input
									type="hidden"
									name="*redirect"
									value="https://moneymade.io/thankyou"
								/>
								<input
									type="hidden"
									name="*default_email"
									value="richard@fintechmedia.io"
								/>

								<input
									type="hidden"
									name="*subject"
									value="MoneyMade contact form submission"
								/>

								<div style={{ textAlign: 'right', marginRight: '-10px' }}>
									<Button
										type="submit"
										variant="contained"
										className="homeBtn lastBtn"
										color="primary"
									>
										Submit
									</Button>
								</div>
							</form>
						</div>
					</Grid>
				</Grid>
			</div>
			<div className="altFooter">
				{' '}
				<Footer />
			</div>
			<div className="sortWrapper footer__disclaimer">
				<p>
					The Content is for informational purposes only, you should not construe any
					such information or other material as legal, tax, investment, financial, or
					other advice. Nothing contained on our Site constitutes a solicitation,
					recommendation, endorsement, or offer by MoneyMade or any third party
					service provider to buy or sell any securities or other financial
					instruments in this or in in any other jurisdiction in which such
					solicitation or offer would be unlawful under the securities laws of such
					jurisdiction. All Content on this site is information of a general nature
					and does not address the circumstances of any particular individual or
					entity. Nothing in the Site constitutes professional and/or financial
					advice, nor does any information on the Site constitute a comprehensive or
					complete statement of the matters discussed or the law relating thereto.
					MoneyMade is not a fiduciary by virtue of any person’s use of or access to
					the Site or Content. You alone assume the sole responsibility of evaluating
					the merits and risks associated with the use of any information or other
					Content on the Site before making any decisions based on such information
					or other Content. In exchange for using the Site, you agree not to hold
					MoneyMade, its affiliates or any third party service provider liable for
					any possible claim for damages arising from any decision you make based on
					information or other Content made available to you through the Site.
					<br />
					<br />
					MoneyMade strives to keep its information accurate and up to date. This
					information may be different than what you see when you visit a financial
					institution, service provider or specific product’s site. All financial
					products, services are presented without warranty. When evaluating offers,
					please review the financial institution’s Terms and Conditions.
					<br />
					<br />
					Advertiser disclosure: We strive to provide users with the most transparent
					and true information about the platforms listed. Some of the platforms
					listed may contain Affiliate links. We may receive payment if you apply for
					and / or receive one of the products listed on MoneyMade.
				</p>
			</div>
		</div>
	);
};
export default Home;
