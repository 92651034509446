import React, { useState, useEffect } from 'react';
import { TextField, Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { useDispatch } from 'react-redux';
import * as actions from "../../redux/actions";
import { Spinner } from '../Spinner/Spinner';
import { trackEvent, checkMail, checkPassword, isEmpty, eventTracker } from '../../utils';
import { signUp } from '../../Services/SignUpAPI';
//import { signIn } from '../../Services/SignInAPI';
import { useSelector, shallowEqual } from "react-redux";
import { loginUser } from '../../Firebase/firebase-service';
//import { auth } from '../../Firebase/firebase';
import firebase from '../../Firebase/firebase';

const CustomButton = withStyles((theme) => ({
	root: {
        [theme.breakpoints.down('500')]: {
            maxWidth: '360px',
            width: '100%'
        },
	},
}))(Button);
const useStyles = makeStyles((theme) => ({
    main: {
        margin: 'auto auto',
        [theme.breakpoints.up('600')]: {
            marginLeft: '100px',
            width: '370px',
        },
        [theme.breakpoints.down('600')]: {
            marginLeft: '80px',
            width: '370px',
        },
        [theme.breakpoints.down('500')]: {
            maxWidth: '370px',
            width: '90%',
            margin: 'auto',
            padding: '0px',
        },
    },
    title: {
        fontFamily: 'Red Hat Display',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '28px',
        lineHeight: '40px',
        color: '#30333E',
        //marginBottom: '12px',
        marginBottom: '40px',
        [theme.breakpoints.down('500')]: {
            textAlign: 'center',
        },
    },
    description: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#73757A',
        marginBottom: '40px',
        [theme.breakpoints.down('500')]: {
            textAlign: 'center',
        },
    },
    label: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#30333E',
        marginBottom: '8px',
    },
    agreementContainer: {
        display: 'block',
        alignItems: 'center',
        //marginBottom: '32px',
    },
    agreementText: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#73757A',
    },
    agreementLink: {
        cursor: 'pointer',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#5056E7',
    },
    button: {
        marginBottom: '36px',
        marginTop: '32px',
        [theme.breakpoints.down('500')]: {
            maxWidth: '360px',
        },
    },
    signInContainer: {
        display: 'block',
        alignItems: 'center',
        marginBottom: '40px',
    },
    signInText: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '##30333E',
    },
    heading: {
        fontSize: '16px',
        lineHeight: '24px',
        color: '#5056E7 !important',
        marginBottom: '30px',
    },
}));
export const SignUpCard = (props) => {
    const { onFinish } = props;
    const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
    const classes = useStyles();
    const dispatch = useDispatch();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userName, setUserName] = useState('');
    const [mail, setMail] = useState('');
    const [password, setPassword] = useState('');
    const [displaySpinner, setDisplaySpinner] = useState(false);
    const [checked, setChecked] = useState(false);
    const [failedFirstName, setFailedFirstName] = useState(false);
    const [failedLastName, setFailedLastName] = useState(false);
    const [failedUserName, setFailedUserName] = useState(false);
    const [failedMail, setFailedMail] = useState(false);
    const [failedPassword, setFailedPassword] = useState(false);
    const [failedCheckbox, setFailedCheckbox] = useState(false);
    const [submitingForm, setSubmitingForm] = useState(false);
    const [submitingSignInForm, setSubmitingSignInForm] = useState(false);
    const [signInForm, setSignInForm] = useState(false);
    const [errorEmail, setErrorEmail] = useState("");
    const [errorPassword, setErrorPassword] = useState("");
    const [errorNewPassword, setErrorNewPassword] = useState("");
    const [forgotPasswordForm, setForgotPasswordForm] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    const newPasswordErrorText = "To be secured password it must have at least 8 symbols: at least 1 number (0-9), 1 upper case symbol (A-Z), 1 lower case symbol (a-z), 1 unique symbol (!,@,#,$,%,^,&,*,_,-)";
    const submissisonText = "Check your email for password recovery instructions";

    // Detect Enter
    useEffect(() => {
        window.addEventListener("keyup", detectEnter);
        return () =>
          window.removeEventListener("keyup", detectEnter);
    }, []);

    // Sign Up hooks
    useEffect(() => {
        if(!failedFirstName && !failedLastName && !failedUserName && !failedMail && !failedPassword && checked && submitingForm && !signInForm) {
            trackEvent('User', 'SignUp', 'SignUp');
            eventTracker("User", 'SignUp', state.itemSource, state.itemMedium, state.itemCompaign, state.userId);

            setFailedFirstName(false);
            setFailedLastName(false);
            setFailedUserName(false);
            setFailedMail(false);
            setFailedPassword(false);
            setFailedCheckbox(false);

            setErrorEmail("");
            setErrorNewPassword("");

            setDisplaySpinner(true);

            const userId = state.userId;

            signUp(mail, firstName, lastName, userName, password, userId)
            .then((data) => {
                if (data.success) {
                    trackEvent('AccountCreated', 'Email', `${mail}`);
                    eventTracker("AccountCreated", `${mail}`, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);

                    signInWithFireBase();
                } else {
                    setErrorEmail(data.message);
                    setFailedMail(true);
                }
                setDisplaySpinner(false);
            }).catch((error) => {
            });
        }
    }, [failedFirstName, failedLastName, failedUserName, failedMail, failedPassword, checked, submitingForm, signInForm]);

    //Sign In hooks
    useEffect(() => {
        if(!failedMail && !failedPassword && submitingSignInForm && signInForm) {
            trackEvent('User', 'SignIn', 'SignIn');
            eventTracker("User", 'SignIn', state.itemSource, state.itemMedium, state.itemCompaign, state.userId);

            setFailedMail(false);
            setFailedPassword(false);

            setDisplaySpinner(true);

            setErrorPassword("");
            setErrorEmail("");

            signInWithFireBase();
        }
    }, [failedMail, failedPassword, submitingSignInForm, signInForm]);

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };
    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
    };
    const handleUserNameChange = (event) => {
        setUserName(event.target.value);
    };
    const handleEmailChange = (event) => {
        setMail(event.target.value);
    };
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };
    const handleCheckboxChange = (event) => {
		setChecked(event.target.checked);
    };
    const detectEnter = (event) => {
        if (event.keyCode === 13 && state.modalSignUpOpen && !signInForm) {
            event.preventDefault();
            !signInForm ? handleSignUp() : handleSignIn();
        }
    };
    const handleSignUp = (event) => {
        if(!isEmpty(firstName.trim()) || !isEmpty(lastName.trim()) || !isEmpty(userName.trim()) || !checkMail(mail.trim()) || !checkPassword(password.trim()) || !checked) {
            if (!isEmpty(firstName.trim())) {
                setFailedFirstName(true);
            } else {
                setFailedFirstName(false);
            }
            if (!isEmpty(lastName.trim())) {
                setFailedLastName(true);
            } else {
                setFailedLastName(false);
            }
            if (!isEmpty(userName.trim())) {
                setFailedUserName(true);
            } else {
                setFailedUserName(false);
            }
            if (!checkMail(mail.trim())) {
                setFailedMail(true);
            } else {
                setFailedMail(false);
            }
            if (!checkPassword(password.trim())) {
                setFailedPassword(true);
                setErrorNewPassword(newPasswordErrorText);
            } else {
                setFailedPassword(false);
            }
            if (!checked) {
                setFailedCheckbox(true);
            } else {
                setFailedCheckbox(false);
            }
            setSubmitingForm(false);
        } else {
            setFailedFirstName(false);
            setFailedLastName(false);
            setFailedUserName(false);
            setFailedMail(false);
            setFailedPassword(false);
            setFailedCheckbox(false);
            setSubmitingForm(true);
        }
    };
    const handleSignIn = (event) => {
        if(!checkMail(mail.trim()) || !isEmpty(password.trim())) {
            if (!checkMail(mail.trim())) {
                setFailedMail(true);
            } else {
                setFailedMail(false);
            }
            if (!isEmpty(password.trim())) {
                setFailedPassword(true);
            } else {
                setFailedPassword(false);
            }
            setSubmitingSignInForm(false);
        } else {
            setFailedMail(false);
            setFailedPassword(false);
            setSubmitingSignInForm(true);
        }
    };
    const signInWithFireBase = () => {
        loginUser(mail, password)
        .then((currentUser) => {
            const displayName = currentUser.user.displayName;
            dispatch(actions.authUser(displayName))
            currentUser.user.getIdToken()
            .then((token) => {
                dispatch(actions.setToken(token));
                onFinish();
            });
        })
        .catch((error) => {
            if (error.code === 'auth/invalid-email') {
                setErrorEmail(error.message);
                setFailedMail(true);
            }
            if (error.code === 'auth/wrong-password') {
                setErrorPassword(error.message);
                setFailedPassword(true);
            }
            console.log(error);
        })
        .finally(() => {
            setSubmitingSignInForm(false);
            setDisplaySpinner(false);
        });
    };
    const handleOpenSignIn = (value) => {
        clear();
        setForgotPasswordForm(false);
        setSignInForm(value);
        setSubmitingForm(false);
        setSubmitingSignInForm(false);
    };
    const clear = () => {
        setFailedFirstName(false);
        setFailedLastName(false);
        setFailedUserName(false);
        setFailedMail(false);
        setFailedPassword(false);
        setFailedCheckbox(false);
        setErrorNewPassword("");
        setErrorPassword("");
        setErrorEmail("");
        setPassword("");
        setShowMessage(false);
    };
    const handleOpenForgotPassword = () => {
        clear();
        setForgotPasswordForm(true);
    };
    const handleSubmitForgotPassword = () => {
        setErrorEmail('');
        setFailedMail(false);
        setDisplaySpinner(true);

        firebase.auth().sendPasswordResetEmail(mail)
        .then(function() {
            setShowMessage(true);
        })
        .catch(function(error) {
            setErrorEmail(error.message);
            setFailedMail(true);
        })
        .finally(() => {
            setDisplaySpinner(false);
        });
    };
    return (
        <>
            {displaySpinner &&
                (<center>
                    {<Spinner displaySpinner={displaySpinner} />}
                </center>)}

            <div className={classes.main}>
                {!signInForm && !displaySpinner && !forgotPasswordForm &&
                (<>
                    <div className={classes.title}>
                        Create account
                    </div>
                    {/*
                    <div className={classes.description}>
                        MoneyMade account opens up new functionality and helps you to pick the best investment opportunities
                    </div>
                    */}
                    <div className={classes.label}>
                        First name*
                    </div>
                    <TextField
                        name="FIRSTNAME"
                        style={{
                            maxWidth: '360px',
                            width: '100%'
                        }}
                        classes={{input: classes.input}}
                        placeholder="e.g. John"
                        variant="outlined"
                        className="signInInput"
                        onChange={handleFirstNameChange}
                        value={firstName}
                        error={failedFirstName}
                        />
                    <div className={classes.label}>
                        Last name*
                    </div>
                    <TextField
                        name="FIRSTNAME"
                        style={{
                            maxWidth: '360px',
                            width: '100%'
                        }}
                        className="signInInput"
                        placeholder="e.g. Hamilton"
                        variant="outlined"
                        onChange={handleLastNameChange}
                        value={lastName}
                        error={failedLastName}
                    />
                    <div className={classes.label}>
                        Username*
                    </div>
                    <TextField
                        name="USERTNAME"
                        style={{
                            maxWidth: '360px',
                            width: '100%'
                        }}
                        className="signInInput"
                        placeholder="e.g. rich_uncle"
                        variant="outlined"
                        onChange={handleUserNameChange}
                        value={userName}
                        error={failedUserName}
                    />
                    <div className={classes.label}>
                        Email*
                    </div>
                    <TextField
                        name="FIRSTNAME"
                        style={{
                            maxWidth: '360px',
                            width: '100%'
                        }}
                        className="signInInput"
                        placeholder="e.g. johnhamilton@gmail.com"
                        variant="outlined"
                        onChange={handleEmailChange}
                        value={mail}
                        error={failedMail}
                        helperText={errorEmail}
                    />
                    <div className={classes.label}>
                        Password*
                    </div>
                    <TextField
                        name="PASSWORD"
                        style={{
                            maxWidth: '360px',
                            width: '100%'
                        }}
                        className="signInInput"
                        placeholder="must have at least 8 characters"
                        variant="outlined"
                        onChange={handlePasswordChange}
                        value={password}
                        error={failedPassword}
                        type="password"
                        helperText={errorNewPassword}
                    />
                    <div className={classes.agreementContainer}>
                        <Checkbox
                            checked={checked}
                            onChange={handleCheckboxChange}
                            style={{
                                    color: failedCheckbox ? '#f44336' : '#DBE0E6',
                            }}
                        />
                        <span className={classes.agreementText}>I agree with MoneyMade </span>
                        <Link
                            className={classes.agreementLink}
                            to="/terms-and-condition"
                            target="_blank"
					        rel="noopener noreferrer"
                        >
                            Terms and Policies
                        </Link>
                    </div>
                    <div className={classes.button}>
                        <CustomButton
                            variant="contained"
                            color="primary"
                            onClick={handleSignUp}
                        >
                            Create account
                        </CustomButton>
                    </div>
                    <div className={classes.signInContainer}>
                        <span className={classes.signInText}>Already have an account? </span>
                        <span className={classes.agreementLink} onClick={() => {handleOpenSignIn(true)}} href="">Sign In</span>
                    </div>
                </>)}
                {signInForm && !displaySpinner && !forgotPasswordForm &&
                (<>
                    <div className={classes.title}>
                        Sign In
                    </div>
                     {/*
                    <div className={classes.description}>
                        MoneyMade account opens up new functionality and helps you to pick the best investment opportunities
                    </div>
                    */}
                    <div className={classes.label}>
                        Email*
                    </div>
                    <TextField
                        name="FIRSTNAME"
                        style={{
                            maxWidth: '360px',
                            width: '100%'
                        }}
                        className="signInInput"
                        placeholder="e.g. johnhamilton@gmail.com"
                        variant="outlined"
                        onChange={handleEmailChange}
                        value={mail}
                        error={failedMail}
                        helperText={errorEmail}
                    />
                    <div className={classes.label}>
                        Password*
                    </div>
                    <TextField
                        name="PASSWORD"
                        style={{
                            maxWidth: '360px',
                            width: '100%'
                        }}
                        className="signInInput"
                        placeholder="must have at least 8 characters"
                        variant="outlined"
                        onChange={handlePasswordChange}
                        value={password}
                        error={failedPassword}
                        type="password"
                        helperText={errorPassword}
                    />
                    <div className={classes.button}>
                        <CustomButton
                            variant="contained"
                            color="primary"
                            onClick={handleSignIn}
                        >
                            Sign In
                        </CustomButton>

                    </div>
                    <div className={classes.signInContainer}>
                        <span className={classes.agreementLink} onClick={handleOpenForgotPassword}>Forgot Password</span>
                        <span className={classes.signInText}> or Don't have an account yet? </span>
                        <span className={classes.agreementLink} onClick={() => {handleOpenSignIn(false)}}>Sign Up</span>
                    </div>
                </>)}
                {forgotPasswordForm && !displaySpinner &&
                 (<>
                    <div className={classes.title}>
                        Enter your email to start your password recovery process
                    </div>
                    {!showMessage
                    ?
                        <>
                            <div className={classes.label}>
                                Email*
                            </div>
                            <TextField
                                name="EMAIL"
                                style={{
                                    maxWidth: '360px',
                                    width: '100%'
                                }}
                                className="signInInput"
                                placeholder="e.g. johnhamilton@gmail.com"
                                variant="outlined"
                                onChange={handleEmailChange}
                                value={mail}
                                error={failedMail}
                                helperText={errorEmail}
                            />
                            <div className={classes.button}>
                                <CustomButton
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmitForgotPassword}
                                >
                                    Submit
                                </CustomButton>
                            </div>
                        </>
                    :
                        <Typography
                            className={classes.heading}
                        >
                            {submissisonText}
                        </Typography>
                    }
                    <div className={classes.signInContainer}>
                        <span className={classes.signInText}>Already have an account? </span>
                        <span className={classes.agreementLink} onClick={() => {handleOpenSignIn(true)}} href="">Sign In</span>
                        <br />
                        <span className={classes.signInText}>Don't have an account yet? </span>
                        <span className={classes.agreementLink} onClick={() => {handleOpenSignIn(false)}}>Sign Up</span>
                    </div>
                </>)
                }
            </div>
        </>
    )
};
