import { REMOTEAPI_TRADE_URL, REMOTEAPI_TRADING_ENTRY_URL } from './ConstAPI';
import PropTypes from 'prop-types';

const createAPIQUery = (urlGenerator) => async (...params) => {
	try {
		const url = urlGenerator(...params);
		const headers = {
			'Content-Type': 'application/json; charset=utxf-8',
		};
		const response = await fetch(url, { method: 'GET', headers });
		const json = await response.json();

		return {
			success: true,
			...json,
		};
	} catch {
		return {
			success: false,
			result: [],
			message: 'There is an issue to get data from server. Please try again later.',
		};
	}
};

const createPOSTAPIQUery = (urlGenerator) => async (...params) => {
	try {
		const headers = {
			'Content-Type': 'application/json; charset=utf-8',
		};
		const url = urlGenerator(...params);

		const response = await fetch(url, { method: 'POST', headers, body: params[0] });

		const json = await response.json();

		return {
			success: true,
			...json,
		};
	} catch {
		return {
			success: false,
			result: [],
			message: 'There is an issue to get data from server. Please try again later.',
		};
	}
};

export const getMore = createAPIQUery((more) => `${more}`);

export const getSearch = createAPIQUery(
	(search) => `${REMOTEAPI_TRADE_URL}/search?q=${search}`
);

export const getSearchWithParams = createAPIQUery(
	(search, params) => `${REMOTEAPI_TRADE_URL}/search?q=${search}${params}`
);

export const getResultWithParams = createAPIQUery(
	(params) => `${REMOTEAPI_TRADE_URL}/${params}`
);

export const getFilterResult = createPOSTAPIQUery(
	(params) => `${REMOTEAPI_TRADE_URL}`
);

export const getOneEntry = createPOSTAPIQUery(
	(params) => `${REMOTEAPI_TRADING_ENTRY_URL}`
);

createAPIQUery.propTypes = {
	headers: PropTypes.array,
	url: PropTypes.string,
	response: PropTypes.object,
};
