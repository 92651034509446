import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Header from './Header';
import Sidebar from './Sidebar';
import InvestmentPlatformTabs from './Tabs';
import ClosableModal from '../ClosableModal';
import CreateReviewForm from './CreateReviewForm';
import { createReview } from '../../Services/ReviewsAPITrade';

const useStyles = makeStyles(theme => ({
	mainModal: {
		paddingBottom: '56px'
	},
	premium: {
		border: '1px solid #F3B81F'
	},

	createReviewModal: {
		maxWidth: '553px',
		width: '100%',
		backgroundColor: '#FFF'
	},
	innerCards: {
		display: 'flex',
		alignItems: 'flex-start',
		padding: '0 8px 0 62px'
	},
	innerCard: {
		border: '1px solid #F2F5F8',
		borderRadius: '8px',
		backgroundColor: '#fff',
		padding: 0,
		margin: '0 8px',

		'& .MuiCardContent-root:last-child': {
			padding: '0 !important'
		}
	},
	tabsCard: {
		width: '70%'
	},
	sidebarCard: {
		width: '30%',
		padding: '24px'
	},
	[theme.breakpoints.down('sm')]: {
		innerCards: {
			flexDirection: 'column',
			padding: '0 16px'
		},
		innerCard: {
			margin: '0 0 20px 0'
		},
		tabsCard: { width: '100%' },
		sidebarCard: { width: '100%' }
	}
}));

const modals = {
	MAIN: 'main',
	CREATE_REVIEW: 'create_review'
};

export default function TradingPlatformModal({ investmentPlatform, onClose }) {
	const classes = useStyles();
	const [visibleModal, setVisibleModal] = React.useState(modals.MAIN);
	const [isWaitingForReview, setIsWaitingForReview] = React.useState(false);

	const openCreateReviewForm = () => setVisibleModal(modals.CREATE_REVIEW);
	const openMainModal = () => setVisibleModal(modals.MAIN);

	const onCreateReviewFormSubmit = async data => {
		setIsWaitingForReview(true);

		await createReview(investmentPlatform.id, {
			reviewerName: data.name,
			reviewerEmail: data.email,
			isVerified: data.isInvesting,
			comment: data.comment,
			rating: data.rating
		});

		setIsWaitingForReview(false);
		setVisibleModal(modals.MAIN);
	};

	return (
		<React.Fragment>
			<ClosableModal
				className={`${classes.mainModal} ${
					investmentPlatform.premium > 0 ? classes.premium : null
				}`}
				open={visibleModal === modals.MAIN}
				onClose={onClose}
			>
				<Header
					investmentPlatform={investmentPlatform}
					platformUrl={window.location.href}
				/>

				<div className={classes.innerCards}>
					<div className={`${classes.innerCard} ${classes.tabsCard}`}>
						<InvestmentPlatformTabs
							investmentPlatform={investmentPlatform}
							onCreateReview={openCreateReviewForm}
						/>
					</div>

					<div className={`${classes.innerCard} ${classes.sidebarCard}`}>
						<Sidebar investmentPlatform={investmentPlatform} />
					</div>
				</div>
			</ClosableModal>

			<ClosableModal
				className={classes.createReviewModal}
				open={visibleModal === modals.CREATE_REVIEW}
				onClose={openMainModal}
			>
				<CreateReviewForm
					isLoading={isWaitingForReview}
					investmentPlatform={investmentPlatform}
					onSubmit={onCreateReviewFormSubmit}
				/>
			</ClosableModal>
		</React.Fragment>
	);
}
