import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const CustomButton = withStyles((theme) => ({
	root: {
		fontFamily: 'Inter',
        lineHeight: '20px',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        borderRadius: '8px',
        maxWidth: '213px',
        minWidth: '90px !important',
        height: '40px',
        textTransform: 'none',
	},
}))(Button);

const useStyles = makeStyles((theme) => ({
	blue: {
        //background: 'rgba(80, 86, 231, 0.84)',
        color: '#fff',
        border: '1px solid #fff',
        [theme.breakpoints.up('400')]: {
            margin: '16px 40px 16px 16px',
        },
        [theme.breakpoints.down('400')]: {
            margin: '0px 16px 0px 0px',
        },
        [theme.breakpoints.down('304')]: {
            margin: '0px 8px 0px 0px',
		},
    },
    white: {
        background: '#fff',
        color: '#5056E7',
        border: '1px solid #5056E7',
        [theme.breakpoints.up('400')]: {
            margin: '16px 40px 16px 16px',
        },
        [theme.breakpoints.down('400')]: {
            margin: '16px 16px 16px 0px',
        },
        [theme.breakpoints.down('304')]: {
            margin: '16px 8px 16px 0px',
		},
    }
}));

export const SignUpButton = (props) => {
    const {
        type,
        handleClick
    } = props;
    const classes = useStyles();
    const typeClass = type ? ((type === 'blue') ? classes.blue : classes.white) : classes.blue;
    const handleClickAction = handleClick ? handleClick : null;
    return (
        <CustomButton
            variant="outlined"
            className={typeClass}
            onClick={handleClickAction}
        >
            Sign Up
        </CustomButton>
    )
}
