import React, { useEffect } from 'react';
import queryString from 'query-string';
import Footer from '../components/Footer/Footer';
import ReactGA from 'react-ga';
import { useDispatch } from 'react-redux';
import * as actions from '../redux/actions';
import { routeNames } from '../data/Data';
import { StreamPage } from '../components/Stream/StreamPage';
import { eventTracker } from '../utils';
import { useSelector, shallowEqual } from 'react-redux';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

export const Stream = (props) => {
	const { utm_campaign, utm_medium, utm_source } = queryString.parse(
		props.location.search
	);
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const dispatch = useDispatch();

	useEffect(() => {
		if (state.userId) {
			ReactGA.pageview(`/${routeNames.stream}`);
			eventTracker("pageview", `/${routeNames.stream}`, utm_source, utm_medium, utm_campaign, state.userId);
		}
		if (utm_source) {
			dispatch(actions.setItemSource(utm_source));
		}
		if (utm_campaign) {
			dispatch(actions.setItemCompaign(utm_campaign));
		}
		if (utm_medium) {
			dispatch(actions.setItemMedium(utm_medium));
		}
	}, [utm_campaign, utm_medium, utm_source, state.userId]);

	return (
		<>
			<div>
				<StreamPage />
			</div>
			<div>
				<Footer utmParams={{ utm_campaign, utm_medium, utm_source }} />
			</div>
		</>
	);
};
export default Stream;
