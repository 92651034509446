import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import { deepDiveDelimiters } from '../../data/Data';
import { catchError } from '../../utils';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});
const useStyles = makeStyles((theme) => ({
    card: {
        marginTop: '32px',
        background: '#FFFFFF',
        border: '1px solid #F2F5F8',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
        borderRadius: '8px',
        padding: '18px 18px 18px 18px',
        [theme.breakpoints.down('460')]: {
            padding: '12px',
        },
    },
    descriptionTitle: {
        fontFamily: 'Red Hat Display',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '36px',
        lineHeight: '48px',
        color: '#30333E',
        marginLeft: '10px',
        marginRight: '10px',
        marginTop: '36px',
        marginBottom: '16px',
    },
    descriptionText: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '28px',
        color: '#30333E',
        marginTop: '16px',
        marginLeft: '10px',
        marginRight: '10px',
    },
    img: {
        width: '100%',
        padding: '10px',
    },
    typeNumberTitle: {
        fontFamily: 'Red Hat Display',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '28px',
        lineHeight: '40px',
        color: '#30333E',
        marginLeft: '10px',
        marginRight: '10px',
        marginTop: '16px',
        marginBottom: '16px',
    },
    typeStepContainer: {
        display: 'flex',
        margin: '16px 10px',
    },
    typeStepTitle: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#30333E',
        //minWidth: '60px',
        whiteSpace: 'nowrap',
    },
    typeStepDesc: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#30333E',
        marginLeft: '10px',
        //marginBottom: '16px',
    },
}));
export const TableOfContents = (props) => {
    const {
        investments,
    } = props;

    const classes = useStyles();
    const [result, setResult] = useState([]);

    useEffect(() => {
        if (investments !== null) {
            const result = [];
            investments.forEach((i, index) => {
                if (i['type'] === `${deepDiveDelimiters.mainType}`) {
                    result.push({
                        title: i['Title'],
                        id: i['id'],
                    });
                }
            });
            setResult(result);
        }
    }, [investments]);

    const handleClick = (id) => {
        try{
            const element = document.getElementById(`${id}`);
            element.scrollIntoView({
                behavior: 'smooth',
                block: "center",
            });
        } catch(err) {
            catchError(err);
        }
    }

	return (
        result.length > 0 &&
            <div className={`${classes.card}`}>
                <div
                    className={`${classes.descriptionTitle}`}
                    key={`TOC`}
                >
                    This review contains:
                </div>
                <div className={`deepDiveBullet`} key={`TOC_Content`}>
                    <ul>
                        {result.map((i, index) => ((
                            <li key={`toc_${index}`}>
                                <a
                                    onClick={() => {handleClick(i['id'])}}
                                    className={`deepDiveLink`}
                                >
                                    {i['title']}
                                </a>
                            </li>
                        )))}
                    </ul>
                </div>
            </div>
	);
};
