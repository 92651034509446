import { REACT_APP_REMOTEAPI_DELETE_FAVORITE_URL } from "./ConstAPI";

const makeURL = () => `${REACT_APP_REMOTEAPI_DELETE_FAVORITE_URL}`;

const makeRequestData = (token, platform) => ({
    method: "POST",
    headers: {
        "Authorization" : `Bearer ${token}`,
        "Content-Type": "application/json",
    },
    body: JSON.stringify(platform),
});

const makeRequest = async (token, platform) => {
    const url = makeURL();
    const requestData = makeRequestData(token, platform);

    return await fetch(url, requestData);
};
/**
 * @param {string} token
 * @param {string} platform
 */
export const deleteFavorite = async (token, platform) => {
    try {
		const response = await makeRequest(token, {platform});
        const responseStatus = await response.json();
        if (response.status === 200) {
            return {
                success: true,
                result: [],
                message: responseStatus.platforms,
            }
        } else {
            return {
                success: false,
                result: [],
                message: responseStatus.platforms,
            };
        }
	} catch (error) {
        console.log(error);
		return {
			success: false,
			result: [],
			message: error,
        };
	}
};
