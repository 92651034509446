import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Button } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import ArrowDropUp from '@material-ui/icons/KeyboardArrowUp';
import ReactGA from 'react-ga';
import InvestmentPlatformCollapsed from './InvestmentPlatform/Collapsed';
import { InvestmentPlatformModalRoute } from './InvestmentPlatform/InvestmentPlatformModalRoute';
import { InvestPlatformHeader } from './InvestmentPlatform/InvestPlatformHeader';
import CalculatorBanner from './Calculator/CalculatorBanner';
import {
	FBSendEvents,
	GASendEvent,
	trackEvent,
	isPaidOnly,
	paidOnlyInvestments,
	convertArrayToObject,
	filterByCategories,
	countObjects,
	eventTracker,
	searchPlatforms } from '../utils';
import { ModalView as ModalQuiz } from './Modal/Modal';
import { ModalSignUp } from './Modal/ModalSignUp';
import { MailCardInvestments }  from './MailCard/MailCardInvestments';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as actions from '../redux/actions';
import { AppBarComponent } from './AppBar/AppBarComponent';
import { getFavorite } from '../Services/GetFavoriteAPI.js';
import { Drawer } from './Drawer/Drawer';
import useDebounce from '../hooks/useDebounce';
import CardCollapsedSkeleton from './Skeleton/CardCollapsedSkeleton';
import { BackToButton } from './Buttons/BackToButton';
import { view } from '../data/Data';
import { DefaultViewCard } from './InvestmentPlatform/DefaultViewCard';
import { ListViewCard } from './InvestmentPlatform/ListViewCard';
// import Clusters from './Clusters/Clusters';
// import { ClustersViewCard } from './InvestmentPlatform/ClustersViewCard';
import { CollectionViewCard } from './InvestmentPlatform/CollectionViewCard';
import { ExploreCollections } from './InvestmentPlatform/ExploreCollections';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles((theme) => ({
	'@global': {
		'*::-webkit-scrollbar': {
			//width: '10px',
			//height: '6px',
			//borderRadius: '10px',
		},
		'*::-webkit-scrollbar-track': {
			'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
		},
		'*::-webkit-scrollbar-thumb': {
			backgroundColor: 'rgba(0,0,0,.1)',
			outline: '1px solid slategrey',
		},
		'::-webkit-scrollbar-thumb:horizontal': {
			background: '#E5E5E5',
			borderRadius: '20px',
		},
	},
	root: {
		display: 'flex',
	},
	appBar: {
		[theme.breakpoints.up('sm')]: {
			zIndex: 9999,
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	toolbar: theme.mixins.toolbar,
	toolbar4Banner: {
		minHeight: 50,
		[theme.breakpoints.up('xs')]: {
			minHeight: 50,
		},
		[theme.breakpoints.up('sm')]: {
			minHeight: 50,
		},
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		marginTop: '5px',
	},
	clusterHeader: {
		margin: 'auto',
		maxWidth: '1200px',
		'&::before': {
			content: '""',
			display: 'block',
			height: '50px',
			margin: '-50px 0 0',
		},
		'& h2': {
			fontFamily: 'Red Hat Display',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: '28px',
			lineHeight: '40px',
			color: '#30333E',
			marginBottom: '0px',
			[theme.breakpoints.down('xs')]: {
				fontSize: '22px',
				maxWidth: '330px',
			},
		},
		'& h4': {
			fontFamily: 'Inter',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: '14px',
			lineHeight: '20px',
			color: '#73757A',
			marginTop: '5px',
			[theme.breakpoints.down('xs')]: {
				fontSize: '12px',
				maxWidth: '330px',
			},
		},
	},
	seeAllButton: {
		margin: 'auto',
		maxWidth: '1200px',
		background: 'rgb(80,86,231)',
		//borderRadius: '8px',
		//borderRadius: '100px',
		color: '#fff',
		'&:hover': {
			backgroundColor: 'rgb(80,86,231, 0.8)',
		},
	},
	fab: {
		position: 'fixed',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
		backgroundColor: '#5056E7',
		color: '#fff',
	},
	hiddenFab: {
		display: 'none',
	},
}));

const LeftNavbarWrapper = (props) => {
	const {
		utmParams,
		expandable,
		expandableBannerShow,
		paidOnly: paidOnlyProp,
	} = props;
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const dispatch = useDispatch();
	const classes = useStyles();

	const [paidOnly, setPaidOnly] = useState(isPaidOnly(state.itemCompaign));
	const [medium, setMedium] = useState(state.itemMedium);
	const [displaySpinner, setDisplaySpinner] = useState(state.displaySpinner);
	const [investments, setInvestments] = useState(paidOnlyInvestments(state.investmentsTotal, paidOnly));
	const [ads, setAds] = useState(state.ads);
	const [clusters, setClusters] = useState(state.clusters);
	const [openModal, setOpenModal] = useState(state.modalOpen);
	const [openModalSignUp, setOpenModalSignUp] = useState(state.modalSignUpOpen);
	const [isListView, setIsListView] = useState(view.default);
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState(0);
	const [activeCluster, setActiveCluster] = useState(true);
	const [clusterInView, setClusterInView] = useState(null);
	const [clusterViewParam, setClusterViewParam] = useState(true);
	const [investmentsMoreArray, setInvestmentsMoreArray] = useState([]);
	const [search, setSearch] = useState('');
	const [searchResultsCount, setSearchResultsCount] = useState('');
	const [liquidity, setLiquidity] = useState('');
	const [type, setType] = useState('');
	const [payoutfreq, setPayoutfreq] = useState('');
	const [accreditedFilter, setAccreditedFilter] = useState('');
	const [ratingFilter, setRatingFilter] = useState('');
	const [mobileOpen, setMobileOpen] = useState(false);
	const [showMenu, setshowMenu] = useState(false);
	const [filter, setfilter] = useState({});
	const [fabClass, setFabClass] = useState(null);
	const [filterCategories, setfilterCategories] = useState({});
	const [typeButtonName, setTypeButtonName] = useState(null);
	const [returnsButtonName, setReturnsButtonName] = useState(null);
	const [liqudityButtonName, setLiquidityButtonName] = useState(null);
	const [payoutButtonName, setPayoutButtonName] = useState(null);
	const [ratingButtonName, setRatingButtonName] = useState(null);
	const [accreditationButtonName, setAccreditationButtonName] = useState(null);
	const [activePlatform, setActivePlatform] = useState(null);
	const [quizValue, setQuizValue] = useState(false);
	const [showDrawer, setShowDrawer] = useState(false);
	const [filtering, setFiltering] = useState(false);
	const [isCollectionActive, setIsCollectionActive] = useState(false);
	const [collectionItem, setCollectionItem] = useState(null);
	const [prevView, setPrevView] = useState(null);

	const debouncedSearch = useDebounce(search, 500);
	const highlighted = _.filter(state.investmentsTotal, (o) => o.highlighted);
	const [highlightedPlatforms, setHighlightedPlatforms] = useState(highlighted);
	const areInvestmentsPresent = Object.values(investments).length !== 0;
	const headerClass = 'reveal-text';
	const bannerName = `banner`;

	let merged = { ...filter };
	const categoriesValues = Object.keys(filterCategories).filter(
		(k) => filterCategories[k]
	);
	if (categoriesValues.length) {
		merged.categories = categoriesValues;
	}
	const countFilters = countObjects(filter, filterCategories, ratingFilter);

	useEffect(() => {
		if (state.itemCompaign) {
			setPaidOnly(isPaidOnly(state.itemCompaign));
		}
	}, [state.itemCompaign]);
	useEffect(() => {
		if (state.investments.length > 0) {
			setInvestments(paidOnlyInvestments(state.investments, paidOnly));
			setIsListView(view.list);
		}
	}, [state.investments]);
	useEffect(() => {
		if (state.itemMedium) {
			setMedium(state.itemMedium);
		}
	}, [state.itemMedium]);
	useEffect(() => {
		if (state.quizResults) {
			setQuizValue(true);
			setIsListView(view.list);
		} else {
			setQuizValue(false);
		}
	}, [state.quizResults]);

	// update clusters by url
	useEffect(() => {
		if ((state.clusterView || clusterInView) && state.clusters.length > 0) {
			let clusterId;
			if (state.clusterView) {
				if (state.clusterView === 'cluster_') {
					setIsListView(view.collection);
					dispatch(actions.setClusterView(''));
					return;
				}
				clusterId = state.clusterView.replace('#cluster_', '');
			} else {
				clusterId = clusterInView.replace('#cluster_', '');
			}
			if (clusterId) {
				if (state.clusters.filter((i) => i.id === clusterId).length > 0) {
					const cluster = state.clusters.filter((i) => i.id === clusterId)[0];
					let clusterInvestments = _.chain(state.investmentsTotal)
						.pickBy((value, key) => {
							if (value.clusters) {
								return value.clusters.includes(cluster.id);
							}
							return false;
						})
						.sortBy([`${cluster.id}_premium`, 'minInvestment'])
						.value();
					clusterInvestments = _.values(clusterInvestments);

					const clusterObject = {
						...cluster,
						...{
							platforms: clusterInvestments
						}
					};

					setIsCollectionActive(true);
					setCollectionItem(clusterObject);
					setInvestments(clusterObject.platforms);
					scrollTop();
					dispatch(actions.setClusterView(''));
				}
			}
		}
	}, [state.clusterView, state.clusters, clusterInView]);
	useEffect(() => {
		if (state.investmentsTotal) {
			setInvestments(paidOnlyInvestments(state.investmentsTotal, paidOnly));
			const highlighted = _.filter(state.investmentsTotal, (o) => o.highlighted);
			setHighlightedPlatforms(highlighted);
		}
	}, [state.investmentsTotal]);
	useEffect(() => {
		if (state.ads) {
			setAds(state.ads);
		}
	}, [state.ads]);
	useEffect(() => {
		if (state.clusters) {
			setClusters(state.clusters);
		}
	}, [state.clusters]);
	useEffect(() => {
		setDisplaySpinner(state.displaySpinner);
	}, [state.displaySpinner]);
	useEffect(() => {
		if (state.modalOpen) {
			setOpenModal(true);
		} else {
			setOpenModal(false);
		}
	}, [state.modalOpen]);
	useEffect(() => {
		if (state.modalSignUpOpen) {
			setOpenModalSignUp(true);
		} else {
			setOpenModalSignUp(false);
		}
	}, [state.modalSignUpOpen]);

	//unmount
	useEffect(() => {
		return function cleanup() {
			dispatch(actions.setQuizResults(false));
			dispatch(actions.setClusterView(''));
			dispatch(actions.setInvestments({}));
		};
	}, []);

	// get favorites investments
	useEffect(() => {
		if (state.loggedIn) {
			getFavorite(state.token)
			.then((data) => {
				dispatch(actions.addInvesmentsToFavorites(data.result));
			}).catch((error) => {
				console.log(error);
			});
		}
	}, [state.loggedIn]);

	// add scroll listener
	useEffect(() => {
		window.addEventListener('scroll', onScroll);
		return () => window.removeEventListener('scroll', onScroll);
	}, [fabClass]);

	// update platforms by Filtering and Searching
	useEffect(() => {
		const requestParams = { ...merged };
		let response;
		if (!isCollectionActive) {
			response = paidOnlyInvestments(state.investmentsTotal, paidOnly);
		} else {
			response = paidOnlyInvestments(collectionItem.platforms, paidOnly);
		}
		if (Object.values(requestParams).length === 0) {
			setFiltering(false);
		} else {
			setFiltering(true);
		}
		if (Object.values(requestParams).length === 0 && debouncedSearch === '') {
			setInvestments(response);
			return;
		}
		if (Object.values(requestParams).length > 0) {
			response = filterByCategories(requestParams, response);
		}
		if (debouncedSearch !== '') {
			response = searchPlatforms(debouncedSearch, response);
			setSearchResultsCount(response.length);
		}
		const responseObj = convertArrayToObject(response, 0);
		setInvestments(responseObj);
		const highlighted = _.filter(responseObj, (o) => o.highlighted);
		setHighlightedPlatforms(highlighted);
		if (Object.keys(filter).length > 0) {
			FBSendEvents('FilterUsed', {
				data: filter,
			});
			for (const key of Object.keys(filter)) {
				if (filter[key]) {
					GASendEvent(`FilterUsed_${key}`, filter[key].toString());
				}
			}
		}
	}, [filter, filterCategories, ratingFilter, debouncedSearch]);

	// add animation to 1st banner
	useEffect(() => {
		if (!displaySpinner) {
			const limitDiv = document.querySelector(`.${bannerName}`);
			if (limitDiv) {
				const observer = new IntersectionObserver((entries) => observerCallback(entries, observer, limitDiv), {
					threshold: 1
				});
				observer.observe(limitDiv);
			}
		}
	}, [displaySpinner]);

	// add animation to all other banners when they loaded
	useEffect(() => {
		if (!displaySpinner && Object.values(investments).length > 0) {
			document.querySelectorAll('.banner').forEach((i) => {
				if (i) {
					const observer = new IntersectionObserver((entries) => observerCallback(entries, observer, i), {
						threshold: 1
					});
					observer.observe(i);
				}
			})
		}
	}, [displaySpinner, investments]);

	const scrollTop = () => {
		window.scrollTo(0, 0);
	};
	const onScroll = (e) => {
		setFabClass(window.pageYOffset > 500 ? classes.fab : classes.hiddenFab);
	};
	const handleResetSort = () => {
		setInvestments(paidOnlyInvestments(state.investmentsTotal, paidOnly));
	};
	const handleResetFiltering = () => {
		resetAccreditation();
		resetRating();
		resetPayout();
		resetLiquidity();
		resetReturns();
		resetTypes();
	};
	const handleClickAway = () => {
		setOpen(0);
		setAnchorEl(null);
	};
	const handleFilterCategory = (name) => (event) => {
		setIsListView(view.list);
		if (event.target.checked) {
			trackEvent('FilterCategory', name, name);
			eventTracker("FilterCategory", name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
			setfilterCategories({ ...filterCategories, [name]: event.target.checked });
			setOpen(0);
			setTypeButtonName(name);
		} else {
			setfilterCategories({ ...filterCategories, [name]: false });
		}
	};
	const handleSearch = (scoop) => {
		setSearch(scoop);
		setIsListView(view.list);
	};
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	const handleClick = (index, event) => {
		if (index === open) {
			setOpen(0);
			setAnchorEl(null);
		} else {
			setAnchorEl(event.currentTarget);
			setOpen(index);
		}
	};
	const resetTypes = () => {
		setOpen(0);
		setfilterCategories({});
		setTypeButtonName(null);
	};
	const resetReturns = () => {
		setOpen(0);
		setAnchorEl(null);
		const filteredData = filter;
		delete filteredData["types"];
		setfilter({ ...filteredData });
		setReturnsButtonName(null);
		setType('');
	};
	const resetLiquidity = () => {
		setOpen(0);
		setAnchorEl(null);
		const filteredData = filter;
		delete filteredData["liquidity"];
		setfilter({ ...filteredData });
		setLiquidityButtonName(null);
		setLiquidity('')
	};
	const resetPayout = () => {
		setOpen(0);
		setAnchorEl(null);
		const filteredData = filter;
		delete filteredData["payout"];
		setfilter({ ...filteredData });
		setPayoutButtonName(null);
		setPayoutfreq('');
	};
	const resetRating = () => {
		setOpen(0);
		setAnchorEl(null);
		const filteredData = filter;
		delete filteredData["rating"];
		setfilter({ ...filteredData });
		setRatingButtonName(null);
		setRatingFilter('');
	};
	const resetAccreditation = () => {
		setOpen(0);
		setAnchorEl(null);
		const filteredData = filter;
		delete filteredData["accredited"];
		setfilter({ ...filteredData });
		setAccreditationButtonName(null);
		setAccreditedFilter('');
	};
	const resetTotal = () => {
		handleResetFiltering();
		setSearch('');
	};
	const observerCallback = (entries, observer, header) => {
		entries.forEach((entry, i) => {
			if (entry.isIntersecting) {
                header.classList.add(headerClass);
				entry.target.style.animationPlayState = 'running';
				observer.unobserve(entry.target);
            }

		});
	};
	const handleMoveToListView = (value) => {
		setIsListView(value);
	};
	const handleClickBackButton = () => {
		dispatch(actions.setQuizResults(false));
		resetTotal();
		setInvestments(paidOnlyInvestments(state.investmentsTotal, paidOnly));
		setShowDrawer(false);
		setIsCollectionActive(false);
		setCollectionItem(null);
	};
	const handleCollection = (cluster) => {
		setIsCollectionActive(true);
		setCollectionItem(cluster);
		setInvestments(cluster.platforms);
		scrollTop();
	};
	const handleType = (type) => {
		scrollTop();
		const requestParams = {
			categories: [type.name]
		};
		const platforms = paidOnlyInvestments(state.investmentsTotal, paidOnly);
		const response = filterByCategories(requestParams, platforms);
		const responseObj = convertArrayToObject(response, 0);
		setInvestments(responseObj);
		setIsCollectionActive(true);
		const obj = {
			...type,
			...{
				platforms: response
			}
		}
		setCollectionItem(obj);
	};
	const handleObjectives = (value) => {
		setIsCollectionActive(true);
		setCollectionItem(value);
		setInvestments(value.platforms);
		scrollTop();
	};
	const hanldeSetIsListView = (val) => {
		setIsListView(val);
		dispatch(actions.setQuizResults(false));
		resetTotal();
		setInvestments(paidOnlyInvestments(state.investmentsTotal, paidOnly));
		setShowDrawer(false);
		setIsCollectionActive(false);
		setCollectionItem(null);
	};

	const backButton = (
		<BackToButton
			handleBack={handleClickBackButton}
			text={`Back to Investing page`}
		/>);

	const calulatorBanner = (
		<div className={bannerName}>
			<CalculatorBanner />
		</div>);

	const drawerComponent = (
		<Drawer
			paidOnly={paidOnly}
			clusters={clusters}
			investments={investments}
			open={open}
			setOpen={setOpen}
			anchorEl={anchorEl}
			setAnchorEl={setAnchorEl}
			type={type}
			setType={setType}
			filter={filter}
			setfilter={setfilter}
			liquidity={liquidity}
			setLiquidity={setLiquidity}
			setReturnsButtonName={setReturnsButtonName}
			liqudityButtonName={liqudityButtonName}
			setLiquidityButtonName={setLiquidityButtonName}
			payoutButtonName={payoutButtonName}
			setPayoutButtonName={setPayoutButtonName}
			payoutfreq={payoutfreq}
			setPayoutfreq={setPayoutfreq}
			handleClick={handleClick}
			handleClickAway={handleClickAway}
			handleFilterCategory={handleFilterCategory}
			resetTypes={resetTypes}
			resetReturns={resetReturns}
			resetLiquidity={resetLiquidity}
			resetPayout={resetPayout}
			resetRating={resetRating}
			resetAccreditation={resetAccreditation}
			setIsListView={setIsListView}
			setClusterInView={setClusterInView}
			clusterInView={clusterInView}
			typeButtonName={typeButtonName}
			categoriesValues={categoriesValues}
			returnsButtonName={returnsButtonName}
			ratingButtonName={ratingButtonName}
			ratingFilter={ratingFilter}
			accreditationButtonName={accreditationButtonName}
			setAccreditationButtonName={setAccreditationButtonName}
			setAccreditedFilter={setAccreditedFilter}
			accreditedFilter={accreditedFilter}
			setRatingFilter={setRatingFilter}
			setRatingButtonName={setRatingButtonName}
			quizValue={quizValue}
			isCollectionActive={isCollectionActive}
		/>);

	const investPlatformHeader = (
		<InvestPlatformHeader
			areInvestmentsPresent={areInvestmentsPresent}
			search={search}
			investments={investments}
			searchResultsCount={searchResultsCount}
			handleSearch={handleSearch}
			isListView={isListView}
			hanldeSetIsListView={hanldeSetIsListView}
			setInvestments={setInvestments}
			handleResetSort={handleResetSort}
			setSearch={setSearch}
			setShowDrawer={setShowDrawer}
			showDrawer={showDrawer}
			handleResetFiltering={handleResetFiltering}
			filtering={filtering}
			quizValue={quizValue}
			isCollectionActive={isCollectionActive}
			collectionItem={collectionItem}
		/>);

	const skeletonElement = (
		<CardCollapsedSkeleton />
	);
	const skeletonArray = Array(4).fill(skeletonElement);

	return (
		<div className={`respCont ${classes.root}`}>
			<CssBaseline />
			<AppBarComponent
				utmParams={utmParams}
				medium={medium}
				showMenu={showMenu}
				paidOnly={paidOnly}
				expandable={expandable}
				handleDrawerToggle={handleDrawerToggle}
				expandableBannerShow={expandableBannerShow}
			/>
			{areInvestmentsPresent && (
				<InvestmentPlatformModalRoute
					investments={investments}
					resetTotal={resetTotal}
					search={search}
					expandable={expandable}
				/>)}
			<main className={`${classes.content} mainPrinc`}>
				<div className={expandable ? classes.toolbar4Banner : classes.toolbar} />

				{isCollectionActive && backButton}
				{quizValue && backButton}
				{quizValue && (
					<MailCardInvestments
						investments={investments}
					/>
				)}
				{!isCollectionActive && (!quizValue && calulatorBanner)}
				<center>
					{investPlatformHeader}
					{showDrawer && drawerComponent}
				</center>
				{areInvestmentsPresent && isListView === view.default && !isCollectionActive &&
					<DefaultViewCard
						expandable={expandable}
						investments={investments}
						investmentsTotal={paidOnlyInvestments(state.investmentsTotal, paidOnly)}
						setActivePlatform={setActivePlatform}
						activePlatform={activePlatform}
						search={search}
						resetTotal={resetTotal}
						calulatorBanner={calulatorBanner}
						ads={ads}
						clusters={clusters}
						handleCollection={handleCollection}
						handleType={handleType}
						handleObjectives={handleObjectives}
						handleMoveToListView={handleMoveToListView}
						isListView={isListView}
					/>}
				{areInvestmentsPresent && isListView === view.collection && !isCollectionActive &&
					<CollectionViewCard
						investments={paidOnlyInvestments(state.investmentsTotal, paidOnly)}
						clusters={clusters}
						handleCollection={handleCollection}
					/>}
				{((areInvestmentsPresent && isListView === view.list) || isCollectionActive) &&
					<ListViewCard
						expandable={expandable}
						investments={investments}
						investmentsTotal={paidOnlyInvestments(state.investmentsTotal, paidOnly)}
						setActivePlatform={setActivePlatform}
						activePlatform={activePlatform}
						search={search}
						resetTotal={resetTotal}
						calulatorBanner={calulatorBanner}
						ads={ads}
						isCollectionActive={isCollectionActive}
						handleMoveToListView={handleMoveToListView}
						isListView={isListView}
					/>}
				{!areInvestmentsPresent && (
					<div>
						{skeletonArray.map((element) => (
							element
						))}
					</div>
				)}
				{isCollectionActive && (
					<ExploreCollections
						clusters={clusters}
						investments={paidOnlyInvestments(state.investmentsTotal, paidOnly)}
						handleCollection={handleCollection}
					/>
				)}
				{!areInvestmentsPresent && (
					<div>
						{/* <p style={{ textAlign: 'center' }}>Loading results...</p> */}
						<Button
							style={{
								background: '#FBFCFD',
								boxShadow: 'unset',
								marginTop: '10px',
								display: countFilters > 0 ? 'block' : 'none',
							}}
							onClick={() => {handleResetFiltering()}}
							fullWidth
							variant="contained"
						>
							Reset filters ({countFilters})
						</Button>
					</div>
				)}
				{investmentsMoreArray &&
					investmentsMoreArray.map((thes, index) => {
						return thes.map((properties) => (
							<InvestmentPlatformCollapsed
								key={properties.id}
								investmentPlatform={properties}
							/>
						));
					})}
			</main>
			<Fab
				aria-label="Go Up"
				className={fabClass}
				onClick={scrollTop}
			>
				<ArrowDropUp />
			</Fab>
			<ModalQuiz
				open={openModal}
			/>
			<ModalSignUp
				open={openModalSignUp}
			/>
		</div>
	);
};
export default LeftNavbarWrapper;
