import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import IconButton from '@material-ui/core/IconButton';
import _ from 'lodash';
import { getShuffle } from '../../utils';

const useStyles = makeStyles((theme) => ({
	root: {
        width: '1200px',
        margin: 'auto',
        position: 'relative',
        maxWidth: '100%',
        marginBottom: '40px',
    },
    container: {
		display: 'flex',
		flexWrap: 'wrap',
        gap: '20px',
        [theme.breakpoints.down('400')]: {
            gap: '10px',
		},
    },
	tile: {
        cursor: 'pointer',
		width: '386px !important',
        maxHeight: '80px !important',
        background: '#FFFFFF',
        border: '1px solid #F2F5F8',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
        borderRadius: '8px',
        [theme.breakpoints.down('400')]: {
            width: '351px !important',
            maxWidth: '100%',
            height: '120px !important',
		},
    },
    tileContainer: {
        textAlign: 'center',
    },
    avatar: {
        width: '48px',
        height: '48px',
    },
    tileTitle: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#30333E',
        maxWidth: '260px',
        textAlign: 'left',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('400')]: {
            margin: 'auto',
		},
    },
    tileDesc: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#73757A',
        marginTop: '8px',
        maxWidth: '260px',
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    tileContainerMobile: {
        padding: '16px',
        display: 'flex',
        gap: '20px',
        alignItems: 'center',
        [theme.breakpoints.down('400')]: {
            gap: '4px',
            padding: '10px',
		},
    },
    descContainerMobile: {
    },
    iconMobile: {
        marginLeft: 'auto',
    },
    explore: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#30333E',
        marginTop: '52px',
        marginBottom: '12px',
    }
}));

export const ExploreCollections = (props) => {
	const {
        clusters,
        investments,
        handleCollection,
    } = props;

    const classes = useStyles();

    const [clustersAdvanced, setClustersAdvanced] = useState([]);

    useEffect(() => {
        if (clusters) {
            getClusters(clusters);
        }
    }, [clusters]);

    const getClusters = () => {
        const clustersArray = [];
        clusters.map(function (cluster, index) {
            let clusterInvestments = _.chain(investments)
                .pickBy((value, key) => {
                    if (value.clusters) {
                        return value.clusters.includes(cluster.id);
                    }
                    return false;
                })
                .sortBy([`${cluster.id}_premium`, 'minInvestment'])
                .value();
            clusterInvestments = _.values(clusterInvestments);
            clustersArray.push({
                ...cluster,
                ...{
                    platforms: clusterInvestments
                }
            });
        });
        const clustersArrayShuffled = getShuffle(clustersArray);
        const clustersArrayLimited = clustersArrayShuffled.slice(0, 3);
        setClustersAdvanced(clustersArrayLimited);
    };

	const handleClick = (cluster) => {
        handleCollection(cluster);
    };

	return (
		<div className={classes.root}>
            <div className={classes.explore}>
                Explore more
            </div>

            <div className={classes.container}>
                {clustersAdvanced.slice(0, 3).map((cluster) => (
                    <div key={cluster.id} className={classes.tile} onClick={() => handleClick(cluster)}>
                        <div className={`${classes.tileContainerMobile}`}>
                            <div className={classes.avatarMobile}>
                                <Avatar
                                    src={cluster.icon}
                                    className={classes.avatar}
                                >
                                </Avatar>
                            </div>
                            <div className={`${classes.tileTitle}`}>
                                {cluster.name}
                            </div>
                            <div className={classes.iconMobile}>
                                <IconButton
                                    onClick={() => handleClick(cluster)}
                                >
                                    <NavigateNextIcon />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
		</div>
	);
}
