import React, { useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowDown';
import Button from '@material-ui/core/Button';
import ReactGA from 'react-ga';
import Header from './Header';
import { generateSubId, getFullPlatformUrl, getDiscoveryPageName } from '../../utils';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles({
	card: {
		'&:hover': { cursor: 'pointer' },
		'&:hover  $expandIcon': {
			display: 'block',
		},
		width: 1200,
		maxWidth: '100%',
		margin: 'auto',
		marginBottom: '12px',
		boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
		position: 'relative',
		borderRadius: '8px',
	},
	premiumCard: {
		border: '1px solid #F3B81F',
	},
	expandIcon: {
		position: 'absolute',
		top: '12px',
		right: '12px',
		background: '#5056E7',
		'&:hover': {
			backgroundColor: '#646AF0',
		},
		color: '#fff',
		borderRadius: '100px',
		textTransform: 'none',
		padding: '6px 16px',
	},
	moreButton: {
		textTransform: 'none',
		color: '#73757A',
		fontFamily: 'Inter',
		fontSize: '14px',
	},
	cardAction: {
		borderTop: '1px solid #F2F5F8',
		padding: '0px',
	},
});

export const CollapsedCard = (props) => {
	const {
		investmentPlatform,
		expandable,
        itemSource,
		itemMedium,
        itemCompaign,
        onCardClick,
        stopPropagation,
	} = props;

	const classes = useStyles();

	const platformUrl =	getDiscoveryPageName(expandable, investmentPlatform.slug);
	const fullPlatformUrl = getFullPlatformUrl(platformUrl);

	return (
        <Card
            className={`mainCard ${classes.card} ${
                investmentPlatform.premium > 0 ? classes.premiumCard : null
            }`}
            onClick={onCardClick}
        >
            <Header
                investmentPlatform={investmentPlatform}
                platformUrl={fullPlatformUrl}
                expandable={expandable}
            />
            <CardActions className={classes.cardAction}>
                <Button
                    endIcon={<KeyboardArrowRightIcon />}
                    fullWidth={true}
                    className={classes.moreButton}
                >
                    See details
                </Button>
            </CardActions>
            <Button
                className={classes.expandIcon}
                target="_blank"
                rel="noopener noreferrer"
                href={
                    investmentPlatform.website
                        ? investmentPlatform.website.replace('%SUBID%', generateSubId(itemSource, itemMedium))
                        : '/discover'
                }
                onClick={stopPropagation}
            >
                More
            </Button>
        </Card>
	);
}
