import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import Avatar from '@material-ui/core/Avatar';
import Skeleton from '@material-ui/lab/Skeleton';
import expandIcon from '../../img/icons/external-link.svg';
import { getSentimentIcon } from '../InvestmentPlatform/helpers';
import { LinkBlank } from '../Buttons/LinkBlank';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { trackEvent, eventTracker, generateSubId, genereateDeepdiveReviewPage, isDeepDiveAllowed } from '../../utils';
import { DeepDiveButton } from '../Buttons/DeepDiveButton';
import { routeNames } from '../../data/Data';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});
const useStyles = makeStyles((theme) => ({
    card: {
        background: '#FFFFFF',
        border: '1px solid #F2F5F8',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
        borderRadius: '8px',
        padding: '28px',
        [theme.breakpoints.down('460')]: {
            padding: '8px',
        },
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    topRow: {
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        fontFamily: 'Red Hat Display',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '28px',
        lineHeight: '40px',
        color: '#30333E',
        marginLeft: '12px',
        [theme.breakpoints.down('460')]: {
            fontSize: '18px',
        },
    },
    website: {
        fontFamily: 'Red Hat Display',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '18px',
        lineHeight: '40px',
        color: '#30333E',
        marginLeft: '20px',
        textDecoration: 'underline',
        [theme.breakpoints.down('460')]: {
            display: 'none',
        },
    },
    rightPosition: {
        marginLeft: 'auto',
        display: 'flex',
        flexDirection: 'column',
    },
    favoritesContainer: {
        //marginLeft: 'auto',
    },
    deepDiveButton: {
        marginTop: '10px',
    },
    favorites: {
        display: 'flex',
        alignItems: 'center',
        background: '#F2F5F8',
        border: '1px solid #DBE0E6',
        boxSizing: 'border-box',
        borderRadius: '100px',
        height: '32px',
        cursor: 'pointer',
        paddingRight: '10px',
    },
    favoritesText: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '20px',
        color: '#30333E',
        paddingRight: '4px',
        paddingLeft: '4px',
    },
    tagsRow: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '12px',
        paddingBottom: '16px',
    },
    tagGrey: {
        background: '#ECF5FF',
        borderRadius: '4px',
        padding: '0px 8px',
        marginRight: '12px',
    },
    tagBlue: {
        background: '#ECF5FF',
        borderRadius: '4px',
        padding: '0px 8px',
        marginRight: '12px',
    },
    tagGreen: {
        background: '#EBFAF1',
        borderRadius: '4px',
        padding: '0px 8px',
        marginRight: '12px',
    },
    tagText: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '20px',
        color: '#30333E',
        letterSpacing: '0.04em',
        textTransform: 'uppercase',
    },
    grey: {
        color: '#73757A',
    },
    blue: {
        color: '#3E94F9',
    },
    green: {
        color: '#33CD71',
    },
    statisticsRow: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('460')]: {
            display: 'none',
        },
    },
    statisticsContainer: {
        marginRight: '60px',
    },
    countText: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '32px',
        color: '#30333E',
    },
    countDescription: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '20px',
        color: '#73757A',
    },
    descriptionRow: {
        marginTop: '16px',
    },
    description: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '20px',
        color: '#73757A',
    },
    cardTitle: {
        cursor: 'pointer',
    },
}));
export const ResultCard = (props) => {
    const {
		investment,
		handleStarClick,
        mentions,
        isLoading,
        starActive,
        isStarActive,
	} = props;
    const classes = useStyles();
    const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
    const href = `/${routeNames.deepdive}/${genereateDeepdiveReviewPage(investment.slug)}`;

    const stopPropagation = (event) => {
		trackEvent('ExternalClick', 'platform', investment.name);
		eventTracker("ExternalClick", investment.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
		event.stopPropagation();
	}
	return (
        <div className={`${classes.card}`}>
            <div className={`${classes.topRow}`}>
                <LinkBlank
                    stopPropagation={stopPropagation}
                    href={investment.website
                        ? investment.website.replace('%SUBID%', generateSubId(state.itemSource, state.itemMedium))
                        : '/discover'}
                >
                    <div className={`${classes.titleContainer}`}>
                        <Avatar aria-label="avatar">
                            <img
                                src={investment.logo}
                                alt={investment.name}
                                style={{width: '40px', height: '40px', background: '#F2F5F8'}}
                            />
                        </Avatar>
                        <div className={`${classes.title}`}>
                            {investment.name}
                        </div>
                        <img
                            alt="expandIcon"
                            style={{ marginBottom: '-2px', marginLeft: '5px' }}
                            src={expandIcon}
                        />
                    </div>
                </LinkBlank>
                <div className={`${classes.rightPosition}`}>
                    <div
                        className={`${classes.favoritesContainer}`}
                        onClick={handleStarClick}
                    >
                        <div className={`${classes.favorites}`}>
                            <img
                                src={isStarActive(starActive)}
                                alt='star'
                                style={{marginLeft: '4px', width: '20px'}}
                            />
                            <div className={`${classes.favoritesText}`}>
                                {starActive ? `Favorite` : window.innerWidth <= 460 ? `Favorites` : `Add to favorites`}
                            </div>
                        </div>
                    </div>
                    {(investment.deepDive || isDeepDiveAllowed(investment.slug)) && <div className={`${classes.deepDiveButton}`}>
                    <DeepDiveButton
                        name={investment.name}
                        href={href}
                        text={window.innerWidth <= 460 ? `Deep Dive` : `Deep Dive Review`}
                    />
                    </div>}
                </div>
            </div>
            <div className={`${classes.tagsRow}`}>
                <div className={`${classes.tagGrey}`}>
                    <div className={`${classes.tagText} ${classes.grey}`}>
                        {investment.industry}
                    </div>
                </div>
                <div className={`${classes.tagBlue}`}>
                    <div className={`${classes.tagText} ${classes.blue}`}>
                        {investment.investors === 'Accredited'
                            ? 'Accredited'
                            : 'NO ACCRED'}
                    </div>
                </div>
                <div className={`${classes.tagGreen}`}>
                    <div className={`${classes.tagText} ${classes.green}`}>
                        {investment.global ? <>Worldwide</> : <>US only</>}
                    </div>
                </div>
            </div>
            <div className={`${classes.statisticsRow}`}>
                <div className={`${classes.statisticsContainer}`}>
                    <div className={`${classes.countText}`}>
                        {investment.favorites || 0}
                    </div>
                    <div className={`${classes.countDescription}`}>
                        Followers
                    </div>
                </div>
                <div className={`${classes.statisticsContainer}`}>
                    <div className={`${classes.countText}`}>
                        {isLoading ?
                            <Skeleton animation="wave" variant="rect" width={50} height={30} /> :
                            mentions.length || 0
                        }
                    </div>
                    <div className={`${classes.countDescription}`}>
                        Mentions
                    </div>
                </div>
                <div className={`${classes.statisticsContainer}`}>
                    <div>
                        <img
                            src={getSentimentIcon(investment.sentiment)}
                            alt='sentiment'
                            style={{width: '24px', height: '24px'}}
                        />
                    </div>
                    <div className={`${classes.countDescription}`}>
                        Sentiment
                    </div>
                </div>
                <div className={`${classes.statisticsContainer}`}>
                    <div className={`${classes.countText}`}>
                        {investment.comments || 0}
                    </div>
                    <div className={`${classes.countDescription}`}>
                        Comments
                    </div>
                </div>
            </div>
            <div className={`${classes.descriptionRow}`}>
                <div className={`${classes.description}`}>
                    {investment.description}
                </div>
            </div>
        </div>
	);
};
