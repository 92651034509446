import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';
import * as actions from "../../redux/actions";
import { SignUpCard } from '../SignUp/SignUpCard';

const useStyles = makeStyles((theme) => ({
    paperWidthSm: {
        height: '100%',
        width: '100%',
        maxWidth: '100% !important',
        marginLeft: '50% !important',
        marginTop: 0,
        padding: 0,
        [theme.breakpoints.down('1200')]: {
            marginLeft: '40% !important',
        },
        [theme.breakpoints.down('1000')]: {
            marginLeft: '30% !important',
        },
        [theme.breakpoints.down('800')]: {
            marginLeft: '20% !important',
        },
        [theme.breakpoints.down('600')]: {
            marginLeft: '10% !important',
        },
        [theme.breakpoints.down('500')]: {
            marginLeft: '0px !important',
		},
    },
    paperScrollPaper: {
        display: 'flex',
        maxHeight: '100%',
    },
    paper: {
        margin: 0,
        position: 'relative',
        overflowY: 'auto',
    },
    modal: {
        overflow: 'auto',
        zIndex: '10000 !important',
    },
    iconClose: {
        marginTop: '12px',
        [theme.breakpoints.up('450')]: {
            float: 'left',
            marginLeft: '12px',
		},
        [theme.breakpoints.down('450')]: {
            float: 'right',
            marginRight: '12px',
		},
    },
    signUpCardContainer: {
    },
}));

export const ModalSignUp = (props) => {
    const {
        open: openProp,
     } = props;
    const classes = useStyles();

    const dispatch = useDispatch();

    const [open, setOpen] = useState(openProp);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const handleClose = () => {
        setOpen(false);
        dispatch(actions.setModalSignUpOpen(false));
    };
    const handleFinish = () => {
        setOpen(false);
        dispatch(actions.setModalSignUpOpen(false));
    };
    useEffect(() => {
        if (openProp) {
            setOpen(true);
        }
    }, [openProp])

    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                className={classes.modal}
                classes={{
                    paperWidthSm: classes.paperWidthSm,
                    paperScrollPaper: classes.paperScrollPaper,
                    paper: classes.paper,
                }}
            >
                <div>
                    <div className={classes.iconClose}>
                        <IconButton
                            aria-label="close"
                            component="span"
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <SignUpCard
                    onFinish={handleFinish}
                />
            </Dialog>
        </>
    );
};
