import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Wizard } from '../Wizard/Wizard';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as actions from "../../redux/actions";

const useStyles = makeStyles((theme) => ({
    modal: {
        overflow: 'auto',
        zIndex: '10000 !important',
        //width: '800px',
        //maxWidth: '800px',
        margin: 'auto',
        //top: "-60px",
    },
    header: {
        display: 'flex',
    },
    title: {
        margin: '20px auto',
        width: '200px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '20px',
        lineHeight: '32px',
        textAlign: 'center',
        color: '#30333E',
    },
    iconClose: {
        float: 'right',
        marginTop: '10px',
    }
}));

export const ModalView = (props) => {
    const {
        open: openProp,
     } = props;
    const classes = useStyles();

    const dispatch = useDispatch();

    const [open, setOpen] = useState(openProp);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const handleClose = () => {
        setOpen(false);
        dispatch(actions.setModalOpen(false));
    };
    const handleFinish = () => {
        setOpen(false);
        dispatch(actions.setModalOpen(false));
    };
    useEffect(() => {
        if (openProp) {
            setOpen(true);
        }
    }, [openProp])

    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                //maxWidth="800px"
                aria-labelledby="responsive-dialog-title"
                className={classes.modal}
                id={`dialog_wizard`}
            >
                <div className={classes.header}>
                    <div className={classes.title}>
                        Investor quiz
                    </div>
                    <div className={classes.iconClose}>
                        <IconButton
                            aria-label="close"
                            component="span"
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                    <Wizard
                        onFinish={handleFinish}
                        open={open}
                    />
            </Dialog>
        </>
    );
};
