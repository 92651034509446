import React, { useState, useRef } from 'react';
import { TextField, Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { trackEvent, eventTracker } from '../../utils';
import Grid from '@material-ui/core/Grid';
import TwoEllipses from '../../img/TwoEllipses.png';
import TwoEllipses2 from '../../img/TwoEllipses2.png';
import TwoEllipses3 from '../../img/TwoEllipses3.png';
import { useSelector, shallowEqual } from 'react-redux';

const CustomTextField = withStyles((theme) => ({
	root: {
		width: '100%',
		[theme.breakpoints.up('1300')]: {
			maxWidth: '394px',
		},
        [theme.breakpoints.down('1300')]: {
			maxWidth: '320px',
		},
		[theme.breakpoints.down('1020')]: {
			maxWidth: '300px',
		},
		[theme.breakpoints.down('1000')]: {
			maxWidth: '438px',
		},
		[theme.breakpoints.down('700')]: {
			maxWidth: '300px',
		},
		[theme.breakpoints.down('550')]: {
			maxWidth: '266px',
		},
		[theme.breakpoints.down('500')]: {
			maxWidth: '360px',
        },
      },
}))(TextField);
const CustomButton = withStyles((theme) => ({
	root: {
        height: '40px',
        marginLeft: '20px',
		marginTop: '10px',
		marginRight: '20px',
        [theme.breakpoints.down('500')]: {
            maxWidth: '360px',
            width: '100%',
			margin: '0',
        },
	},
}))(Button);
const useStyles = makeStyles((theme) => ({
	mail: {
		maxWidth: '1200px',
		margin: 'auto',
		width: '100%',
		marginTop: '16px',
		background: '#ffffff',
		border: '1px solid #f2f5f8',
		boxSizing: 'border-box',
		boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
		borderRadius: '8px',
		padding: '0px',
		marginBottom: '20px',
		backgroundRepeat: 'no-repeat',
		[theme.breakpoints.up('1000')]: {
			backgroundImage: `url(${TwoEllipses})`,
			display: 'flex',
			height: '104px',
        },
        [theme.breakpoints.down('1000')]: {
			backgroundImage: `url(${TwoEllipses2})`,
			display: 'block',
			height: '162px',
		},
		[theme.breakpoints.down('500')]: {
			backgroundImage: `url(${TwoEllipses3})`,
			backgroundPosition: 'top',
			display: 'block',
			height: '295px',
		},
	},
	item1: {
		position: 'relative',
        [theme.breakpoints.down('1000')]: {
			maxWidth: '100% !important',
		},
		[theme.breakpoints.down('500')]: {
			textAlign: 'center',
			marginTop: '59px',
        },
    },
    item2: {
		[theme.breakpoints.up('1000')]: {
			textAlign: 'right',
        },
        [theme.breakpoints.down('1000')]: {
			textAlign: 'none',
			maxWidth: '100% !important',
		},
		[theme.breakpoints.down('500')]: {
			textAlign: 'center',
			marginTop: '16px',
        },
    },
	mailContainer: {
	},
	headerContainer: {
		maxWidth: '391px',
		width: '100%',
		[theme.breakpoints.up('1020')]: {
			margin: '20px 0px 20px 72px',
		},
		[theme.breakpoints.down('1020')]: {
			margin: '20px 0px 20px 52px',
		},
		[theme.breakpoints.down('1000')]: {
			margin: '20px 0px 20px 72px',
		},
		[theme.breakpoints.down('500')]: {
			margin: 'auto',
        },
	},
	heading1: {
		fontFamily: 'Red Hat Display',
		fontStyle: 'normal',
		fontWeight: 500,
		fontSize: '28px',
		lineHeight: '40px',
		color: '#30333E',
	},
	heading2: {
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '14px',
		lineHeight: '20px',
		color: '#73757A',
	},
	pseudoborderContainer: {
		position: 'relative',
		width: '1px',
	},
	pseudoborder: {
		height: '64px',
		background: '#f2f5f8',
		position: 'absolute',
		marginTop: '20px',
		marginLeft: 'calc(100%);',
		width: '1px',
		top: '0px',
		[theme.breakpoints.down('1000')]: {
			display: 'none',
		},
	},
	subscribeContainer: {
		margin: '20px 0px 0px 0px',
		maxWidth: '800px',
		[theme.breakpoints.down('1000')]: {
			margin: '20px 0px 0px 72px',
		},
        [theme.breakpoints.down('500')]: {
			textAlign: 'center',
			margin: '0',
			padding: '16px',
        },
    },
}));
export const PseudoMailCard = (props) => {
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const classes = useStyles();
	const [email, setEmail] = useState("");
	const form = useRef();

	const handleEmail = (event) => {
        setEmail(event.target.value);
	}
	const handleSubscribe = (event) => {
		form.current.submit();
    }
    const handleSubmit = (event) => {
		trackEvent('User', 'Newsletter', 'Newsletter');
		eventTracker("User", 'Newsletter', state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
    }
    return (
		<div>
			<Grid className={classes.mail}>
                    <Grid item xs={6} lg={6} className={classes.item1}>
						<div className={classes.headerContainer}>
							<div className={classes.heading1}>
								Grow your wealth
							</div>
							<div className={classes.heading2}>
								Get notified of new investment opportunities and platforms
							</div>
						</div>
						<div className={classes.pseudoborder}></div>
                    </Grid>
                    <Grid item xs={6} lg={6} className={classes.item2}>
						<div className={classes.subscribeContainer}>
							<CustomTextField
								name="EMAIL"
								className="mailInput"
								placeholder="Enter your email"
								variant="outlined"
								value={email}
								onChange={handleEmail}
							/>
							<CustomButton
								type="submit"
								variant="contained"
								color="primary"
								onClick={handleSubscribe}
							>
								Subscribe
							</CustomButton>
						</div>
						<div style={{display: 'none'}}>
							<form
								action="https://moneymade.us4.list-manage.com/subscribe/post?u=9922df745a85edbac4d1eef0b&amp;id=360bd75c7c"
								method="post"
								name="mc-embedded-subscribe-form"
								noValidate
								onSubmit={handleSubmit}
								ref={form}
							>
								<input
									name="EMAIL"
									defaultValue={email}
								/>
							</form>
						</div>
                    </Grid>
			    </Grid>
		</div>
    )
}
