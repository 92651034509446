const REMOTEAPI_URL = process.env.REACT_APP_REMOTEAPI_API_URL;
const REMOTEAPI_HOST = process.env.REACT_APP_REMOTEAPI_API_HOST;
const REMOTEAPI_TRADE_URL = process.env.REACT_APP_REMOTEAPI_TRADE_API_URL;
const REMOTEAPI_TRADE_HOST = process.env.REACT_APP_REMOTEAPI_TRADE_API_HOST;
const REMOTEAPI_ADS_URL = process.env.REACT_APP_REMOTEAPI_ADS_API_URL;
const REMOTEAPI_ENTRY_URL = process.env.REACT_APP_REMOTEAPI_ENTRY_URL;
const REMOTEAPI_TRADING_ENTRY_URL =
	process.env.REACT_APP_REMOTEAPI_TRADING_ENTRY_URL;

const REMOTEAPI_CALCULATOR_URL = process.env.REACT_APP_REMOTEAPI_CALCULATOR_API_URL;
const REMOTEAPI_CALCULATOR_HOST =
	process.env.REACT_APP_REMOTEAPI_CALCULATOR_API_HOST;
const REMOTEAPI_KEY = process.env.REACT_APP_REMOTEAPI_API_KEY;
const REMOTEAPI_BASE_URL = process.env.REACT_APP_REMOTEAPI_API_BASE_URL;

const REMOTEAPI_PREMIUM_URL = process.env.REACT_APP_REMOTEAPI_PREMIUM_API_URL;

const REMOTEAPI_CLUSTERS_URL = process.env.REACT_APP_REMOTEAPI_CLUSTERS_API_URL;

const REACT_APP_REMOTEAPI_SEND_QUIZ_RESULT_URL = process.env.REACT_APP_REMOTEAPI_SEND_QUIZ_RESULT_URL;
const REACT_APP_REMOTEAPI_SEND_SUBSCRIBE_TIPS_URL = process.env.REACT_APP_REMOTEAPI_SEND_SUBSCRIBE_TIPS_URL;

const REACT_APP_REMOTEAPI_SIGNUP_URL = process.env.REACT_APP_REMOTEAPI_SIGNUP_URL;
const REACT_APP_REMOTEAPI_SIGNIN_URL = process.env.REACT_APP_REMOTEAPI_SIGNIN_URL;
const REACT_APP_REMOTEAPI_CHECK_USER_URL = process.env.REACT_APP_REMOTEAPI_CHECK_USER_URL;

const REACT_APP_REMOTEAPI_ADD_FAVORITE_URL = process.env.REACT_APP_REMOTEAPI_ADD_FAVORITE_URL;
const REACT_APP_REMOTEAPI_GET_FAVORITE_URL = process.env.REACT_APP_REMOTEAPI_GET_FAVORITE_URL;
const REACT_APP_REMOTEAPI_DELETE_FAVORITE_URL = process.env.REACT_APP_REMOTEAPI_DELETE_FAVORITE_URL;

const REACT_APP_GET_CLUSTER_URL = process.env.REACT_APP_GET_CLUSTER_URL;
const REACT_APP_ADD_USER_INFO_URL = process.env.REACT_APP_ADD_USER_INFO_URL;

const REACT_APP_SESSION_INFO_URL = process.env.REACT_APP_SESSION_INFO_URL;
const REACT_APP_EVENT_TRACKING_URL = process.env.REACT_APP_EVENT_TRACKING_URL;

const REACT_APP_CHECK_USER_INFO_URL = process.env.REACT_APP_CHECK_USER_INFO_URL;

const REACT_APP_ADD_COMMENT_URL = process.env.REACT_APP_ADD_COMMENT_URL;
const REACT_APP_BUMP_COMMENT_URL = process.env.REACT_APP_BUMP_COMMENT_URL;
const REACT_APP_GET_USER_LIKES_URL = process.env.REACT_APP_GET_USER_LIKES_URL;
const REACT_APP_GET_STREAM_URL = process.env.REACT_APP_GET_STREAM_URL;

const REACT_APP_UPLOAD_IMAGE_URL = process.env.REACT_APP_UPLOAD_IMAGE_URL;
const REACT_APP_GET_NEWS_URL = process.env.REACT_APP_GET_NEWS_URL;
const REACT_APP_GET_LATEST_STREAM_URL = process.env.REACT_APP_GET_LATEST_STREAM_URL;
const REACT_APP_GET_ONE_STREAM_URL = process.env.REACT_APP_GET_ONE_STREAM_URL;
const REACT_APP_GET_TYPES_URL = process.env.REACT_APP_GET_TYPES_URL;

export {
	REMOTEAPI_URL,
	REMOTEAPI_HOST,
	REMOTEAPI_KEY,
	REMOTEAPI_BASE_URL,
	REMOTEAPI_TRADE_URL,
	REMOTEAPI_TRADE_HOST,
	REMOTEAPI_CALCULATOR_URL,
	REMOTEAPI_CALCULATOR_HOST,
	REMOTEAPI_ADS_URL,
	REMOTEAPI_ENTRY_URL,
	REMOTEAPI_TRADING_ENTRY_URL,
	REMOTEAPI_PREMIUM_URL,
	REMOTEAPI_CLUSTERS_URL,
	REACT_APP_REMOTEAPI_SEND_QUIZ_RESULT_URL,
	REACT_APP_REMOTEAPI_SEND_SUBSCRIBE_TIPS_URL,
	REACT_APP_REMOTEAPI_SIGNUP_URL,
	REACT_APP_REMOTEAPI_SIGNIN_URL,
	REACT_APP_REMOTEAPI_CHECK_USER_URL,
	REACT_APP_REMOTEAPI_ADD_FAVORITE_URL,
	REACT_APP_REMOTEAPI_GET_FAVORITE_URL,
	REACT_APP_REMOTEAPI_DELETE_FAVORITE_URL,
	REACT_APP_GET_CLUSTER_URL,
	REACT_APP_ADD_USER_INFO_URL,
	REACT_APP_SESSION_INFO_URL,
	REACT_APP_EVENT_TRACKING_URL,
	REACT_APP_CHECK_USER_INFO_URL,
	REACT_APP_ADD_COMMENT_URL,
	REACT_APP_BUMP_COMMENT_URL,
	REACT_APP_GET_USER_LIKES_URL,
	REACT_APP_GET_STREAM_URL,
	REACT_APP_UPLOAD_IMAGE_URL,
	REACT_APP_GET_NEWS_URL,
	REACT_APP_GET_LATEST_STREAM_URL,
	REACT_APP_GET_ONE_STREAM_URL,
	REACT_APP_GET_TYPES_URL,
};
