import React, { useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ReactGA from 'react-ga';
import { eventTracker, trackEvent } from '../../utils';
import * as actions from "../../redux/actions";
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

ReactGA.initialize('UA-148517382-4');

const useStyles = makeStyles((theme) => ({
	tooltip: {
		zIndex: 10001, // to be visible inside the modal
    },
}));

export const ChipsInvestment = (props) => {
	const {
		investmentPlatform,
	} = props;
	const classes = useStyles();
	return (
		<>
            <Tooltip
                disableFocusListener
                disableTouchListener
                classes={{ popper: classes.tooltip }}
                placement="top"
                title={
                    investmentPlatform.fees === '0'
                        ? `ZERO FEES`
                        : `Fee: ${
                                investmentPlatform.fees < 1
                                    ? investmentPlatform.fees
                                    : investmentPlatform.fees
                            }%`
                }
                arrow
            >
                <Chip
                    label={
                        investmentPlatform.fees === '0'
                            ? `ZERO FEES`
                            : `Fee: ${
                                    investmentPlatform.fees < 1
                                        ? investmentPlatform.fees
                                        : investmentPlatform.fees
                                }%`
                    }
                    className="ctag green"
                />
            </Tooltip>
            <Tooltip
                disableFocusListener
                disableTouchListener
                classes={{ popper: classes.tooltip }}
                placement="top"
                title={`Term of investment: ${
                    investmentPlatform.timeframe === '0' ||
                    investmentPlatform.timeframe.toLowerCase() === 'zero'
                        ? 'Zero'
                        : `${investmentPlatform.timeframe} months`
                }`}
                arrow
            >
                <Chip
                    label={
                        investmentPlatform.timeframe === '0' ||
                        investmentPlatform.timeframe.toLowerCase() === 'zero'
                            ? 'Zero'
                            : `${investmentPlatform.timeframe} months`
                    }
                    className="ctag purple"
                />
            </Tooltip>
            <Tooltip
                disableFocusListener
                disableTouchListener
                classes={{ popper: classes.tooltip }}
                placement="top"
                title={`Accredited: ${
                    investmentPlatform.investors === 'Accredited'
                        ? 'Accredited'
                        : 'NO ACCRED'
                }`}
                arrow
            >
                <Chip
                    label={
                        investmentPlatform.investors === 'Accredited'
                            ? 'Accredited'
                            : 'NO ACCRED'
                    }
                    className="ctag blue"
                />
            </Tooltip>
		</>
	);
}
