export const routeNames = {
    home: 'home',
    discover: 'discover',
    trading: 'trading',
    calculator: 'calculator',
    investorTips: 'investortips',
    dashboard: 'dashboard',
    thankYou: 'thankyou',
    customWidget: 'customWidget',
    investopediaWidget: 'investopediaWidget',
    investopediaWidget2: 'investopediaWidget2',
    calculatorPaid: 'calculatorPaid',
    reviews: 'reviews',
    embedEntry: 'embedEntry',
    embedTrading: 'embedTrading',
    embedCalculator: 'embedCalculator',
    expandableDiscovery: 'expandableDiscovery',
    termsAndCondition: 'terms-and-condition',
    stream: 'stream',
    deepdive: 'deepdive',
    qaSession: 'QA-session',
};
export const initialData = {
    itemCompaign: '',
    itemMedium: '',
    itemSource: '',
    itemIndustry: '',
    modalOpen: false,
    modalSignUpOpen: false,
    investments: {},
    quizResults: false,
    clusterView: '',
    userId: '',
    favoritesInvestments: [],
    userName: '',
    token: '',
    loggedIn: false,
    investmentsTotal: {},
    clusters: [],
    ads: [],
    displaySpinner: false,
    displayRecommended: true,
    fpSent: false,
    quizAnswers: {},
    types: [],
};
export const view = {
    default: 0,
    collection: 1,
    list: 2,
};
export const localStorageData = {
    userId: 'userId',
};
export const localStorageState = "moneyMadeStorage";

export const mobileWidth = 750;
export const mobileWidth2 = 650;
export const widgetWidth = 2000;

export const responseTest = {
    success: true,
    message: {
        "replies": [
            {
                "timestamp": {
                    "_seconds": 1599274314,
                    "_nanoseconds": 917000000
                },
                "platforms": [
                    "EYdzku7rIrO3Z5JMUR5k"
                ],
                "blocked": false,
                "user": {
                    "displayName": "Jay Bone",
                    "uid": "7542ada6-8583-4a53-9c39-32bdd8bcd93f"
                },
                "text": "@Ryfo I invest with $Fundrise and am a big fan. 2 years in and no complaints. Monthly dividends (that I reinvest) and consistent returns. My 2019 weighted average returns for my portfolio on Fundrise was 8%",
                "likes": 0,
                "imgUrl": "https://storage.googleapis.com/benchmark-media.appspot.com/uploads/1600210824732_image.png",
            },
            {
                "likes": 0,
                "text": "@Jabo Have you seen a dip over the past months with COVID?  ",
                "user": {
                    "uid": "mIwJCxdymyaPGJxsuh69ojMAI4O2",
                    "displayName": "Ryan Fochtman"
                },
                "platforms": [],
                "blocked": false,
                "timestamp": {
                    "_seconds": 1599307436,
                    "_nanoseconds": 69000000
                },
                "imgUrl": null
            }
        ],
    }
};
export const deepDiveDelimiters = {
    meta: 'meta',
    keyInfo: 'keyInfo',
    bestFor: 'bestFor',
    intro: 'intro',
    prosConsType: 'ProsCons',
    mainType: `main`,
    delimetr: '|||',
    delimetrImg: 'img|||',
    orderNone: 'none',
    orderBullet: 'bullet',
    orderBulletDoubled: 'bullet',
    orderNumber: 'number',
    orderSymbol: 'symbol',
    orderStep: 'step',
};
export const meta = {
    title: 'MoneyMade',
    desc: 'Find and compare investment opportunities',
};
