import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import chevronLeftRight from '../../img/chevron-left-right.svg';
import { addCommas, addPlusSign } from '../../utils';

const sliderHeight = 2;
const CustomSliderElement = withStyles({
	root: {
		color: '#5056E7',
        height: sliderHeight,
        padding: '20px 0'
	},
	thumb: {
		height: 32,
		width: 32,
		backgroundColor: '#5056E7',
		border: '1px solid currentColor',
		marginTop: -14,
		marginLeft: -12,
		'&:focus, &:hover, &$active': {
			boxShadow: 'inherit',
		},
	},
	active: {},
	valueLabel: {
		left: 'calc(-50% + 4px)',
	},
	track: {
		height: sliderHeight,
		//borderRadius: 4,
		color: '#5056E7',
	},
	rail: {
		height: sliderHeight,
		//borderRadius: 4,
		color: '#EEEFFD',
		opacity: 1,
	},
})(Slider);

const ThumbComponent = (props) => {
	return (
		<span {...props}>
			<img src={chevronLeftRight} />
		</span>
	);
};
const useStyles = makeStyles((theme) => ({
    slider: {
		[theme.breakpoints.up('830')]: {
            width: '306px',
            marginLeft: '6px',
		},
        [theme.breakpoints.down('830')]: {
            width: '306px',
            marginLeft: '6px',
        },
        [theme.breakpoints.down('360')]: {
            width: '236px',
            marginLeft: '6px',
        },
	},
    sliderText: {
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        background: '#FFFFFF',
        border: '1px solid #C5C8D1',
        boxSizing: 'border-box',
        borderRadius: '8px',
        fontFamily: 'Inter',
        lineHeight: '32px',
        color: '#30333E',
        padding: '10px',
        position: 'relative',
        top: '21px',
        [theme.breakpoints.up('830')]: {
            width: '320px',
		},
        [theme.breakpoints.down('830')]: {
            width: '320px',
        },
        [theme.breakpoints.down('360')]: {
            width: '250px',
        },
    },
}));

export const InlineSlider = (props) => {
    const {
        sliderValue: sliderValueProp,
        handleSliderChange: handleSliderChangeProp
    } = props;
    const classes = useStyles();
    const [sliderValue, setSliderValue] = useState(sliderValueProp);

    const maxValue = 25000;

    const handleSliderChange = (e, value) => {
        setSliderValue(value);
        handleSliderChangeProp(value);
    }
    useEffect(() => {
        setSliderValue(sliderValue);
    },[sliderValue]);
    return (
        <>
            <div className={classes.sliderText}>
                ${addCommas(addPlusSign(sliderValue, maxValue))}
            </div>
            <CustomSliderElement
                value={sliderValue}
                onChange={handleSliderChange}
                step={100}
                min={500}
                max={maxValue}
                aria-labelledby="slider"
                className={classes.slider}
                ThumbComponent={ThumbComponent}
                onChangeCommitted={handleSliderChange}
            />
        </>
    )
}