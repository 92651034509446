import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import Header from './AdsHeader';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

import VizSensor from 'react-visibility-sensor';

import ReactGA from 'react-ga';
import { trackEvent, eventTracker } from './../../utils';
import { useSelector, shallowEqual } from 'react-redux';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles({
	card: {
		'&:hover': { cursor: 'pointer' },
		'&:hover  $expandIcon': {
			display: 'block',
		},
		width: 1200,
		maxWidth: '100%',
		margin: 'auto',
		marginBottom: '12px',
		boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
		position: 'relative',
		borderRadius: '8px',
		border: '1px solid #5056E7',
	},
	tradingCard: {
		width: 1129,
	},
	premiumCard: {
		border: '1px solid #5056E7',
	},
	expandIcon: {
		position: 'absolute',
		top: '12px',
		right: '12px',
		background: '#5056E7',
		'&:hover': {
			backgroundColor: '#646AF0',
		},
		borderRadius: '100px',
		color: '#fff',
		padding: '6px 16px',
		textTransform: 'none',
	},
});

export default function InvestmentPlatformCollapsed(props) {
	const { ad, trading } = props;
	const classes = useStyles();
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);

	function onCardClick() {
		trackEvent('ExternalClick', 'platform', ad.name);
		eventTracker("ExternalClick", ad.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);

		const win = window.open(ad.website, '_blank');
		if (win != null) {
			win.focus();
		}
	}

	function stopPropagation(event) {
		trackEvent('ExternalClick', 'platform', ad.name);
		eventTracker("ExternalClick", ad.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);

		event.stopPropagation();
	}

	return (
		<VizSensor
			onChange={(isVisible) => {
				trackEvent('AdImpression', ad.name, ad.name);
				eventTracker("AdImpression", ad.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
			}}
		>
			<Card
				className={`mainCard ${classes.card} ${trading ? classes.tradingCard : ''}`}
				onClick={onCardClick}
			>
				<Header investmentPlatform={ad} />
				<Button
					color="primary"
					className={classes.expandIcon}
					target="_blank"
					rel="noopener noreferrer"
					href={ad.website}
					onClick={stopPropagation}
				>
					More
				</Button>
			</Card>
		</VizSensor>
	);
}
