import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import CssBaseline from '@material-ui/core/CssBaseline';
import LazyLoad from 'react-lazyload';
import { TextField, Button, MenuItem } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import Card from '@material-ui/core/Card';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as actions from '../../../redux/actions';
import { getAges } from '../../../utils';
import Close from '../../../img/dashboard/CloseLight.svg';
import IconButton from "@material-ui/core/IconButton";
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { addUserInfo } from '../../../Services/AddUserInfoAPI';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const CustomTextField = withStyles((theme) => ({
	root: {
		[theme.breakpoints.up('1460')]: {
			width: '220px',
		},
		[theme.breakpoints.down('1460')]: {
			width: '220px',
		},
		[theme.breakpoints.down('530')]: {
			maxWidth: '400px',
			width: '100%',
        },
      },
}))(TextField);
const useStyles = makeStyles((theme) => ({
	investCard: {
		'&:hover  $expandIcon': {
			display: 'block',
        },
        position: 'relative',
        width: '96%',
        padding: '4px 0 0px 40px',
        marginRight: '70px',
        marginBottom: '12px',
        [theme.breakpoints.down('770')]: {
            width: '100%',
        },
    },
	container: {
	},
	headerContainer: {
		display: 'flex',
	},
	headerTitle: {
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '16px',
		lineHeight: '24px',
		color: '#30333E',
		margin: '20px',
	},
	headerClose: {
		marginLeft: 'auto',
	},
	bodyContainer: {
		display: 'flex',
		alignItems: 'flex-end',
		flexDirection: 'row',
		padding: '0 0 32px 20px',
		[theme.breakpoints.down('1460')]: {
			display: 'block',
		},
		[theme.breakpoints.down('530')]: {
			display: 'flex',
			justifyСontent: 'center',
			flexDirection: 'column',
			alignItems: 'center',
		},
	},
	formControl: {
		minWidth: 220,
		background: '#FFFFFF',
		borderRadius: '8px',
		[theme.breakpoints.down('530')]: {
			maxWidth: '400px',
			width: '100%',
		},
	},
	selectEmpty: {
		padding: 0,
		height: '48px',
	},
	inputTitle: {
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '14px',
		lineHeight: '20px',
		color: '#73757A',
		marginBottom: '8px',
	},
	bodyItems: {
		marginRight: '20px',
		[theme.breakpoints.down('1460')]: {
			marginBottom: '20px',
			float: 'left',
		},
		[theme.breakpoints.down('530')]: {
			marginBottom: '20px',
			clear: 'left',
			maxWidth: '400px',
			width: '100%',
		},
	},
	bodyItemsButton: {
		marginRight: '20px',
		[theme.breakpoints.down('1460')]: {
			marginBottom: '20px',
			clear: 'left',
		},
		[theme.breakpoints.down('530')]: {
			marginBottom: '20px',
			clear: 'left',
			maxWidth: '400px',
			width: '100%',
        },
	},
	buttonContainer: {
        [theme.breakpoints.down('530')]: {
			textAlign: 'center',
			margin: '0',
        },
    },
    button: {
        width: '128px',
        height: '48px',
		background: '#5056E7',
		'&:hover': {
			backgroundColor: '#646AF0',
		},
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
		cursor: 'pointer',
		[theme.breakpoints.down('1460')]: {
			width: '220px',
		},
		[theme.breakpoints.down('530')]: {
			maxWidth: '400px',
			width: '100%',
			marginTop: '10px',
        },
    },
    buttonText: {
        fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 500,
		fontSize: '16px',
		lineHeight: '24px',
        color: '#FFFFFF',
	},
	disabled: {
		opacity: '0.4',
		cursor: 'default',
	},
	moreButton: {
		textTransform: 'none',
		color: '#73757A',
		fontFamily: 'Inter',
		fontSize: '14px',
    },
	card: {
		'&:hover': { cursor: 'pointer' },
	},
	cardAction: {
		borderTop: '1px solid #F2F5F8',
		padding: '0px',
    },
    premiumCard: {
		border: '1px solid #F3B81F',
	},
	expandIcon: {
		position: 'absolute',
		top: '12px',
		right: '12px',
		background: '#5056E7',
		'&:hover': {
			backgroundColor: '#646AF0',
		},
		color: '#fff',
		borderRadius: '100px',
		textTransform: 'none',
		padding: '6px 16px',
	},
	[theme.breakpoints.down('1495')]: {
		investCard: {
			padding: '4px 0 24px 0px',
			marginRight: '0',
		},
	},
}));

export const RecommendedCard = (props) => {
	const {
        handleCloseCard,
		handleDisplaySpinner,
		handleSentRecommendation,
		displaySpinner,
    } = props;
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const dispatch = useDispatch();
	const classes = useStyles();

	const [age, setAge] = useState('');
	const [ageArray, setAgeArray] = useState([]);
	const [zip, setZip] = useState('');
	const [annualIncome, setAnnualIncome] = useState(2);
	const [accredited, setAccredited] = useState(0);

	const RecommendedCardId = "RecommendedCard";

	const annualIncomeArray = [
		'Up to $34,999',
		'$35,000 - $49,999',
		'$50,000 - $69,999',
		'$70,000 - $99,999',
		'$100,000 - $149,999',
		'$150,000 - $249,999',
		'$250,000 - $399,999',
		'$400,000+'
	];
	const accreditedArray = [
		'I don’t know',
		'No',
		'Yes',
	];

	useEffect(() => {
		setAgeArray(getAges());
		return function cleanup () {
		};
	}, []);
	useEffect(() => {
		if (displaySpinner) {
			flowAsync();
		}
	}, [displaySpinner]);

	const flowAsync = async () => {
		const a = Number(ageArray[age]);
		const z = zip;
		const ai = annualIncomeArray[annualIncome];
		const ac = accreditedArray[accredited];
		const response = await addUserInfo(state.token, a, z, ai, ac);
		handleDisplaySpinner(false);
		handleSentRecommendation(true);
	}
	const handleAgeChange = (event) => {
		setAge(event.target.value);
	};
	const handleAnnualIncomeChange = (event) => {
		setAnnualIncome(event.target.value);
	};
	const handleAccreditedChange = (event) => {
		setAccredited(event.target.value);
	};
	const hadleZipChange = (event) => {
		setZip(event.target.value);
	};
	const handleSubmit = () => {
		handleDisplaySpinner(true);
	}

	return (
		<div className={classes.investCard} id={RecommendedCardId}>
			<Card className={`mainCard ${classes.container}`}>
				<div className={classes.headerContainer}>
					<div className={classes.headerTitle}>
						Complete your profile to get personal recommendations
					</div>
					<div className={classes.headerClose} onClick={handleCloseCard}>
						<IconButton>
							<img
								src={Close}
								alt="Close"
							/>
						</IconButton>
					</div>
				</div>
				<div className={classes.bodyContainer}>
					<div className={classes.bodyItems}>
						<div className={classes.inputTitle}>
							Your age
						</div>
						<FormControl className={`${classes.formControl} recommendation`} variant="outlined">
							<Select
								value={age}
								onChange={handleAgeChange}
								displayEmpty
								className={classes.selectEmpty}
								inputProps={{ 'aria-label': 'Without label' }}
								renderValue={(selected) => {
									if (selected.length === 0) {
										return <span className='selected-placeholder'>Enter your age</span>;
									}
									return (selected === ageArray.length-1) ? `65+` : ageArray[selected]
									}}
							>
								{ageArray.map((item, index) => {
									return (
										<MenuItem
											value={index}
											key={`age_${index}`}
										>
											{(index === ageArray.length-1) ? `65+` : item}
										</MenuItem>
									)
								})}
							</Select>
						</FormControl>
					</div>

					<div className={classes.bodyItems}>
						<div className={classes.inputTitle}>
							Zip code
						</div>
						<CustomTextField
							className='recommendationInput'
							variant='outlined'
							value={zip}
							onChange={hadleZipChange}
							type='number'
							placeholder='Enter your ZIP code'
							InputProps={{ inputProps: { min: 0 } }}
						/>
					</div>

					<div className={classes.bodyItems}>
						<div className={classes.inputTitle}>
							Annual income
						</div>
						<FormControl className={`${classes.formControl} recommendation`} variant="outlined">
							<Select
								value={annualIncome}
								onChange={handleAnnualIncomeChange}
								displayEmpty
								className={classes.selectEmpty}
								inputProps={{ 'aria-label': 'Without label' }}
								variant='outlined'
							>
								{annualIncomeArray.map((item, index) => {
									return (
										<MenuItem
											value={index}
											key={`annual_${index}`}
										>
											{item}
										</MenuItem>
									)
								})}
							</Select>
						</FormControl>
					</div>

					<div className={classes.bodyItems}>
						<div className={classes.inputTitle}>
							Are you Accredited?
						</div>
						<FormControl className={`${classes.formControl} recommendation`} variant="outlined">
							<Select
								value={accredited}
								onChange={handleAccreditedChange}
								displayEmpty
								className={classes.selectEmpty}
								inputProps={{ 'aria-label': 'Without label' }}
							>
								{accreditedArray.map((item, index) => {
									return (
										<MenuItem
											value={index}
											key={`accredited_${index}`}
										>
											{item}
										</MenuItem>
									)
								})}
							</Select>
						</FormControl>
					</div>

					<div className={`${classes.bodyItemsButton}`}>
						<div className={classes.buttonContainer}>
							<div
								className={`${classes.button} ${(age.length === 0 || zip.length === 0) ? classes.disabled : ''}`}
								onClick={() => {
									if ((age.length === 0 || zip.length === 0)) {
										return;
									}
									handleSubmit();
								}}
							>
							<div className={classes.buttonText}>
								Submit
							</div>
						</div>
					</div>
				</div>
				</div>
			</Card>
		</div>)
    };
