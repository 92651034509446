import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as actions from "../redux/actions";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import avatartablethomepage from '../img/avatartablethomepage.svg';
import avatar from '../img/avatar.svg';
import AppBarRes from './AppBar/AppBar.jsx';
import openedIcon from '../img/icons/opened.svg';
import cmenuIcon from '../img/icons/normal.svg';
import { makeStyles } from '@material-ui/core/styles';
import { LogoComponent } from './Logo/LogoComponent';
import { SignUpButton } from './Buttons/SignUpButton';
import { ModalSignUp } from './Modal/ModalSignUp';
import { checkUser } from '../Services/CheckUserAPI';
import { UserAvatar } from './Avatar/Avatar';
import { loginUser, checkAuth, getToken } from '../Firebase/firebase-service';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex'
	},
	appBar: {
		[theme.breakpoints.up('sm')]: {
			zIndex: 9999
		}
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('md')]: {
			display: 'none'
		}
	},
	toolbar: theme.mixins.toolbar,

	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	rightPanel: {
		marginLeft: 'auto',
		display: 'flex',
	},
	menu: {
		[theme.breakpoints.up('1145')]: {
			display: 'none',
		},
		[theme.breakpoints.down('1145')]: {
			//width: '100%',
			//textAlign: 'right'
			display: 'inherit',
		},
	}
}));

const Home = () => {
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const dispatch = useDispatch();
	const classes = useStyles();
	const [showMenu, setshowMenu] = useState(false);
	const [changeNavbarOnScroll, setChangeNavbarOnchangeNavbarOnScroll] = useState(0);
	const [openModalSignUp, setOpenModalSignUp] = useState(state.modalSignUpOpen);

	useEffect(() => {
		const onScroll = (e) => {
			const changeNavbarOnScrollCheck = window.scrollY < 100;
			if (changeNavbarOnScrollCheck !== changeNavbarOnScroll) {
				setChangeNavbarOnchangeNavbarOnScroll(changeNavbarOnScrollCheck);
			}
		};
		window.addEventListener('scroll', onScroll);
		return function cleanup () {
			window.removeEventListener('scroll', onScroll)
		};
	});

	useEffect(() => {
		if (state.modalSignUpOpen) {
			setOpenModalSignUp(true);
		} else {
			setOpenModalSignUp(false);
		}
	}, [state.modalSignUpOpen]);

	// check is user logged in
	useEffect(() => {
		checkAuth(callbackAuth);
	}, []);

	const callbackAuth = async (currentUser) => {
		if (currentUser !== null) {
			const token = await currentUser.getIdToken();
			dispatch(actions.setToken(token));
			setShowSignUp(false);
			dispatch(actions.setLoggedInStatus(true));
			setCheckingToken(false);
		} else {
			setCheckingToken(false);
			setShowSignUp(true);
			dispatch(actions.setLoggedInStatus(false));
		}
    };
	const handleClickOpenModalSignUp = () => {
		dispatch(actions.setModalSignUpOpen(true));
	};

	const [showSignUp, setShowSignUp] = useState(true);
	const [checkingToken, setCheckingToken] = useState(true);

	return (
		<AppBar position="fixed" className={classes.appBar}>
			<Toolbar
				style={{
					background:
						window.scrollY < 100 ? 'transparent' : 'rgba(80, 86, 231, 0.84)'
				}}
			>
				<LogoComponent
					color="white"
				/>
				<div style={{
					marginTop: '-10px'
				}}>
					<AppBarRes
						showMenu={showMenu}
						mobMenuTransparent={true}
					/>
				</div>
				<div className={classes.rightPanel}>
					{(state.token && !checkingToken) || !checkingToken ?
						showSignUp ?
						<SignUpButton
							handleClick={handleClickOpenModalSignUp}
						/> :
						<UserAvatar
							name={state.userName}
						/>
						:
						null
					}
					<div className={classes.menu}>
						<img
							className="logo hideWeb"
							style={{}}
							onClick={() => setshowMenu(!showMenu)}
							src={showMenu ? openedIcon : cmenuIcon}
							alt="HamburgerMenu"
						/>
					</div>
				</div>
			</Toolbar>
			<ModalSignUp
				open={openModalSignUp}
			/>
		</AppBar>
	);
};
export default Home;
