import React, { useState, useMemo, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import { Button } from '@material-ui/core';
import { UserAvatar } from '../Avatar/Avatar';
import { getPlatformIdsByTiltes, arrayUniqueItems, addObserver, setCaret, paste, checkFileType, checkImageSize, readUploadedFileAsBase64, updateImageLogo } from '../../utils';
import { addComment } from '../../Services/AddCommentAPI';
import * as actions from "../../redux/actions";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import imageicon from '../../img/stream/imageicon.svg';
import Close from '../../img/dashboard/Close.svg';
import { uploadImage } from '../../Services/UploadImageAPI';
import { Spinner } from '../Spinner/Spinner';
//import { responseTest } from '../../data/Data';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});
const CustomButton = withStyles((theme) => ({
	root: {
        height: '48px',
        width: '83px',
        marginLeft: '10px',
        //marginTop: '10px',
        [theme.breakpoints.down('460')]: {
            display: 'block',
        },
	},
}))(Button);
const useStyles = makeStyles((theme) => ({
    postCommentContainer: {
        [theme.breakpoints.up('400')]: {
            padding: '10px 10px 20px 0px',
        },
        [theme.breakpoints.down('400')]: {
            padding: '0px',
        },
    },
    shareContainer: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    inputPost: {
        position: 'relative',
        background: '#FFFFFF',
        border: '1px solid #DBE0E6',
        boxSizing: 'border-box',
        borderRadius: '8px',
        width: '569px',
        maxWidth: '100%',
        marginLeft: '16px',
        display: 'flex',
        justifyContent: 'flex-end',
        flexWrap: 'wrap',
    },
    commentInput: {
        position: 'relative',
        minHeight: '48px',
        outline: 'none',
        userSelect: 'text',
        overflowWrap: 'break-word',
        padding: '12px 0 0 12px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#30333E',
        cursor: 'text',
        flex: '1 1 auto',
    },
    inputImage: {
        alignSelf: 'flex-end',
        padding: '12px 12px 12px 8px',
    },
    image: {
        cursor: 'pointer',
    },
    placeholder: {
        '&:empty::before': {
			content: 'attr(placeholder)',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#73757A',
            cursor: 'text',
        },
    },
    userName: {
        display: 'inline-block',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        //color: '#5056E7',
        color: '#30333E',
    },
    postButtonBig: {
        [theme.breakpoints.up('460')]: {
            display: 'inherit',
        },
        [theme.breakpoints.down('460')]: {
            display: 'none',
        },
    },
    postButtonSmall: {
        [theme.breakpoints.up('460')]: {
            display: 'none',
        },
        [theme.breakpoints.down('460')]: {
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'flex-end',
        },
    },
    imagePlaceholdeContainer: {
        display: 'none',
        position: 'relative',
        borderRadius: '8px',
        border: '1px solid #DBE0E6',
        marginLeft: '65px',
        marginTop: '10px',
    },
    imagePlaceholder: {
        display: 'flex',
        margin: '4px',
    },
    closeButton: {
        cursor: 'pointer',
        position: 'absolute',
        right: '4px',
        top: '4px',
    },
    avatar: {
        /*[theme.breakpoints.up('400')]: {
            display: 'inherit',
        },
        [theme.breakpoints.down('400')]: {
            display: 'none',
        },*/
    },
}));
export const MakePostCard = (props) => {
    const {
		userName,
        sharePostId,
        token,
        investmentsTotal,
        setReplying,
        setReplyed,
        reply,
        userNameReply: userNameReplyProp,
        postId,
        id,
        handlePostedComment,
		setAddingComment,
        setStreaming,
        individualStreamName,
        loggedIn,
        handleNewPost,
        modal: modalProp,
	} = props;
    const classes = useStyles();
    const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
    const dispatch = useDispatch();
    const [ideaText, setIdeaText] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [displaySpinner, setDisplaySpinner] = useState(false);

    const investments = useMemo(() => Object.values(investmentsTotal), [investmentsTotal]);

    const modal = modalProp ? `modal` : ``;
    const placeholderText = "Share an idea (use $ before ticket: e.g. $Fundrise)";
    const prefix = id ? id : 'postId';
    const uploadImageId = `${modal}_${prefix}_uploadImageId`;
    const imagePlaceholderId = `${modal}_${prefix}_imagePlaceholderId`;
    const imgHeight = '100';
    const imagePlaceholdeContainer = `${modal}_${prefix}_imagePlaceholdeContainer`;

    const userNameAt = userNameReplyProp ? `@${userNameReplyProp} ` : ``;

    useEffect(() => {
        if (id && postId) {
            if (id !== postId) {
                handleClose();
            }
        }
    }, [id, postId]);

    // eventListener on paste
    useEffect(() => {
        const el = document.getElementById(sharePostId);
        if (el) {
            el.addEventListener('paste', paste);
        }
        return () => {
            const el = document.getElementById(sharePostId);
            if (el) {
                window.removeEventListener('paste', paste)
            }
        };
    });

    // enabling lazy loading
    useEffect(() => {
        if (reply) {
            addObserver(sharePostId, observerCallback);
        }
    }, []);

    const observerCallback = (entries, observer, el) => {
		entries.forEach((entry, i) => {
            if (entry.isIntersecting) {
                setCaret(sharePostId);
            }
		});
    }
    const onCommentChange = (value) => {
        setIdeaText(value);
    }
    const handlePostIdea = () => {
        if (!loggedIn) {
            dispatch(actions.setModalSignUpOpen(true));
            return;
        }
        if (!ideaText) {
            return;
        }
        if (reply) {
            setReplying(true);
        } else {
			setAddingComment(true);
        }
        let newIdeaText = "";
        if (individualStreamName !== "" && individualStreamName !== undefined) {
            if (ideaText.indexOf(`$${individualStreamName}`) === -1) {
                newIdeaText = `${ideaText} $${individualStreamName}`;
            } else {
                newIdeaText = ideaText;
            }
        } else {
            newIdeaText = ideaText;
        }
        const ids = getPlatformIdsByTiltes(Object.values(investments), newIdeaText);
        const uniqueIds = ids.filter(arrayUniqueItems);

        clearContent(sharePostId);
        handleClose();

        if (reply) {
            addCommentAsync(token, newIdeaText, uniqueIds, postId);
        } else {
            addCommentAsync(token, newIdeaText, uniqueIds);
        }
    }
    const addCommentAsync = async (token, ideaText, uniqueIds, replyId) => {
        const response = await addComment(token, ideaText, uniqueIds, replyId, imageUrl);
        //const response = responseTest;
        if (response.success) {
            if (reply) {
                handlePostedComment(response.message, postId);
                setReplyed(true);
            } else {
                if (handleNewPost !== null && handleNewPost !== undefined) {
                    handleNewPost(response.message.timestamp);
                }
            }
        } else {
            dispatch(actions.setModalSignUpOpen(true));
        }
        if (reply) {
            setReplying(false);
        } else {
			//setAddingComment(false);
            //setStreaming(true);
		}
    }
    const clearContent = (id) => {
        document.getElementById(id).innerHTML = "";
    }
    const button = (
        <CustomButton
            type="submit"
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={handlePostIdea}
        >
            Post
        </CustomButton>
    );
    const handleImageClick = () => {
        emulateClick();
    }
    const emulateClick = () => {
        if (!loggedIn) {
            dispatch(actions.setModalSignUpOpen(true));
            return;
        }
        document.getElementById(uploadImageId).click();
    }
    const handleFiles = (value) => {
        let file = value[0];
        if(!checkFileType(file)){
          alert("Please choose image file for site logo!");
          return;
        }
        if(!checkImageSize(file)){
          alert("Image logo should be less then 5 MB!");
          return;
        }
        setDisplaySpinner(true);
        handleClose();
        uploadImageAsync(file);
    }
    const handleClose = () => {
        const container = document.getElementById(imagePlaceholdeContainer);
        container.style.display = 'none';
        setImageUrl("");
        const hiddenInput = document.getElementById(uploadImageId);
        if (hiddenInput) {
            hiddenInput.value = '';
        }
    }
    const uploadImageAsync = async (file) => {
        const imgBase64 = await readUploadedFileAsBase64(file);
        let fileName = "";
        let imgBase64Decoded = "";
        if(file){
            fileName = `image.${file.type.split("/")[1]}`;
            imgBase64Decoded = imgBase64;
        }
        const response = await uploadImage(state.token, fileName, imgBase64Decoded);
        if (response.success) {
            setImageUrl(response.result);
            updateImageLogo(response.result, imagePlaceholderId, imagePlaceholdeContainer, imgHeight);
        } else {
            dispatch(actions.setModalSignUpOpen(true));
        }
        setDisplaySpinner(false);
    }

	return (
        <div className={`${classes.postCommentContainer}`}>
            <div className={`${classes.shareContainer}`}>
                <div className={`${classes.avatar}`}>
                    <UserAvatar
                        name={userName}
                        clickIgnore={true}
                        size='48'
                        styleignore={false}
                    />
                </div>
                <div className={`${classes.inputPost}`}>
                    <div className={`${classes.commentInput} ${classes.placeholder}`}
                        id={sharePostId}
                        contentEditable="true"
                        onInput={(e) => onCommentChange(e.currentTarget.textContent)}
                        //onFocus={(e) => onCommentFocus()}
                        //onBlur={(e) => onCommentBlur()}
                        placeholder={placeholderText}
                        suppressContentEditableWarning={true}
                    >
                        {reply ? `${userNameAt}` : ''}
                    </div>
                    <div className={`${classes.inputImage}`}>
                        <img
                            src={imageicon}
                            alt='upload icon'
                            className={`${classes.image}`}
                            onClick={handleImageClick}
                        />
                        <input
                            type="file"
                            accept="image/*"
                            id={uploadImageId}
                            style={{display:'none'}}
                            onChange={(e) => handleFiles(e.target.files)}
                        />
                    </div>
                </div>
                <div className={`${classes.postButtonBig}`}>
                    {button}
                </div>
            </div>
            <div className={`${classes.postButtonSmall}`}>
                {button}
            </div>

            {displaySpinner && (<center>
                    <Spinner displaySpinner={displaySpinner} />
                </center>)}
            <div
                id={imagePlaceholdeContainer}
                className={`${classes.imagePlaceholdeContainer}`}
            >
                <div
                    id={imagePlaceholderId}
                    className={`${classes.imagePlaceholder}`}
                >
                    {
                        <img
                            src={imageUrl}
                            alt="preview"
                            height={imgHeight}
                        />}
                </div>
                <img
                    src={Close}
                    alt=""
                    className={`${classes.closeButton}`}
                    onClick={handleClose}
                />
            </div>
        </div>
	);
};
