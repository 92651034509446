import React from 'react';
import ReactDOM from 'react-dom';
import 'es7-object-polyfill';
import * as Sentry from '@sentry/browser';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import { configureStore } from "./redux";
import App from './App';
import * as serviceWorker from './serviceWorker';
import theme from './theme';

Sentry.init({
	dsn: 'https://0ff728c50ad2494582df6b7d27f979c9@o409492.ingest.sentry.io/5282173',
});

const store = configureStore();

const Root = () => (
	<BrowserRouter basename="/">
		<Provider store={store}>
			<MuiThemeProvider theme={theme}>
				<App />
			</MuiThemeProvider>
		</Provider>
	</BrowserRouter>
);

ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.unregister();
