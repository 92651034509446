import React, { useEffect, useState, useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import * as actions from "../../redux/actions";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import replyIcon from '../../img/stream/reply.svg';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});
const useStyles = makeStyles((theme) => ({
    replyContainer: {
        display: 'flex',
        cursor: 'pointer',
    },
    postsReplyText: {
        marginLeft: '8px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#73757A',
    },
    postsReplyImg: {
        marginLeft: '21px',
    },
    cursorExist: {
        cursor: 'pointer',
    },
    cursorNotExist: {
        cursor: 'default',
    },
    hide: {
        [theme.breakpoints.up('400')]: {
            display: 'inherit',
        },
        [theme.breakpoints.down('400')]: {
            display: 'none',
        },
    }
}));
export const ReplyButton = (props) => {
    const {
        handleReplyClick: handleReplyClickProp,
        postId,
        loggedIn,
        userName,
	} = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleReplyClick = (e) => {
        if (!loggedIn) {
            dispatch(actions.setModalSignUpOpen(true));
            return;
        }
        handleReplyClickProp(postId, true, userName);
    }

	return (
        <div
            className={`${classes.replyContainer}`}
            onClick={handleReplyClick}
        >
            <img
                src={replyIcon}
                alt='reply'
                className={classes.postsReplyImg}
            />
            <div className={`${classes.postsReplyText} ${classes.hide}`}>
                Reply
            </div>
        </div>
	);
};
