import Avatar from "@material-ui/core/Avatar";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
    avatar: {
        fontSize: "16px",
    },
    purpleAvatar: {
        backgroundColor: "#EEEFFD",
        color: "#5056E7",
    },
    greenAvatar: {
        backgroundColor: "#EBFAF1",
        color: "#33CD71",
    },
    blueAvatar: {
        backgroundColor: "#ECF5FF",
        color: "#3E94F9",
    }
});

const randomArrayEntry = array => array[Math.floor(Math.random() * array.length)];

export default function ReviewAvatar({name}) {
    const { avatar, purpleAvatar, greenAvatar, blueAvatar } = useStyles();
    const availableColors = [purpleAvatar, greenAvatar, blueAvatar];
    const randomColorClass = randomArrayEntry(availableColors);

    return <Avatar className={`${avatar} ${randomColorClass}`}>{name[0]}</Avatar>
}
