import { REACT_APP_GET_CLUSTER_URL } from './ConstAPI';
import PropTypes from 'prop-types';

const createAPIQUery = (urlGenerator) => async (...params) => {
	try {
		const url = urlGenerator(...params);
		const headers = {
			'Content-Type': 'application/json; charset=utf-8',
		};
		const response = await fetch(url, { method: 'GET', headers });
        const json = await response.json();
        if (response.status === 200) {
            return {
                success: true,
                result: json,
                message: "",
            }
        }  else {
            return {
                success: false,
                result: [],
                message: "",
            };
        }
	} catch {
		return {
			success: false,
			result: [],
			message: 'There is an issue to get data from server. Please try again later.',
		};
	}
};

export const getCluster = createAPIQUery((params) => `${REACT_APP_GET_CLUSTER_URL}`);

createAPIQUery.propTypes = {
	headers: PropTypes.array,
	url: PropTypes.string,
	response: PropTypes.object,
};
