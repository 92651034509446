import React, { useEffect } from 'react';
import CardContent from '@material-ui/core/CardContent';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ReactGA from 'react-ga';
import { eventTracker, trackEvent, isDeepDiveAllowed, genereateDeepdiveReviewPage } from '../../utils';
import { useSelector, shallowEqual } from 'react-redux';
import { VisitWebsite } from '../Buttons/VisitWebsite';
import { SidebarCountry } from './SidebarCountry';
import { SidebarMore } from './SidebarMore';
import { DeepDiveButton } from '../Buttons/DeepDiveButton';
import { routeNames } from '../../data/Data';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles({
	root: {
		padding: '24px !important',
	},
	button: {
		marginBottom: '10px',
	}
});

export default function Sidebar({ investmentPlatform, color }) {
	const classes = useStyles();
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const [source, setSource] = React.useState(state.itemSource || 'MoneyMade');

	const href = `/${routeNames.deepdive}/${genereateDeepdiveReviewPage(investmentPlatform.slug)}`;

	return (
		<CardContent className={classes.root}>
			{(investmentPlatform.deepDive || isDeepDiveAllowed(investmentPlatform.slug)) && <div className={classes.button}>
				<DeepDiveButton
					name={investmentPlatform.name}
					href={href}
				/>
			</div>}
			<div className={classes.button}>
				<VisitWebsite
					investmentPlatform={investmentPlatform}
					color={color}
				/>
			</div>
			<SidebarCountry
				investmentPlatform={investmentPlatform}
			/>
			<SidebarMore
				investmentPlatform={investmentPlatform}
			/>
		</CardContent>
	);
}
