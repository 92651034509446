import { REMOTEAPI_BASE_URL } from './ConstAPI';
import { cookiesEnabled, setStorage, getStorage } from '../utils';

const BUMPS_STORAGE_KEY = 'bumpedReviews';

const getBumpsStorage = () => (
	cookiesEnabled()
		? JSON.parse(getStorage(BUMPS_STORAGE_KEY)) || {}
		: {}
);

const recordReviewBumped = (platformId, reviewId) => {
	const bumpsStorage = getBumpsStorage();
	const platformsBumps = bumpsStorage[platformId] || {};
	platformsBumps[reviewId] = 1;
	bumpsStorage[platformId] = platformsBumps;
    if (cookiesEnabled()) {
        setStorage(BUMPS_STORAGE_KEY, JSON.stringify(bumpsStorage));
    }
};

const addBumpsToReviews = (platformId, reviews) => {
	const bumpsStorage = getBumpsStorage();
	const platformsBumps = bumpsStorage[platformId] || {};

	return reviews.map(review => ({
		...review,
		isBumped: !!platformsBumps[review.id]
	}));
};

const makeURL = path => `${REMOTEAPI_BASE_URL}/${path}`;

const defaultHeaders = {
	'Content-Type': 'application/json; charset=utf-8'
};

const makeRequestData = data => ({
	method: 'POST',
	headers: defaultHeaders,
	body: JSON.stringify(data)
});

const makeRequest = async (path, data) => {
	const url = makeURL(path);
	const requestData = makeRequestData(data);

	return await fetch(url, requestData);
};

const fetchJSON = async (path, data) => {
	const response = await makeRequest(path, data);
	return await response.json();
};

export const getReviews = async (platformId, rating = null) => {
	const response = await makeRequest('getReviews', {
		platformType: 1,
		platformId,

		...(rating && { rating })
	});
	const reviews = await response.json();

	if (response.status === 200) {
		return addBumpsToReviews(platformId, reviews);
	}

	return reviews;
};

export const createReview = async (platformId, reviewData) => {
	return await fetchJSON('addReview', {
		platformId,
		platformType: 1,
		name: reviewData.reviewerName,
		email: reviewData.reviewerEmail,
		verified: reviewData.isVerified,
		comment: reviewData.comment,
		rating: reviewData.rating
	});
};

export const bumpReview = async (platformId, reviewId) => {
	const response = await makeRequest('bumpReview', {
		platformId,
		reviewId,
		platformType: 1
	});

	if (response.status === 200) {
		recordReviewBumped(platformId, reviewId);
	}

	return await response.json();
};
