import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Avatar from '@material-ui/core/Avatar';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '20px',
    },
    avatar: {
        width: '48px',
        height: '48px',
    },
    tileTitle: {
        fontFamily: 'Red Hat Display',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '36px',
        lineHeight: '48px',
        color: '#30333E',
        marginLeft: '10px',
        marginBottom: '8px',
        textAlign: 'left',
    },
    tileDesc: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#73757A',
        marginBottom: '24px',
        textAlign: 'left',
    },
});

export const CollectionItemInfo = (props) => {
	const {
        collectionItem,
    } = props;

    const classes = useStyles();
    return (
        <div>
            <div className={classes.container}>
                <Avatar
                    src={collectionItem.icon}
                    className={classes.avatar}
                >
                    L
                </Avatar>
                <div className={`${classes.tileTitle}`}>
                    {collectionItem.name}
                </div>
           </div>
            <div className={`${classes.tileDesc}`}>
                {collectionItem.description}
            </div>
        </div>
    )
};
