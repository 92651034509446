import React, { useEffect, useState, useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom';
import { SkeletonPost } from './SkeletonPost';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { getStream } from '../../Services/GetStreamAPI';
import { TextWithTags } from './TextWithTags';
import { PostHeader } from './PostHeader';
import { convertToAutocompleteObject } from '../../utils';
import { JoinConversation } from '../Buttons/JoinConversation';
import { routeNames } from '../../data/Data';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});
const useStyles = makeStyles((theme) => ({
    tab: {
        padding: '10px 0',
    },
    card: {
        background: '#FFFFFF',
        border: '1px solid #F2F5F8',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
        borderRadius: '8px',
        padding: '18px',
        margin: '12px 12px 0 12px',
        [theme.breakpoints.down('460')]: {
            padding: '12px',
        },
    },
    hidden: {
        display: 'none',
    },
    postsContainer: {
        padding: '18px 10px',
    },
    title: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#30333E',
        textAlign: 'center',
    },
    button: {
        margin: '20px 10px 20px 10px',
    },
}));
export const StreamTab = (props) => {
    const {
        id,
        slug,
        name,
		count,
	} = props;
    const classes = useStyles();
    const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
    const history = useHistory();

    const [investmentTitles, setInvestmentTitles] = useState([]);
    const [stream, setStream] = useState([]);
    const [streaming, setStreaming] = useState(false);
    const [individualStreamName, setIndividualStreamName] = useState(name);
    const [noResults, setNoResults] = useState(false);

    const urlId = `/${routeNames.stream}/${slug}`;

    // start stream
    useEffect(() => {
        setStreaming(true);
    }, [id]);

    // get stream
    useEffect(() => {
        if (streaming) {
            if (individualStreamName !== "" && individualStreamName !== undefined) {
                getStreamAsync("", id);
            } else {
                getStreamAsync();
            }
        }
    }, [streaming]);

    // set autocomplete titles
    useEffect(() => {
		if (Object.values(state.investmentsTotal).length > 0) {
            const titles = convertToAutocompleteObject(state.investmentsTotal);
			setInvestmentTitles(titles);
		}
	}, [state.investmentsTotal]);

    const getStreamAsync = async (commentId = "", platformId = "") => {
        const response = await getStream(commentId, platformId);
        const data = response.message.stream;
        if (data.length > 0) {
            setStream([]);
            if (count) {
                setStream(data.slice(0, count));
            } else {
                setStream(data);
            }
            setNoResults(false);
        } else {
            setStream([]);
            setNoResults(true);
        }
        setStreaming(false);
    }
    const handleClick = (platform) => {
        history.push(`/${routeNames.stream}/${platform.slug}`);
    }

    const button = (
        <div className={`${classes.button}`}>
            <JoinConversation
                name={name}
                href={`${urlId}`}
            />
        </div>
    )

	return (
        <div className={`${classes.tab}`}>
            <div className={`${classes.title}`}>
                Latest conversations
            </div>
            {button}
            {stream.length === 0 ?
                        !noResults ?
                        <div>
                            <SkeletonPost />
                        </div> : null :
                    stream.map((i, index) =>(
                        <div className={`${classes.card}`} key={`card_${index}`}>
                            <div key={`container_${index}`}>
                                <div
                                    key={index}
                                    className={`${classes.postsContainer}`}
                                >
                                    <PostHeader
                                        displayName={i.user.displayName}
                                        timestamp={i.timestamp._seconds}
                                    />
                                    <TextWithTags
                                        text={i.text}
                                        investmentTitles={investmentTitles}
                                        platforms={i.platforms}
                                        handleClick={handleClick}
                                        imgUrl={i.imgUrl}
                                    />
                                </div>
                            </div>
                        </div>)
                    )}
            {stream.length > 0 ? button : ''}
        </div>
	);
};
