import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import CssBaseline from '@material-ui/core/CssBaseline';
import LazyLoad from 'react-lazyload';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import VizSensor from 'react-visibility-sensor';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as actions from '../../../redux/actions';
import { Spinner } from '../../Spinner/Spinner';
import ExpandedPlatform from '../../InvestmentPlatform/ExpandedPlatform';
import { trackEvent, catchError, eventTracker, getFullPlatformUrl } from '../../../utils';
import { routeNames } from '../../../data/Data';
import { getUserInfo } from '../../../Services/GetUserInfoAPI';
import { RecommendedCard } from './RecommendedCard';
import { CollapsedCard } from './CollapsedCard';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		//marginTop: '5px',
	},
	favoritesTitle: {
		fontFamily: 'Red Hat Display',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '28px',
		lineHeight: '40px',
		color: '#30333E',
		padding: '40px 0 0 40px',
	},
	favoritesTitle2: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#73757A',
        padding: '4px 0 24px 40px',
	},
	favoritesCount: {
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '14px',
		lineHeight: '20px',
		color: '#73757A',
		padding: '4px 0 24px 40px',
	},
	investCard: {
		'&:hover  $expandIcon': {
			display: 'block',
        },
        position: 'relative',
        width: '96%',
        padding: '4px 0 0px 40px',
        marginRight: '70px',
        marginBottom: '12px',
        [theme.breakpoints.down('770')]: {
            width: '100%',
        },
    },
	container: {
	},
	expandIcon: {
		position: 'absolute',
		top: '12px',
		right: '12px',
		background: '#5056E7',
		'&:hover': {
			backgroundColor: '#646AF0',
		},
		color: '#fff',
		borderRadius: '100px',
		textTransform: 'none',
		padding: '6px 16px',
	},
	[theme.breakpoints.down('1495')]: {
		favoritesTitle: {
			padding: '40px 0 0 0px',
		},
		favoritesCount: {
			padding: '4px 0 24px 0px',
		},
		investCard: {
			padding: '4px 0 24px 0px',
			marginRight: '0',
		},
	},
}));

export const RecommendedPlatforms = (props) => {
	const { platforms, handleLoadRecommended } = props;
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const dispatch = useDispatch();
	const classes = useStyles();

	const [displayRecommended, setDisplayRecommended] = useState(state.displayRecommended);
	const [displaySpinner, setDisplaySpinner] = useState(false);
	const [displaySpinner2, setDisplaySpinner2] = useState(false);
	const [sentRecommendation, setSentRecommendation] = useState(false);
	const [investments, setInvestments] = useState({});
	const [activePlatform, setActivePlatform] = useState(null);

	const successTest = "Profile details successfully submitted.";
	const RecommendedCardId = "RecommendedCard";

	const areInvestmentsPresent = Object.values(investments).length > 0;

	useEffect(() => {
		dispatch(actions.setInvestments({}));
		setDisplaySpinner(true);
		initAsync();
	}, []);
	useEffect(() => {
		setInvestments(state.investments);

	}, [state.investments]);

	// unmount
	useEffect(() => {
		return function cleanup () {
			dispatch(actions.setInvestments({}));
			dispatch(actions.setQuizResults(false));
			setSentRecommendation(false);
		};
	}, []);

	//scroll to active platform
	useEffect(() => {
		if (activePlatform !== null && activePlatform !== undefined) {
			try{
				let element = document.getElementById(`platform_ex_null${activePlatform.id}`);
				element.scrollIntoView({
					behavior: 'smooth',
				});
			} catch(err) {
				catchError(err);
			}
		}
	}, [activePlatform]);

	// get user info
	const initAsync = async () => {
		const response = await getUserInfo(state.token);
		if (response.success) {
			setDisplayRecommended(false);
		} else {
			setDisplayRecommended(true);
		}
		setDisplaySpinner(false);
	}

	const handleCloseCard = () => {
		document.getElementById(RecommendedCardId).style.display = "none";
	}
	const handleCardClick = async (id, investmentPlatform) => {
        const platformUrl = `/${routeNames.discover}/${id}`;
        ReactGA.pageview(platformUrl);
        eventTracker("pageview", platformUrl, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);

        setActivePlatform(investmentPlatform);
	};
	const stopPropagation = (event, investmentPlatform) => {
        trackEvent('ExternalClick', 'platform', investmentPlatform.name);
        eventTracker("ExternalClick", investmentPlatform.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);

		event.stopPropagation();
	};
	const handleSentRecommendation = (value) => {
		setSentRecommendation(value);
	};
	const handleDisplaySpinner = (value) => {
		setDisplaySpinner2(value);
	};

	const recommendedCard = (
		<RecommendedCard
			handleCloseCard={handleCloseCard}
			handleDisplaySpinner={handleDisplaySpinner}
			handleSentRecommendation={handleSentRecommendation}
			displaySpinner={displaySpinner2}
		/>);

	const spinner = (
		<center>
			<Spinner displaySpinner={displaySpinner || displaySpinner2} />
		</center>);

	const successText = (
		<div className={classes.favoritesTitle2}>{successTest}</div>);

	const commingSoonText = (
		<div className={classes.favoritesTitle2}>Coming Soon</div>);

	const hiddenDiv = (
		<div className={classes.favoritesTitle2} style={{visibility: 'hidden'}}>Coming Soon</div>);

	return (
		<div className={`respCont ${classes.root}`}>
			<CssBaseline />
			<div className={classes.toolbar} />
			<main className={`${classes.content} mainPrinc`}>
				<div className={classes.favoritesTitle}>Recommended for you</div>

				{displaySpinner ? spinner : null}
				{displaySpinner2 ? spinner : null}

				{!displaySpinner && displayRecommended
					? !sentRecommendation
						? recommendedCard
						: successText
					: null}

				{!displaySpinner && !displayRecommended && commingSoonText}

				{areInvestmentsPresent? hiddenDiv : null}

				{areInvestmentsPresent && Object.values(investments)
                    .map(function (investment, index) {
                        let investmentComponent = null;
                        const fullPlatformUrl = getFullPlatformUrl(`discover/${investment.slug}`);

                        investmentComponent = (
                            <div
                                id={`platform_${investment.id}`}
                                key={`platform_${investment.id}`}
                                className={classes.investCard}
                            >
                                <LazyLoad
                                    height={230}
                                    offset={500}
                                    once
                                    key={`lazy_${investment.id}`}
                                >
                                    <VizSensor
                                        onChange={(isVisible) => {
                                            trackEvent('PlatformImpression', 'platform', investment.name);
                                            eventTracker("PlatformImpression", investment.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
                                        }}
                                    >
										<CollapsedCard
											fullPlatformUrl={fullPlatformUrl}
											investment={investment}
											stopPropagation={stopPropagation}
											handleCardClick={handleCardClick}
											itemSource={state.itemSource}
											itemMedium={state.itemMedium}
										/>
                                    </VizSensor>
                                </LazyLoad>
                            </div>
                        );
                        if (activePlatform === investment) {
                            const similarInvestments = activePlatform.similars.map((similar) => {
                                const similarInvestment = Object.values(investments).find(
                                    (investment) => investment.id === similar
                                );
                                return similarInvestment;
                            });
                            investmentComponent = (
                                <div
                                    className={classes.investCard}
                                    key={`ExpandedPlatform_${investment.id}`}
                                >
                                    <ExpandedPlatform
                                        key={investment.id}
                                        investmentPlatform={investment}
                                        similarInvestments={similarInvestments}
                                        setActivePlatform={setActivePlatform}
                                        expandable={false}
                                        embed={true}
                                        favorites={true}
                                    />
                                </div>
                            );
                        }
                        return (
							investmentComponent
						)
				    })
                }
			</main>
		</div>
	);
};
