import React, { useState, useEffect } from 'react';
import { getMentions } from '../../Services/MetionsAPITrading';
import { Spinner } from '../Spinner/Spinner';
import InvestmentPlatformMention from './Mention';
import SentimentStats from './SentimentStats';

import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
	loader: {
		width: '100%',
		height: '200px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	}
});

export default function InvestmentPlatformMentions({ investmentPlatform }) {
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(true);

	const [mentions, setMentions] = useState([]);

	useEffect(() => {
		const effect = async () => {
			const mentions = await getMentions(investmentPlatform.id);
			//	console.log(mentions);
			setMentions(mentions);
			setIsLoading(false);
		};

		setIsLoading(true);
		effect();
	}, [investmentPlatform.id, investmentPlatform.sentiment]);
	//console.log(mentions);
	return (
		<React.Fragment>
			{isLoading ? (
				<div className={classes.loader}>
					<Spinner displaySpinner />
				</div>
			) : (
				<React.Fragment>
					<SentimentStats
						mentions={mentions}
						sentiment={investmentPlatform.sentiment}
					/>
					{mentions.map(mention => (
						<InvestmentPlatformMention key={mention.id} mention={mention} />
					))}
				</React.Fragment>
			)}
		</React.Fragment>
	);
}
