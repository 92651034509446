import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { Spinner } from '../Spinner/Spinner.js';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import { Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useSelector, shallowEqual } from 'react-redux';
import { getNews } from '../../Services/GetNewsAPI';
import { PressItems } from './PressItems';
//import { test } from '../../utils';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles((theme) => ({
	root: {
        marginBottom: '32px',
        [theme.breakpoints.down('750')]: {
            paddingTop: '12px',
        },
    },
    generalContainer: {
        position: 'relative',
        padding: '0 12px',
    },
    itemContainer: {
        display: 'flex',
        overflowX: 'auto',
        flexWrap: 'wrap',
        /*[theme.breakpoints.down('750')]: {
            flexWrap: 'wrap',
        },*/
    },
    desktopView: {
        [theme.breakpoints.down('750')]: {
			display: 'none',
		},
    },
    adaptView: {
        /*[theme.breakpoints.up('750')]: {
			display: 'none',
        },*/
    },
	title: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#30333E',
    },
    titleCard: {
        textAlign: 'center',
        /*[theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },*/
    },
    description: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#73757A',
        marginBottom: '16px',
        //textOverflow: 'ellipsis',
        //overflow: 'hidden',
    },
    marginStyle: {
        marginTop: '12px',
        marginBottom: '8px',
    },
    marginStyle2: {
        marginLeft: '20px',
    },
    image: {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '12px 16px',
    },
    avatarContainer: {
        paddingTop: '18px',
        paddingLeft: '12px',
        paddingRight: '12px',
    },
    avatar: {
		width: '28px',
		height: '28px',
    },
    /*backButton: {
		position: 'absolute',
		zIndex: '9999',
        left: '0px',
        top: '60px',
		marginTop: '20px',
		width: '55px !important',
		[theme.breakpoints.down('750')]: {
			display: 'none',
		},
	},
	forwardButton: {
		position: 'absolute',
		marginTop: '20px',
        right: '0px',
        top: '60px',
		zIndex: '9999',
		width: '55px !important',
		[theme.breakpoints.down('750')]: {
			display: 'none',
		},
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginTop: '10px'
    },
    seeAllButton: {
		margin: 'auto',
        maxWidth: '1200px',
		background: 'rgb(80,86,231)',
		//borderRadius: '8px',
		//borderRadius: '100px',
		color: '#fff',
		'&:hover': {
			backgroundColor: 'rgb(80,86,231, 0.8)',
		},
	},*/
}));

export const Press = (props) => {
    const {
        platformId,
    } = props;
	const classes = useStyles();
    const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);

    const [showData, setShowData] = useState(false);
    const [data, setData] = useState([]);
    const [shortData, setShortData] = useState([]);
    const [buttonClick, setButtonClick] = useState(false);

    const newsDiv = React.useRef();
    useEffect(() => {
        if (platformId) {
            getNewsAsync(platformId);
        }
    }, [platformId]);

    useEffect(() => {
        if (buttonClick) {
            setShortData(data);
        }
    }, [buttonClick]);

    const getNewsAsync = async (platformId) => {
        const response = await getNews(platformId);
        if (response.success) {
            if (response.result.length > 0) {
                //setData(response.result.slice(0, 2));
                //setData(response.result);
                setData(response.result);
                setShortData(response.result.slice(0, 2));
            } else {
                setData([]);
            }
        } else {
            setData([]);
        }
        setShowData(true);
    }
    const handleForwardClick = (e) => {
		if (newsDiv.current) {
			newsDiv.current.scrollLeft += 200;
		}
	}
	const handleBackClick = (e) => {
		if (newsDiv.current) {
			newsDiv.current.scrollLeft -= 200;
		}
    }
    const handleClickButton = () => {
        setButtonClick(true);
    }

	return (
        <div>
            {showData ?
                data.length > 0 ? (
                    <div className={classes.root}>
                        <div className={`${classes.title} ${classes.titleCard}`}>
                            In the press
                        </div>
                        <div className={classes.generalContainer}>
                            {/*
                            <IconButton
                                aria-label="back"
                                color="primary"
                                variant="outlined"
                                onClick={handleBackClick}
                                className={classes.backButton}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            */}
                            <div className={`${classes.itemContainer} ${classes.adaptView}`} ref={newsDiv}>
                                <PressItems
                                    data={data}
                                />
                            </div>
                            {/*
                            <div className={`${classes.itemContainer} ${classes.adaptView}`}>
                                <PressItems
                                    data={shortData}
                                />
                                {!buttonClick && data.length > 2 && (
                                    <div className={`${classes.buttonContainer} `}>
                                    <Button
                                        fullWidth={true}
                                        className={classes.seeAllButton}
                                        onClick={() => handleClickButton()}
                                    >
                                        See All
                                    </Button>
                                </div>)}
                            </div>
                            */}
                           {/*
                            <IconButton
                                    aria-label="forward"
                                    color="primary"
                                    variant="outlined"
                                    onClick={handleForwardClick}
                                    className={classes.forwardButton}
                                >
                                <ArrowForwardIcon />
                            </IconButton>
                           */}
                        </div>
                    </div>) : null :
               <center>
                    <Spinner displaySpinner={true} />
                </center>}
        </div>
	)
}
