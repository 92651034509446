import React, { useEffect, useState, useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import commentsIcon from '../../img/stream/comments.svg';
import commentsBlueIcon from '../../img/stream/commentsBlue.svg';
import { isArrayExistAndNotEmpty } from '../../utils';
import { Spinner } from '../Spinner/Spinner';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});
const useStyles = makeStyles((theme) => ({
    comments: {
        display: 'flex',
        userSelect: 'none',
    },
    postsCommentsText: {
        marginLeft: '8px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        display: 'flex',
    },
    grey: {
        color: '#73757A',
    },
    blue: {
        color: '#5056E7',
    },
    cursorExist: {
        cursor: 'pointer'
    },
    cursorNotExist: {
        cursor: 'default'
    },
    hide: {
        [theme.breakpoints.up('400')]: {
            display: 'inherit',
        },
        [theme.breakpoints.down('400')]: {
            display: 'none',
        },
    },
    commentsText: {
        marginLeft: '4px',
    }
}));
export const CommentsButton = (props) => {
    const {
        replies,
        handleCommentClick: handleCommentClickProp,
        postId,
        modal,
        postLoading,
	} = props;
    const classes = useStyles();

    /*const clickable = replies
        ? (replies.length > 0)
            ? true
            : false
        : false;*/
    const clickable = modal
        ? false
        : true;

    const cursorStyle = clickable ? classes.cursorExist : classes.cursorNotExist;

    const [openComments, setOpenComments] = useState(false);

    const handleCommentClick = (e) => {
        if (!clickable) {
            return;
        }
        setOpenComments(!openComments);
        handleCommentClickProp(postId, !openComments);
    }

	return (
        <div
            className={`${cursorStyle} ${classes.comments} ${isArrayExistAndNotEmpty(replies) ? classes.blue : classes.grey}`}
            onClick={handleCommentClick}
        >
            {postLoading !== postId ? (
                <>
                    <img
                        src={isArrayExistAndNotEmpty(replies) ? commentsBlueIcon : commentsIcon}
                        alt='comments'
                        className={classes.postsCommentsImg}
                    />
                    <div className={`${classes.postsCommentsText} `}>
                        {replies ? replies.length : 0} <span className={`${classes.hide} ${classes.commentsText}`}>Comments</span>
                    </div>
                </>
            ) : <Spinner displaySpinner={true} />}
        </div>
	);
};
