import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ClosableModal from './ClosableModal';
import { getOneStream } from '../../Services/GetOneStreamAPI';
import {
	trackEvent,
	eventTracker,
	getDiscoveryPageName,
	getFullPlatformUrl,
} from '../../utils';
import { OnePostCard } from './OnePostCard';
import { useSelector, shallowEqual } from 'react-redux';
const useStyles = makeStyles((theme) => ({
	mainModal: {
		paddingBottom: '56px',
	},
	premium: {
		border: '1px solid #F3B81F',
	},

	createReviewModal: {
		maxWidth: '553px',
		width: '100%',
		backgroundColor: '#FFF',
	},
	innerCards: {
		display: 'flex',
		alignItems: 'flex-start',
		padding: '0 8px 0 62px',
	},
	innerSimilarCards: {
		display: 'flex',
		alignItems: 'flex-start',
		padding: '0 0 0 62px',
		width: '70%',
	},
	innerCard: {
		border: '1px solid #F2F5F8',
		borderRadius: '8px',
		backgroundColor: '#fff',
		padding: 0,
		margin: '0 8px',

		'& .MuiCardContent-root:last-child': {
			padding: '0 !important',
		},
	},
	tabsCard: {
		width: '70%',
	},
	similarHeader: {
		fontWeight: '500',
		fontSize: '16px',
		lineHeight: '24px',
		marginLeft: '1em',
		padding: '0 8px 0 62px',
	},
	sidebarCard: {
		width: '30%',
		padding: '24px',
	},
	[theme.breakpoints.down('sm')]: {
		innerCards: {
			flexDirection: 'column',
			padding: '0 16px',
		},
		innerCard: {
			margin: '0 0 20px 0',
		},
		innerSimilarCards: { width: '100%', flexDirection: 'column', padding: '0 16px' },
		tabsCard: { width: '100%' },
		sidebarCard: { width: '100%' },
	},
	cardContainerDesktop: {
		[theme.breakpoints.up('750')]: {
			display: 'inherit',
		},
		[theme.breakpoints.down('750')]: {
			display: 'none',
		},
	},
	cardContainerMobile: {
		[theme.breakpoints.up('750')]: {
			display: 'none',
		},
		[theme.breakpoints.down('750')]: {
			display: 'inherit',
		},
	},
}));

const modals = {
	MAIN: 'main',
};

export const StreamPostModal = (props) => {
	const {
		embed,
		onClose,
		postId,
		currentUrl,
		handleCloseModal,
		handlePostedComment,
		handlePostLoaded,
	} = props;
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const classes = useStyles();

	const [openModal, setOpenModal] = useState(false);
	const [post, setPost] = useState(null);

	useEffect(() => {
		if (postId) {
			getOneStreamAsync(postId);
		}
	}, []);

	const getOneStreamAsync = async (postId) => {
		const response = await getOneStream(postId);
		if (response.success) {
			setPost(response.result.result);
			setOpenModal(true);
			trackEvent('openPost', 'postId', postId);
			eventTracker(
				'openPost',
				postId,
				state.itemSource,
				state.itemMedium,
				state.itemCompaign,
				state.userId
			);
		} else {
			setPost(null);
			setOpenModal(false);
		}
		handlePostLoaded();
	};

	//console.log(post);

	return (
		<>
			{openModal && post !== null && (
				<ClosableModal
					className={`${classes.mainModal}`}
					open={modals.MAIN}
					onClose={onClose}
					embed={embed}
					currentUrl={currentUrl}
				>
					<div id="modal" />
					{
						<OnePostCard
							post={post}
							postId={postId}
							currentUrl={currentUrl}
							onClose={onClose}
							handleCloseModal={handleCloseModal}
							handlePostedComment={handlePostedComment}
						/>
					}
				</ClosableModal>
			)}
		</>
	);
};
