import React, { useState, useEffect } from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { isEmbeded } from '../../utils';
import { useSelector, shallowEqual } from "react-redux";

const useStyles = makeStyles({
	tooltip: {
		zIndex: 10001, // to be visible inside the modal
	},
});

export default function SimpleBottomNavigation(props) {
	const classes = useStyles();
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const [value, setValue] = useState(0);
	const [embed, setEmbed] = useState(false);
	const [medium, setMedium] = useState(state.itemMedium);
	useEffect(() => {
		if (state.itemMedium) {
			setMedium(state.itemMedium);
		}
	}, [state.itemMedium]);

	useEffect(() => {
		if (isEmbeded(medium,'embed')) {
			setEmbed(true);
			if (window.dojoRequire) {
				window.dojoRequire(['mojo/signup-forms/Loader'], function (L) {
					L.start({
						baseUrl: 'mc.us4.list-manage.com',
						uuid: '9922df745a85edbac4d1eef0b',
						lid: '360bd75c7c',
						uniqueMethods: true,
					});
				});
			}
		}
	}, [medium]);
	return (
		<>
			<BottomNavigation
				value={value}
				onChange={(event, newValue) => {
					setValue(newValue);
				}}
				style={{ /* maxWidth: "100%", */ background: 'transparent', bottom: '0px' }}
				showLabels
				className="footerFx"
			>
				{embed === false ? (
					<BottomNavigationAction
						label={
							<a
								style={{
									color:
										window.location.pathname.replace(/^\/([^\/]*).*$/, '$1') === ''
											? 'white'
											: '#73757A',
								}}
								href="mailto:richard@fintechmedia.io"
								target="_top"
							>
								Contact
							</a>
						}
					/>
				) : null}
				{!embed ? (
					<BottomNavigationAction
						label={
							<a
								style={{
									color:
										window.location.pathname.replace(/^\/([^\/]*).*$/, '$1') === ''
											? 'white'
											: '#73757A',
								}}
								href="mailto:richard@fintechmedia.io"
								target="_top"
							>
								Advertise
							</a>
						}
					/>
				) : null}
				{!embed ? (
					<BottomNavigationAction
						label="Terms & Conditions"
						href="/terms-and-condition"
					/>
				) : null}
				<Tooltip
					classes={{ popper: classes.tooltip }}
					disableFocusListener
					disableTouchListener
					placement="top"
					title="The Content is for informational purposes only, you should not construe any
							such information or other material as legal, tax, investment, financial, or
							other advice. Nothing contained on our Site constitutes a solicitation,
							recommendation, endorsement, or offer by MoneyMade or any third party
							service provider to buy or sell any securities or other financial
							instruments in this or in in any other jurisdiction in which such
							solicitation or offer would be unlawful under the securities laws of such
							jurisdiction. All Content on this site is information of a general nature
							and does not address the circumstances of any particular individual or
							entity. Nothing in the Site constitutes professional and/or financial
							advice, nor does any information on the Site constitute a comprehensive or
							complete statement of the matters discussed or the law relating thereto.
							MoneyMade is not a fiduciary by virtue of any person’s use of or access to
							the Site or Content. You alone assume the sole responsibility of evaluating
							the merits and risks associated with the use of any information or other
							Content on the Site before making any decisions based on such information
							or other Content. In exchange for using the Site, you agree not to hold
							MoneyMade, its affiliates or any third party service provider liable for
							any possible claim for damages arising from any decision you make based on
							information or other Content made available to you through the Site.

							MoneyMade strives to keep its information accurate and up to date. This
							information may be different than what you see when you visit a financial
							institution, service provider or specific product’s site. All financial
							products, services are presented without warranty. When evaluating offers,
							please review the financial institution’s Terms and Conditions.

							Advertiser disclosure: We strive to provide users with the most transparent
							and true information about the platforms listed. Some of the platforms
							listed may contain Affiliate links. We may receive payment if you apply for
							and / or receive one of the products listed on MoneyMade."
					arrow
				>
					<BottomNavigationAction label="Disclaimer" href="/terms-and-condition" />
				</Tooltip>
				<BottomNavigationAction
					className="bigField"
					label={
						<div>MoneyMade</div>
					}
					style={{cursor: 'inherit'}}
				/>
			</BottomNavigation>
		</>
	);
}
