import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import Dashboard from '../../img/dashboard/Dashboard.svg';
import Favorites from '../../img/dashboard/Favorites.svg';
import Platforms from '../../img/dashboard/Platforms.svg';
import Potrfolio from '../../img/dashboard/Potrfolio.svg';
import Reviews from '../../img/dashboard/Reviews.svg';
import Quiz from '../../img/dashboard/Quiz.svg';
import openedIcon from '../../img/icons/opened.svg';
import cmenuIcon from '../../img/icons/normal.svg';
import { LogoComponent } from '../Logo/LogoComponent';
import { UserAvatar } from '../Avatar/Avatar';
import * as actions from '../../redux/actions';

const useStyles = makeStyles((theme) => ({
    logo: {
        marginLeft: '30px',
        marginBottom: '51px',
        [theme.breakpoints.down('1145')]: {
            marginTop: '23px',
            marginBottom: '23px',
            //lineHeight: '24px',
            display: 'flex',
        },
    },
    hamburger: {
        [theme.breakpoints.up('1145')]: {
           display: 'none',
        },
        [theme.breakpoints.down('1145')]: {
            filter: 'brightness(5.5)',
            display: 'flex',
            marginLeft: 'auto',
            paddingRight: '20px',
            cursor: 'pointer',
        },
    },
    top: {
        paddingBottom: '80px',
        [theme.breakpoints.down('1145')]: {
            paddingBottom: '0px',
        },
    },
    menu: {
        [theme.breakpoints.down('1145')]: {
            display: 'none'
        },
    },
    showMenu: {
        [theme.breakpoints.up('1145')]: {
            display: 'inherit',
            maxWidth: 'inherit',
            margin: 'inherit',
            paddingBottom: 'inherit',
            textAlign: 'inherit',
        },
        [theme.breakpoints.down('1145')]: {
            display: 'block !important',
            maxWidth: '126px',
            margin: 'auto',
            paddingBottom: '5px',
            textAlign: 'center',
        },
        [theme.breakpoints.down('434')]: {
            maxWidth: '100%',
        },
	},
    link: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#FFF',
        cursor: 'pointer',
        paddingLeft: '28px',
        marginLeft: '24px',
        paddingTop: '12px',
        paddingBottom: '12px',
        [theme.breakpoints.down('1145')]: {
            paddingLeft: '0px',
            marginLeft: '0px',
            borderBottom: '2px solid #30333E',
        },
    },
    linkActive: {
        background: '#5056E7',
        cursor: 'pointer',
        [theme.breakpoints.down('1145')]: {
            paddingBottom: '0px',
            background: 'none',
            borderBottom: '2px solid #5056E7',
        },
        [theme.breakpoints.down('434')]: {
            paddingBottom: '0px',
            background: 'none',
            borderBottom: 'none',
            borderLeft: '2px solid #5056e7',
        },
    },
    link1Img: {
        backgroundImage: `url(${Dashboard})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left center',
        [theme.breakpoints.down('1145')]: {
            backgroundImage: `none`,
        },
    },
    link2Img: {
        backgroundImage: `url(${Favorites})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left center',
        [theme.breakpoints.down('1145')]: {
            backgroundImage: `none`,
        },
    },
    link3Img: {
        backgroundImage: `url(${Reviews})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left center',
        [theme.breakpoints.down('1145')]: {
            backgroundImage: `none`,
        },
    },
    link4Img: {
        backgroundImage: `url(${Potrfolio})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left center',
        [theme.breakpoints.down('1145')]: {
            backgroundImage: `none`,
        },
    },
    link5Img: {
        backgroundImage: `url(${Platforms})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left center',
        [theme.breakpoints.down('1145')]: {
            backgroundImage: `none`,
        },
    },
    hline: {
        width: '152px',
        height: '1px',
        background: 'rgb(256,256,256,0.2)',
        marginLeft: '24px',
        marginTop: '12px',
        marginBottom: '12px',
        [theme.breakpoints.down('1145')]: {
            display: 'none',
        },
    },
    bottom: {
        marginTop: 'auto',
        paddingBottom: '40px',
        [theme.breakpoints.down('1145')]: {
           display: 'none',
        },
    },
    banner: {
        cursor: 'pointer',
        background: '#5056E7',
        boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
        borderRadius: '8px',
        width: '152px',
        height: '179px',
        marginLeft: '24px',
        marginBottom: '40px',
        position: 'relative',
    },
    bottomImage: {
        textAlign: 'center',
        position: 'absolute',
        left: '34px',
        top: '-44px',
    },
    bannerTitle: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '18px',
        lineHeight: '24px',
        color: '#FFF',
        paddingTop: '35px',
        marginLeft: '16px',
        paddingBottom: '16px',
    },
    bannerDescription: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '20px',
        color: '#FFF',
        marginLeft: '16px',

    },
    avatar: {
        marginLeft: '80px',
        paddingBottom: '44px',
    },
}));

export const Navigation =  (props) => {
    const {
        navigation: navigationProp,
        handleClick: handleClickProp,
        showMenu: showMenuProp,
    } = props;
    const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
    const dispatch = useDispatch();
    const [navigation, setNavigation] = useState(navigationProp);
    const [showMenu, setshowMenu] = useState(showMenuProp);
    const classes = useStyles();

    const handleClick = (number) => {
        setNavigation(number);
        if (showMenu) {
            setshowMenu(false);
        }
        handleClickProp(number);
    };
    const handleOpen = () => {
		if (!props.embed) {
			dispatch(actions.setModalOpen(true));
		} else {
			props.openCalculator();
		}
	};
    return (
        <>
            <div className={classes.top}>
                <div className={classes.logo}>
                    <LogoComponent
                        color="whiteGreen"
                        clearSpace={true}
                    />
                    <img
                        className={`${classes.hamburger}`}
                        onClick={() => setshowMenu(!showMenu)}
                        src={showMenu ? openedIcon : cmenuIcon}
                        alt="HamburgerMenu"
                    />
                </div>
                <div className={`
                    ${classes.menu}
                    ${showMenu ? classes.showMenu : ''}
                `}>
                    {/*
                    <div className={`${navigation === 1 ? classes.linkActive: ''}`}>
                        <div
                            className={`
                                ${classes.link}
                                ${classes.link1Img}
                            `}
                            onClick={() => {handleClick(1)}}
                        >
                            Dashboard
                        </div>
                    </div>
                    */}
                    <div className={`${navigation === 2 ? classes.linkActive: ''}`}>
                        <div
                            className={`
                                ${classes.link}
                                ${classes.link2Img}
                            `}
                            onClick={() => {handleClick(2)}}
                        >
                            Favorites
                        </div>
                    </div>
                   {/*
                    <div className={`${navigation === 3 ? classes.linkActive: ''}`}>
                        <div
                            className={`
                                ${classes.link}
                                ${classes.link3Img}
                            `}
                            onClick={() => {handleClick(3)}}
                        >
                            Portfolio
                        </div>
                    </div>*/}
                    {/*<div className={`${navigation === 4 ? classes.linkActive: ''}`}>
                        <div
                            className={`
                                ${classes.link}
                                ${classes.link4Img}
                            `}
                            onClick={() => {handleClick(4)}}
                        >
                            Your reviews
                        </div>
                    </div>*/}
                    <div className={classes.hline}></div>
                    <div className={`${navigation === 5 ? classes.linkActive: ''}`}>
                        <div
                            className={`
                                ${classes.link}
                                ${classes.link5Img}
                            `}
                            onClick={() => {handleClick(5)}}
                        >
                            New Platforms
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.bottom}>
                <div
                    className={classes.banner}
                    onClick={handleOpen}
                >
                    <div className={classes.bottomImageContainer}>
                        <img src={Quiz} className={classes.bottomImage} />
                    </div>
                    <div className={classes.bannerTitle}>
                        Take the investor quiz
                    </div>
                    <div className={classes.bannerDescription}>
                        Get investment opportunities best suited for you.
                    </div>
                </div>
                <div className={classes.avatar}>
                    <UserAvatar
						name={state.userName}
					/>
                </div>
            </div>
        </>
    )
}
