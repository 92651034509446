import { REACT_APP_CHECK_USER_INFO_URL } from "./ConstAPI";

const makeURL = () => `${REACT_APP_CHECK_USER_INFO_URL}`;

const makeRequestData = (data) => ({
    method: "GET",
    headers: {
        "Authorization" : `Bearer ${data}`,
    },
});

const makeRequest = async (data) => {
    const url = makeURL();
    const requestData = makeRequestData(data);

    return await fetch(url, requestData);
};
/**
 * @param {string} token
 */
export const getUserInfo = async (token) => {
    try {
		const response = await makeRequest(token);
        const responseStatus = await response.json();
        if (response.status === 200) {
            if (responseStatus.filled) {
                return {
                    success: true,
                    result: responseStatus,
                    message: '',
                }
            } else {
                return {
                    success: false,
                    result: responseStatus,
                    message: '',
                }
            }
        } else {
            return {
                success: false,
                result: [],
                message: "",
            };
        }
	} catch (error) {
        console.log(error);
		return {
			success: false,
			result: [],
			message: error,
        };
	}
};
