import React, { useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ReactGA from 'react-ga';
import { Button } from '@material-ui/core';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles({
	visitWebsiteButton: {
		textTransform: 'none',
		margin: 'auto',
        maxWidth: '150px',
        width: '100%',
        color: '#fff',
        borderRadius: '100px',
        position: 'absolute',
        zIndex: 1,
        marginLeft: 'auto',
        marginRight: 'auto',
        left: 0,
        right: 0,
        top: '-20px',
        textAlign: 'center',
	},
	color1: {
		background: '#5056E7',
		'&:hover': {
			backgroundColor: 'rgb(80,86,231, 0.8)',
		},
	},
});

export const NewPostsButton = (props) => {
	const {
        count,
        onNewPostsClick,
    } = props;

	const classes = useStyles();

	return (
		<Button
			fullWidth={false}
			className={`${classes.visitWebsiteButton} ${classes.color1}`}
			onClick={onNewPostsClick}
		>
			{count} New Posts
		</Button>
	);
};
