import * as actions from "./types";
/**
 * Action to set utm_campaign
 * @param {string} payload
 */
export const setItemCompaign = (payload) => ({
    type: actions.SET_ITEM_COMPAIGN,
    payload,
});
/**
 * Action to set utm_medium
 * @param {string} payload
 */
export const setItemMedium = (payload) => ({
    type: actions.SET_ITEM_MEDIUM,
    payload,
});
/**
 * Action to set utm_source
 * @param {string} payload
 */
export const setItemSource = (payload) => ({
    type: actions.SET_ITEM_SOURCE,
    payload,
});
/**
 * Action to set utm_source
 * @param {string} payload
 */
export const setItemIndustry = (payload) => ({
    type: actions.SET_ITEM_INDUSTRY,
    payload,
});
export const setModalOpen = (payload) => ({
    type: actions.SET_MODAL_OPEN,
    payload,
});
export const setModalSignUpOpen = (payload) => ({
    type: actions.SET_MODAL_SIGNUP_OPEN,
    payload,
});
export const setInvestments = (payload) => ({
    type: actions.SET_INVESTMENTS,
    payload,
});
export const setQuizResults = (payload) => ({
    type: actions.SET_QUIZ_RESULTS,
    payload,
});
export const setClusterView = (payload) => ({
    type: actions.SET_CLUSTER_VIEW,
    payload,
});
export const setUserId = (payload) => ({
    type: actions.SET_USER_ID,
    payload,
});
export const setInvestmentsTotal = (payload) => ({
    type: actions.SET_INVESTMENTS_TOTAL,
    payload,
});
export const setClusters = (payload) => ({
    type: actions.SET_CLUSTERS,
    payload,
});
export const setAds = (payload) => ({
    type: actions.SET_ADS,
    payload,
});
export const addInvesmentToFavorites = (payload) => ({
    type: actions.ADD_INVESTMENT_TO_FAVORITES,
    payload,
});
export const addInvesmentsToFavorites = (payload) => ({
    type: actions.ADD_INVESTMENTS_TO_FAVORITES,
    payload,
});
export const removeInvestmentFromFavorites = (payload) => ({
    type: actions.REMOVE_INVESTMENT_FROM_FAVORITES,
    payload,
});
export const authUser = (payload) => ({
    type: actions.AUTHORISE_USER,
    payload,
});
export const setToken = (payload) => ({
    type: actions.SET_TOKEN,
    payload,
});
export const setLoggedInStatus = (payload) => ({
    type: actions.SET_LOGGED_IN_STATUS,
    payload,
});
export const displaySpinner = (payload) => ({
    type: actions.DISPLAY_SPINNER,
    payload,
});
export const displayRecommended = (payload) => ({
    type: actions.DISPLAY_RECOMMENDED,
    payload,
});
export const fingerprintSent = (payload) => ({
    type: actions.FINGERPRINT_SENT,
    payload,
});
export const setQuizAnswers = (payload) => ({
    type: actions.SET_QUIZ_ANSWERS,
    payload,
});
export const setTypes = (payload) => ({
    type: actions.SET_TYPES,
    payload,
});

