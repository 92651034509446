import React, { useState, useEffect, useMemo } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import { AdCard } from './AdCard';
import { getPremiumEntries } from '../../Services/BaseAPI.js';
import { Spinner } from '../../components/Spinner/Spinner.js';
import {
	getShuffle,
	isObjectEmpty,
	trackEvent,
	isPaidOnly,
	eventTracker,
} from '../../utils';
import { routeNames } from '../../data/Data';
import { SliderCard } from '../Slider/SliderCard';
import LeftNavbarWrapper from '../LeftNavbarWrapper.jsx';
import { useSelector, shallowEqual } from 'react-redux';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		margin: 0,
		padding: 0,
	},
	content: {
		flexGrow: 1,
		paddingTop: '0px !important',
	},
}));

export const CustomWidgetCard = (props) => {
	const { utmParams, paidOnly } = props;
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const { w, h, i, utm_campaign, utm_source, utm_medium } = utmParams;
	const [bannerShow, setBannerShow] = useState(true);
	const [investments, setInvestments] = useState({});
	const [displaySpinner, setDisplaySpinner] = useState(false);
	const [incriment, setIncriment] = useState(false);

	const shuffledInvestments = useMemo(() => getShuffle(investments), [investments]);
	const areInvestmentsPresent = isObjectEmpty(shuffledInvestments);

	const slicer = i ? i : shuffledInvestments.length;
	const width = w ? `${w}px` : 'inherit';
	const height = h ? `${h}px` : 'inherit';

	const classes = useStyles();

	const rootStyle = {
		maxWidth: `${width}`,
		height: `${height}`,
		overflowX: 'hidden',
		overflowY: 'auto',
		padding: '0px',
	};

	const number = 2500;
	const [sliderValue, setSliderValue] = React.useState(number);

	const siteUrl = `https://moneymade.io/${routeNames.discover}?utm_source=${utm_source}&utm_campaign=${utm_campaign}`;

	const handleChange = (event, newValue) => {
		setSliderValue(newValue);
	};
	useEffect(() => {
		setDisplaySpinner(true);
		setInvestments([]);
		getPremiumEntries().then((data) => {
			setInvestments(data);
			setDisplaySpinner(false);
		});
	}, [w, h, i]);

	useEffect(() => {
		if (incriment) {
			if (sliderValue >= 5000) {
				setIncriment(false);
			} else {
				setTimeout(function(){
					setSliderValue(sliderValue + 100);
				},  50)
			}
		}

	}, [incriment, sliderValue]);

	useEffect(() => {
		if (!displaySpinner && shuffledInvestments.length > 0) {
			const header = document.getElementById('slider');
			const observer = new IntersectionObserver((entries) => observerSliderCallback(entries, observer, header), {
				threshold: 1
			});
			observer.observe(header);
		}
	}, [shuffledInvestments, displaySpinner]);

	const observerSliderCallback = (entries, observer, header) => {
		entries.forEach((entry, i) => {
			setIncriment(true);
			observer.unobserve(entry.target);
		});
	};

	const onCardClick = () => {
		if (w < 600) {
			window.open(siteUrl);
			//setBannerShow(!bannerShow);
			trackEvent('BannerClick', 'state', bannerShow);
			eventTracker(
				'BannerClick',
				bannerShow ? 'true' : 'false',
				state.itemSource,
				state.itemMedium,
				state.itemCompaign,
				state.userId
			);
		} else {
			//window.open(siteUrl);
			setBannerShow(!bannerShow);
			trackEvent('ExpandableOpen', 'state', bannerShow);
			eventTracker(
				'ExpandableOpen',
				bannerShow ? 'true' : 'false',
				state.itemSource,
				state.itemMedium,
				state.itemCompaign,
				state.userId
			);
		}
	};
	const sliderElement = (
		<SliderCard
			sliderValue={sliderValue}
			onCardClick={onCardClick}
			handleChange={handleChange}
		/>
	);
	return (
		<>
			{displaySpinner ? (
				<center>
					<Spinner displaySpinner={displaySpinner} />
				</center>
			) : null}
			{bannerShow ? (
				<main className={`${classes.content}`} style={rootStyle}>
					{shuffledInvestments.length > 0 && (
						<div
							style={{
								maxWidth: '100%',
								padding: '0px 12px',
							}}
							id={`slider`}
						>
							{state.userId.length > 0 && sliderElement}
						</div>)}
					{areInvestmentsPresent &&
						state.userId.length > 0 &&
						Object.values(shuffledInvestments)
							.filter((i) => i !== true)
							.slice(0, slicer)
							.map(function (investment, index) {
								return (
									<AdCard
										key={investment.id}
										investmentPlatform={investment}
										embedOnclick={null}
										expandable={false}
										w={w}
										h={h}
									/>
								);
							})}
				</main>
			) : (
				<LeftNavbarWrapper
					utmParams={{ utm_campaign, utm_medium, utm_source }}
					expandable={true}
					paidOnly={isPaidOnly(utm_campaign)}
				/>
			)}
		</>
	);
};
