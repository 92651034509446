import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import Header from './Header';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import IconButton from '@material-ui/core/IconButton';
import VizSensor from 'react-visibility-sensor';
import ReactGA from 'react-ga';
import { trackEvent, eventTracker, getFullPlatformUrl } from '../../utils';
import { useSelector, shallowEqual } from 'react-redux';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles({
	card: {
		width: 1129,
		maxWidth: '100%',
		margin: 'auto',
		marginBottom: '12px',
		boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
		position: 'relative',
		borderRadius: '8px',
		'&:hover': { cursor: 'pointer' },
		'&:hover  $expandIcon': {
			display: 'block',
		},
	},
	premiumCard: {
		border: '1px solid #F3B81F',
	},
	expandIcon: {
		position: 'absolute',
		top: '12px',
		right: '12px',
	},
});

export default function TradingPlatformCollapsed(props) {
	const { investmentPlatform } = props;
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const classes = useStyles();
	const history = useHistory();
	const { url: currentUrl } = useRouteMatch();

	const platformUrl = `${currentUrl}/${investmentPlatform.slug}`;
	const fullPlatformUrl = getFullPlatformUrl(`${platformUrl}`);

	function onCardClick() {
		history.push(platformUrl);
	}

	return (
		<VizSensor
			onChange={(isVisible) => {
				trackEvent('PlatformImpression', 'platform', investmentPlatform.name);
				eventTracker("PlatformImpression", investmentPlatform.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
			}}
		>
			<Card
				className={`mainCard ${classes.card} ${
					investmentPlatform.premium > 0 ? classes.premiumCard : null
				}`}
				onClick={onCardClick}
			>
				<IconButton className={classes.expandIcon} aria-label="Expand">
					<KeyboardArrowDownIcon />
				</IconButton>

				<Header
					investmentPlatform={investmentPlatform}
					platformUrl={fullPlatformUrl}
				/>
			</Card>
		</VizSensor>
	);
}
