import React from 'react';
import Rating from '@material-ui/lab/Rating';

import makeStyles from '@material-ui/core/styles/makeStyles';

const ratings = [1, 2, 3, 4, 5];

const size = (props) => `${props.size || 14}px`;
const cursor = (props) => (props.editable ? 'pointer' : 'default');
const useStyles = makeStyles({
	rating: {
		display: 'inline-flex',
	},
	golden: { color: '#F3B81F', fontSize: size, cursor },
	gray: { color: '#DBE0E6', fontSize: size, cursor },
});

export default function Ratings({ value, className, size, editable, onChange }) {
	const classes = useStyles({ size, editable });
	const [hoveredValue, setHoveredValue] = React.useState(null);
	const [currentValue, setCurrentValue] = React.useState(value);
	const displayedValue = (editable && hoveredValue) || value;

	const handleRatingChange = (newValue) => {
		//console.log(newValue);
		onChange(newValue);
		setCurrentValue(newValue);
	};

	return (
		<div className={`${classes.rating} ${className}`}>
			<Rating
				name="read-only"
				value={currentValue}
				readOnly={!editable}
				onChange={(event, newValue) => handleRatingChange(newValue)}
				precision={editable ? 1 : 0.5}
				size="small"
			/>
			{/*ratings.map((rating) => (
				<StarIcon
					key={rating}
					className={rating <= displayedValue ? classes.golden : classes.gray}
					{...(editable ? makeEditableRatingProps(rating) : {})}
				/>
            ))*/}
		</div>
	);
}
