import makeStyles from "@material-ui/core/styles/makeStyles";

export const useCommonStyles = makeStyles(theme => ({
    root: {
        padding: "0 24px 24px 24px",
    },
    heading: {
        fontSize: "18px",
        lineHeight: "32px",
        color: "#30333E",
        marginTop: "20px",
    },
    text: {
        fontSize: "14px",
        lineHeight: "20px",
        color: "#30333E",
    },
    textField: {
        width: "100%",
    },
    submitButtonContainer: {
        textAlign: "right",
        marginTop: "24px",

        "& .Mui-disabled": {
            backgroundColor: "#C5C8D1 !important",
            color: "#FFFFFF",
        },
    },
    [theme.breakpoints.down("xs")]: {
        root: {
            padding: "0 16px 24px 16px",
        },
    },
}));
