import React, { useEffect, useState, lazy } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import { routeNames, deepDiveDelimiters } from '../../data/Data';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});
const useStyles = makeStyles((theme) => ({
    card: {
        marginTop: '32px',
        background: '#FFFFFF',
        border: '1px solid #F2F5F8',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
        borderRadius: '8px',
        padding: '18px 0 18px 18px',
        [theme.breakpoints.down('460')]: {
            padding: '12px',
        },
    },

    cardProsContainer: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('960')]: {
            flexDirection: 'column',
        },
    },
    cardPros: {
        maxWidth: '390px',
        width: '100%',
    },
    cardRight: {
        marginLeft: '20px',
        [theme.breakpoints.down('960')]: {
            marginLeft: '0px',
        },
    },
    prosConsTitle: {
        fontFamily: 'Red Hat Display',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '28px',
        lineHeight: '40px',
        marginLeft: '10px',
    },
    green: {
        color: '#33CD71',
    },
    red: {
        color: '#F04362',
    },
    hl: {
        width: '334px',
        maxWidth: '100%',
        height: '1px',
        background: '#F2F5F8',
        marginTop: '8px',
        marginBottom: '19px',
        marginLeft: '10px',
    },

    title: {
        fontFamily: 'Red Hat Display',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '28px',
        lineHeight: '40px',
        color: '#30333E',
        marginLeft: '12px',
        [theme.breakpoints.down('500')]: {
            textAlign: 'center',
            fontSize: '32px',
            lineHeight: '44px',
        },
    },
}));
export const ProsCons = (props) => {
    const {
		investmentAdvanced,
	} = props;
    const classes = useStyles();

	return (
        <div className={`${classes.cardProsContainer} prosCons`}>
            {investmentAdvanced !== null && investmentAdvanced.map((i, index) => {
                const prosCons = [];
                if (i['type'] === deepDiveDelimiters.prosConsType) {
                    if (i['Pros']) {
                        prosCons.push(
                            <div className={`${classes.card} ${classes.cardPros}`} key={`prosCons_Pros_${index}`}>
                                <div className={`${classes.prosConsTitle} ${classes.green}`} >
                                    Pros
                                </div>
                                <div className={`${classes.hl}`}></div>
                                <ul >
                                {i['Pros'].map((i, index) => {
                                    return (<li key={`Pros_${index}`}>{i}</li>);
                                })}
                                </ul>
                            </div>
                        );
                    }
                    if (i['Cons']) {
                        prosCons.push(
                            <div className={`${classes.card} ${classes.cardPros} ${classes.cardRight}`} key={`prosCons_Cons_${index}`}>
                                <div className={`${classes.prosConsTitle} ${classes.red}`} >
                                    Cons
                                </div>
                                <div  className={`${classes.hl}`}></div>
                                <ul>
                                {i['Cons'].map((i, index) => {
                                    return (<li key={`Cons_${index}`}>{i}</li>);
                                })}
                                </ul>
                            </div>
                        );
                    }
                    return prosCons;
                }
            })}
        </div>
	);
};
