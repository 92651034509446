import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import AppBarRes from './AppBar';
import ReactGA from 'react-ga';
import { isEmbeded, isPaidOnly } from '../../utils';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { LogoComponent } from '../Logo/LogoComponent';
import openedIcon from '../../img/icons/opened.svg';
import cmenuIcon from '../../img/icons/normal.svg';
import searchIcon from '../../img/icons/search.svg';
import starOutline from '../../img/dashboard/starOutline.svg';
import ArrowDropUp from '@material-ui/icons/KeyboardArrowUp';
import { SignUpButton } from '../Buttons/SignUpButton';
import * as actions from '../../redux/actions';
import { UserAvatar } from '../Avatar/Avatar';
import { useDispatch } from 'react-redux';
import { useSelector, shallowEqual } from 'react-redux';
import Badge from '@material-ui/core/Badge';
import { useHistory } from 'react-router-dom';
import { routeNames } from '../../data/Data';
import { loginUser, checkAuth, getToken } from '../../Firebase/firebase-service';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles((theme) => ({
	appBar: {
		[theme.breakpoints.up('sm')]: {
			zIndex: 9999,
		},
	},
	toolbar: theme.mixins.toolbar,
	toolbar4Banner: {
		minHeight: 50,
		[theme.breakpoints.up('xs')]: {
			minHeight: 50,
		},
		[theme.breakpoints.up('sm')]: {
			minHeight: 50,
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	/*menu: {
		[theme.breakpoints.up('1145')]: {
			display: 'none',
		},
		[theme.breakpoints.down('1145')]: {
			width: '100%',
			textAlign: 'right'
        },
	},*/
	rightPanel: {
		marginLeft: 'auto',
		display: 'flex',
	},
	menu: {
		display: 'flex',
		alignItems: 'center',
	},
	hide: {
		[theme.breakpoints.up('1145')]: {
			display: 'none',
		},
	},
	badge: {
		cursor: 'pointer',
		marginRight: '20px',
	},
}));

export const AppBarComponent = (props) => {
	const {
		medium,
		showMenu: showMenuProp,
		paidOnly,
		expandable,
		handleDrawerToggle,
		expandableBannerShow,
		handleShowSearch,
	} = props;
	const classes = useStyles();
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const dispatch = useDispatch();
	const history = useHistory();

	const [showMenu, setshowMenu] = useState(showMenuProp);

	const handleClickOpenModalSignUp = () => {
		dispatch(actions.setModalSignUpOpen(true));
	};
	const [showSignUp, setShowSignUp] = useState(true);
	const [checkingToken, setCheckingToken] = useState(true);

	// check is user logged in
	useEffect(() => {
		checkAuth(callbackAuth);
	}, []);

	const callbackAuth = async (currentUser) => {
		if (currentUser !== null) {
			const token = await currentUser.getIdToken();
			dispatch(actions.setToken(token));
			setShowSignUp(false);
			dispatch(actions.setLoggedInStatus(true));
			setCheckingToken(false);
		} else {
			setCheckingToken(false);
			setShowSignUp(true);
			dispatch(actions.setLoggedInStatus(false));
		}
	};
	const handleClick = () => {
		history.push(`/${routeNames.dashboard}`);
	};

	return (
		<>
			<AppBar position="fixed" className={classes.appBar}>
				<Toolbar>
					{!isEmbeded(medium, 'embed') && !isEmbeded(medium, 'embed_single') ? (
						<>
							<IconButton
								color="inherit"
								aria-label="open drawer"
								edge="start"
								onClick={handleDrawerToggle}
								className={`${classes.menuButton} hideMob`}
							>
								<MenuIcon />
							</IconButton>
							<LogoComponent />
						</>
					) : null}
					<AppBarRes showMenu={showMenu} />

					<div className={classes.rightPanel}>
						{!isEmbeded(medium, 'embed') &&
						!isEmbeded(medium, 'embed_single') &&
						!paidOnly &&
						!expandable ? (
							<>
								<div className={`${classes.menu}`}>
									{handleShowSearch && (
										<img
											className={`logo hideWeb ${classes.hide}`}
											style={{ marginRight: '20px' }}
											onClick={handleShowSearch}
											src={searchIcon}
											alt="SearchIcon"
										/>
									)}
									{(state.token && !checkingToken) || !checkingToken ? (
										showSignUp ? (
											<SignUpButton
												type="white"
												handleClick={handleClickOpenModalSignUp}
											/>
										) : (
											<>
												<div className={classes.badge} onClick={handleClick}>
													<Badge
														badgeContent={state.favoritesInvestments.length}
														color="primary"
													>
														<img src={starOutline} alt="badge favorites" />
													</Badge>
												</div>
												<UserAvatar name={state.userName} />
											</>
										)
									) : null}
									<img
										className={`logo hideWeb ${classes.hide}`}
										onClick={() => setshowMenu(!showMenu)}
										src={showMenu ? openedIcon : cmenuIcon}
										alt="HamburgerMenu"
									/>
								</div>
							</>
						) : (
							<div className={classes.menu}>
								<IconButton
									className={classes.expandIcon}
									aria-label="Collapse"
									onClick={expandableBannerShow}
								>
									<ArrowDropUp />
								</IconButton>
							</div>
						)}
					</div>
				</Toolbar>
			</AppBar>
		</>
	);
};
