import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import { HeaderInvestCollapsed } from './HeaderInvestCollapsed';
import CardActions from '@material-ui/core/CardActions';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowDown';
import Button from '@material-ui/core/Button';
import VizSensor from 'react-visibility-sensor';
import { ObserverSensor } from '../ObserverSensor/ObserverSensor';
import ReactGA from 'react-ga';
import { eventTracker, trackEvent, generateSubId, getDiscoveryPageName, getFullPlatformUrl } from '../../utils';
import { useSelector, shallowEqual } from 'react-redux';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles({
	card: {
		'&:hover': { cursor: 'pointer' },
		'&:hover  $expandIcon': {
			display: 'block',
		},
		width: 1200,
		maxWidth: '100%',
		margin: 'auto',
		marginBottom: '12px',
		boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
		position: 'relative',
		borderRadius: '8px',
	},
	premiumCard: {
		border: '1px solid #F3B81F',
	},
	expandIcon: {
		position: 'absolute',
		top: '12px',
		right: '12px',
		background: '#323A56',
		'&:hover': {
			backgroundColor: '#323A56',
		},
		color: '#fff',
		borderRadius: '100px',
		textTransform: 'none',
		padding: '6px 16px',
	},
	moreButton: {
		textTransform: 'none',
		color: '#73757A',
		fontFamily: 'Inter',
		fontSize: '14px',
	},
	cardAction: {
		borderTop: '1px solid #F2F5F8',
		padding: '0px',
	},
});

export const InvestCardCollapsed = (props) => {
	const {
		investmentPlatform,
		calculator,
		embedOnclick,
		expandable,
		setActivePlatform,
		cluster,
	} = props;
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const classes = useStyles();
	const [source, setSource] = React.useState(state.itemSource || 'MoneyMade');
	const history = useHistory();
	const { url: currentUrl } = useRouteMatch();

	const platformUrl = getDiscoveryPageName(expandable, investmentPlatform.slug);
	const fullPlatformUrl = getFullPlatformUrl(platformUrl);

	function stopPropagation(event) {
		trackEvent('ExternalClick', 'platform', investmentPlatform.name);
		eventTracker("ExternalClick", investmentPlatform.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
		event.stopPropagation();
	}

	const onCardClick = async () => {
		if (embedOnclick) {
			embedOnclick();
		} else {
			ReactGA.pageview(platformUrl);
			eventTracker("pageview", platformUrl, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);

			await setActivePlatform(investmentPlatform);
			let element = document.getElementById(
				`platform_ex_${cluster ? `${cluster}_` : null}${investmentPlatform.id}`
			);
			element.scrollIntoView({
				behavior: 'smooth',
			});
		}
	};
	useEffect(() => {});
	return (
		<VizSensor
			onChange={(isVisible) => {
				trackEvent('PlatformImpression', 'platform', investmentPlatform.name);
				eventTracker("PlatformImpression", investmentPlatform.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
			}}
		>
			<ObserverSensor
				 id={`cardCollapsed_${investmentPlatform.id}`}
				 onMouseOver={() => {
					trackEvent('PlatformMouseOver', 'platform', investmentPlatform.name);
					eventTracker("PlatformMouseOver", investmentPlatform.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
				 }}
				 onScroll={() => {
					trackEvent('PlatformScroll', 'platform', investmentPlatform.name);
					eventTracker("PlatformScroll", investmentPlatform.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
				 }}
			>
				<Card
					className={`mainCard ${classes.card} ${
						investmentPlatform.premium > 0 ? classes.premiumCard : null
					}`}
					onClick={onCardClick}
				>
					<HeaderInvestCollapsed
						investmentPlatform={investmentPlatform}
						platformUrl={fullPlatformUrl}
					/>
					<CardActions className={classes.cardAction}>
						<Button
							endIcon={<KeyboardArrowRightIcon />}
							fullWidth={true}
							className={classes.moreButton}
						>
							See details
						</Button>
					</CardActions>
					<Button
						className={classes.expandIcon}
						target="_blank"
						rel="noopener noreferrer"
						href={
							investmentPlatform.website
								? investmentPlatform.website.replace('%SUBID%', generateSubId(state.itemSource, state.itemMedium))
								: '/discover'
						}
						onClick={stopPropagation}
					>
						More
					</Button>
				</Card>
			</ObserverSensor>
		</VizSensor>
	);
};
