import React from 'react';
import { useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import BannerBg from '../../img/lpbgs/calculator-banner-bg.png';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import * as actions from '../../redux/actions';

const useStyles = makeStyles((theme) => ({
	card: {
		width: 1200,
		height: 56,
		maxWidth: '100%',
		margin: 'auto',
		marginBottom: '12px',
		boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
		position: 'relative',
		borderRadius: '8px',
		cursor: 'pointer',
		background: '#5056E7',
		backgroundImage: `url(${BannerBg})`,
		backgroundRepeat: 'no-repeat',
	},

	item: {
		[theme.breakpoints.down('xs')]: {
			minWidth: '30% !important',
		},
		[theme.breakpoints.down('440')]: {
			minWidth: '30% !important',
		},
	},
	title: {
		marginLeft: '58px',
		fontSize: '22px',
		lineHeight: '15px',
		fontWeight: 500,
		color: '#ffffff',

		[theme.breakpoints.down('xs')]: {
			lineHeight: '20px',
			fontSize: '17px',
			fontWeight: 500,
		},
		[theme.breakpoints.up('sm')]: {
			lineHeight: '10px',
			fontSize: '24px',
		},
		[theme.breakpoints.up('lg')]: {
			lineHeight: '10px',
			fontSize: '22px',
		},
		[theme.breakpoints.down('436')]: {
			marginLeft: '18px',
			lineHeight: '25px',
			fontSize: '15px',
			fontWeight: 500,
		},
		[theme.breakpoints.down('368')]: {
			marginLeft: '18px',
			lineHeight: '25px',
			fontSize: '14px',
			fontWeight: 500,
		},
	},
	subTitle: {
		marginLeft: '58px',
		fontSize: '16px',
		lineHeight: '24px',
		fontWeight: 500,
		color: '#ffffff',
		[theme.breakpoints.down('436')]: {
			marginLeft: '18px',
			lineHeight: '13px',
			fontSize: '12px',
			fontWeight: 500,
		},
		[theme.breakpoints.down('xs')]: {
			lineHeight: '13px',
			fontSize: '12px',
		},
		[theme.breakpoints.up('sm')]: {
			lineHeight: '18px',
			fontSize: '12px',
		},
		[theme.breakpoints.up('lg')]: {
			lineHeight: '20px',
			fontSize: '16px',
		},
	},
	button: {
		position: 'absolute',
		right: '20px',
		fontSize: '16px',
		marginTop: '10px',
		fontWeight: '500',
		color: '#5056E7',
		background: '#ffffff',
		textTransform: 'none',
		padding: '4px 16px',
		[theme.breakpoints.down('xs')]: {
			marginTop: '14px',
			fontSize: '12px',
			marginLeft: '24px',
		},
		'&:hover': {
			backgroundColor: '#F2F5F8',
		},
	},
}));

export default function CalculatorBanner(props) {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();

	function onCardClick() {
		if (!props.embed) {
			history.push('/calculator');
		} else {
			props.openCalculator();
		}
	}
	const handleOpen = () => {
		if (!props.embed) {
			dispatch(actions.setModalOpen(true));
		} else {
			props.openCalculator();
		}
	};

	return (
		<Card
			direction="row"
			className={`mainCard ${classes.card}`}
			justify="center"
			//onClick={onCardClick}
			onClick={handleOpen}
		>
			<Grid container>
				<Grid item xs={8} lg={10} className={classes.item}>
					<h3 className={classes.title}>Take the Investor Quiz</h3>
				</Grid>
				<Grid item xs={4} lg={2} className={classes.item}>
					<Button
						className={classes.button}
						//onClick={onCardClick}
						//onClick={handleOpen}
						color="primary"
					>
						Let's Go
					</Button>
				</Grid>
			</Grid>
		</Card>
	);
}
