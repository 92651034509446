import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import SimilarHeader from './SimilarHeader';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import IconButton from '@material-ui/core/IconButton';
import { eventTracker, delay, getFullPlatformUrl } from '../../utils';
import { useSelector, shallowEqual } from 'react-redux';
import { routeNames, view } from '../../data/Data';

import ReactGA from 'react-ga';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles({
	card: {
		width: 350,
		height: 172,
		maxWidth: '100%',
		marginRight: '15px',
		marginBottom: '12px',
		boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
		position: 'relative',
		borderRadius: '8px',
		cursor: 'pointer',
	},
	premiumCard: {
		border: '1px solid #F3B81F',
	},
	expandIcon: {
		position: 'absolute',
		top: '12px',
		right: '12px',
	},
});

export default function SimilarPlatform(props) {
	const {
		investmentPlatform,
		highlighted,
		expanded,
		setActivePlatform,
		cluster,
		resetTotal,
		search,
		setActive,
		deepDive,
		handleMoveToListView,
		isListView,
	} = props;
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const classes = useStyles();
	const history = useHistory();
	const { url: currentUrl } = useRouteMatch();

	const routeName = currentUrl.indexOf(routeNames.expandableDiscovery) > -1 ? routeNames.expandableDiscovery : routeNames.discover;
	const platformUrl = `${routeName}/${investmentPlatform.slug}`;
	const fullPlatformUrl = getFullPlatformUrl(`${platformUrl}`);

	function onCardClick() {
		if (!highlighted) {
			//let element = document.getElementById('modal');
			//element.scrollIntoView(true);
		}

		ReactGA.pageview(platformUrl);
		eventTracker("pageview", platformUrl, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
		history.push(`/${platformUrl}`);
	}

	const onCardClickExpanded = async () => {
		if (deepDive) {
			setActivePlatform(investmentPlatform);
			return;
		}
		setActivePlatform(null);
		resetTotal();
		if (search !== '') {
			await delay(1000);
		}
		if (isListView !== view.list) {
			handleMoveToListView(view.list);
			await delay(1000);
		}
		await setActivePlatform(investmentPlatform);
		let element = document.getElementById(
			`platform_ex_${cluster ? `${cluster}_` : null}${investmentPlatform.id}`
		);
		element.scrollIntoView({
			behavior: 'smooth',
		});

		ReactGA.pageview(platformUrl);
		eventTracker("pageview", platformUrl, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
	};

	return (
		<Card
			className={`mainCard ${classes.card} ${
				investmentPlatform.premium > 0 ? classes.premiumCard : null
			}`}
			onClick={!expanded ? onCardClick : onCardClickExpanded}
		>
			<IconButton className={classes.expandIcon} aria-label="Expand">
				<KeyboardArrowRightIcon />
			</IconButton>

			<SimilarHeader
				investmentPlatform={investmentPlatform}
				platformUrl={fullPlatformUrl}
			/>
		</Card>
	);
}
