import React from 'react';
import queryString from 'query-string';
import ReactGA from 'react-ga';
import { eventTracker, isPaidOnly, isEmbeded, disableMailChimp } from '../utils';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as actions from '../redux/actions';
import { QASessionPage } from '../components/QASession/QASessionPage';
import { routeNames } from '../data/Data';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const QASession = (props) => {
	const { utm_campaign, utm_medium, utm_source } = queryString.parse(
		props.location.search
	);
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const dispatch = useDispatch();
	React.useEffect(() => {
		if (state.userId) {
			ReactGA.pageview(`/${routeNames.qaSession}`);
			eventTracker("pageview", `/${routeNames.qaSession}`, utm_source, utm_medium, utm_campaign, state.userId);
		}
		if (utm_source) {
			dispatch(actions.setItemSource(utm_source));
		}
		if (utm_campaign) {
			dispatch(actions.setItemCompaign(utm_campaign));
		}
		if (utm_medium) {
			dispatch(actions.setItemMedium(utm_medium));
		}
	}, [utm_campaign, utm_medium, utm_source, state.userId]);

	return (
		<div>
			<QASessionPage />
		</div>
	);
};
export default QASession;
