import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import InvestmentPlatformOverview from '../InvestmentPlatform/Overview';
import { HeaderInvestExpanded } from './HeaderInvestExpanded';
import Sidebar from '../InvestmentPlatform/Sidebar';
import { trackEvent, eventTracker } from '../../utils';
import { useSelector, shallowEqual } from 'react-redux';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles((theme) => ({
	mainModal: {
		width: 1200,
		maxWidth: '100%',
		margin: 'auto',
		marginBottom: '12px',
		paddingBottom: '10px',
		boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
		position: 'relative',
		borderRadius: '8px',
	},
	anchor: {
		display: 'block',
		position: 'relative',
		top: '-65px',
		visibility: 'hidden',
	},
	expandIcon: {
		position: 'absolute',
		top: '12px',
		right: '12px',
	},
	websiteIcon: {
		position: 'absolute',
		top: '12px',
		right: '50px',
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	premium: {
		border: '1px solid #F3B81F',
	},

	createReviewModal: {
		maxWidth: '553px',
		width: '100%',
		backgroundColor: '#FFF',
	},
	innerCards: {
		display: 'flex',
		alignItems: 'flex-start',
		padding: '0 8px 0 62px',
	},
	innerCard: {
		border: '1px solid #F2F5F8',
		borderRadius: '8px',
		backgroundColor: '#fff',
		padding: 0,
		marginRight: '8px',

		'& .MuiCardContent-root:last-child': {
			padding: '0 !important',
		},
	},
	tabsCard: {
		width: '70%',
		paddingTop: '16px',
	},
	sidebarCard: {
		width: '30%',
		//padding: '24px',
	},
	[theme.breakpoints.down('sm')]: {
		innerCards: {
			flexDirection: 'column',
			padding: '0 16px',
		},
		innerCard: {
			margin: '0 0 20px 0',
		},
		tabsCard: { width: '100%' },
		sidebarCard: { width: '100%' },
	},
}));

const modals = {
	MAIN: 'main',
	CREATE_REVIEW: 'create_review',
};

export const InvestCardExpanded = ({
	investmentPlatform,
	similarInvestments,
	setActivePlatform,
	embed,
	cluster,
}) => {
	const classes = useStyles();
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const [visibleModal, setVisibleModal] = React.useState(modals.MAIN);
	const [isWaitingForReview, setIsWaitingForReview] = React.useState(false);
	const [source, setSource] = React.useState(state.itemSource || 'MoneyMade');

	const openCreateReviewForm = () => setVisibleModal(modals.CREATE_REVIEW);
	const openMainModal = () => setVisibleModal(modals.MAIN);

	useEffect(() => {
		trackEvent('PlatformExpandedImpression', 'platform', investmentPlatform.name);
	});
	function stopPropagation(event) {
		trackEvent('ExternalClick', 'platform', investmentPlatform.name);
		eventTracker("ExternalClick", investmentPlatform.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
		event.stopPropagation();
	}

	return (
		<>
			<div
				className={`${classes.mainModal} mainCard ${
					investmentPlatform.premium > 0 ? classes.premium : null
				}`}
			>
				<a
					id={`platform_ex_${cluster ? `${cluster}_` : null}${
						investmentPlatform.id
					}`}
					className={classes.anchor}
				/>
				<IconButton
					className={classes.expandIcon}
					onClick={() => setActivePlatform(null)}
					aria-label="Expand"
					color="primary"
				>
					<KeyboardArrowUpIcon />
				</IconButton>

				<HeaderInvestExpanded
					investmentPlatform={investmentPlatform}
					platformUrl={window.location.href}
					expandable={true}
					expanded={true}
				/>
				<div className={classes.innerCards}>
					<div className={`${classes.innerCard} ${classes.tabsCard}`}>
						<InvestmentPlatformOverview investmentPlatform={investmentPlatform} />
					</div>

					<div className={`${classes.innerCard} ${classes.sidebarCard}`}>
						<div style={{ padding: '24px 24px 16px 24px' }}>
							<Sidebar
								investmentPlatform={investmentPlatform}
								color="2"
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
