import { REACT_APP_GET_TYPES_URL } from "./ConstAPI";

const makeURL = () => `${REACT_APP_GET_TYPES_URL}`;

const makeRequestData = (data) => ({
    method: "GET",
    headers: {
        "Content-Type" : "application/json",
    },
});

const makeRequest = async () => {
    const url = makeURL();
    const requestData = makeRequestData();

    return await fetch(url, requestData);
};
/**
 * @param {string} lastId
 * @param {string} platformId
 */
export const getTypes = async () => {
    try {
		const response = await makeRequest();
        const responseStatus = await response.json();
        if (response.status === 200) {
            return {
                success: true,
                result: responseStatus,
                message: responseStatus,
            }
        } else {
            return {
                success: false,
                result: [],
                message: responseStatus,
            };
        }
	} catch (error) {
        console.log(error);
		return {
			success: false,
			result: [],
			message: error,
        };
	}
};
