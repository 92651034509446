import React, { useEffect } from 'react';
import Card from '@material-ui/core/Card';
import Header from './HeaderShort';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowDown';
import Button from '@material-ui/core/Button';
import VizSensor from 'react-visibility-sensor';
import { ObserverSensor } from '../ObserverSensor/ObserverSensor';
import ReactGA from 'react-ga';
import { eventTracker, trackEvent, generateSubId, getFullPlatformUrl, getDiscoveryPageName } from '../../utils';
import { useSelector, shallowEqual } from 'react-redux';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles((theme) => ({
	card: {
		'&:hover': { cursor: 'pointer' },
		'&:hover  $expandIcon': {
			display: 'block',
		},
		width: 1200,
		maxWidth: '100%',
		margin: 'auto',
		marginBottom: '12px',
		boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
		position: 'relative',
		borderRadius: '8px',
	},
	premiumCard: {
		border: '1px solid #F3B81F',
	},
	expandIcon: {
		position: 'absolute',
		top: '12px',
		right: '12px',
		background: '#5056E7',
		'&:hover': {
			backgroundColor: '#646AF0',
		},
		borderRadius: '100px',
		color: '#fff',
		padding: '6px 16px',
		textTransform: 'none',
		[theme.breakpoints.down('519')]: {
			padding: '6px',
			minWidth: '30px',
		},

		[theme.breakpoints.down('445')]: {
			padding: '6px 16px',
			minWidth: '64px',
		},
	},
}));

export default function InvestmentPlatformCollapsed(props) {
	const {
		investmentPlatform,
		calculator,
		embedOnclick,
		expandable,
		setActivePlatform,
		cluster,
	} = props;
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const classes = useStyles();
	const [source, setSource] = React.useState(state.itemSource || 'MoneyMade');

	const platformUrl = getDiscoveryPageName(expandable, investmentPlatform.slug);
	const fullPlatformUrl = getFullPlatformUrl(`${platformUrl}`);

	function stopPropagation(event) {
		trackEvent('ExternalClick', 'platform', investmentPlatform.name);
		eventTracker("ExternalClick", investmentPlatform.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);

		event.stopPropagation();
	}

	const onCardClick = async () => {
		if (embedOnclick) {
			embedOnclick();
		} else {
			ReactGA.pageview(platformUrl);
			eventTracker("pageview", platformUrl, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);

			await setActivePlatform(investmentPlatform);
			let element = document.getElementById(
				`platform_ex_${cluster ? `${cluster}_` : null}${investmentPlatform.id}`
			);
			element.scrollIntoView({
				behavior: 'smooth',
			});
		}
	};

	useEffect(() => {});
	//	console.log(fullPlatformUrl);

	return (
		<VizSensor
			onChange={(isVisible) => {
				trackEvent('PlatformImpression', 'platform', investmentPlatform.name);
				eventTracker("PlatformImpression", investmentPlatform.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
			}}
		>
			<ObserverSensor
				 id={`cardCollapsed_${investmentPlatform.id}`}
				 onMouseOver={() => {
					trackEvent('PlatformMouseOver', 'platform', investmentPlatform.name);
					eventTracker("PlatformMouseOver", investmentPlatform.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
				 }}
				 onScroll={() => {
					trackEvent('PlatformScroll', 'platform', investmentPlatform.name);
					eventTracker("PlatformScroll", investmentPlatform.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
				 }}
			>
			<Card
				className={`mainCard ${classes.card} ${
					investmentPlatform.premium > 0 ? classes.premiumCard : null
				}`}
				onClick={onCardClick}
			>
				<Header
					investmentPlatform={investmentPlatform}
					platformUrl={fullPlatformUrl}
				/>
				<Button
					color="primary"
					className={classes.expandIcon}
					target="_blank"
					rel="noopener noreferrer"
					href={
						investmentPlatform.website
							? investmentPlatform.website.replace('%SUBID%', generateSubId(state.itemSource, state.itemMedium))
							: '/discover'
					}
					onClick={stopPropagation}
				>
					{window.innerWidth > 519 || window.innerWidth <= 444 ? (
						'More'
					) : (
						<KeyboardArrowRightIcon />
					)}
				</Button>
			</Card>
			</ObserverSensor>
		</VizSensor>
	);
}
