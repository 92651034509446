import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import { FBSendEvents, GASendEvent,	trackEvent,	isPaidOnly } from '../../utils';
import { SelectedPlatforms } from './Favorites/SelectedPlatforms';
import { RecommendedPlatforms } from './Favorites/RecommendedPlatforms';
import { Navigation } from './Navigation';
import { ReviewsCard } from './Reviews/ReviewsCard';
import { NewPlatforms } from './New/NewPlatforms';
import { getFavorite } from '../../Services/GetFavoriteAPI';
import { checkUser } from '../../Services/CheckUserAPI';
import { routeNames } from '../../data/Data';
import * as actions from '../../redux/actions';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { ModalView as ModalQuiz } from '../Modal/Modal';
import { loginUser, checkAuth, getToken } from '../../Firebase/firebase-service';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles((theme) => ({
	leftPanel: {
		width: '200px',
		background: '#30333E',
		height: '100%',
		position: 'fixed',
		overflow: 'auto',
		padding: '43px 0 0 0px',
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.down('1145')]: {
			width: '100%',
			height: 'inherit',
			padding: '0',
			display: 'flex',
			overflow: 'hidden',
			position: 'fixed',
			background: '#30333E',
			flexDirection: 'column',
			zIndex: '1100',
		},
	},
	rightPanel: {
		position: 'relative',
		left: '200px',
		overflow: 'auto',
		background: '#fbfcfd',
		height: '100%',
		width: 'calc(100% - 200px)',
		[theme.breakpoints.down('1145')]: {
			position: 'inherit',
			top: '0px',
			left: '0px',
			width: '100%',
			paddingTop: '40px',
		},
	},
}));
export const DashboardComponent = (props) => {
	const { utmParams, expandable, expandableBannerShow } = props;
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const [openModal, setOpenModal] = useState(state.modalOpen);
	const [navigation, setNavigation] = useState(2);
	const [paidOnly, setPaidOnly] = useState(isPaidOnly(state.itemCompaign));
	const [medium, setMedium] = useState(state.itemMedium);
	const [displaySpinner, setDisplaySpinner] = useState(true);

	const [recommendedPlatforms, setRecommendedPlatforms] = useState({});
	const [reviews, setReviews] = useState([]);

	const handleLoadRecommended = (value) => {
		setRecommendedPlatforms(value);
	};
	const handleLoadReviews = (value) => {
		setReviews(value);
	};

	useEffect(() => {
		if (state.modalOpen) {
			setOpenModal(true);
		} else {
			setOpenModal(false);
		}
	}, [state.modalOpen]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (state.itemCompaign) {
			setPaidOnly(isPaidOnly(state.itemCompaign));
		}
	}, [state.itemCompaign]);

	useEffect(() => {
		if (state.itemMedium) {
			setMedium(state.itemMedium);
		}
	}, [state.itemMedium]);

	//check is user Logged In
	useEffect(() => {
		if (state.loggedIn) {
			getFavorite(state.token)
				.then((data) => {
					dispatch(actions.addInvesmentsToFavorites(data.result));
					setDisplaySpinner(false);
				})
				.catch((error) => {
					console.log(error);
					setDisplaySpinner(false);
				});
		} else {
			setDisplaySpinner(true);
		}
	}, [state.loggedIn]);

	// check is user logged in
	useEffect(() => {
		checkAuth(callbackAuth);
	}, []);

	const callbackAuth = async (currentUser) => {
		if (currentUser !== null) {
			const token = await currentUser.getIdToken();
			dispatch(actions.setToken(token));
			dispatch(actions.setLoggedInStatus(true));
		} else {
			dispatch(actions.setLoggedInStatus(false));
			redirect();
		}
	};
	const handleClick = (number) => {
		setNavigation(number);
	};
	const redirect = () => {
		history.push(`/${routeNames.discover}`);
	};

	return (
		<>
			<div className={` ${classes.root}`}>
				<nav className={classes.leftPanel}>
					<Navigation navigation={navigation} handleClick={handleClick} />
				</nav>
				<div className={`${classes.rightPanel}`}>
					<div>
						{navigation === 1 && <div>Dashboard</div>}
						{navigation === 2 && (
							<div className={classes.favorites}>
								{
									<div>
										<SelectedPlatforms displaySpinner={displaySpinner} />
									</div>
								}
                                <div>
                                    <RecommendedPlatforms
                                        platforms={recommendedPlatforms}
                                        handleLoadRecommended={handleLoadRecommended}
                                    />
                                </div>
							</div>
						)}
						{navigation === 3 && <div>Portfolio</div>}
						{navigation === 4 && (
							<div>
								<ReviewsCard
									reviews={reviews}
									handleLoadReviews={handleLoadReviews}
								/>
							</div>
						)}
						{navigation === 5 && (
							<div>
								<NewPlatforms />
							</div>
						)}
					</div>
				</div>
			</div>
			<ModalQuiz
				open={openModal}
			/>
		</>
	);
};
