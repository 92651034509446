import ReactGA from 'react-ga';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { localStorageState, localStorageData, mobileWidth, routeNames } from '../data/Data';
import { eventTrackingAPI } from '../Services/EventTrackingAPI';
import moment from "moment";
import Tribute from "tributejs";

/**
 * Function to generate RFC4122 UUID v4
 * @returns string
 */
export const generateId = () => {
	return uuidv4();
};
/**
 * Function to detect does URL contain this value
 * @param {string} s
 * @returns boolean
 */
export const urlHas = (s) => {
	return window.location.href.indexOf(s) > -1;
};
/**
 * Function to check the enabled cookies
 * @returns {boolean} true - enabled
 * @returns {boolean} false - disabled
 */
export const cookiesEnabled = () => {
	try {
		if (typeof localStorage === 'object') {
			return true;
		}
	} catch (e) {
		//console.log(e.message);
		return false;
	}
};
/**
 * Function to generate userId
 * @returns {string} string
 */
export const generateUserId = () => {
	return generateId();
};
/**
 * Function to generate userId and put it to LocalStorage
 * @returns {string} string
 */
export const recordUserId = () => {
	let userId;
	if (cookiesEnabled) {
		userId = getStorage(localStorageData.userId);
		if (userId === null) {
			userId = generateId();
			setStorage(localStorageData.userId, userId);
		}
	} else {
		userId = generateId();
	}
	return userId;
};
/**
 * Function to get data from localStorage
 * @returns void
 */
export const loadState = () => {
	const defaultData = {
		userId: '',
		userName: '',
		token: '',
		//displayRecommended: true,
		fpSent: false,
	};
	try {
		const serializedState = localStorage.getItem(localStorageState);
		if (serializedState === null) {
			return JSON.parse(JSON.stringify(defaultData));
		} else {
			return JSON.parse(serializedState);
		}
	} catch (err) {
		catchError(err);
		return JSON.parse(JSON.stringify(defaultData));
	}
};
/**
 * Function to save data to localStorage
 * @param {string} data
 * @returns void
 */
export const saveState = (data) => {
	try {
		const serializedState = JSON.stringify(data);
		localStorage.setItem(localStorageState, serializedState);
	} catch (err) {
		catchError(err);
	}
};
/**
 * Function to log the catch error
 * @param {string} error
 * @returns void
 */
export const catchError = (error) => {
	console.log(error);
};
/**
 * Function to set Local Storage item
 * @param {string} name
 * @param {*} value
 * @returns void
 */
export const setStorage = (name, value) => {
	localStorage.setItem(name, value);
};
/**
 * Function to get Local Storage item
 * @param {string} name
 * @returns any
 */
export const getStorage = (name) => {
	return localStorage.getItem(name);
};
/**
 * Function to capitalize first symbols
 * @param {string} s
 * @example 'real estate' => 'R E'
 * @returns {string} string
 */
export const userAbbr = (s) => {
	return s
		.trim()
		.toLowerCase()
		.split(' ')
		.map((i) => i[0].toUpperCase())
		.reduce((ac, i) => `${ac}${i}`);
};
/**
 * Function to send trackingCode to Google Analitics
 * @param {string} trackingCode
 * @returns void
 */
export const GAInit = (trackingCode) => {
	ReactGA.initialize(trackingCode, {
		gaOptions: {
			cookieFlags: 'max-age=7200;secure;samesite=none',
		},
	});
};
/**
 * Function to send pageview to Google Analitics
 * @param {string} path
 * @returns void
 */
export const GASendPageView = (path) => {
	ReactGA.pageview(path);
};
/**
 * Function to send event to Google Analitics
 * @param {string} category
 * @param {string} action
 * @returns void
 */
export const GASendEvent = (category, action) => {
	ReactGA.event({
		category,
		action,
	});
};
/**
 * Function to send data to Facebook tracking or so more
 * @param {string} category
 * @param {string} actionName
 * @param {any} action
 * @returns void
 */
export const FBSendEvent = (category, actionName, action) => {
	if (window.fbq) {
		window.fbq('trackCustom', category, {
			[actionName]: action,
		});
	}
};
/**
 * Function to send FB event if it is exist
 * @param {*} category
 * @param {*} actions
 * @returns void
 */
export const FBSendEvents = (category, actions) => {
	if (window.fbq) {
		window.fbq('trackCustom', category, actions);
	}
};
/**
 * Function to track both Facebook (category, action) and Google Analitics (category, actionName, action)
 * @param {string} category
 * @param {string} actionName
 * @param {*} action
 * @returns void
 */
export const trackEvent = (category, actionName, action) => {
	GASendEvent(category, action.toString());
	FBSendEvent(category, actionName, action);
};
/**
 * Function to track event
 * @param {string} category
 * @param {string} name
 * @param {string} itemSource
 * @param {string} itemMedium
 * @param {string} itemCompaign
 * @param {string} userId
 * @returns void
 */
export const eventTracker = (category, name, itemSource, itemMedium, itemCompaign, userId) => {
	eventTrackingAPI(
		category,
		name,
		itemSource,
		itemMedium,
		itemCompaign,
		userId
	);
}
/**
 * Function to return array of headline items
 * @param {boolean} paidOnly
 * @returns {array} array
 */
export const getHeadLines = (paidOnly = false) => {
	return [
		'Best Investments in 2020',
		'Best Investments That Pay You Monthly and more',
		`Check out these ${paidOnly ? '25+' : '100+'} Alternative Investments`,
		'Alternative investments you should consider in 2020',
		'Compare Alternative Investments',
	];
};
/**
 * Function to return array item provided by value
 * @param {array} itemArray
 * @param {number} i
 * @returns {string} string
 */
export const getSample = (itemArray, i) => {
	return _.sample(itemArray, i);
};
/**
 * Function to filter the data
 * @param {pbject} data
 * @param {function} func
 */
export const filter = (data, func) => {
	return _.filter(data, func);
};
/**
 * Function to return shuffled array
 * @param {array} itemArray
 * @returns {array} []
 */
export const getShuffle = (itemArray) => {
	return _.shuffle(itemArray);
};
/**
 * Function to check is current object empty
 * @param {object} obj
 * @returns {boolean} boolean
 */
export const isObjectEmpty = (obj) => {
	return Object.values(obj).length !== 0;
};
/**
 * Function to check is array empty
 * @param {array} ar
 * @returns {boolean} boolean
 */
export const isArrayEmpty = (ar) => {
	return ar.length !== 0;
};
/**
 * Function to check is object undefined
 * @param {object} obj
 * @returns {boolean} boolean
 */
export const isUndefined = (obj) => {
	return obj === undefined ? true : false;
};
/**
 * Function to check is object empty or undefined
 * @param {object} obj
 * @returns {boolean} boolean
 */
export const isObjectEmptyAndUndefined = (obj) => {
	if (!isUndefined(obj)) {
		if (isObjectEmpty(obj)) {
			return false;
		} else {
			return false;
		}
	} else {
		return true;
	}
};
/**
 * Function to check is array empty or undefined
 * @param {array} ar
 * @returns {boolean} boolean
 */
export const isArrayEmptyAndUndefined = (ar) => {
	if (!isUndefined(ar)) {
		if (!isArrayEmpty(ar)) {
			return false;
		} else {
			return false;
		}
	} else {
		return true;
	}
};
/**
 * Function to check the equality of objects
 * @param {object} o1
 * @param {object} o2
 * @returns {boolean} boolean
 */
export const isObjectEqual = (o1, o2) => {
	return JSON.stringify(o1) === JSON.stringify(o2);
};
/**
 * Function to check is utm_campaign equal to 'paidOnly'
 * @param {string} utm_campaign
 * @returns {boolean} boolean
 */
export const isPaidOnly = (utm_campaign) => {
	if (typeof utm_campaign === 'string') {
		if (utm_campaign !== '') {
			return utm_campaign.toLowerCase() === 'paidOnly'.toLowerCase() ? true : false;
		}
		return false;
	} else {
		return false;
	}
};
/**
 * Function to add + sign to current value if max detects the current value state
 * @param {string} value
 * @param {number} max
 * @returns {string} string
 */
export const addPlusSign = (value, max) => {
	return value >= max ? `${value}+` : `${value}`;
};
/**
 * Function to check is the text empty
 * @param {string} text
 * @returns {boolean} boolean
 */
export const isEmpty = (text) => {
	if (typeof text === 'string') {
		if (text !== '') {
			return true;
		}
		return false;
	} else {
		return false;
	}
};
/**
 * Function to check is utm_medium equal to provided string
 * @param {string} utm_campaign
 * @param {string} embed
 * @example embed: 'embed', 'embed_single'
 * @returns {boolean} boolean
 */
export const isEmbeded = (utm_campaign, embed) => {
	if (typeof utm_campaign === 'string') {
		if (utm_campaign !== '') {
			return utm_campaign.toLowerCase() === embed.toLowerCase() ? true : false;
		}
		return false;
	} else {
		return false;
	}
};
/**
 * Function to return paid only data array
 * @param {object} data
 * @returns {array} array
 */
export const paidOnlyData = (data) => {
	return Object.values(data).filter((i) => i['paid'] === true);
};
/**
 * Function to capitalize first symbols
 * @param {string} s
 * @example 'real estate' => 'Real Estate'
 * @returns {string} string
 */
export const capitalLetters = (s) => {
	return s
		.trim()
		.toLowerCase()
		.split(' ')
		.map((i) => i[0].toUpperCase() + i.substr(1))
		.reduce((ac, i) => `${ac} ${i}`);
};
/**
 * Function to add commas to a value
 * @example '1000000' => '1,000,000'
 * @param {number} value
 * @returns {string} string
 */
export const addCommas = (val) => {
	const value = String(val);
	if (typeof value === 'string') {
		if (value !== '') {
			return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		} else {
			return '';
		}
	} else {
		return '';
	}
};
/**
 * Function to ASC sort objects in array
 * @param {object} a
 * @param {object} b
 * @param {string} prop
 * @returns {number} number
 */
export const sortAsc = (prop) => {
	return function (a, b) {
		if (a[prop] < b[prop]) {
			return -1;
		}
		if (a[prop] > b[prop]) {
			return 1;
		}
		return 0;
	};
};
/**
 * Function to DESC sort objects in array
 * @param {object} a
 * @param {object} b
 * @param {string} prop
 * @returns {number} number
 */
export const sortDesc = (prop) => {
	return function (a, b) {
		if (a[prop] < b[prop]) {
			return 1;
		}
		if (a[prop] > b[prop]) {
			return -1;
		}
		return 0;
	};
};
/**
 * Function to DESC sort objects in array
 * @param {object} a
 * @param {object} b
 * @param {string} prop
 * @returns {number} number
 */
export const sortDescTwoLevels = (prop1, prop2) => {
	return function (a, b) {
		if (a[prop1][prop2] < b[prop1][prop2]) {
			return 1;
		}
		if (a[prop1][prop2] > b[prop1][prop2]) {
			return -1;
		}
		return 0;
	};
};
/**
 * Function to ASC sort Target propery
 * @param {object} a
 * @param {object} b
 * @returns {number} number
 */
export const sortTargetAsc = (a, b) => {
	if (!a.target || !b.target === 1) {
		return 0;
	} else {
		var x =
			a.target.min === '' ? 100 : parseFloat(a.target.min);
		var y =
			b.target.min === '' ? 100 : parseFloat(b.target.min);
		return x > y ? 1 : y > x ? -1 : 0;
	}
}
/**
 * Function to DESC sort Target propery
 * @param {object} a
 * @param {object} b
 * @returns {number} number
 */
export const sortTargetDesc = (a, b) => {
	if (!a.target || !b.target === 1) {
		return 0;
	} else {
		var x =
			a.target.max === '' ? 100 : parseFloat(a.target.max);
		var y =
			b.target.max === '' ? 100 : parseFloat(b.target.max);
		return x > y ? 1 : y > x ? -1 : 0;
	}
}
/**
 * Function to DESC sort Rating propery
 * @param {object} a
 * @param {object} b
 * @returns {number} number
 */
export const sortRatingDesc = (a, b) => {
	const rating = a.averageRating || 0;
	const otherRating = b.averageRating || 0;

	return rating < otherRating
		? 1
		: otherRating < rating
		? -1
		: 0;
}
/**
 * Function to filter digits only
 * @param {*} string
 * @returns {string} string
 */
export const filterDigits = (number) => {
	return number.replace(/\D+/g, '');
};
/**
 * Function to check is the count of numbers in current string more than 9
 * @param {string} string
 * @returns {boolean} boolean
 */
export const checkPhoneNumber = (number) => {
	return filterDigits(number).length > 9;
};
/**
 * Function to remove +1
 * @param {*} string
 * @returns {string} string
 */
export const removeCountryCode = (number) => {
	return number.trim().slice(2);
};
/**
 * Function to detect is value related to password or email
 * @param {string} value
 * @returns {string} string
 */
export const detectErrorType = (value) => {
	if (value.toLowerCase().indexOf('password') > 0) {
		return 'password';
	} else {
		return 'email';
	}
};
/**
 * Function to detect is this correct password by provided value
 * @param {object} investments
 * @param {string} paidOnly
 */
export const paidOnlyInvestments = (investments, paidOnly) => {
	if (paidOnly) {
		return paidOnlyData(investments);
	} else {
		return investments;
	}
};
/**
 * Function to convert Array to Object
 * @param {array} array
 * @param {string} key
 * @returns {object} object
 */
export const convertArrayToObject = (array, key) => {
	const initialValue = {};
	return array.reduce((obj, item) => {
		return {
			...obj,
			[key++]: item,
		};
	}, initialValue);
};
/**
 * Function to check is String Exist And Not Empty
 * @param {string} value
 * @return {boolean} boolean
 */
export const isStringExistAndNotEmpty = (value) => {
	if (value !== undefined) {
		if (typeof value === 'string') {
			if (value.length > 0) {
				if (value !== 'undefined') {
					return true;
				} else {
					return false;
				}
			} else {
				return false;
			}
		} else {
			return false;
		}
	} else {
		return false;
	}
}
/**
 * Function to check is Array Exist And Not Empty
 * @param {array} value
 * @return {boolean} boolean
 */
export const isArrayExistAndNotEmpty = (value) => {
	if (value !== undefined) {
		if (Array.isArray(value)) {
			if (value.length > 0) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	} else {
		return false;
	}
}
/**
 * Function to return false if both input values are undefined or empty
 * @param {string} promoVideo
 * @param {array} media
 * @returns {boolean} boolean
 */
export const hideMedia = (promoVideo, media) => {
	let hidePromo = true, hideMedia = true;
	if (isStringExistAndNotEmpty(promoVideo)) {
		hidePromo = false;
	}
	if (isArrayExistAndNotEmpty(media)) {
		hidePromo = false;
	}
	return (hidePromo && hideMedia);
}
/**
 * Function to get Sorce attribute from HTML code
 * @param {string} src
 * @return {string} string
 */
export const getSrcFromHtml = (src) => {
	const srcObj = src.split(' ').filter(i => i.includes('src='));
	if (srcObj.length > 0) {
		const src = srcObj[0].replace('src=', '');
		return src.substring(1, src.length - 1);
	} else {
		if (src.indexOf('http') > -1) {
			return src;
		} else {
			return "";
		}
	}
}
/**
 * Function to detect is this correct mail
 * @param {string} value
 * @returns {boolean} boolean
 */
export const checkMail = (value) => {
	return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
};
/**
 * Function to detect is this correct password
 * @param {string} text
 * @returns {boolean} boolean
 */
export const checkPassword = (value) => {
	//middle
	//return /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/.test(value);
	//strong
	return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_-])(?=.{8,})/.test(value);
};
/**
 * Function detect are there next/prev array items exist
 * @param {array} imgsIds
 * @param {string} currentId
 * @param {string} direction
 * @return {string} string/undefined
 */
export const detectNextImg = (imgsIds, currentId, direction = "next") => {
	const index = imgsIds.findIndex(i => i === currentId);
	if (direction === "next") {
		const nextId = imgsIds[index + 1]
		if (nextId !== undefined) {
			return nextId;
		} else {
			return undefined;
		}
	} else {
		const prevId = imgsIds[index - 1]
		if (prevId !== undefined) {
			return prevId;
		} else {
			return undefined;
		}
	}
}
/**
 * Function to set image src to provided DOM element
 * @param {string} imgId
 * @param {object} modalImg
 * @return {void} void
 */
export const setImageModal = (imgId, modalImg) => {
	modalImg.src = imgId;
}
/**
 * Function to open modal screen with image inside
 * @param {object} modal
 * @param {object} img
 * @param {object} modalImg
 * @return {void} void
 * @
 */
export const openModalScreen = (modal, img, modalImg) => {
	modal.style.display = "block";
	setImageModal(img, modalImg);
}
/**
 * Function to close model screen
 * @param {object} modal
 * @return {void} void
 */
export const closeModalScreen = (modal) => {
	modal.style.display = "none";
}
/**
 * Function to open image in full screen
 * @param {object} imgDom
 * @return {void} void
 */
export const makeFullScreen = (imgId) => {
	const imgDom = document.getElementById(imgId);

	if (imgDom === null) {
		console.log("image doesn't exist");
		return;
	}
	if (imgDom.requestFullscreen) {
		imgDom.requestFullscreen();
	} else if (imgDom.msRequestFullscreen) {
		imgDom.msRequestFullscreen();
	} else if (imgDom.mozRequestFullScreen) {
		imgDom.mozRequestFullScreen();
	} else if (imgDom.webkitRequestFullscreen) {
		imgDom.webkitRequestFullscreen();
	} else {
		console.log("Fullscreen API is not supported");
	}
}
/**
 * Function to exit full screen
 * @return {void} void
 */
export const closeFullscreen = () => {
	if (document.exitFullscreen) {
	  document.exitFullscreen();
	} else if (document.mozCancelFullScreen) {
	  document.mozCancelFullScreen();
	} else if (document.webkitExitFullscreen) {
	  document.webkitExitFullscreen();
	} else if (document.msExitFullscreen) {
	  document.msExitFullscreen();
	}
}
/**
 * Function to detect accredited value
 * @param {string} prop
 * @returns {string} string
 */
export const detectAccreditedValue = (prop) => {
	return prop === "Accredited only" ? "Accredited" : "All";
}
/**
 * Function to filter array providing key and value
 * @param {array} ar
 * @param {string} key
 * @param {string} value
 * @return {array} array
 */
export const filterArrayKeyByValue = (ar, key, value) => {
	return Object.values(ar).filter((i) => i[key].trim().toLowerCase() === value.toLowerCase());
}
/**
 * Function to filter response object by provided requestParams
 * @param {object} requestParams
 * @param {object} response
 * @return {object} object
 */
export const filterByCategories = (requestParams, response) => {
	if (requestParams.categories) {
		if (requestParams.categories.length > 0) {
			response = filterArrayKeyByValue(response, "industry", requestParams.categories[0]);
		}
	}
	if (requestParams.types !== undefined && requestParams.types !== false) {
		response = filterArrayKeyByValue(response, "howMoney", requestParams.types);
	}
	if (requestParams.liquidity !== undefined && requestParams.liquidity !== false) {
		response = filterArrayKeyByValue(response, "liquidity", requestParams.liquidity);
	}
	if (requestParams.payout !== undefined && requestParams.payout !== false) {
		response = filterArrayKeyByValue(response, "payoutFrequency", requestParams.payout);
	}
	if (requestParams.accredited !== undefined && requestParams.accredited !== false) {
		response = filterArrayKeyByValue(response, "investors", detectAccreditedValue(requestParams.accredited));
	}
	if (requestParams.rating!== undefined && requestParams.rating !== false) {
		response =  Object.values(response).filter(i => parseInt(i["averageRating"]) === (parseInt(requestParams.rating) || 0));
	}
	return response;
}
/**
 * Function to filter adhoc rating checkboxes
 * @param {object} response
 * @param {object} requestParams
 * @returns {object} response
 */
export const filterRatingCheckboxes = (response, requestParams) => {
	requestParams.rating = requestParams.rating.map(
		(textRating) => parseInt(textRating) || 0
	);
	if (requestParams.rating.length === 1) {
		response = Object.values(response).filter(i => parseInt(i.averageRating) === requestParams.rating[0]);
	} else {
		let ar0 = [], ar1 = [], ar2 = [], ar3 = [], ar4 = [], ar5 = [];
		for (let i = 0; i <= 5; i++) {
			if (requestParams.rating[i] === 0) {
				ar0 = Object.values(response).filter(i => parseInt(i.averageRating) === 0);
			}
			if (requestParams.rating[i] === 1) {
				ar1 = Object.values(response).filter(i => parseInt(i.averageRating) === 1);
			}
			if (requestParams.rating[i] === 2) {
				ar2 = Object.values(response).filter(i => parseInt(i.averageRating) === 2);
			}
			if (requestParams.rating[i] === 3) {
				ar3 = Object.values(response).filter(i => parseInt(i.averageRating) === 3);
			}
			if (requestParams.rating[i] === 4) {
				ar4 = Object.values(response).filter(i => parseInt(i.averageRating) === 4);
			}
			if (requestParams.rating[i] === 5) {
				ar5 = Object.values(response).filter(i => parseInt(i.averageRating) === 5);
			}
		}
		response = [...ar0, ...ar1, ...ar2, ...ar3, ...ar4, ...ar5];
	}
	return response;
}
/**
 * Function to count 3 filter objects
 * @param {object} filter
 * @param {object} filterCategories
 * @param {object} ratingFilter
 * @return {number} countFilters
 */
export const countObjects = (filter, filterCategories, ratingFilter) => {
	const countFilters =
	Object.values(filter).filter((v) => v).length +
	Object.values(filterCategories).filter((v) => v).length +
	Object.values(ratingFilter).filter((v) => v).length;
	return countFilters;
}
/**
 * Function to filter array by name and decription
 * @param {strung} query
 * @param {object} response
 * @return {array} response
 */
export const searchPlatforms = (query, response) => {
	return response.filter((i) => i.name.toLowerCase().indexOf(query) > -1 || i.description.toLowerCase().indexOf(query) > -1)
}
/**
 * Function to generate array from 18 till 65
 * @return {array} ageArray
 */
export const getAges = () => {
	const ageArray = [];
	for(let i = 18; i <= 65; i++) {
		ageArray.push(i);
	}
	return ageArray;
}
/**
 * Function to generate adhoc Fingerprint2 object with hash and userId
 * @param {object} components
 * @param {string} murmur
 * @param {string} userId
 * @returns {object} object
 */
export const generateFingerprintObject = (components, murmur, userId) => {
	return {
		"adBlock": components[21].value,
		"availableScreenResolutionX": components[7].value[0],
		"availableScreenResolutionY": components[7].value[1],
		"colorDepth": components[3].value,
		"deviceMemory": components[4].value,
		"fingerprint": murmur,
		"hardwareConcurrency":  components[5].value,
		"hasLiedBrowser":  components[25].value,
		"hasLiedLanguages":  components[22].value,
		"hasLiedOs":  components[24].value,
		"hasLiedResolution":  components[23].value,
		"indexedDb":  components[12].value,
		"language":  components[2].value,
		"localStorage":  components[11].value,
		"openDatabase":  components[14].value,
		"platform":  components[16].value,
		"screenResolutionX": components[6].value[0],
		"screenResolutionY": components[6].value[1],
		"sessionStorage":  components[10].value,
		"timezone": components[9].value,
		"timezoneOffset": components[8].value,
		"userAgent": components[0].value,
		"userId": userId,
	}
}
/**
 * Function to generate adhoc Quiz Answers object
 * @param {string} amount
 * @param {string} risk
 * @param {string} cashpayments
 * @param {string} timeframe
 * @param {string} liquidity
 * @param {string} accredited
 * @returns {object} object
 */
export const generateQuizAnswersObject = (amount, risk, cashpayments, timeframe, liquidity, accredited) => {
	return {
		"QuizData_amount": amount,
		"QuizData_risk": risk,
		"QuizData_cashpayments": cashpayments,
		"QuizData_timeframe": timeframe,
		"QuizData_liquidity": liquidity,
		"QuizData_accredited": accredited,
	}
}
/**
 * Function to hide MailChimp modal
 * @returns void
 */
export const disableMailChimp = () => {
	const id = setInterval(detectDOMElement, 100);
	function detectDOMElement() {
		const mc = document.getElementById("PopupSignupForm_0");
		if (mc) {
			mc.style.display = 'none';
			clearInterval(id);
			console.log(`MailChimp modal is disabled`);
		}
	}
}
/**
 * Function to generate SubId string
 * @param {string} utm_source
 * @param {string} utm_medium
 * @returns {string} string
 */
export const generateSubId = (utm_source, utm_medium) => {
	let source = utm_source;
	let medium = utm_medium;
	if (!source) {
		source = 'moneymade';
	}
	if (!medium) {
		medium = 'direct';
	}
	const subId = `${source}_${medium}`;
	return subId;
}
/**
 * Function to add text at current cursor position
 * @param {string} text
 * @returns void
 */
export const insertTextAtCursor = (text) => {
	var sel, range;
	if (window.getSelection) {
	  sel = window.getSelection();
	  if (sel.getRangeAt && sel.rangeCount) {
		range = sel.getRangeAt(0);
		range.deleteContents();
		range.insertNode(document.createTextNode(text));
	  }
	} else if (document.selection && document.selection.createRange) {
	  document.selection.createRange().text = text;
	}
}
/**
 * Function to parse input content as plaint text
 * @param {event} e
 * @returns void
 */
export const paste = (e) => {
	e.preventDefault();
	if (e.clipboardData && e.clipboardData.getData) {
		var text = e.clipboardData.getData("text/plain");
		document.execCommand("insertHTML", false, text);
	} else if (window.clipboardData && window.clipboardData.getData) {
	  var text = window.clipboardData.getData("Text");
	  insertTextAtCursor(text);
	}
}
/**
 * Function to change the last name with dot
 * @example Erick Karlson -> Erick K.
 * @param {string} value
 * @returns {string} string
 */
export const lastNameWithDot = (value = "") => {
	if (value === "") {
		return "";
	}
	return value.trim().split(" ")[0] + " " + value.trim().split(" ")[1].substr(0,1) + ".";
}
/**
 * Function to combine first name and last name
 * @example Erick Karlson -> Erka
 * @param {string} value
 * @returns {string} string
 */
export const firstNameAndLastNameCombination = (value = "") => {
	if (value === "") {
		return "";
	}
	if (value.trim().split(" ").length > 1) {
		return value.trim().split(" ")[0].substr(0,2) + value.trim().split(" ")[1].toLowerCase().substr(0,2)
	}
	return value;
}
/**
 * Function to filter the object by array of ids
 * @param {array} platforms
 * @param {object} investmentTitles
 * @returns {object} object
 */
export const getPlatformsById = (platforms, investmentTitles) => {
	if (platforms === null) {
		return [];
	}
	const platformTagged = [];
	for (let i = 0; i < platforms.length; i++) {
		for (let j = 0; j < investmentTitles.length; j++) {
			if (investmentTitles[j]["id"] === platforms[i]) {
				platformTagged.push(investmentTitles[j]);
				break;
			}
		}
	}
	return platformTagged;
}
/**
 * Function to get Platform Ids By Tiltes
 * @param {array} investmentTitles
 * @param {string} textWithTags
 * @returns {array} array
 */
export const getPlatformIdsByTiltes = (investmentTitles, textWithTags) => {
	const ar = textWithTags.split("$");
	const ids = [];;
	for (let i = 1; i < ar.length; i++) {
		for (let j = 0; j < investmentTitles.length; j++) {
			if (ar[i].toLowerCase().indexOf(investmentTitles[j].name.trim().toLowerCase()) === 0) {
				ids.push(investmentTitles[j].id);
			}
		}
	}
	return ids;
}
/**
 * Function to leave unique array items
 */
export const arrayUniqueItems = (value, index, self) => {
    return self.indexOf(value) === index;
}
/**
 * Function to detect is array contains value
 * @param {array} investmentsTotal
 * @param {string} newValue
 * @returns {object} object
 */
export const detectPlatform = (investmentsTotal, newValue) => {
	return investmentsTotal.filter((i) => i.name.trim().toLowerCase() === newValue.name.trim().toLowerCase())[0];
};
/**
 * Function to detect is array contains value
 * @param {array} investmentsTotal
 * @param {string} id
 * @returns {object} object
 */
export const detectPlatformById = (investmentsTotal, id) => {
	return investmentsTotal.filter((i) => i.id.toLowerCase() === id.toLowerCase());
};
/**
 * Function to detect is array contains value
 * @param {*} investmentsTotal
 * @param {*} slug
 * @returns {object} object
 */
export const detectPlatformBySlug = (investmentsTotal, slug) => {
	return investmentsTotal.filter((i) => {
		let slugPlatform = i.slug;
		if (slugPlatform) {
			slugPlatform = slugPlatform.toLowerCase();
		}
		return slugPlatform === slug.toLowerCase();
	});
};
/**
 * Function to detect is array contains object's value
 * @param {array} favoritesInvestments
 * @param {object} platform
 * @returns {boolean} boolean
 */
export const detectActiveStar = (favoritesInvestments, platform) => {
	return favoritesInvestments.filter((i) => i === platform.id).length > 0 ? true : false;
};
/**
 * Function to get data from now provided by seconds using Momet.js
 * @param {string} seconds
 * @returns {string} string
 */
export const getFromNowDate = (seconds) => {
	return moment.unix(seconds).fromNow();
}
/**
 * Function to get Tribute.js instance
 * @param {object} investmentTitles
 * @returns {object} object
 */
export const getTribute = (investmentTitles) => {
	const tribute = new Tribute({
		values: investmentTitles,
		trigger: '$',
		selectClass: "has-background-primary",
		allowSpaces: false,
		autocompleteMode: false,
		noMatchTemplate: null,
		menuItemLimit: 5,
		menuItemTemplate: getMenuTemplate
	});
	return tribute;
}
/**
 * Function to make template for Tribute.js
 * @param {object} item
 * @returns {HTMLElement}
 */
export const getMenuTemplate = (item) => {
	return "<div>" + item.original.key + "</div>";
}
/**
 * Function to init IntersectionObserver object for provided Id and callback
 * @param {string} sharePostId
 * @param {function} observerCallback
 * @returns void
 */
export const addObserver = (Id, observerCallback) => {
	const limitDiv = document.getElementById(Id);
	if (limitDiv) {
		const observer = new IntersectionObserver((entries) => observerCallback(entries, observer, limitDiv), {
			threshold: 1
		});
		observer.observe(limitDiv);
	}
}
/**
 * Function to set focus after the last charecter for provided element's id
 * @param {string} sharePostId
 * @returns void
 */
export const setCaret = (sharePostId) => {
	var el = document.getElementById(sharePostId);
	var range = document.createRange();
	var sel = window.getSelection();
	if (el.childNodes[0] !== undefined) {
		range.setStart(el.childNodes[0], el.innerText.length);
		range.collapse(true);
		sel.removeAllRanges();
		sel.addRange(range);
		el.focus();
	}
}
/**
 * Function to check is Value in Array
 * @param {array} userLikes
 * @param {string} value
 * @returns {boolean} boolean
 */
export const isValueinArray = (userLikes, value) => {
	return userLikes.filter(i => i["commentId"] === value).length > 0 ? true : false;
}
/**
 * Function to check is Values in Array
 * @param {array} userLikes
 * @param {string} value
 * @param {string} index
 * @returns {boolean} boolean
 */
export const isValuesinArray = (userLikes, id, index) => {
	return userLikes.filter(i => (i["commentId"] === id) && (i["replyIndex"] === index)).length > 0 ? true : false;
}
/**
 * Function to delay the execution
 * @param {string} ms
 * @returns Promise
 */
export const delay = (ms) => {
	return new Promise((resolve) => setTimeout(resolve, ms));
}
/**
 * Function to generate unput file to base64 file
 * @param {*} inputFile
 * @returns Promise
 */
export const readUploadedFileAsBase64 = (inputFile) => {
    const temporaryFileReader = new FileReader();
    return new Promise((resolve, reject) => {
      if(!inputFile){
        resolve("");
      }
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject("Problem parsing input file.");
      };
      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsDataURL(inputFile);
    });
}
/**
 * Function to check is blob file has image type
 * @param {*} blob
 * @returns {boolean} boolean
 */
export const checkFileType = (blob) => {
    if(blob.type.indexOf("image") === -1){
      return false;
    }
    else{
      return true;
    }
}
/**
 * Function to check is blob file has size more then 5 MB
 * @param {*} blob
 * @returns {boolean} boolean
 */
export const checkImageSize = (blob) => {
    if(blob.size > 5000000){
      return false;
    }
    else{
      return true;
    }
}
/**
 * Function to show the DOM element
 * @param {string} url
 * @param {string} imagePlaceholderId
 * @param {string} imagePlaceholdeContainer
 * @param {string} imgHeight
 * @returns void
 */
export const updateImageLogo = (url, imagePlaceholderId, imagePlaceholdeContainer, imgHeight) => {
	const container = document.getElementById(imagePlaceholdeContainer);
	if (container) {
		container.style.display = 'inherit';
	}
}
/**
 * Function to update posts array with new data
 * @param {array} posts
 * @param {string} id
 * @param {boolean} toggle
 * @returns {array} array
 */
export const updatePosts = (posts, id, toggle) => {
	if (posts.filter((i) => i.id === id).length > 0) {
		posts = posts.filter((i) => i.id !== id);
		posts.push({
			id,
			toggle,
		});
	} else {
		posts.push({
			id,
			toggle,
		});
	}
	return posts;
}
/**
 * Function to update stream array with new data
 * @param {array} stream
 * @param {string} postId
 * @param {array} result
 * @returns {array} array
 */
export const updateStream = (stream, postId, result) => {
	let arrIndex = "";
	stream.forEach((i, index) => {
		if (i.id === postId) {
			arrIndex = index
		}
	});
	let newStream = stream;
	newStream[arrIndex].replies = result.replies;
	return newStream;
}
/**
 * Function to open and scroll to DOM element or hide it
 * @param {boolean} toggle
 * @param {HTMLElement} element
 * @returns void
 */
export const openPostOrHide = (toggle, element) => {
	if (toggle) {
		element.style.display = "block";
		element.scrollIntoView({behavior: "smooth", block: "start"});
	} else {
		element.style.display = "none";
	}
}
/**
 * Function to highlight active stream posts
 * @param {array} posts
 * @param {string} containerId
 * @returns void
 */
export const toggleActviePostClass = (posts, containerId) => {
	if (posts.length > 0) {
		for (let i = 0; i < posts.length; i++) {
			const id = posts[i]["id"];
			const toggle = posts[i]["toggle"];
			const el = document.getElementById(`${containerId}${id}`);
			if (el) {
				if (toggle) {
					el.classList.add("stream-post-active");
				} else {
					el.classList.remove("stream-post-active");
				}
			}
		}
	}
}
/**
 * Function to convert array to ibject
 * @param {array} investmentsTotal
 * @returns {object} object
 */
export const convertToAutocompleteObject = (investmentsTotal) => {
	return investmentsTotal.map(i => ({
		title: i.name.trim(),
		key: i.name.trim(),
		value: i.name.trim(),
		name: i.name.trim(),
		slug: i.slug,
		id: i.id
	}));
}
/**
 * Function to detect is URL has params.platformId pattern
 * @param {object} match
 * @returns {boolean} boolean
 */
export const detectRouteUrl = (match) => {
	if (match !== null && match !== undefined) {
		if (match.params !== undefined) {
			if (match.params.platformId !== undefined) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	} else {
		return false;
	}
}
/**
 * Function to detect if current width in desktop view
 * @returns {boolean} boolean
 */
export const isDesktopView = () => {
	return window.innerWidth < mobileWidth ? false : true;
}
/**
 * Function to prepare the dangerouslysetinnerhtml element
 * @param {HTMLElement} html
 * @returns {object} object
 */
export const createMarkup = (html) => {
	return {
		__html: html
	};
}
/**
 * Function to update the url by checking the 'changeattribute' attribute
 * @param {string} url
 * @returns void
 */
export const changeUrls = (url) => {
	const id = setInterval(detectDOMElement, 100);
	function detectDOMElement() {
		const mc = document.querySelectorAll(".deepDiveLink");
		if (mc) {
			mc.forEach(i => {
				if (i.getAttribute("changeattribute") === "true") {
					i.href = url;
				}
			});
			clearInterval(id);
			console.log(`urls updated`);
		}
	}
}
/**
 * Function to polifill the new deepdive platform until its slug prop become true
 * @param {string} nameDynamic
 * @param {string} nameStatic
 * @returns {boolean} boolean
 */
export const isDeepDiveAllowed = (nameDynamic, nameStatic = "origin-investments") => {
	return nameDynamic.toLowerCase() === nameStatic.toLowerCase() ? true : false;
}
/**
 * Function to get array's last id
 * @param {array} data
 * @returns {string} string
 */
export const getLastId = (data) => {
	if (Array.isArray(data)) {
		if (data.length > 0) {
			return data[data.length-1].id;
		}
	}
	return "";
}
/**
 * Function to get array's first id
 * @param {array} data
 * @returns {string} string
 */
export const getFirstId = (data) => {
	if (Array.isArray(data)) {
		if (data.length > 0) {
			return data[0].id;
		}
	}
	return "";
}
/**
 * Function to check is current url contain provided value
 * @param {string} currentUrl
 * @param {string} value
 * @returns {boolean} boolean
 */
export const isStreamUrl = (currentUrl, value) => {
	return currentUrl.indexOf(value) > -1 ? true : false;
}
/**
 * Function to generate specific '-review' suffix url page name
 * @param {string} name
 * @returns {string} string
 */
export const genereateDeepdiveReviewPage = (name) => {
	return `${name}-review`;
}
/**
 * Function to generate gull path url with provided string
 * @param {string} name
 * @returns {string} string
 */
export const getFullPlatformUrl = (name) => {
	const { protocol, host } = window.location;
	const url = `${protocol}//${host}/${name}`;
	return url;
}
/**
 * Function to detect is current string discover or expandableDiscovery
 * @param {boolean} expandable
 * @param {string} name
 * @returns {string} string
 */
export const getDiscoveryPageName = (expandable, name) => {
	return !expandable
		? `${routeNames.discover}/${name}`
		: `${routeNames.expandableDiscovery}/${name}`;
}
/**
 * Function to copy text to clipboard
 * @param {string} copyText
 */
export const copyToClipboard = (copyText) => {
	if (window.isSecureContext) {
		navigator.clipboard.writeText(copyText);
	} else {
		console.log(`the context isn't secure`);
	}
}
