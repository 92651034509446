import React, { useState, useEffect, useMemo } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import { InvestCard } from '../InvestopediaWidget/InvestCard';
import { InvestCardCollapsed } from '../InvestopediaWidget/InvestCardCollapsed2';
import { InvestCardExpanded } from '../InvestopediaWidget/InvestCardExpanded2';
import { getShuffle, isObjectEmpty, capitalLetters, sortAsc } from '../../utils';
import { useSelector, shallowEqual } from 'react-redux';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		fontFamily: 'Inter',
		//margin: 'auto',
		//padding: 0,
	},
	content: {
		flexGrow: 1,
		//paddingTop: '0px !important',
		//padding: theme.spacing(3),
	},
	cards: {
		padding: '0px 12px',
	},
	headerContent: {
		color: '#19193E',
		marginTop: '0px',
		background: '#fff',
		marginBottom: '5px',
		overflow: 'hidden',
		paddingLeft: '24px',
		paddingRight: '14px',
		paddingTop: '14px',
		paddingBottom: '14px',
		boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
		[theme.breakpoints.down('xs')]: {
			fontSize: '15px',
		},
		boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04);',
	},
	header: {
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 'bold',
		fontSize: '16px',
		lineHeight: '24px',
	},
	headerCheckbox: {
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '14px',
		lineHeight: '20px',
		color: '#73757A',
	},
	checkboxSection: {
		display: 'flex',
		alignItems: 'center',
	},
}));

const CustomCheckbox = withStyles({
	root: {
		color: '#DBE0E6',
		'&$checked': {
			color: '#73757A',
		},
	},
	checked: {},
})((props) => <Checkbox color="default" {...props} />);

export const InvestopediaCard = (props) => {
	const {
		utmParams: { w, h, i },
		investments,
	} = props;
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const [checked, setChecked] = useState(false);
	const [header, setHeader] = useState(state.itemIndustry);
	const investmentsMemo = useMemo(() => getShuffle(investments), [investments]);

	const [shuffledInvestments, setShuffledInvestments] = useState(investmentsMemo);
	const areInvestmentsPresent = isObjectEmpty(shuffledInvestments);

	const slicer = i ? i : shuffledInvestments.length;
	const width = w ? `${w}px` : 'inherit';
	const height = h ? `${h}px` : 'inherit';

	const headline = `Best ${header} Investments`;
	const checkboxHeadline = 'Accredited only';
	const classes = useStyles();

	const [activePlatform, setActivePlatform] = useState(null);

	const handleChange = (event) => {
		setChecked(event.target.checked);
		if (event.target.checked) {
			setShuffledInvestments(
				investmentsMemo.filter((i) => i['investors'] === 'Accredited')
			);
		} else {
			setShuffledInvestments(investmentsMemo);
		}
	};
	useEffect(() => {
		setShuffledInvestments(investmentsMemo);
	}, [investmentsMemo]);

	useEffect(() => {
		if (state.itemIndustry !== '') {
			setHeader(`${capitalLetters(state.itemIndustry)}`);
		}
	}, [state.itemIndustry]);

	const rootStyle = {
		maxWidth: `${width}`,
		height: `${height}`,
		overflowX: 'hidden',
		overflowY: 'auto',
		padding: '0px',
		border: '1px solid #f2f5f8',
	};
	return (
		<>
			<div className={`respCont ${classes.root}`}>
				<main className={`${classes.content} `} style={rootStyle}>
					<div
					//className="mainPrinc"
					>
						<div className={classes.headerContent}>
							<Grid
								container
								direction="row"
								justify="space-between"
								alignItems="center"
							>
								<Grid sm={9} xs={7} item>
									<div className={classes.header}>{headline}</div>
								</Grid>
								<Grid sm={3} xs={5} item>
									<div className={classes.checkboxSection}>
										<CustomCheckbox checked={checked} onChange={handleChange} />
										<div className={classes.headerCheckbox}>{checkboxHeadline}</div>
									</div>
								</Grid>
							</Grid>
						</div>
						<div className={classes.cards}>
							{areInvestmentsPresent && state.userId.length > 0 &&
								Object.values(shuffledInvestments)
									.filter((i) => i !== true)
									.sort(sortAsc('minInvestment'))
									.slice(0, slicer)
									.map(function (investment, index) {
										if (investment === true) {
											return null;
										}
										let investmentComponent = null;
										investmentComponent = (
											<>
												<div id={`platform_${investment.id}`} />
												<InvestCardCollapsed
													key={investment.id}
													investmentPlatform={investment}
													embedOnclick={null}
													expandable={false}
													setActivePlatform={setActivePlatform}
													w={w}
													h={h}
												/>
											</>
										);
										if (activePlatform === investment) {
											const similarInvestments = activePlatform.similars.map(
												(similar) => {
													const similarInvestment = Object.values(investments).find(
														(investment) => investment.id === similar
													);
													return similarInvestment;
												}
											);
											investmentComponent = (
												<InvestCardExpanded
													key={investment.id}
													investmentPlatform={investment}
													similarInvestments={similarInvestments}
													setActivePlatform={setActivePlatform}
												/>
											);
										}
										return investmentComponent;
									})}
						</div>
					</div>
				</main>
			</div>
		</>
	);
};
