import { createMuiTheme } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';

const theme = createMuiTheme({
	primary: purple,
	typography: {
		fontFamily: ['Inter'].join(','),
	},
	button: {
		fontStyle: 'italic',
	},
});

export default theme;