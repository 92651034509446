import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import expandIcon from '../../img/icons/external-link.svg';
import Tooltip from '@material-ui/core/Tooltip';
import LazyLoad from 'react-lazyload';
import ShareIcon from '@material-ui/icons/Share';
import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import InvestmentPlatformAvatar from './Avatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Rating from '../Rating';
import { getSentimentText } from './helpers';

import ReactGA from 'react-ga';
import { eventTracker, trackEvent, generateSubId } from '../../utils';
import { useSelector, shallowEqual } from 'react-redux';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles((theme) => ({
	borderedColumn: {
		position: 'relative',

		'& .pseudoborder': {
			height: 'calc(100% - 40px) !important',
			top: '28px',
			left: '-24px',
			margin: '0 !important',
		},
	},
	secondColumn: {
		paddingRight: '32px',
		[theme.breakpoints.down('440')]: {
			minWidth: '50% !important',
		},
	},
	thirdColumn: {
		[theme.breakpoints.down('440')]: {
			minWidth: '50% !important',
		},
	},
	subheader: {
		maxWidth: '220px',
		alignItems: 'center',
	},
	rating: {},
	tooltip: {
		zIndex: 10001, // to be visible inside the modal
	},
	subTitle: {
		fontSize: '14px',
		margin: '0',
		marginTop: '16px',
		color: '#73757A',
		[theme.breakpoints.down('440')]: {
			marginTop: '0px',
		},
	},
}));

export default function Header(props) {
	const { investmentPlatform } = props;
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const [copySuccess, setCopySuccess] = React.useState(false);
	const [source, setSource] = React.useState(state.itemSource || 'MoneyMade');
	const classes = useStyles();

	useEffect(() => {});

	function clickCopy(url) {
		//console.log(url);
		copyToClipboard(url);
		setCopySuccess(true);
		setTimeout(() => setCopySuccess(false), 1200);
	}

	function copyToClipboard(copyText) {
		var textField = document.createElement('textarea');
		textField.innerText = copyText;
		document.body.appendChild(textField);
		textField.select();
		document.execCommand('copy');
		textField.remove();
	}

	function stopPropagation(event) {
		trackEvent('ExternalClick', 'platform', investmentPlatform.name);
		eventTracker("ExternalClick", investmentPlatform.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);

		event.stopPropagation();
	}

	return (
		<Grid container alignItems="flex-start">
			<Grid className="firstgrid" item sm={5} xs={5}>
				<CardHeader
					avatar={
						<Tooltip
							disableFocusListener
							disableTouchListener
							classes={{ popper: classes.tooltip }}
							placement="top"
							title={`${getSentimentText(investmentPlatform.sentiment)} sentiment`}
							arrow
						>
							<span>
								<InvestmentPlatformAvatar investmentPlatform={investmentPlatform} />
							</span>
						</Tooltip>
					}
					title={
						<div>
							<Tooltip
								disableFocusListener
								disableTouchListener
								classes={{ popper: classes.tooltip }}
								placement="top"
								title={`Visit site`}
								arrow
							>
								<a
									target="_blank"
									rel="noopener noreferrer"
									style={{ cursor: 'pointer' }}
									href={
										investmentPlatform.website
											? investmentPlatform.website.replace('%SUBID%', generateSubId(state.itemSource, state.itemMedium))
											: '/discover'
									}
									onClick={stopPropagation}
								>
									{investmentPlatform.name}
									<img
										alt="expandIcon"
										style={{ marginBottom: '-2px', marginLeft: '5px' }}
										src={expandIcon}
									/>
								</a>
							</Tooltip>
						</div>
					}
					subheader={
						<div className={classes.subheader}>
							{(investmentPlatform.averageRating || null) && (
								<Rating
									className={classes.rating}
									value={investmentPlatform.averageRating}
								/>
							)}
						</div>
					}
				/>
			</Grid>

			<Grid
				className={`${classes.borderedColumn} ${classes.secondColumn}`}
				item
				sm={3}
				xs={3}
			>
				<p className={classes.subTitle}>Min investment</p>
				<h4
					style={{
						fontWeight: 'normal',
						marginTop: '0',
						marginBottom: '10px',
						fontSize: '20px',
					}}
				>
					$
					{investmentPlatform.minInvestment
						.toString()
						.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
				</h4>

				<div className="pseudoborder"></div>
			</Grid>

			<Grid
				className={`${classes.thirdColumn} ${classes.borderedColumn}`}
				item
				sm={3}
				xs={3}
			>
				<div>
					<p className={classes.subTitle}>Target return</p>
					<h4
						style={{
							fontWeight: 'normal',
							marginTop: '0',
							marginBottom: '10px',
							fontSize: '20px',
						}}
					>
						{investmentPlatform.target
							? investmentPlatform.target.min
								? `${investmentPlatform.target.min}%`
								: 'Varied'
							: ''}
						{investmentPlatform.target
							? investmentPlatform.target.max &&
							  ` - ${investmentPlatform.target.max}%`
							: ''}
					</h4>

					<div className="pseudoborder"></div>
				</div>
			</Grid>
		</Grid>
	);
}
