import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import searchIcon from '../../img/stream/search.svg';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles((theme) => ({
    searchContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    topCard: {
        marginTop: '32px',
        marginBottom: '20px',
    },
    autocompleteImg: {
        backgroundImage: `url(${searchIcon})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '23px center',
        backgroundColor: '#FFFFFF',
    },
}));
export const AutocompleteCard = (props) => {
    const {
		value,
		investmentTitles,
		handleAutocomplete,
	} = props;
    const classes = useStyles();

	return (
        <div className={`${classes.searchContainer} ${classes.topCard}`}>
            <div className={classes.autocompleteImg} id="combo-box-autocomplete-container">
                <Autocomplete
                    value={value}
                    id="combo-box-autocomplete"
                    options={investmentTitles}
                    getOptionLabel={
                        (option) => option.title
                    }
                    style={{ width: '320px' }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            id="search-autocomplete"
                            placeholder="Search..."
                        />
                    )}
                    onChange={handleAutocomplete}
                />
            </div>
        </div>
	);
};
