import React from 'react';
import { useHistory, useRouteMatch, Route } from 'react-router-dom';
import InvestmentPlatformModal from './Modal';
import { trackEvent, eventTracker } from '../../utils';
import { useSelector, shallowEqual } from 'react-redux';

export const InvestmentPlatformModalRoute = (props) => {
    const {
        investments,
        resetTotal,
        search,
        expandable,
    } = props;
    const { url: currentUrl } = useRouteMatch();
    const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
    const history = useHistory();
    return (
        <>
            <Route
                path={`${currentUrl}/:platformId`}
                render={({ match }) => {
                    const selectedId = match.params.platformId;
                    const selectedInvestment = Object.values(state.investmentsTotal).find(
                        (investment) => investment.slug === selectedId
                    );
                    if (!selectedInvestment) {
                        history.push(currentUrl);
                        return null;
                    }
                    const similarInvestments = selectedInvestment.similars.map((similar) => {
                        const similarInvestment = Object.values(state.investmentsTotal).find(
                            (investment) => investment.id === similar
                        );
                        return similarInvestment;
                    });
                    trackEvent('PlatformExpandedImpression','platform', selectedInvestment.name);
                    eventTracker("PlatformExpandedImpression", selectedInvestment.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);

                    return (
                        <InvestmentPlatformModal
                            similarInvestments={similarInvestments}
                            investmentPlatform={selectedInvestment}
                            onClose={() => history.push(currentUrl)}
                            resetTotal={resetTotal}
                            search={search}
                            expandable={expandable}
                        />
                    );
                }}
            />
        </>
    )
}
