import { createStore, combineReducers } from "redux";
import { initialData } from "../data/Data";
import { getReducer } from "./reducers";
import { loadState, saveState, cookiesEnabled } from '../utils';

const persistedState = cookiesEnabled() ? loadState() : null;

export const rootReducer = combineReducers({
  Item: getReducer,
});

export const configureStore = () => {
  const store = createStore(
    rootReducer,
    {
      Item: {
        ...initialData,
        ...persistedState
      },
    }
  );
  store.subscribe(() => {
    if (!cookiesEnabled()) {
      return;
    }
    saveState({
      userId: store.getState().Item.userId,
      userName: store.getState().Item.userName,
      token: store.getState().Item.token,
      //displayRecommended: store.getState().Item.displayRecommended,
      fpSent: store.getState().Item.fpSent,
    });
  });
  return store;
};
