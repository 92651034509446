export const SET_ITEM_COMPAIGN = "SET_ITEM_COMPAIGN";
export const SET_ITEM_MEDIUM = "SET_ITEM_MEDIUM";
export const SET_ITEM_SOURCE = "SET_ITEM_SOURCE";
export const SET_ITEM_INDUSTRY = "SET_ITEM_INDUSTRY";
export const SET_MODAL_OPEN = "SET_MODAL_OPEN";
export const SET_INVESTMENTS = "SET_INVESTMENTS";
export const SET_QUIZ_RESULTS = "SET_QUIZ_RESULTS";
export const SET_CLUSTER_VIEW = "SET_CLUSTER_VIEW";
export const SET_USER_ID = "SET_USER_ID";
export const SET_MODAL_SIGNUP_OPEN = "SET_MODAL_SIGNUP_OPEN";
export const SET_INVESTMENTS_TOTAL = "SET_INVESTMENTS_TOTAL";
export const SET_CLUSTERS = "SET_CLUSTERS";
export const SET_ADS = "SET_ADS";
export const ADD_INVESTMENT_TO_FAVORITES = "ADD_INVESTMENT_TO_FAVORITES";
export const ADD_INVESTMENTS_TO_FAVORITES = "ADD_INVESTMENTS_TO_FAVORITES";
export const REMOVE_INVESTMENT_FROM_FAVORITES = "REMOVE_INVESTMENT_FROM_FAVORITES";
export const AUTHORISE_USER = "AUTHORISE_USER";
export const SET_TOKEN = "SET_TOKEN";
export const SET_LOGGED_IN_STATUS = "SET_LOGGED_IN_STATUS";
export const DISPLAY_SPINNER = "DISPLAY_SPINNER";
export const DISPLAY_RECOMMENDED = "DISPLAY_RECOMMENDED";
export const FINGERPRINT_SENT = "FINGERPRINT_SENT";
export const SET_QUIZ_ANSWERS = "SET_QUIZ_ANSWERS";
export const SET_TYPES = "SET_TYPES";
