import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ReactGA from 'react-ga';
import { useSelector, shallowEqual } from 'react-redux';
import clsx from 'clsx';
import { trackEvent, eventTracker, generateSubId } from '../../utils';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles((theme) => ({
	buttonLink: {
		display: 'block',
		borderRadius: '100px',
		color: '#fff',
		padding: '6px 16px',
		textTransform: 'none',
		textDecoration: 'none',
		fontFamily: 'Inter, Helvetica,Arial,sans-serif',
		fontSize: '0.875rem',
		fontWeight: '500',
		lineHeight: '1.75',
		cursor: 'pointer',
		margin: '16px',
	},
	buttonText: {
		textAlign: 'center',
	},
	color1: {
		background: '#5056E7',
	},
	color2: {
		background: '#323A56',
	},
}));

export const LearnMoreButton = (props) => {
	const { investmentPlatform, color } = props;
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const classes = useStyles();

	const [source, setSource] = useState(state.itemSource || 'MoneyMade');

	const stopPropagation = (event) => {
		trackEvent('ExternalClick', 'platform', investmentPlatform.name);
		eventTracker("ExternalClick", investmentPlatform.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
		event.stopPropagation();
	};
	let backgroundColor;

	switch (color) {
		case '1':
			backgroundColor = classes.color1;
			break;
		case '2':
			backgroundColor = classes.color2;
			break;
		default:
			backgroundColor = classes.color1;
			break;
	}
	useEffect(() => {
		if (state.itemSource) {
			setSource(state.itemSource);
		}
	}, [state.itemSource]);

	return (
		<>
			<div>
				<a
					target="_blank"
					rel="noopener noreferrer"
					className={clsx(classes.buttonLink, backgroundColor)}
					href={
						investmentPlatform.website
							? investmentPlatform.website.replace('%SUBID%', generateSubId(state.itemSource, state.itemMedium))
							: '/discover'
					}
					onClick={stopPropagation}
				>
					<div className={classes.buttonText}>Learn more</div>
				</a>
			</div>
		</>
	);
};
