import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AllInboxIcon from '@material-ui/icons/AllInbox';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import ListIcon from '@material-ui/icons/List';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Button } from '@material-ui/core';
import { view } from '../../data/Data';


const useStyles = makeStyles({
    filtersMenu: {
        zIndex: "10001 !important",
    },
    tooltip: {
        zIndex: "10001 !important",
	},
});

const CustomButton = withStyles((theme) => ({
	root: {
		color: '#73757A',
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '14px',
		lineHeight: '20px',
		textTransform: 'none',
		padding: '4px',
		marginLeft: '14px',
		[theme.breakpoints.down('500')]: {
			marginLeft: '0px',
		},
	},
}))(Button);
const StyledMenuItem = withStyles((theme) => ({
    root: {
        zIndex: '10001',
    },
}))(MenuItem);

const StyledMenu = withStyles({
    paper: {
      border: '1px solid #F2F5F8',
      boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
      top: '184px !important',
    },
  })((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
));

export const ViewMenu = (props) => {
    const {
        modal: modalProp,
        isListView,
        hanldeSetIsListView,
    } = props;
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const [viewTitle, setViewTitle] = useState(view.list);
    const [viewIcon, setViewIcon] = useState(null);

    const modal = modalProp ? `modal` : `nomodal`;
    const collectionTitle = `Collection view`;
    const defaultTitle = `Default view`;
    const listTitle = `List view`;

    useEffect(() => {
        detectView(isListView);
    }, [isListView]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const detectView = (val) => {
        switch (val) {
            case view.default:
                setViewTitle(`${defaultTitle}`);
                setViewIcon(<VisibilityOutlinedIcon />);
                break;
            case view.collection:
                setViewTitle(`${collectionTitle}`);
                setViewIcon(<AllInboxIcon />);
                break;
            case view.list:
                setViewTitle(`${listTitle}`);
                setViewIcon(<ListIcon />);
                break;
            default:
                setViewTitle(`${listTitle}`);
                setViewIcon(<ListIcon />);
                break;
        }
    };
    const handleSetListView = (val) => {
        hanldeSetIsListView(val);
        handleClose();
    };

    return (
        <>
            <CustomButton
                size="small"
                onClick={handleClick}
                startIcon={viewIcon}
            >
                {viewTitle}
            </CustomButton>

            <StyledMenu
                id={`${modal}-customized-menu`}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.filtersMenu}
            >
                {<StyledMenuItem
                    onClick={() => {handleSetListView(view.default)}}
                    selected={view.default === isListView}
                >
                    <ListItemIcon>
                        <VisibilityOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={`${defaultTitle}`} />
                </StyledMenuItem>}

                <StyledMenuItem
                    onClick={() => {handleSetListView(view.collection)}}
                    selected={view.collection === isListView}
                >
                    <ListItemIcon>
                        <AllInboxIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={`${collectionTitle}`} />
                </StyledMenuItem>

                <StyledMenuItem
                    onClick={() => {handleSetListView(view.list)}}
                    selected={view.list === isListView}
                >
                    <ListItemIcon>
                        <ListIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={`${listTitle}`} />
                </StyledMenuItem>
            </StyledMenu>
       </>
    )
}
