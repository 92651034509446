import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import queryString from 'query-string';
import InvestmentPlatformCollapsed from '../components/InvestmentPlatform/CollapsedEmbed';
import Modal from '../components/InvestmentPlatform/Modal.jsx';
import { Spinner } from '../components/Spinner/Spinner.js';
import { getOneEntry } from '../Services/BaseAPI.js';

import ReactGA from 'react-ga';
import { eventTracker } from '../utils';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as actions from '../redux/actions';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const EmbedEntry = (props) => {
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	//console.log(props);
	const [investments, setInvestments] = useState([]);
	const embededEnryRef = useRef();

	const [height, setHeight] = useState(0);
	//const componentRef = React.();

	const [displaySpinner, setDisplaySpinner] = useState(false);

	useEffect(() => {
		const platformIDs = props.match.params.id.split('-');
		platformIDs.forEach((platformId) => {
			let newInvestments = investments;
			getOneEntry(JSON.stringify({ entryId: platformId })).then((data) => {
				setDisplaySpinner(true);
				//	console.log(data);
				let dataWithId = data;
				dataWithId.id = platformId;

				newInvestments.push(dataWithId);
				setInvestments(newInvestments);
				setDisplaySpinner(false);
			});
		});
	}, []);

	useEffect(() => {
		setHeight(embededEnryRef.current.clientHeight);
		//console.log(embededEnryRef.current.clientHeight);
		if (window.parent) {
			window.parent.postMessage(
				{
					frameId: frameId,
					action: 'setHeight',
					height: embededEnryRef.current.clientHeight + 8,
				},
				'*'
			);
		}
	});

	function expandHandler() {
		setHeight(embededEnryRef.current.clientHeight);

		if (window.parent) {
			window.parent.postMessage(
				{
					frameId: frameId,
					action: 'setHeight',
					height: embededEnryRef.current.clientHeight,
				},
				'*'
			);
		}
	}

	const { utm_campaign, utm_medium, utm_source, frameId } = queryString.parse(
		props.location.search
	);
	const dispatch = useDispatch();
	React.useEffect(() => {
		ReactGA.pageview(`/embedEntry/${props.match.params.id}`);
		eventTracker("pageview", `/embedEntry/${props.match.params.id}`, utm_source, utm_medium, utm_campaign, state.userId);
		if (utm_source) {
			dispatch(actions.setItemSource(utm_source));
		}
		if (utm_campaign) {
			dispatch(actions.setItemCompaign(utm_campaign));
		}
		if (utm_medium) {
			dispatch(actions.setItemMedium(utm_medium));
		}
	}, [utm_campaign, utm_medium, utm_source]);

	//console.log(investments);
	//console.log(investments.length);
	return (
		<div ref={embededEnryRef}>
			<center>
				<Spinner displaySpinner={displaySpinner} />
			</center>
			{investments.length > 0
				? investments.map((investment) => (
						<InvestmentPlatformCollapsed
							key={investment.id}
							investmentPlatform={investment}
							frameId={frameId}
							expandHandler={expandHandler}
						/>
				  ))
				: null}
		</div>
	);
};
export default EmbedEntry;
