import React, { useState, useEffect } from 'react';
import LazyLoad from 'react-lazyload';
import ReactGA from 'react-ga';
import InvestmentPlatformCollapsed from './Collapsed';
import ExpandedPlatform from './ExpandedPlatform';
import { isDesktopView  } from '../../utils';
import { PseudoMailCard } from '../MailCard/PseudoMailCard';
import { ParallelMarkets } from '../ParallelMarkets/ParallelMarkets';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

export const ListViewCard = (props) => {
	const {
        expandable,
        investments,
        investmentsTotal,
        setActivePlatform,
        activePlatform,
        search,
        resetTotal,
        calulatorBanner,
        ads,
        isCollectionActive,
        handleMoveToListView,
		isListView,
	} = props;

	return (
        Object.values(investments).map(function (investment, index) {
            if (investment === true) {
                return null;
            }
            let investmentComponent = null;
            const query = search.toLowerCase();

            investmentComponent = (
                <>
                    <LazyLoad
                        height={230}
                        offset={500}
                        once
                        key={`lazy_${investment.id}`}
                    >
                        <div id={`platform_${investment.id}`} />
                        <InvestmentPlatformCollapsed
                            key={investment.id}
                            investmentPlatform={investment}
                            expandable={expandable}
                            setActivePlatform={setActivePlatform}
                        />
                    </LazyLoad>
                </>
            );
            if (activePlatform === investment &&
                !isDesktopView()) {
                const similarInvestments = activePlatform.similars.map((similar) => {
                    const similarInvestment = Object.values(investmentsTotal).find(
                        (investment) => investment.id === similar
                    );
                    return similarInvestment;
                });
                investmentComponent = (
                    <ExpandedPlatform
                        key={investment.id}
                        investmentPlatform={investment}
                        similarInvestments={similarInvestments}
                        setActivePlatform={setActivePlatform}
                        expandable={expandable}
                        resetTotal={resetTotal}
                        search={search}
                        handleMoveToListView={handleMoveToListView}
						isListView={isListView}
                    />
                );
            }
            if (index === 3 && !isCollectionActive) {
                return (
                    <div key={`mailCard_${investment.id}_${index}`}>
                        <PseudoMailCard />
                        {investmentComponent}
                    </div>
                );
            }
            if (index % 10 === 0 && index > 0 && query === '' && !isCollectionActive) {
                return (
                    <div key={`calculatorBanner_${investment.id}_${index}`}>
                        {calulatorBanner}
                        {investmentComponent}
                    </div>
                );
            }
            if (
                (
                    (index === 5 && ads.length > 0) ||
                    (Object.keys(investments).length < 6 && index === Object.keys(investments).length - 2)
                ) && !isCollectionActive
            ) {
                return (
                    <div key={`ParallelMarkets${investment.id}_${index}`}>
                        {/*ads.length > 0 ? <AdUnit key={ads[0].name} ad={ads[0]} /> : null*/}
                        <ParallelMarkets
                            id={investment.id}
                        />
                        {investmentComponent}
                    </div>
                );
            }

            return investmentComponent;
        })
	);
};
