import React, { useEffect, useState, useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import { firstNameAndLastNameCombination, getFromNowDate } from '../../utils';
import { UserAvatar } from '../Avatar/Avatar';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});
const useStyles = makeStyles((theme) => ({
	postsTopRow: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '12px',
	},
	postsPerson: {
		marginLeft: '16px',
	},
	postsDate: {
		marginLeft: 'auto',
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '14px',
		lineHeight: '20px',
		color: '#73757A',
	},
}));
export const PostHeader = (props) => {
	const { displayName, timestamp, avatar } = props;
	const classes = useStyles();

	return (
		<div className={`${classes.postsTopRow}`}>
			<div className={`${classes.postsAvatar}`}>
				<UserAvatar
					name={displayName}
					avatarUrl={avatar}
					clickIgnore={true}
					size="48"
					randomColor={true}
					styleignore={false}
				/>
			</div>
			<div className={`${classes.postsPerson}`}>
				{firstNameAndLastNameCombination(displayName)}
			</div>
			<div className={`${classes.postsDate}`}>{getFromNowDate(timestamp)}</div>
		</div>
	);
};
