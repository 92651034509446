import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

export const SidebarCountry = ({ investmentPlatform }) => {
	return (
		<>
            <Typography style={{ marginBottom: '5px' }} paragraph>
				Country availability
			</Typography>
			<Typography style={{ color: 'black' }} paragraph>
				{investmentPlatform.global ? <>Worldwide</> : <>US only</>}
			</Typography>
        </>
	);
}
