import { REACT_APP_REMOTEAPI_SEND_SUBSCRIBE_TIPS_URL } from "./ConstAPI";

const makeURL = () => `${REACT_APP_REMOTEAPI_SEND_SUBSCRIBE_TIPS_URL}`;

const defaultHeaders = {
  "Content-Type" : "application/json; charset=utf-8"
};

const makeRequestData = data => ({
    method: "POST",
    headers: defaultHeaders,
    body: JSON.stringify(data),
});

const makeRequest = async (data) => {
    const url = makeURL();
    const requestData = makeRequestData(data);

    return await fetch(url, requestData);
};
/**
 * @param {string} email
 * @param {string} phone
 * @param {string} userId
 * @param {boolean} accredited
 */
export const sendSubscribeTipsData = async (email, phone, userId, accredited) => {
    try {
		const response = await makeRequest(
            {
                email,
                phone,
                userId,
                accredited
            }
        );
        const responseStatus = await response.json();
        if (response.status === 200) {
            return {status: "OK"};
        } else {
            return responseStatus;
        }
	} catch (error) {
        console.log(error);
		return {
			success: false,
			result: [],
			message: error,
        };
	}
};
