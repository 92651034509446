import {
	REMOTEAPI_URL,
	REMOTEAPI_ENTRY_URL,
	REMOTEAPI_PREMIUM_URL,
	REMOTEAPI_CLUSTERS_URL,
} from './ConstAPI';
import PropTypes from 'prop-types';

const createAPIQUery = (urlGenerator) => async (...params) => {
	try {
		const url = urlGenerator(...params);
		const headers = {
			'Content-Type': 'application/json; charset=utf-8',
		};
		const response = await fetch(url, { method: 'GET', headers });
		const json = await response.json();

		return json;
	} catch {
		return {
			success: false,
			result: [],
			message: 'There is an issue to get data from server. Please try again later.',
		};
	}
};

const createPOSTAPIQUery = (urlGenerator) => async (...params) => {
	//console.log(params);
	try {
		const headers = {
			'Content-Type': 'application/json; charset=utf-8',
		};
		const url = urlGenerator(...params);

		const response = await fetch(url, { method: 'POST', headers, body: params[0] });

		const json = await response.json();

		return {
			success: true,
			...json,
		};
	} catch {
		return {
			success: false,
			result: [],
			message: 'There is an issue to get data from server. Please try again later.',
		};
	}
};

export const getMore = createAPIQUery((more) => `${more}`);

export const getSearch = createAPIQUery((search) => `${REMOTEAPI_URL}`);

export const getSearchWithParams = createAPIQUery(
	(search, params) => `${REMOTEAPI_URL}/search?q=${search}${params}`
);

export const getResultWithParams = createAPIQUery(
	(params) => `${REMOTEAPI_URL}/${params}`
);

export const getFilterResult = createPOSTAPIQUery((params) => `${REMOTEAPI_URL}`);

export const getOneEntry = createPOSTAPIQUery((params) => `${REMOTEAPI_ENTRY_URL}`);

export const getPremiumEntries = createPOSTAPIQUery(
	(params) => `${REMOTEAPI_PREMIUM_URL}`
);

export const getClusters = createAPIQUery(() => `${REMOTEAPI_CLUSTERS_URL}`);

createAPIQUery.propTypes = {
	headers: PropTypes.array,
	url: PropTypes.string,
	response: PropTypes.object,
};
