import React, { useState } from "react";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { getUserLikes } from '../../Services/GetUserLikesAPI';
import { bumpComment } from '../../Services/BumpCommentAPI';
import * as actions from "../../redux/actions";

const useStyles = makeStyles({
    button: {
        padding: "0 0 0 12px",
    },
    noBumpButton: {
        "& $counter": {
            backgroundColor: "#F2F5F8",
        },
        "&:hover": {
            backgroundColor: "#F2F5F8",
        }
    },
    bumpedButton: {
        color: "#FFF",
        backgroundColor: "#33CD71",
        cursor: "default",
        "& $counter": {
            //backgroundColor: "#27BF64",
            backgroundColor: "#F2F5F8"
        },
        "&:hover": {
            //backgroundColor: "#33CD71",
            backgroundColor: "#F2F5F8",
        }
    },
    counter: {
        borderRadius: "5px",
        lineHeight: "32px",
        height: "32px",
        minWidth: "32px",
        marginLeft: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    progress: {
        width: "20px !important",
        height: "20px !important",
    },
    bump: {
        //marginLeft: "16px",
    },
});
export const BleakButton = withStyles({
    root: {
        fontSize: "14px",
        lineHeight: "20px",
        color: "#73757A",
        textTransform: "none",
        backgroundColor: "#FBFCFD",
    }
})(Button);

export const BumpButton = (props) => {
    const {
        postId,
        isBumped,
        counter,
        index: indexProp,
        loggedIn,
    } = props;
    const classes = useStyles();
    const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
    const dispatch = useDispatch();

    //const buttonClass = isBumped ? classes.bumpedButton : classes.noBumpButton;

    const [isBumpLoading, setIsBumpLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const onBump = async () => {
        setIsBumpLoading(true);
        const index = (indexProp !== null) ? indexProp : null;
        const response = await bumpComment(state.token, postId, index);
        if (!response.success) {
            dispatch(actions.setModalSignUpOpen(true));
            setIsLoaded(false);
        } else {
            setIsLoaded(true);
        }
        setIsBumpLoading(false);
    };
    const handleClick = (e) => {
        if (!state.loggedIn || loggedIn) {
            dispatch(actions.setModalSignUpOpen(true));
            return;
        }
        if (isBumped || isLoaded) {
            return;
        } else {
            onBump();
        }
    }

    return (
        <BleakButton
            className={`${classes.button} ${(isBumped || isLoaded) ? classes.bumpedButton : classes.noBumpButton} ${classes.bump}`}
            startIcon={<ThumbUpAltIcon />}
            onClick={handleClick}
        >
            {(isBumped || isLoaded) ? "Liked" : "Like"}
            <div className={classes.counter}>
                {isBumpLoading ?
                    <CircularProgress className={classes.progress} /> :
                    !isLoaded ?
                        counter: counter + 1}
            </div>
        </BleakButton>
    )
}
