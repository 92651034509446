import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { makeStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import { getFilterResult } from '../Services/BaseAPI.js';
import { InvestopediaCard } from '../components/InvestopediaWidget/InvestopediaCard2';
import { Spinner } from '../components/Spinner/Spinner.js';
import { isPaidOnly, paidOnlyData, capitalLetters, eventTracker } from '../utils';
import { routeNames } from '../data/Data';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as actions from '../redux/actions';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles((theme) => ({
	expandIcon: {
		position: 'absolute',
		top: '12px',
		right: '12px',
	},
}));

export const InvestopediaWidget2 = (props) => {
	const {
		utm_campaign,
		utm_medium,
		utm_source,
		w,
		h,
		i,
		industry,
	} = queryString.parse(props.location.search);
	const dispatch = useDispatch();
	const [displaySpinner, setDisplaySpinner] = useState(false);
	const [investments, setInvestments] = useState({});
	const classes = useStyles();
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	useEffect(() => {
		if (state.userId) {
			ReactGA.pageview(`/${routeNames.investopediaWidget2}`);
			eventTracker("pageview", `/${routeNames.investopediaWidget2}`, utm_source, utm_medium, utm_campaign, state.userId);
		}
		if (utm_source) {
			dispatch(actions.setItemSource(utm_source));
		}
		if (utm_campaign) {
			dispatch(actions.setItemCompaign(utm_campaign));
		}
		if (utm_medium) {
			dispatch(actions.setItemMedium(utm_medium));
		}
		if (industry) {
			dispatch(actions.setItemIndustry(industry));
		}
	}, [utm_campaign, utm_medium, utm_source, state.userId]);

	useEffect(() => {
		if (state.investmentsTotal) {
			let inv;
			if (isPaidOnly(utm_campaign)) {
				inv = paidOnlyData(state.investmentsTotal);
			} else {
				inv = state.investmentsTotal;
			}
			if (industry) {
				setInvestments(
					Object.values(inv).filter((i) => i.industry === capitalLetters(industry))
				);
			} else {
				setInvestments(inv);
			}
		}
	}, [state.investmentsTotal]);

	return (
		<div>
			<center>
				<Spinner displaySpinner={state.displaySpinner} />
			</center>
			<InvestopediaCard utmParams={{ w, h, i }} investments={investments} />
		</div>
	);
};
export default InvestopediaWidget2;
