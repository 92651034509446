import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MailCard } from './MailCard';

const useStyles = makeStyles((theme) => ({
	h2: {
		fontSize: '28px',
		lineHeight: '40px',
		fontFamily: 'Red Hat Display',
		color: '#30333E',
		fontWeight: 'normal',
		marginTop: '5px',
		marginBottom: '5px',
	},
	h5: {
		fontSize: '14px',
		lineHeight: '20px',
		fontFamily: 'Red Hat Display',
		color: '#73757A',
		fontWeight: 'normal',
	},
}));
export const MailCardInvestments = (props) => {
    const { investments } = props;
    const classes = useStyles();
    return (
        <center>
            <h2 className={classes.h2}>Your personal investment opportunities</h2>
            <h5 className={classes.h5}>
                Here is what we found based on your answers
            </h5>
            <MailCard investments={investments} />
        </center>
    )
}
