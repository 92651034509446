import React, { useState, useEffect, useMemo } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ReactGA from 'react-ga';
import { CustomSlider } from './CustomSlider';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const sliderText = {
	fontFamily: 'Inter',
	fontStyle: 'normal',
	fontWeight: '500',
	fontSize: '16px',
	lineHeight: '32px',
	paddingTop: '10px',

	color: '#30333E',
};
const sliderValueStyle = {
	fontFamily: 'Inter',
	color: '#30333E',
	backgroundColor: '#F2F5F8',
	borderRadius: '8px',
	padding: '10px',
	lineHeight: '24px',
	fontStyle: 'normal',
	fontWeight: 500,
	fontSize: '16px',
	maxWidth: '90px',
};
const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		margin: 0,
		padding: 0,
	},
	content: {
		flexGrow: 1,
		paddingTop: '0px !important',
	},
	expandableHeader: {
		[theme.breakpoints.down('301')]: {
			display: 'none',
		},
		backgroundColor: '#5056E7',
		color: '#FFFFFF',
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: '500',
		fontSize: '18px',
		lineHeight: '28px',
		textAlign: 'center',
		padding: '10px 0 10px 0',
	},
	slider: {
		width: '100%',
	},
	sliderText1: {
		[theme.breakpoints.up('499')]: {
			display: 'none',
		},
		[theme.breakpoints.down('499')]: {
			display: 'inline-block',
			margin: '0 0 0 14px',
		},
		[theme.breakpoints.down('320')]: {
			display: 'inline-block',
			margin: '0',
		},
	},
	sliderValue1: {
		[theme.breakpoints.up('499')]: {
			display: 'none',
		},
		[theme.breakpoints.down('499')]: {
			display: 'inline-block',
			float: 'right',
			marginRight: '14px',
		},
		[theme.breakpoints.down('320')]: {
			display: 'inline-block',
			float: 'right',
			marginRight: '0',
		},
	},
	sliderText2: {
		fontSize: '14px',
		lineHeight: '20px',
		fontWeight: '500',
		fontFamily: 'Inter',
	},
	sliderValue2: {
		fontFamily: 'Red Hat Display',
		fontSize: '28px',
		lineHeight: '40px',
		color: '#30333E',
		backgroundColor: '#F2F5F8',
		borderRadius: '8px',
		padding: '10px',
		textAlign: 'center',
		fontWeight: 500,
		[theme.breakpoints.down('380')]: {
			fontSize: '20px',
			marginRight: '0',
		},
	},
	dynamicContainer: {
		[theme.breakpoints.up('499')]: {
			display: 'flex',
			alignItems: 'center',
		},
		[theme.breakpoints.down('499')]: {
			display: 'block',
		},
	},
}));

export const SliderCard = (props) => {
	const { sliderValue, onCardClick, handleChange } = props;
	const classes = useStyles();
	const [value, setValue] = React.useState(sliderValue);

	const handleChangeAction = (newValue) => {
		setValue(newValue);
		handleChange(newValue);
	};
	const sliderTextElement = `${
		window.innerWidth > 370 ? 'Select ' : ''
	}Investment Amount`;
	const sliderValueElement = (
		<span>${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
	);
	const sliderElement = (
		<CustomSlider
			sliderValue={value}
			onCardClick={onCardClick}
			handleChange={handleChangeAction}
			sliderClass={classes.slider}
		/>
	);
	useEffect(() => {
		if (sliderValue) {
			setValue(sliderValue);
		}
	}, [sliderValue]);

	return (
		<>
			<Grid container direction="row" justify="space-between" alignItems="center">
				<Grid xs={7} sm={8} item>
					<div className={classes.sliderText2} style={sliderText}>
						{sliderTextElement}
					</div>
					<div className={classes.slider}>{sliderElement}</div>
				</Grid>
				<Grid xs={4} sm={3} item>
					<div className={classes.sliderValue2}>{sliderValueElement}</div>
				</Grid>
			</Grid>
		</>
	);
};
