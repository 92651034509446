import React, { useState, useEffect, useMemo } from 'react';
import Avatar from '@material-ui/core/Avatar';

import CssBaseline from '@material-ui/core/CssBaseline';

import { makeStyles, useTheme, withStyles, fade } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import Card from '@material-ui/core/Card';
import VizSensor from 'react-visibility-sensor';

import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as actions from "../../../redux/actions";
import { getAds } from '../../../Services/AdsAPI';
import { getClusters, getFilterResult, getSearch } from '../../../Services/BaseAPI.js';
import { Spinner } from '../../Spinner/Spinner';

import { FBSendEvents, GASendEvent, trackEvent, isPaidOnly, generateId } from '../../../utils';
import { routeNames } from '../../../data/Data';

import moment from "moment";

import CheckIcon from "@material-ui/icons/Check";
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import ReviewAvatar from "../../InvestmentPlatform/ReviewAvatar";
import Rating from "../../Rating";
import BumpReviewButton from "../../InvestmentPlatform/BumpReviewButton";
import {bumpReview} from "../../../Services/ReviewsAPI";

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const drawerWidth = 247;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        "& p": {
            marginTop: 0,
        }
    },
    content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
    favoritesTitle: {
        fontFamily: 'Red Hat Display',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '28px',
        lineHeight: '40px',
        color: '#30333E',
        padding: '40px 0 0 0px',
    },
    favoritesCount: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#73757A',
        padding: '4px 0 24px 0px',
    },
    investCard: {
        width: '100%',
        maxWidth: '978px',
        margin: 'auto',
        padding: '0px 0 0px 24px;',

        background: '#FFFFFF',
        border: '1px solid #F2F5F8',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
        borderRadius: '8px',

        [theme.breakpoints.down('770')]: {
            width: '100%',
        },
    },
    header: {
        width: '100%',
        maxWidth: '978px',
        margin: 'auto',
    },
    reviewHeading: {
        display: "flex",
        justifyContent: "space-between",
    },
    reviewsBlockRow: {
        paddingLeft: "24px",
        paddingRight: "24px",
        paddingTop: '20px',
        paddingBottom: '24px',
        [theme.breakpoints.down('340')]: {
            paddingLeft: "0px",
        },
    },
    borderStyle: {
        borderBottom: "1px solid #F2F5F8",
    },
    headingSubsection: {
        display: "flex",
        alignItems: "center",
        marginBottom: "12px",
    },
    reviewerName: {
        color: "#30333E",
        fontSize: "14px",
        fontWeight: "bold",
        margin: "0 0 0 16px",
    },
    rating: {
        marginLeft: "10px",
    },
    verifiedBadge: {
        fontSize: "14px",
        color: "#33CD71",
        margin: 0,
        "& span": { verticalAlign: "middle" },
    },
    verifiedIcon: {
        fontSize: "16px",
        verticalAlign: "middle"
    },
    moderateBadge: {
        fontSize: "14px",
        color: "#F3B81F",
        margin: 0,
        "& span": { verticalAlign: "middle" },
    },
    declainedBadge: {
        fontSize: "14px",
        color: "red",
        margin: 0,
        "& span": { verticalAlign: "middle" },
    },
    bump: {
        marginLeft: "16px",
    },
    comment: {
        fontSize: "14px",
        lineHeight: "20px",
        color: "#30333E",
        marginBottom: "12px",
    },
    date: {
        fontSize: "14px",
        color: "#73757A",
        textAlign: "right",
        marginBottom: 0,
    },
    [theme.breakpoints.down("xs")]: {
        reviewHeading: {
            flexWrap: "wrap",
        },
        headingSubsection: {
            width: "100%",
        },
        rightSubsection: {
            justifyContent: "space-between",
        },
        bump: { marginLeft: 0 },
    },
    noBumpButton: {
        "& $counter": {
            backgroundColor: "#F2F5F8",
        },
    },
    counter: {
        borderRadius: "5px",
        lineHeight: "32px",
        height: "32px",
        minWidth: "32px",
        marginLeft: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    avatar: {
		backgroundColor: '#f3f5f8',
	},
}));

export const ReviewsCard = (props) => {
    const {
        reviews: reviewsProp,
        handleLoadReviews
    } = props;
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
    const dispatch = useDispatch();
    const classes = useStyles();
	const theme = useTheme();

	const [paidOnly, setPaidOnly] = useState(isPaidOnly(state.itemCompaign));
    const [medium, setMedium] = useState(state.itemMedium);
    const [source, setSource] = React.useState(state.itemSource || 'MoneyMade');
	const [displaySpinner, setDisplaySpinner] = useState(false);
	const [isListView, setIsListView] = useState(true);
	const [open, setOpen] = useState(0);
	const [reviews, setReviews] = useState(reviewsProp);
	const [ads, setAds] = useState([]);
	const [openModal, setOpenModal] = useState(state.modalOpen);
	const [openModalSignUp, setOpenModalSignUp] = useState(state.modalSignUpOpen);
    const [quizValue, setQuizValue] = useState(false);

    const areInvestmentsPresent = Object.values(reviews).length !== 0;

	useEffect(() => {
		if (state.itemCompaign) {
			setPaidOnly(isPaidOnly(state.itemCompaign));
		}
    }, [state.itemCompaign]);
	useEffect(() => {
		if (state.itemMedium) {
			setMedium(state.itemMedium);
		}
    }, [state.itemMedium]);
    useEffect(() => {
		if (state.itemMedium) {
			setMedium(state.itemMedium);
		}
	}, [state.itemMedium]);
	useEffect(() => {
        if (reviews.length > 0) {
            return;
        }
		const requestParams = {};
        setDisplaySpinner(true);
        getFilterResult(JSON.stringify(requestParams))
        .then((data) => {
            const reviews = [
            {
                id: generateId(),
                name: 'DiversyFund',
                rating: 5,
                logo: 'https://firebasestorage.googleapis.com/v0/b/benchmark-media.appspot.com/o/logos%2Fdiversyfund.png?alt=media&token=a200f777-1078-4112-bddf-327f9e2d04e4',
                verified: 0,
                helpful: 4,
                comment: 'A black hole is a region of spacetime exhibiting gravitational acceleration so strong that nothing—no particles or even electromagnetic radiation such as light—can escape from it. The theory of general relativity predicts that a sufficiently compact mass can deform spacetime to form a black hole.',
                timestamp: '1594311117',
            },
            {
                id: generateId(),
                name: 'Masterworks',
                rating: 4,
                logo: 'https://firebasestorage.googleapis.com/v0/b/benchmark-media.appspot.com/o/logos%2F1593640292444_Materworks.gif?alt=media&token=533bf607-12e5-4329-82e1-42542babd6dc',
                verified: 1,
                helpful: 1,
                comment: 'In for Banksy! Love owning a piece of history...but also hoping it hits the paper shredder on its next auction ;) Great platform and no complaints here',
                timestamp: '1594327521',
            },
            {
                id: generateId(),
                name: 'LexShares',
                rating: 5,
                logo: 'https://firebasestorage.googleapis.com/v0/b/benchmark-media.appspot.com/o/logos%2F1588138664659_Lexshares.png?alt=media&token=dd423312-6dc4-4be8-9ca5-d3c1b91b4474',
                verified: 2,
                helpful: 0,
                comment: 'A black hole is a region of spacetime exhibiting gravitational acceleration so strong that nothing—no particles or even electromagnetic radiation such as light—can escape from it. The theory of general relativity predicts that a sufficiently compact mass can deform spacetime to form a black hole.',
                timestamp: '1580275002',
            },
            ];
            setReviews(reviews);
            handleLoadReviews(reviews);
            setDisplaySpinner(false);
        });
    }, []);

	return (
		<div className={`respCont ${classes.root}`}>
            <CssBaseline />
            <div className={classes.toolbar} />
			<main className={`${classes.content} mainPrinc`}>
               <div className={classes.header}>
                    <div className={classes.favoritesTitle}>
                        Your reviews
                    </div>
                    <div className={classes.favoritesCount}>
                        {`${reviews.length}`} reviews
                    </div>
               </div>
                {areInvestmentsPresent ?
                    (<div className={classes.investCard}>
                        {reviews.map(function (review, index) {
						    return (<div key={review.id}>
                                    <div className={`${classes.reviewsBlockRow} ${(reviews.length - 1 !== index) ? classes.borderStyle : null}`}>
                                        <div className={classes.reviewHeading}>
                                            <div className={classes.headingSubsection}>
                                                <Avatar aria-label="avatar" className={`${classes.avatar}`}>
                                                    <img
                                                        alt={review.name}
                                                        style={{ maxWidth: '40px' }}
                                                        src={review.logo}
                                                    />
                                                </Avatar>
                                                <p className={classes.reviewerName}>{review.name}</p>
                                                <Rating className={classes.rating} value={review.rating} />
                                            </div>

                                            <div className={`${classes.headingSubsection} ${classes.rightSubsection}`}>
                                                {review.verified === 0 && (
                                                    <p className={classes.verifiedBadge}>
                                                        <CheckIcon className={classes.verifiedIcon} /> <span>Verified</span>
                                                    </p>
                                                )}
                                                {review.verified === 1 && (
                                                    <p className={classes.moderateBadge}>
                                                        <AccessTimeIcon className={classes.verifiedIcon} /> <span>On moderation</span>
                                                    </p>
                                                )}
                                                {review.verified === 2 && (
                                                    <p className={classes.declainedBadge}>
                                                        <CheckIcon className={classes.verifiedIcon} /> <span>Declained</span>
                                                    </p>
                                                )}
                                                <div className={classes.noBumpButton}>
                                                    <div className={classes.counter}>
                                                        {review.helpful}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <p className={classes.comment}>
                                            {review.comment}
                                        </p>

                                        <p className={classes.date}>{moment.unix(review.timestamp).fromNow()}</p>
                                    </div>
                                    </div>)
                            })}
                    </div>) : null
                }
				{!areInvestmentsPresent && (
                    <>
                        <center>
                            <Spinner displaySpinner={displaySpinner} />
                        </center>
                        <div>
                            <p style={{ textAlign: 'center' }}>Loading results...</p>
                        </div>
                    </>
				)}
			</main>
		</div>
	);
};
