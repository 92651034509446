import React from "react";
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import DualBallLoading from '../../img/loading/DualBallLoading.gif';

export const Spinner = props => {

  return (
          <div style={{ display: props.displaySpinner ? 'block' : 'none', position: 'relative', zIndex: '91209190' }}>
            <img
              src={DualBallLoading}
              alt="Loading"
              height={50}
              width={50}
            />
          {/*
          <Loader
            type="TailSpin"
            color="#5056E7"
            height={60}
            width={60}
            timeout={10000}
          />
          */}
          </div>
  );
};
