import React, { useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ReactGA from 'react-ga';
import { eventTracker, trackEvent } from '../../utils';
import * as actions from "../../redux/actions";
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { ChipsInvestment } from './ChipsInvestment';
import { ChipsTarget } from './ChipsTarget';

ReactGA.initialize('UA-148517382-4');

const useStyles = makeStyles((theme) => ({
	tooltip: {
		zIndex: 10001, // to be visible inside the modal
    },
    chipsMobile: {
        [theme.breakpoints.down('445')]: {
			display: 'none',
		},
    },
}));

export const HeaderThirdGrid = (props) => {
	const {
		investmentPlatform,
		expanded,
        expandable,
        pseudoborder,
        hide,
	} = props;
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const dispatch = useDispatch();
	const classes = useStyles();

	const [source, setSource] = React.useState(state.itemSource || 'MoneyMade');

	return (
		<>
            <div>
                <p
                    style={{
                        fontSize: '14px',
                        margin: '0',
                        marginTop: `${hide ? '14px' : '28px'}`,
                        color: '#73757A',
                    }}
                >
                    Target return
                </p>
                <h4
                    style={{
                        fontWeight: 'normal',
                        marginTop: '0',
                        marginBottom: '10px',
                        fontSize: '20px',
                    }}
                >
                    {investmentPlatform.target
                        ? investmentPlatform.target.min
                            ? `${investmentPlatform.target.min}%`
                            : 'Varied'
                        : ''}
                    {investmentPlatform.target
                        ? investmentPlatform.target.max &&
                            ` - ${investmentPlatform.target.max}%`
                        : ''}
                </h4>

                <div className={`${hide === true ? classes.chipsMobile : ''}`}>
                    <ChipsTarget
                        investmentPlatform={investmentPlatform}
                    />
                    </div>

                {pseudoborder &&
                <div className="pseudoborder"></div>}
            </div>
		</>
	);
}
