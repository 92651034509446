import React from 'react';
import moment from 'moment';

import CheckIcon from '@material-ui/icons/Check';
import makeStyles from '@material-ui/core/styles/makeStyles';

import ReviewAvatar from './ReviewAvatar';
import Rating from '../Rating';
import { useReviewStyles } from './review-styles';
import BumpReviewButton from './BumpReviewButton';
import { bumpReview } from '../../Services/ReviewsAPITrade';

const useStyles = makeStyles(theme => ({
	root: {
		paddingBottom: '24px',
		marginTop: '24px',

		'& p': {
			marginTop: 0
		}
	},
	reviewHeading: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	headingSubsection: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '12px'
	},
	reviewerName: {
		color: '#30333E',
		fontSize: '14px',
		fontWeight: 'bold',
		margin: '0 0 0 16px'
	},
	rating: {
		marginLeft: '10px'
	},
	verifiedBadge: {
		fontSize: '14px',
		color: '#33CD71',
		margin: 0,

		'& span': { verticalAlign: 'middle' }
	},
	verifiedIcon: {
		fontSize: '16px',
		verticalAlign: 'middle'
	},
	bump: {
		marginLeft: '16px'
	},
	comment: {
		fontSize: '14px',
		lineHeight: '20px',
		color: '#30333E',
		marginBottom: '12px'
	},
	date: {
		fontSize: '14px',
		color: '#73757A',
		textAlign: 'right',
		marginBottom: 0
	},
	[theme.breakpoints.down('xs')]: {
		reviewHeading: {
			flexWrap: 'wrap'
		},
		headingSubsection: {
			width: '100%'
		},
		rightSubsection: {
			justifyContent: 'space-between'
		},
		bump: { marginLeft: 0 }
	}
}));

const shortenName = name => {
	const [firstName, lastName] = name.split(' ');
	return lastName ? `${firstName} ${lastName[0]}.` : name;
};

export default function InvestmentPlatformReview({ platformId, review, onUpdate }) {
	const classes = useStyles();
	const commonClasses = useReviewStyles();
	const [isBumpLoading, setIsBumpLoading] = React.useState(false);

	const onBump = async () => {
		setIsBumpLoading(true);
		await bumpReview(platformId, review.id);
		onUpdate({ ...review, helpful: review.helpful + 1, isBumped: true });
		setIsBumpLoading(false);
	};

	return (
		<div className={`${classes.root} ${commonClasses.reviewsBlockRow}`}>
			<div className={classes.reviewHeading}>
				<div className={classes.headingSubsection}>
					<ReviewAvatar name={review.name} />
					<p className={classes.reviewerName}>{shortenName(review.name)}</p>
					<Rating className={classes.rating} value={review.rating} />
				</div>

				<div className={`${classes.headingSubsection} ${classes.rightSubsection}`}>
					{review.verified && (
						<p className={classes.verifiedBadge}>
							<CheckIcon className={classes.verifiedIcon} /> <span>Verified</span>
						</p>
					)}

					<BumpReviewButton
						isBumped={review.isBumped}
						isLoading={isBumpLoading}
						onBump={onBump}
						className={classes.bump}
						review={review}
					/>
				</div>
			</div>

			<p className={classes.comment}>{review.comment}</p>

			<p className={classes.date}>{moment.unix(review.timestamp).fromNow()}</p>
		</div>
	);
}
