import React, { useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ReactGA from 'react-ga';
import { Button } from '@material-ui/core';
import { eventTracker, trackEvent, generateSubId } from '../../utils';
import { useSelector, shallowEqual } from 'react-redux';
import arrow from '../../img/arrow.svg';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles({
	visitWebsiteButton: {
		textTransform: 'none',
		//marginBottom: '10px',
		margin: 'auto',
		maxWidth: '1200px',
		//borderRadius: '8px',
		//borderRadius: '100px',
		color: '#fff',
	},
	color1: {
		background: '#5056E7',
		'&:hover': {
			backgroundColor: 'rgb(80,86,231, 0.8)',
		},
	},
	color2: {
		background: '#323A56',
		'&:hover': {
			backgroundColor: 'rgb(50,58,86, 0.8)',
		},
	},
});

export const VisitWebsite = (props) => {
	const { investmentPlatform, color } = props;
	const classes = useStyles();
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const [source, setSource] = React.useState(state.itemSource || 'MoneyMade');

	const stopPropagation = (event) => {
		trackEvent('ExternalClick', 'platform', investmentPlatform.name);
		eventTracker(
			'ExternalClick',
			investmentPlatform.name,
			state.itemSource,
			state.itemMedium,
			state.itemCompaign,
			state.userId
		);
		event.stopPropagation();
	};
	let backgroundColor;
	switch (color) {
		case '1':
			backgroundColor = classes.color1;
			break;
		case '2':
			backgroundColor = classes.color2;
			break;
		default:
			backgroundColor = classes.color1;
			break;
	}

	return (
		<Button
			fullWidth={true}
			href={investmentPlatform.website.replace('%SUBID%', generateSubId(state.itemSource, state.itemMedium))}
			target="_blank"
			className={`${classes.visitWebsiteButton} ${backgroundColor}`}
			rel="noopener noreferrer"
			onClick={stopPropagation}
		>
			Visit website
			<img src={arrow} alt="arrow" style={{ marginLeft: '10px' }} />
		</Button>
	);
};
