import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import expandIcon from '../../img/icons/external-link.svg';
import Tooltip from '@material-ui/core/Tooltip';
import ShareIcon from '@material-ui/icons/Share';
import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import InvestmentPlatformAvatar from './Avatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Rating from '../Rating';
import { getSentimentText } from './helpers';

import ReactGA from 'react-ga';
import { eventTracker, trackEvent, generateSubId } from '../../utils';
import { useSelector, shallowEqual } from 'react-redux';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles({
	firstGrid: {
		marginRight: '20px',
	},
	borderedColumn: {
		position: 'relative',

		'& .pseudoborder': {
			minHeight: '100px',
			height: 'calc(100% - 40px) !important',
			top: '28px',
			left: '-24px',
			margin: '0 !important',
		},
	},
	secondColumn: {
		paddingRight: '32px',
	},
	subheader: {
		display: 'flex',
		alignItems: 'center',
	},
	rating: {
		marginLeft: '8px',
	},
	tooltip: {
		zIndex: 10001, // to be visible inside the modal
	},
});

export default function Header(props) {
	const { investmentPlatform } = props;
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const [copySuccess, setCopySuccess] = React.useState(false);
	const [source, setSource] = React.useState(state.itemSource || 'MoneyMade');
	const classes = useStyles();

	useEffect(() => {});

	function stopPropagation(event) {
		trackEvent('ExternalClick', 'platform', investmentPlatform.name);
		eventTracker("ExternalClick", 'Parallel markets', state.itemSource, state.itemMedium, state.itemCompaign, state.userId);

		event.stopPropagation();
	}

	function clickCopy(url) {
		//	console.log(url);
		copyToClipboard(url);
		setCopySuccess(true);
		setTimeout(() => setCopySuccess(false), 1200);
	}

	function copyToClipboard(copyText) {
		var textField = document.createElement('textarea');
		textField.innerText = copyText;
		document.body.appendChild(textField);
		textField.select();
		document.execCommand('copy');
		textField.remove();
	}

	return (
		<Grid container alignItems="flex-start">
			<Grid className={`${classes.firstGrid} `} item xs={5}>
				<CardHeader
					avatar={
						<Tooltip
							disableFocusListener
							disableTouchListener
							classes={{ popper: classes.tooltip }}
							placement="top"
							title={getSentimentText(investmentPlatform.sentiment)}
							arrow
						>
							<span>
								<InvestmentPlatformAvatar investmentPlatform={investmentPlatform} />
							</span>
						</Tooltip>
					}
					title={
						<div>
							<Tooltip
								disableFocusListener
								disableTouchListener
								classes={{ popper: classes.tooltip }}
								placement="top"
								title={`Visit site`}
								arrow
							>
								<a
									target="_blank"
									rel="noopener noreferrer"
									style={{ cursor: 'pointer' }}
									href={investmentPlatform.website.replace('%SUBID%', generateSubId(state.itemSource, state.itemMedium))}
									onClick={stopPropagation}
								>
									{investmentPlatform.name}
									<img
										alt="expandIcon"
										style={{ marginBottom: '-2px', marginLeft: '5px' }}
										src={expandIcon}
									/>
								</a>
							</Tooltip>
							<Tooltip
								disableFocusListener
								disableTouchListener
								classes={{ popper: classes.tooltip }}
								placement="top"
								title={`Click to copy profile URL to share`}
								arrow
							>
								<a
									onClick={(event) => {
										event.stopPropagation();
										props.platformUrl && clickCopy(props.platformUrl);
									}}
								>
									<Tooltip
										disableFocusListener={true}
										disableHoverListener={true}
										className=""
										placement="bottom"
										title={`Copied!`}
										open={copySuccess}
										arrow
									>
										<ShareIcon
											style={{
												cursor: 'pointer',
												color: '#cacbce',
												marginBottom: '-5px',
												marginLeft: '7px',
											}}
											fontSize="small"
										/>
									</Tooltip>
								</a>
							</Tooltip>
						</div>
					}
					subheader={
						<div className={classes.subheader}>
							<Chip
								style={{ marginTop: '5px' }}
								label={investmentPlatform.level}
								className="ctag gray"
							/>

							{(investmentPlatform.averageRating || null) && (
								<Rating
									className={classes.rating}
									value={investmentPlatform.averageRating}
								/>
							)}
						</div>
					}
				/>

				<CardContent style={{ paddingTop: '0px', paddingRight: '24px' }}>
					<Typography
						variant="body2"
						color="textSecondary"
						component="p"
						style={{ maxWidth: '635px', marginLeft: '56px', color: '#73757A' }}
					>
						{investmentPlatform.description}
					</Typography>
				</CardContent>
				<CardActions disableSpacing></CardActions>
			</Grid>
			<Grid
				className={`${classes.borderedColumn} ${classes.secondColumn}`}
				item
				xs={3}
			>
				<p
					style={{
						fontSize: '14px',
						margin: '0',
						marginTop: '28px',
						marginBottom: '10px',
						color: '#73757A',
					}}
				>
					Trade
				</p>
				{investmentPlatform.trades.map((trade) => (
					<Tooltip
						disableFocusListener
						disableTouchListener
						classes={{ popper: classes.tooltip }}
						placement="top"
						title={trade}
						arrow
					>
						<Chip label={trade} className="ctag blue" />
					</Tooltip>
				))}
				<div className="pseudoborder"></div>
			</Grid>
			<Grid
				className={`${classes.borderedColumn} ${classes.secondColumn}`}
				item
				xs={3}
			>
				<p
					style={{
						fontSize: '14px',
						margin: '0',
						marginTop: '28px',
						color: '#73757A',
					}}
				>
					Account min
				</p>
				<h4
					style={{
						fontWeight: 'normal',
						marginTop: '0',
						marginBottom: '10px',
						fontSize: '20px',
					}}
				>
					${' '}
					{investmentPlatform.minInvestment
						.toString()
						.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
				</h4>
				<Tooltip
					disableFocusListener
					disableTouchListener
					classes={{ popper: classes.tooltip }}
					placement="top"
					title={
						investmentPlatform.commissionFree ? `COMISSION FREE` : `HAS COMISSION`
					}
					arrow
				>
					<Chip
						label={
							investmentPlatform.commissionFree ? `COMISSION FREE` : `HAS COMISSION`
						}
						className="ctag green"
					/>
				</Tooltip>
				<div className="pseudoborder"></div>
			</Grid>
		</Grid>
	);
}
