import React, { useEffect, Suspense, lazy } from 'react';
import './App.scss';
import ReactGA from 'react-ga';
import * as actions from './redux/actions';
import { routeNames } from './data/Data';
import { generateUserId, generateFingerprintObject, eventTracker, disableMailChimp } from './utils';
import { getFilterResult, getClusters } from './Services/BaseAPI';
import { getAds } from './Services/AdsAPI';
import { sessionInfoAPI } from './Services/SessionInfoAPI';
import { getTypes } from './Services/GetTypesAPI';
import { Switch, Route } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Fingerprint2 from 'fingerprintjs2';

import Home from './pages/Home.jsx';
import ThankYou from './pages/ThankYou';
import Discover from './pages/Discover.jsx';
import Trading from './pages/Trading.jsx';
import Calculator from './pages/Calculator.jsx';
import CalculatorPaid from './pages/CalculatorPaid.jsx';
import EmbedEntry from './pages/EmbedEntry';
import EmbedTradingEntry from './pages/EmbedTradingEntry';
import EmbedCalculator from './pages/EmbedCalculator';
import ExpandableDiscovery from './pages/ExpandableDiscovery';
import TermsAndCondition from './pages/TermsAndCondition.jsx';
import CustomWidget from './pages/CustomWidget';
import InvestopediaWidget from './pages/InvestopediaWidget';
import InvestopediaWidget2 from './pages/InvestopediaWidget2';
import InvestorTips from './pages/InvestorTips';
import Dashboard from './pages/Dashboard';
import Stream from './pages/Stream';
import Deepdive from './pages/Deepdive';
import QASession from './pages/QASession';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const App = () => {
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const dispatch = useDispatch();

	useEffect(() => {
		if (window !== window.parent) {
			disableMailChimp();
		}
	}, []);
	useEffect(() => {
		if (!state.userId) {
			let userId = '';
			try {
				userId = generateUserId();
				if (!userId) {
					eventTracker("userIdNotGenerated", userId.toString(), state.itemSource, state.itemMedium, state.itemCompaign, userId.toString());
				} else {
					dispatch(actions.setUserId(userId));
				}
			} catch (error) {
				eventTracker("userIdNotGenerated", error.toString(), state.itemSource, state.itemMedium, state.itemCompaign, userId.toString());
			}
		} else {
			if (!state.fpSent) {
				getSystemInfo();
			}
		}
	}, [state.userId]);

	const getSystemInfo = () => {
		if (window.requestIdleCallback) {
			requestIdleCallback(() => {
				getFingetPrint2();
			});
		} else {
			setTimeout(() => {
				getFingetPrint2();
			}, 500);
		}
	}

	const getFingetPrint2 = async () => {
		Fingerprint2.get({}, (components) => {
			var values = components.map((component) => { return component.value });
			var murmur = Fingerprint2.x64hash128(values.join(''), 31);

			const fp = generateFingerprintObject(components, murmur, state.userId);
			sessionInfoAPI(fp).then((data) => {
				if (data.success) {
					dispatch(actions.fingerprintSent(true));
				} else {
					console.log(data);
				}
			}).catch((error) => {
				console.log(error);
			});
		});
	}
	useEffect(() => {
		flowAsync();
	}, []);

	const flowAsync = async () => {
		dispatch(actions.displaySpinner(true));

		const investments = await getFilterResult(JSON.stringify({}));
		const filteredInvestments = Object.values(investments).filter((i) => i !== true);
		dispatch(actions.setInvestmentsTotal(filteredInvestments));

		const ads = await getAds();
		dispatch(actions.setAds(ads));

		const clusters = await getClusters();
		dispatch(actions.setClusters(clusters));

		const types = await getTypes();
		dispatch(actions.setTypes(types.result));

		dispatch(actions.displaySpinner(false));
	};

	/*
	const Home = lazy(() => import('./pages/Home.jsx'));
	const ThankYou = lazy(() => import('./pages/ThankYou.jsx'));
	const Discover = lazy(() => import('./pages/Discover.jsx'));
	const Trading = lazy(() => import('./pages/Trading.jsx'));
	const Calculator = lazy(() => import('./pages/Calculator.jsx'));
	const CalculatorPaid = lazy(() => import('./pages/CalculatorPaid.jsx'));
	const EmbedEntry = lazy(() => import('./pages/EmbedEntry.jsx'));
	const EmbedTradingEntry = lazy(() => import('./pages/EmbedTradingEntry.jsx'));
	const EmbedCalculator = lazy(() => import('./pages/EmbedCalculator.jsx'));
	const ExpandableDiscovery = lazy(() => import('./pages/ExpandableDiscovery.jsx'));
	const TermsAndCondition = lazy(() => import('./pages/TermsAndCondition.jsx'));

	const CustomWidget = lazy(() => import('./pages/CustomWidget.jsx'));
	const InvestopediaWidget = lazy(() => import('./pages/InvestopediaWidget.jsx'));
	const InvestopediaWidget2 = lazy(() => import('./pages/InvestopediaWidget2.jsx'));
	const InvestorTips = lazy(() => import('./pages/InvestorTips.jsx'));
	const Dashboard = lazy(() => import('./pages/Dashboard.jsx'));*/


	return (
		<Switch>
			<Route exact path="/" component={Home} />
			<Route exact path="/thankyou" component={ThankYou} />
			<Route path="/discover" component={Discover} />
			<Route path="/trading" component={Trading} />
			<Route
				path={`/${routeNames.dashboard}`}
				component={Dashboard}
			/>
			<Route
				path={`/${routeNames.stream}`}
				component={Stream}
			/>
			<Route
				path={`/${routeNames.deepdive}`}
				component={Deepdive}
			/>
			<Route exact path="/calculator" component={Calculator} />
			<Route exact path="/calculatorPaid" component={CalculatorPaid} />
			<Route path="/embedEntry/:id" component={EmbedEntry} />
			<Route path="/embedTrading/:id" component={EmbedTradingEntry} />
			<Route path="/embedCalculator" component={EmbedCalculator} />
			<Route path="/expandableDiscovery" component={ExpandableDiscovery} />
			<Route
				path={`/${routeNames.investorTips}`}
				component={InvestorTips}
			/>
			<Route
				path={`/${routeNames.customWidget}`}
				component={CustomWidget}
			/>
			<Route
				path={`/${routeNames.investopediaWidget}`}
				component={InvestopediaWidget}
			/>
			<Route
				path={`/${routeNames.investopediaWidget2}`}
				component={InvestopediaWidget2}
			/>
			<Route exact path="/terms-and-condition" component={TermsAndCondition} />
			<Route
				path={`/${routeNames.qaSession}`}
				component={QASession}
			/>
		</Switch>
	);
};

export default App;
