import React from "react";

import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { BleakButton } from "../BleakButton";

const useStyles = makeStyles({
    button: {
        padding: "0 0 0 12px",
    },
    noBumpButton: {
        "& $counter": {
            backgroundColor: "#F2F5F8",
        },
        "&:hover": {
            backgroundColor: "#F2F5F8",
        }
    },
    bumpedButton: {
        color: "#FFF",
        backgroundColor: "#33CD71",
        cursor: "default",
        
        "& $counter": {
            backgroundColor: "#27BF64",
        },
        "&:hover": {
            backgroundColor: "#33CD71",
        }
    },
    counter: {
        borderRadius: "5px",
        lineHeight: "32px",
        height: "32px",
        minWidth: "32px",
        marginLeft: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    progress: {
        width: "20px !important",
        height: "20px !important",
    },
});

export default function BumpReviewButton({ className, review, isBumped, isLoading, onBump }) {
    const classes = useStyles();
    const buttonClass = isBumped ? classes.bumpedButton : classes.noBumpButton;

    return (
        <BleakButton
            className={`${classes.button} ${buttonClass} ${className}`}
            startIcon={<ThumbUpAltIcon />}
            onClick={isBumped ? null : onBump}
        >
            {isBumped ? "Thanks!" : "Helpful"}
            <div className={classes.counter}>
                {isLoading ? <CircularProgress className={classes.progress} /> : review.helpful}
            </div>
        </BleakButton>
    )
}