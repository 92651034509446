import * as actions from "./types";
import { initialData } from "../data/Data";

export const getReducer = (
    state = initialData,
    action,
  ) => {
    switch (action.type) {
      case actions.SET_ITEM_COMPAIGN:
        return {
          ...state,
          itemCompaign: action.payload,
        };
      case actions.SET_ITEM_MEDIUM:
        return {
          ...state,
          itemMedium: action.payload,
        };
      case actions.SET_ITEM_SOURCE:
        return {
          ...state,
          itemSource: action.payload,
        };
      case actions.SET_ITEM_INDUSTRY:
        return {
          ...state,
          itemIndustry: action.payload,
        };
      case actions.SET_MODAL_OPEN:
        return {
          ...state,
          modalOpen: action.payload,
        };
      case actions.SET_MODAL_SIGNUP_OPEN:
        return {
          ...state,
          modalSignUpOpen: action.payload,
        };
      case actions.SET_INVESTMENTS:
        return {
          ...state,
          investments: action.payload,
        };
      case actions.SET_QUIZ_RESULTS:
        return {
          ...state,
          quizResults: action.payload,
        };
      case actions.SET_CLUSTER_VIEW:
        return {
          ...state,
          clusterView: action.payload,
        };
      case actions.SET_USER_ID:
        return {
          ...state,
          userId: action.payload,
        };
      case actions.SET_INVESTMENTS_TOTAL:
        return {
          ...state,
          investmentsTotal: action.payload,
        };
      case actions.SET_CLUSTERS:
        return {
          ...state,
          clusters: action.payload,
        };
      case actions.SET_ADS:
        return {
          ...state,
          ads: action.payload,
        };
      case actions.ADD_INVESTMENTS_TO_FAVORITES:
        return {
          ...state,
          favoritesInvestments: action.payload,
        };
      case actions.ADD_INVESTMENT_TO_FAVORITES:
        return {
          ...state,
          favoritesInvestments: [action.payload, ...state.favoritesInvestments],
        };
      case actions.REMOVE_INVESTMENT_FROM_FAVORITES:
        return {
          ...state,
          favoritesInvestments: state.favoritesInvestments.filter((item) => item !== action.payload),
      };
      case actions.AUTHORISE_USER:
        return {
          ...state,
          userName: action.payload,
      };
      case actions.SET_TOKEN:
        return {
          ...state,
          token: action.payload,
      };
      case actions.SET_LOGGED_IN_STATUS:
        return {
          ...state,
          loggedIn: action.payload,
      };
      case actions.DISPLAY_SPINNER:
        return {
          ...state,
          displaySpinner: action.payload,
      };
      case actions.DISPLAY_RECOMMENDED:
        return {
          ...state,
          displayRecommended: action.payload,
      };
      case actions.FINGERPRINT_SENT:
        return {
          ...state,
          fpSent: action.payload,
      };
      case actions.SET_QUIZ_ANSWERS:
        return {
          ...state,
          quizAnswers: action.payload,
      };
      case actions.SET_TYPES:
        return {
          ...state,
          types: action.payload,
      };
      default:
        return state;
    }
};
