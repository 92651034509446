import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ReactGA from 'react-ga';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Button } from '@material-ui/core';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles((theme) => ({
    backButton: {
		fontSize: '14px',
		lineHeight: '20px',
		color: '#73757A',
        textTransform: 'none',
        marginBottom: '24px',
    },
    container: {
        width: '1200px',
        maxWidth: '100%',
        margin: 'auto',
        display: 'flex',
        justifyContent: 'flex-start',
    },
}));

export const BackToButton = (props) => {
	const {
        handleBack,
        text,
    } = props;

	const classes = useStyles();

	return (
        <div className={classes.container}>
            <Button
                className={classes.backButton}
                onClick={handleBack}
            >
                <ArrowBackIosIcon style={{ fontSize: 14 }} /> {text}
            </Button>
        </div>
	);
};
