import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useReviewStyles } from './review-styles';

import positive from '../../img/icons/positive.png';
import neutral from '../../img/icons/neutral.png';
import negative from '../../img/icons/negative.png';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingTop: '24px',
		paddingBottom: '24px'
	},
	filtersMenu: {
		zIndex: '10001 !important'
	},
	writeReviewButton: {
		color: '#73757A',
		fontSize: '14px'
	},
	sentimentImage: {
		width: '17px',
		height: '17px'
	},
	sentimentNeutral: {
		display: 'inline-flex',
		color: '#F3B81F',
		fontWeight: 'bold'
	},
	sentimentNegative: {
		display: 'inline-flex',
		color: '#F04362',
		fontWeight: 'bold'
	},
	sentimentPositive: {
		display: 'inline-flex',
		color: '#33CD71',
		fontWeight: 'bold'
	},

	[theme.breakpoints.down('xs')]: {
		root: {
			flexWrap: 'wrap',
			paddingTop: '20px',
			paddingBottom: '20px'
		},
		writeReviewButton: {
			textColor: '#73757A',
			fontSize: '14px'
		}
	}
}));

export default function SentimentStats({ mentions, sentiment }) {
	const classes = useStyles();
	const reviewClasses = useReviewStyles();

	const getSentiment = sentiment => {
		//console.log(sentiment);
		if (sentiment < 0) {
			return (
				<span className={classes.sentimentNegative}>
					<img className={classes.sentimentImage} src={negative} />{' '}
					&nbsp;&nbsp;Negative
				</span>
			);
		}

		if ((sentiment >= 0 && sentiment < 0.1) || !sentiment) {
			return (
				<span className={classes.sentimentNeutral}>
					<img className={classes.sentimentImage} src={neutral} />{' '}
					&nbsp;&nbsp;Neutral
				</span>
			);
		}

		if (sentiment >= 0.1) {
			return (
				<span className={classes.sentimentPositive}>
					<img className={classes.sentimentImage} src={positive} />{' '}
					&nbsp;&nbsp;Positive
				</span>
			);
		}
	};

	return (
		<div className={`${classes.root} ${reviewClasses.reviewsBlockRow}`}>
			<span>
				<b>Sentiment:</b> &nbsp; {getSentiment(sentiment)}
			</span>

			<div className={`${classes.writeReviewButton}`}>
				{mentions.length} mentions in last 30 days
			</div>
		</div>
	);
}
