import React, { useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ReactGA from 'react-ga';
import { eventTracker, trackEvent } from '../../utils';
import * as actions from "../../redux/actions";
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

ReactGA.initialize('UA-148517382-4');

const useStyles = makeStyles((theme) => ({
	tooltip: {
		zIndex: 10001, // to be visible inside the modal
    },
}));

export const ChipsTarget = (props) => {
	const {
		investmentPlatform,
	} = props;
	const classes = useStyles();
	return (
		<>
            <Tooltip
                    disableFocusListener
                    disableTouchListener
                    classes={{ popper: classes.tooltip }}
                    placement="top"
                    title={`How you make money: ${investmentPlatform.howMoney}`}
                    arrow
                >
                    <Chip label={investmentPlatform.howMoney} className="ctag green" />
            </Tooltip>
            <Tooltip
                disableFocusListener
                disableTouchListener
                classes={{ popper: classes.tooltip }}
                placement="top"
                title={`Payout frequency: ${investmentPlatform.payoutFrequency}`}
                arrow
            >
                <Chip
                    label={investmentPlatform.payoutFrequency}
                    className="ctag purple"
                />
            </Tooltip>
            <Tooltip
                disableFocusListener
                disableTouchListener
                classes={{ popper: classes.tooltip }}
                placement="top"
                title={`Liquidity: ${investmentPlatform.liquidity}`}
                arrow
            >
                <Chip label={investmentPlatform.liquidity} className="ctag blue" />
            </Tooltip>
		</>
	);
}
