import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { isStringExistAndNotEmpty, openModalScreen, closeModalScreen, detectNextImg, setImageModal } from '../../utils';
import play from '../../img/media/playTriangle.svg';
import { isMobile, isTablet } from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        justifyContent: 'center',
    },
    view: {
        //16:9 aspect ratio
        paddingTop: '56.25%',
        display: 'flex',
        overflow: 'hidden',
        position: 'relative',
        borderRadius: '4px',
        width: '100%',
    },
    iframe: {
        height: '100%',
        left: '0',
        position: 'absolute',
        top: '0',
        width: '100%',
    },
    imgContainer: {
        width: '72px',
        height: '72px',
        position: 'relative',
        marginRight: '12px',
    },
    img: {
        overflow: 'hidden',
        display: 'block',
        maxWidth: '907px',
        maxHeight: '478px',
        width: '100%',
        height: '100%',
        objectFit: 'scale-down',
        borderRadius: '4px',
    },
    imgPlay: {
        position: 'absolute',
        top: '26px',
        left: '28px',
        cursor: 'pointer',
    },
    preview: {
        height: '88px',
        display: 'flex',
        marginTop: '16px',
        marginBottom: '32px',
        overflowX: 'auto',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '0px',
        },
    },
    thumbnail: {
        cursor: 'pointer',
        display: 'block',
        width: '72px',
        height: '72px',
        objectFit: 'scale-down',
        marginRight: '12px',
        borderRadius: '4px',
        border: '2px solid #fff',
    },
    active: {
        border: '2px solid #5056E7',
    },
    hideButtons: {
	    display: 'none',
    },
}));

export const MediaCard = (props) => {
    const {
        id,
        promoVideo,
        media,
        mediaArray,
        logo,
    } = props;
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [imgsIds, setImgIds] = useState([]);
    const [currentId, setCurrentId] = useState(null);
    const [hideLeftButton, setHideLeftButton] = useState(false);
    const [hideRightButton, setHideRightButton] = useState(false);

    const handleClick = (index) => {
        setActiveStep(() => index);
    }

    const GUID = `preview_Iframe_${id}`;
    const modalImgContainer = `modalImgContainer`;
    const modalImgTemplate = `modalImgTemplate`;

    const modal = document.querySelectorAll(`#${modalImgContainer}`)[1];
    const modalImg = document.querySelectorAll(`#${modalImgTemplate}`)[1];

    useEffect(() => {
        if (mediaArray.length > 0) {
            const ar = imgIdsCollector(mediaArray);
            setImgIds(ar);
        }
    }, []);

    useEffect(() => {
        if (currentId) {
            if (!detectNextImg(imgsIds, currentId, "next")) {
                setHideRightButton(true);
            } else {
                setHideRightButton(false);
            }
            if (!detectNextImg(imgsIds, currentId, "prev")) {
                setHideLeftButton(true);
            } else {
                setHideLeftButton(false);
            }
        }
    }, [currentId]);

    const imgIdsCollector = (mediaArray) => {
        return mediaArray.map((item, index) => {
            if (index === 0) {
                if (isStringExistAndNotEmpty(promoVideo)) {
                    return undefined;
                } else {
                    return `${item}`;
                }
            } else {
                return `${item}`;
            }
        });
    }

    const handleImgClick = (imgId) => {
        if (isMobile || isTablet) {
            openModalScreen(modal, imgId, modalImg);
            setCurrentId(imgId);
        }
    }
    const handleCloseModal = () => {
        closeModalScreen(modal);
    }
    const handleLeftModal = () => {
        const prevId = detectNextImg(imgsIds, currentId, "prev");
        if (prevId) {
            setImageModal(prevId, modalImg);
            setCurrentId(prevId);
        }
    }
    const handleRightModal = () => {
        const nextId = detectNextImg(imgsIds, currentId, "next");
        if (nextId) {
            setImageModal(nextId, modalImg);
            setCurrentId(nextId);
        }
    }

    return (
        <div className={classes.root} key={`mediaContainer_${id}`}>
            <div className={`${classes.view}`}>
                {isStringExistAndNotEmpty(promoVideo) && (activeStep === 0) ?
                    (<iframe
                        title={`${GUID}`}
                        frameBorder="0"
                        allow="autoplay; fullscreen"
                        width="100%"
                        src={promoVideo}
                        className={classes.iframe}
                        id={`iframe_${id}`}
                        key={`iframe_${id}`}
                    />) :
                    (<div
                        className={classes.iframe}
                        key={`imgs_${id}`}
                    >
                        <img
                            className={classes.img}
                            src={mediaArray[activeStep]}
                            alt="media"
                            id={`img_${id}_${activeStep}`}
                            key={`img_${id}_${activeStep}`}
                            onClick={() => {
                                //handleImgClick(`img_${id}_${activeStep}`)
                                handleImgClick(`${mediaArray[activeStep]}`)
                            }}
                        />
                    </div>)
                }
            </div>
            <div className={`${classes.preview} preview_Iframe`}>
               {
               mediaArray.map((i, index) => {
                    if (index === 0) {
                        if (isStringExistAndNotEmpty(promoVideo)) {
                            return (
                            <div
                                className={`${classes.imgContainer}`}
                                id={`imgcontainer_${id}`}
                                key={`imgcontainer_${id}`}
                            >
                                <img
                                    src={logo}
                                    alt="preview"
                                    className={`
                                        ${classes.thumbnail} ${(activeStep === index) ? classes.active : ""}
                                    `}
                                    onClick={() => {handleClick(index)}}
                                    style={{opacity: '0.3', width: '72px', height: '72px'}}
                                    id={`preview_${id}_${index}`}
                                    key={`preview_${id}_${index}`}
                                />
                                <img
                                    src={play}
                                    alt="preview"
                                    className={`${classes.imgPlay}`}
                                    onClick={() => {handleClick(index)}}
                                    id={`play_${id}_${index}`}
                                    key={`play_${id}_${index}`}
                                />
                            </div>
                            )
                        } else {
                            return (
                                <img
                                    src={i}
                                    alt="preview"
                                    className={`
                                        ${classes.thumbnail} ${(activeStep === index) ? classes.active : ""}
                                    `}
                                    onClick={() => {handleClick(index)}}
                                    id={`preview_${id}_${index}`}
                                    key={`preview_${id}_${index}`}
                                />
                            )
                        }
                    } else {
                        return (
                            <img
                                src={i}
                                alt="preview"
                                className={`
                                    ${classes.thumbnail} ${(activeStep === index) ? classes.active : ""}
                                `}
                                onClick={() => {handleClick(index)}}
                                id={`preview_${id}_${index}`}
                                key={`preview_${id}_${index}`}
                            />
                        )
                    }
                })}
           </div>
            <div className={`modal`} id={modalImgContainer}>
                <span
                    className={`iconModal closeModal `}
                    onClick={handleCloseModal}
                >
                    &times;
                </span>
                <span
                    className={`iconModal leftModal ${
                        hideLeftButton ? classes.hideButtons : ``
                    }`}
                    onClick={handleLeftModal}
                >
                    &lt;
                </span>
                <span
                    className={`iconModal rightModal ${
                        hideRightButton ? classes.hideButtons : ``
                    }`}
                    onClick={handleRightModal}
                >
                    &gt;
                </span>
                <img
                    className={`modal-content`}
                    id={modalImgTemplate}
                    alt="modal img"
                />
            </div>
        </div>
    );
}
