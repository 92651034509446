import { REMOTEAPI_ADS_URL } from './ConstAPI';
import PropTypes from 'prop-types';

const createAPIQUery = (urlGenerator) => async (...params) => {
	try {
		const url = urlGenerator(...params);
		const headers = {
			'Content-Type': 'application/json; charset=utxf-8',
		};
		const response = await fetch(url, { method: 'GET', headers });
		const json = await response.json();

		return json;
	} catch {
		return {
			success: false,
			result: [],
			message: 'There is an issue to get data from server. Please try again later.',
		};
	}
};

export const getAds = createAPIQUery((params) => `${REMOTEAPI_ADS_URL}`);

createAPIQUery.propTypes = {
	headers: PropTypes.array,
	url: PropTypes.string,
	response: PropTypes.object,
};
