import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useRouteMatch } from 'react-router-dom';
import VizSensor from 'react-visibility-sensor';
import { ObserverSensor } from '../ObserverSensor/ObserverSensor';
import ReactGA from 'react-ga';
import { eventTracker, trackEvent, isDesktopView, getDiscoveryPageName } from '../../utils';
import { useSelector, shallowEqual } from 'react-redux';
import { CollapsedCard } from './CollapsedCard';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles({
});

export default function InvestmentPlatformCollapsed(props) {
	const {
		investmentPlatform,
		calculator,
		embedOnclick,
		expandable,
		setActivePlatform,
		cluster,
	} = props;
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);

	const platformUrl = getDiscoveryPageName(expandable, investmentPlatform.slug);

	const history = useHistory();
	const { url: currentUrl } = useRouteMatch();

	const stopPropagation = (event) => {
		trackEvent('ExternalClick', 'platform', investmentPlatform.name);
		eventTracker(
			'ExternalClick',
			investmentPlatform.name,
			state.itemSource,
			state.itemMedium,
			state.itemCompaign,
			state.userId
		);

		event.stopPropagation();
	}

	const onCardClick = async () => {
		if (embedOnclick) {
			embedOnclick();
		} else {
			ReactGA.pageview(platformUrl);
			eventTracker(
				'pageview',
				platformUrl,
				state.itemSource,
				state.itemMedium,
				state.itemCompaign,
				state.userId
			);
			if (!isDesktopView()) {
				await setActivePlatform(investmentPlatform);
				let element = document.getElementById(
					`platform_ex_${cluster ? `${cluster}_` : null}${investmentPlatform.id}`
				);
				element.scrollIntoView({
					behavior: 'smooth',
				});
			} else {
				history.push(`${currentUrl}/${investmentPlatform.slug}`);
			}
		}
	};

	return (
		<VizSensor
			onChange={(isVisible) => {
				trackEvent('PlatformImpression', 'platform', investmentPlatform.name);
				eventTracker(
					'PlatformImpression',
					investmentPlatform.name,
					state.itemSource,
					state.itemMedium,
					state.itemCompaign,
					state.userId
				);
			}}
		>
			<ObserverSensor
				id={`cardCollapsed_${investmentPlatform.id}`}
				onMouseOver={() => {
					trackEvent('PlatformMouseOver', 'platform', investmentPlatform.name);
					eventTracker(
						'PlatformMouseOver',
						investmentPlatform.name,
						state.itemSource,
						state.itemMedium,
						state.itemCompaign,
						state.userId
					);
				}}
				onScroll={() => {
					trackEvent('PlatformScroll', 'platform', investmentPlatform.name);
					eventTracker(
						'PlatformScroll',
						investmentPlatform.name,
						state.itemSource,
						state.itemMedium,
						state.itemCompaign,
						state.userId
					);
				}}
			>
				<CollapsedCard
					investmentPlatform={investmentPlatform}
					expandable={expandable}
					itemSource={state.itemSource}
					itemMedium={state.itemMedium}
					itemCompaign={state.itemCompaign}
					onCardClick={onCardClick}
					stopPropagation={stopPropagation}
				/>
			</ObserverSensor>
		</VizSensor>
	);
}
