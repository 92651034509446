import React, { useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ShareIcon from '@material-ui/icons/Share';
import LinkIcon from '@material-ui/icons/Link';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import * as actions from "../../redux/actions";
import { copyToClipboard, getFullPlatformUrl } from '../../utils';
import { routeNames } from '../../data/Data';

const useStyles = makeStyles({
    filtersMenu: {
        zIndex: "10001 !important",
    },
    tooltip: {
        zIndex: "10001 !important",
	},
});

const StyledMenuItem = withStyles((theme) => ({
    /*root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },*/
    root: {
        zIndex: '10001',
    }
}))(MenuItem);

export const ShareButton = (props) => {
    const {
        postId,
        modal: modalProp,
    } = props;
    const classes = useStyles();

    const StyledMenu = withStyles({
        paper: {
          border: '1px solid #d3d4d5',
        },
      })((props) => (
        <Menu
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            {...props}
        />
    ));

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [copySuccess, setCopySuccess] = React.useState(false);

    const modal = modalProp ? `modal` : `nomodal`;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleCopy = () => {
        const url = getFullPlatformUrl(`${routeNames.stream}/${postId}`);
        copyToClipboard(url);
        handleClose();
        //setCopySuccess(true);
		//setTimeout(() => setCopySuccess(false), 1200);
    };

    return (
       <div>
            <IconButton
                className={classes.iconButton}
                aria-label="camera"
                aria-haspopup="true"
                onClick={handleClick}
                id={`${modal}-icon-button`}
            >
                <ShareIcon />
            </IconButton>
            <StyledMenu
                id={`${modal}-customized-menu`}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.filtersMenu}
            >
                <Tooltip
                    disableFocusListener
                    disableTouchListener
                    classes={{ popper: classes.tooltip }}
                    placement="top"
                    title={`Click to copy post URL to share`}
                    arrow
                >
                    <a
                        onClick={(event) => {
                            event.stopPropagation();
                            handleCopy();
                        }}
                    >
                        <Tooltip
                            disableFocusListener={true}
                            disableHoverListener={true}
                            placement="bottom"
                            title={`Copied!`}
                            open={copySuccess}
                            arrow
                        >
                            <StyledMenuItem>
                                <ListItemIcon>
                                    <LinkIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Copy Link" />
                            </StyledMenuItem>
                        </Tooltip>
                    </a>
                </Tooltip>
            </StyledMenu>
       </div>
    )
}
