import React, { useState, useEffect, useRef } from 'react';
import queryString from 'query-string';
import { getClusters, getFilterResult } from '../Services/BaseAPI.js';
import { getAds } from '../Services/AdsAPI.js';
import { makeStyles } from '@material-ui/core/styles';
import { Spinner } from '../components/Spinner/Spinner.js';
import LeftNavbarWrapper from '../components/LeftNavbarWrapper.jsx';
import { ClusterView } from '../components/Clusters/ClusterView';
import ReactGA from 'react-ga';
import { isPaidOnly, trackEvent, eventTracker, isEmbeded, disableMailChimp } from '../utils';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as actions from '../redux/actions';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles((theme) => ({
	expandIcon: {
		position: 'absolute',
		top: '12px',
		right: '12px',
	},
	embedContent: {
		justifyContent: 'center',
		margin: 'auto',
	},
}));

const ExpandableDiscovery = (props) => {
	const [investments, setInvestments] = useState({});
	const [clusters, setClusters] = useState([]);
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const [paidOnly, setPaidOnly] = useState(state.itemCompaign);
	const [ads, setAds] = useState([]);
	const embededEnryRef = useRef();
	const classes = useStyles();

	const [height, setHeight] = useState(0);

	const [bannerShow, setBannerShow] = useState(true);

	const [displaySpinner, setDisplaySpinner] = useState(false);
	const { utm_campaign, utm_medium, utm_source, frameId } = queryString.parse(
		props.location.search
	);
	const dispatch = useDispatch();
	const cluster_view = props.location.hash;

	useEffect(() => {
		if (state.userId) {
			ReactGA.pageview(`/expandableDiscovery`);
			eventTracker("pageview", `/expandableDiscovery`, utm_source, utm_medium, utm_campaign, state.userId);
		}
		if (utm_campaign === 'paidOnly') {
			setPaidOnly(true);
		}
		if (utm_source) {
			dispatch(actions.setItemSource(utm_source));
		}
		if (utm_campaign) {
			dispatch(actions.setItemCompaign(utm_campaign));
		}
		if (utm_medium) {
			dispatch(actions.setItemMedium(utm_medium));
		}
		if (cluster_view) {
			dispatch(actions.setClusterView(cluster_view));
		}
		//dispatch(actions.setClusterView('cluster_'));
	}, [utm_campaign, utm_medium, utm_source, state.userId]);
	useEffect(() => {
		let dynamicHeight;
		//console.log(bannerShow);
		const initialHeight = Math.round(window.innerWidth * 0.39);
		if (bannerShow) {
			dynamicHeight = initialHeight < 375 ? 375 : initialHeight;
		} else {
			dynamicHeight = 800;
		}
		//console.log(dynamicHeight);
		setHeight(dynamicHeight);
		if (window.parent) {
			window.parent.postMessage(
				{
					frameId: frameId,
					action: 'setHeight',
					height: dynamicHeight,
				},
				'*'
			);
		}
	}, [bannerShow]);

	React.useEffect(() => {
		if (isEmbeded(utm_medium, 'embed')) {
			//disableMailChimp();
		}
	}, []);

	function openDiscovery() {
		setBannerShow(!bannerShow);
		trackEvent('ExpandableOpen', 'state', bannerShow);
		eventTracker("ExpandableOpen", bannerShow ? "true" : "false", state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
	}

	return (
		<div ref={embededEnryRef} className={classes.embedContent}>
			{bannerShow ? (
				<ClusterView
					investments={investments}
					clusters={clusters}
					expandableBannerShow={openDiscovery}
					ads={ads}
				/>
			) : (
				<LeftNavbarWrapper
					utmParams={{ utm_campaign, utm_medium, utm_source }}
					expandable={true}
					expandableBannerShow={openDiscovery}
					paidOnly={isPaidOnly(utm_campaign)}
					investments={investments}
					clusters={clusters}
					ads={ads}
				/>
			)}
		</div>
	);
};
export default ExpandableDiscovery;
