import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { urlHas, isPaidOnly, isEmbeded } from '../../utils';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { routeNames } from '../../data/Data';

const drawerWidth = 247;
const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	appBar: {
		[theme.breakpoints.up('sm')]: {
			zIndex: 9999,
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		width: drawerWidth,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	menuContainer: {
		display: 'block',
	},
	bar: {
		lineHeight: '62px',
		height: '62px',
		marginRight: '50px',
		textAlign: 'center',

		[theme.breakpoints.down('1145')]: {
			minWidth: '95px',
		},
	},
	barSelected: {
		[theme.breakpoints.up('444')]: {
			borderBottom: '2px solid #5056e7',
		},
		[theme.breakpoints.down('444')]: {
			borderBottom: 'unset',
			borderLeft: '2px solid #5056e7',
			marginLeft: '-10px',
			paddingLeft: '10px',
		},
	},
	barSelectedTransparent: {
		[theme.breakpoints.up('444')]: {
			borderBottom: '2px solid #fff',
		},
		[theme.breakpoints.down('444')]: {
			borderBottom: 'unset',
			borderLeft: '2px solid #fff',
			marginLeft: '-10px',
			paddingLeft: '10px',
		},
	},
	showMenu: {
		display: 'block !important',
		[theme.breakpoints.down('1145')]: {
			paddingLeft: '200px',
		},
		[theme.breakpoints.down('960')]: {
			paddingLeft: '150px',
		},
		[theme.breakpoints.down('860')]: {
			paddingLeft: '100px',
		},
		[theme.breakpoints.down('760')]: {
			paddingLeft: '80px',
		},
		[theme.breakpoints.down('660')]: {
			paddingLeft: '60px',
		},
		[theme.breakpoints.down('600')]: {
			paddingLeft: '40px',
		},
		[theme.breakpoints.down('550')]: {
			paddingLeft: '20px',
		},
		[theme.breakpoints.down('500')]: {
			paddingLeft: '10px',
		},
	},
	mobMenu: {
		fontSize: '14px',
		[theme.breakpoints.up('1145')]: {
			display: 'flex',
			width: '100%',
		},
		[theme.breakpoints.down('1145')]: {
			display: 'none',
			marginLeft: '40px',
			marginBottom: '20px',
		},
		[theme.breakpoints.down('500')]: {
			marginLeft: '0px',
		},
		[theme.breakpoints.down('444')]: {
			display: 'none',
			flexDirection: 'column',
			position: 'absolute',
			background: 'white',
			width: '100%',
			top: '50px',
			height: 'auto',
			marginBottom: '20px',
			marginLeft: '0px',
			left: 0,
			paddingLeft: '10px',
		},
	},
	mobMenuEmbed: {
		[theme.breakpoints.up('1145')]: {
			display: 'flex',
			width: '100%',
		},
		[theme.breakpoints.down('1145')]: {
			display: 'flex',
			width: '100%',
			flexDirection: 'row',
			position: 'relative',
			background: 'white',
		},
	},
	mobMenuTransparent: {
		background: 'rgba(80, 86, 231, 0.84)',
		marginTop: '6px',
	},
}));

const AppBarRes = (props) => {
	const { mobMenuTransparent } = props;
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const classes = useStyles();
	const [nowActive, setnowActive] = useState('');
	const [medium, setMedium] = useState(state.itemMedium);
	const [paidOnly, setPaidOnly] = useState(state.itemCompaign);

	useEffect(() => {
		if (state.itemCompaign) {
			setPaidOnly(isPaidOnly(state.itemCompaign));
		}
	});
	useEffect(() => {
		if (urlHas(routeNames.discover)) {
			setnowActive(routeNames.discover);
		} else if (urlHas(routeNames.expandableDiscovery)) {
			setnowActive(routeNames.discover);
		} else if (urlHas(routeNames.calculator)) {
			setnowActive(routeNames.calculator);
		} else if (urlHas(routeNames.investorTips)) {
			setnowActive(routeNames.investorTips);
		} else if (urlHas(routeNames.dashboard)) {
			setnowActive(routeNames.dashboard);
		} else if (urlHas(routeNames.stream)) {
			setnowActive(routeNames.stream);
		} else if (!urlHas(routeNames.termsAndCondition)) {
			setnowActive(routeNames.home);
		}
	});
	useEffect(() => {
		if (state.itemMedium) {
			setMedium(state.itemMedium);
		}
	}, [state.itemMedium]);

	return (
		/* detail */
		<>
			<div
				className={`
				${!isEmbeded(medium, 'embed') ? `${classes.mobMenu}` : `${classes.mobMenuEmbed}`}
				${mobMenuTransparent ? `${classes.mobMenuTransparent}` : ''}
				${props.showMenu ? classes.showMenu : ''}`}
			>
				{!isEmbeded(medium, 'embed_single') && !paidOnly ? (
					<>
						<div
							className={`
							${classes.bar}
							${
								nowActive === 'discover'
									? `${
											mobMenuTransparent
												? `${classes.barSelectedTransparent}`
												: `${classes.barSelected}`
									  }`
									: ''
							}
						`}
						>
							<Link to="/discover">Investing</Link>
						</div>

						<div
							className={`
							${classes.bar}
							${
								nowActive === 'calculator'
									? `${
											mobMenuTransparent
												? `${classes.barSelectedTransparent}`
												: `${classes.barSelected}`
									  }`
									: ''
							}
						`}
						>
							<Link to="/calculator">Investor Quiz</Link>
						</div>
						<div
							className={`
							${classes.bar}
							${
								nowActive === routeNames.investorTips
									? `${
											mobMenuTransparent
												? `${classes.barSelectedTransparent}`
												: `${classes.barSelected}`
									  }`
									: ''
							}
						`}
						>
							<Link to={`/${routeNames.investorTips}`}>Investor Tips</Link>
						</div>
						{(<div
							className={`
								${classes.bar}
								${nowActive === routeNames.stream ? `${mobMenuTransparent ? `${classes.barSelectedTransparent}` : `${classes.barSelected}`}` : ''}
							`}
						>
							<Link to={`/${routeNames.stream}`}>Stream</Link>
						</div>)}
					</>
				) : null}
			</div>
		</>
	);
};
export default AppBarRes;
