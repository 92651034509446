import React, { useEffect, useState, useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
    postsContainer: {
        marginBottom: '10px',
    },
    postsTopRow: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '12px',
    },
    postsPerson: {
        marginLeft: '16px',
    },
    postsDate: {
        marginLeft: 'auto',
    },
    postsText: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#30333E',
    },
    postsAction: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '12px',
    },
    postsActionLeft: {
        display: 'flex',
        alignItems: 'center',
    },
    postsActionRight: {
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center',
    },
    postsCommentsText: {
        marginLeft: '8px',
    },
    postsReplyText: {
        marginLeft: '8px',
    },
    postsReplyImg: {
        marginLeft: '21px',
    },
}));
export const SkeletonMakePost = (props) => {
    const classes = useStyles();
	return (
        <div key={`container_skeleton`}>
            <div key={`index_skeleton`} className={`${classes.postsContainer}`}>
                <div className={`${classes.postsTopRow}`}>
                    <div className={`${classes.postsAvatar}`}>
                        <Skeleton animation="wave" variant="circle" width={48} height={48} />
                    </div>
                    <div className={`${classes.postsPerson}`}>
                        <Skeleton animation="wave" height={10} width={100} />
                    </div>
                    <div className={`${classes.postsDate}`}>
                        <Skeleton animation="wave" height={10} width={100} />
                    </div>
                </div>
                <Skeleton animation="wave" variant="rect" width='100%' height={16} />
            </div>
        </div>
	);
};
