import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import _ from 'lodash';
import { paidOnlyInvestments } from '../../utils';
import PassiveIncome from '../../img/InvestObjective/PassiveIncome.svg';
import BalancedInvesting from '../../img/InvestObjective/BalancedInvesting.svg';
import LongTermGrowth from '../../img/InvestObjective/LongTermGrowth.svg';
import { mobileWidth, widgetWidth } from '../../data/Data';

export const ObjectiveList = (props) => {
	const {
        paidOnly,
        investments,
        handleObjectives,
        expandable,
    } = props;

    const limit = !expandable ? mobileWidth : widgetWidth;

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            overflow: 'hidden',
            paddingTop: '0px',
            //width: '100%',
            //maxWidth: '1200px',
            position: 'relative',
            padding: '0px',
        },
        tile: {
            cursor: 'pointer',
            width: '386px !important',
            height: '376px !important',
            background: '#FFFFFF',
            border: '1px solid #F2F5F8',
            boxSizing: 'border-box',
            boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
            borderRadius: '8px',
            marginRight: '14px',
            marginBottom: '14px',
            position: 'relative',
            [theme.breakpoints.down(`${limit}`)]: {
                width: '220px !important',
                marginRight: '12px',
                marginBottom: '12px',
            },
        },
        tileContainer: {
            textAlign: 'center',
            position: 'relative',
        },
        container: {
            position: 'relative',
        },
        avatar: {
            width: '80px',
            height: '80px',
            marginTop: '40px',
            marginLeft: 'auto',
            marginRight: 'auto',
            [theme.breakpoints.down(`${limit}`)]: {
                marginTop: '60px',
            },
            [theme.breakpoints.down('488')]: {
                //marginTop: '40px',
            },
        },
        tileTitle: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '20px',
            lineHeight: '32px',
            color: '#30333E',
            marginTop: '20px',
            maxWidth: '260px',
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            [theme.breakpoints.down(`${limit}`)]: {
                padding: '0 5px 0 5px',
            },
            [theme.breakpoints.down('488')]: {
                padding: '0',
            },
        },
        tileDesc: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#73757A',
            marginTop: '8px',
            maxWidth: '260px',
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            [theme.breakpoints.down(`${limit}`)]: {
                padding: '0 5px 0 5px',
            },
            [theme.breakpoints.down('488')]: {
                padding: '0',
            },
        },
        button: {
            width: '192px',
            height: '48px',
            textTransform: 'none',
            //marginBottom: '10px',
            //marginTop: '20px',
            maxWidth: '1200px',
            //borderRadius: '8px',
            //borderRadius: '100px',
            color: '#ffffff',
            //border: '1px solid #5056E7',
            background: '#5056E7',
            '&:hover': {
                backgroundColor: 'rgb(80,86,231, 0.8)',
            },
            position: 'absolute',
            top: '260px;',
            marginLeft: 'auto',
            marginRight: 'auto',
            left: 0,
            right: 0,
            textAlign: 'center',
            [theme.breakpoints.down(`${limit}`)]: {
                top: '240px',
            },
            [theme.breakpoints.down('488')]: {
                //top: '260px',
            },
        },
        backButton: {
            position: 'absolute',
            zIndex: '9999',
            left: '0px',
            right: 'inherit',
            top: '145px',
            marginTop: '20px',
            //width: '55px !important',
            [theme.breakpoints.up(`${limit}`)]: {
                display: 'none',
            },
        },
        forwardButton: {
            position: 'absolute',
            marginTop: '20px',
            right: '0px',
            top: '145px',
            zIndex: '9999',
            //width: '55px !important',
            [theme.breakpoints.up(`${limit}`)]: {
                display: 'none',
            },
        },
        desktopView: {
            display: 'flex',
            flexWrap: 'wrap',
            //justifyContent: 'center',
            [theme.breakpoints.down(`${limit}`)]: {
                display: 'none !important',
            },
        },
        mobileView: {
            position: 'relative',
            flexWrap: 'nowrap',
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: 'translateZ(0)',
            [theme.breakpoints.up(`${limit}`)]: {
                display: 'none !important',
            },
        },
    }));

	const classes = useStyles();
    const [objectives, setObjectives] = useState([]);
    const clusterDiv = React.useRef();

    useEffect(() => {
        if (Object.values(investments).length > 0) {
            getObjectives(investments);
        }
    }, [investments]);

    const getObjectives = () => {
        const investmentsData = paidOnlyInvestments(investments, paidOnly);
        const passiveIncome = _.filter(investmentsData, (investment) => {
            return (
                investment.howMoney === 'Dividends' ||
                investment.howMoney === 'Interest'
            );
        });
        const balanced = _.filter(investmentsData, (investment) => {
            return (
                investment.howMoney === 'Dividends + Value'
            );
        });
        const longTermWorth = _.filter(investmentsData, (investment) => {
            return (
                investment.howMoney === 'Value'
            );
        });
        const objectivesArray = [
            {
                ...{
                    platforms: passiveIncome,
                    icon: PassiveIncome,
                    name: `Passive Income`,
                    description: `Create an attractive, consistent income stream`,
                }
            },
            {
                ...{
                    platforms: balanced,
                    icon: BalancedInvesting,
                    name: `Balanced Investing`,
                    description: `Build wealth confidently with high diversification`,
                }
            },
            {
                ...{
                    platforms: longTermWorth,
                    icon: LongTermGrowth,
                    name: `Long Term Growth`,
                    description: `Pursue superior overall returns over the long term`,
                }
            },
        ];
        setObjectives(objectivesArray);
    };

	const handleClick = (cluster) => {
        handleObjectives(cluster);
    };
    function handleForwardClick(e) {
		if (clusterDiv.current) {
			clusterDiv.current.scrollLeft += 200;
		}
	}
	function handleBackClick(e) {
		if (clusterDiv.current) {
			clusterDiv.current.scrollLeft -= 200;
		}
	}

	return (
		<div className={classes.root}>
            <IconButton
                aria-label="back"
                color="primary"
                variant="outlined"
                onClick={handleBackClick}
                className={classes.backButton}
            >
                <ArrowBackIcon />
            </IconButton>

            <div className={`${classes.desktopView}`}>
                {objectives.map((cluster) => (
                    <div key={cluster.id} className={classes.tile} onClick={() => handleClick(cluster)}>
                        <Avatar
                            src={cluster.icon}
                            className={classes.avatar}
                        >
                        </Avatar>
                        <div className={`${classes.tileTitle}`}>
                            {cluster.name}
                        </div>
                        <div className={`${classes.tileDesc}`}>
                            {cluster.description}
                        </div>
                        <Button
                            className={`${classes.button} ${classes.color1}`}
                            onClick={() => handleClick(cluster)}
                        >
                            Explore {cluster.platforms.length} options
                        </Button>
                    </div>
                ))}
            </div>
            <GridList className={`${classes.mobileView}`} ref={clusterDiv} cols={1}>
                {objectives.map((cluster) => (
                    <GridListTile key={cluster.id} className={classes.tile} onClick={() => handleClick(cluster)}>
                        <div className={classes.tileContainer}>
                            <Avatar
                                src={cluster.icon}
                                className={classes.avatar}
                            >
                            </Avatar>
                            <div className={`${classes.tileTitle}`}>
                                {cluster.name}
                            </div>
                            <div className={`${classes.tileDesc}`}>
                                {cluster.description}
                            </div>
                            <Button
                                className={`${classes.button} ${classes.color1}`}
                                onClick={() => handleClick(cluster)}
                            >
                                Explore {cluster.platforms.length} options
                            </Button>
                        </div>
                    </GridListTile>
                ))}
            </GridList>

            <IconButton
                aria-label="forward"
                color="primary"
                variant="outlined"
                onClick={handleForwardClick}
                className={classes.forwardButton}
            >
                <ArrowForwardIcon />
            </IconButton>
        </div>
	);
}
