import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { LearnMoreButton } from '../Buttons/LearnMore';
import ReactGA from 'react-ga';
import { eventTracker, trackEvent } from '../../utils';
import * as actions from "../../redux/actions";
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { HeaderFirstGrid } from './HeaderFirstGrid';
import { HeaderSecondGrid } from './HeaderSecondGrid';
import { HeaderThirdGrid } from './HeaderThirdGrid';
import { ChipsInvestment } from './ChipsInvestment';

ReactGA.initialize('UA-148517382-4');

const useStyles = makeStyles((theme) => ({
	borderedColumn: {
		position: 'relative',

		'& .pseudoborder': {
			height: 'calc(100% - 40px) !important',
			top: '28px',
			left: '-24px',
			margin: '0 !important',
		},
	},
	secondColumn: {
		paddingRight: '32px',
	},
	chipsMobile: {
		[theme.breakpoints.up('445')]: {
			display: 'none',
		},
        [theme.breakpoints.down('445')]: {
			display: 'block',
		},
    },
}));

export default function HeaderFavorites (props) {
	const {
		investmentPlatform,
		expanded,
		expandable,
		platformUrl
	} = props;
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const dispatch = useDispatch();
	const classes = useStyles();

	const expandedProp = /* expanded */false;
	const [source, setSource] = React.useState(state.itemSource || 'MoneyMade');

	const id = `cardHeader_${investmentPlatform.id}`;

	return (
		<>
			<Grid container alignItems="flex-start" id={id}>
				<Grid className="firstgrid" item sm={5} xs={5}>
					<HeaderFirstGrid
						investmentPlatform={investmentPlatform}
						expanded={expanded}
						expandable={expandable}
						platformUrl={platformUrl}
					/>
				</Grid>
				<Grid className={`${classes.borderedColumn} ${classes.secondColumn}`} item sm={3} xs={3}>
					<HeaderSecondGrid
						investmentPlatform={investmentPlatform}
						expanded={expanded}
						expandable={expandable}
						hide={true}
					/>
				</Grid>
				<Grid className={`thirdgrid ${classes.borderedColumn}`} item sm={3} xs={3}>
					<HeaderThirdGrid
						investmentPlatform={investmentPlatform}
						expanded={expanded}
						expandable={expandable}
						hide={true}
					/>
				</Grid>
				<Grid className={`thirdgrid ${classes.borderedColumn} ${classes.chipsMobile}`} item>
					<ChipsInvestment
						investmentPlatform={investmentPlatform}
					/>
				</Grid>
			</Grid>
			{expandable && expanded &&
				<LearnMoreButton
					investmentPlatform={investmentPlatform}
					color="1"
				/>}
		</>
	);
}
