import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

export const ObserverSensor = (props) => {
    const {
        children,
        id,
        onMouseOver: onMouseOverProp,
        onScroll: onScrollProp,
    } = props;
	const [hovered, setHovered] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    const element = document.getElementById(id);
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
		return function cleanup () {
            window.removeEventListener('scroll', handleScroll)
		};
    });

	const handleMouseOver = (e) => {
		if (e.currentTarget.id === id) {
			if (!hovered) {
				setHovered(true);
                onMouseOverProp();
                //console.log(`Hovered: ${id}`);
			}
		}
	}
	const handleScroll = (e) => {
        if (element && !scrolled && isMobile) {
            const windowPosition = window.scrollY;
            const elementPosition = element.getBoundingClientRect().bottom;
            if (windowPosition > elementPosition) {
                if (!scrolled) {
                    setScrolled(true);
                    onScrollProp();
                    //console.log(`Scrolled ${id}`);
                }
            }
        }
	}
    return (
        <>
            <div
                id={id}
                onMouseOver={handleMouseOver}
            >
                {children}
            </div>
        </>
    )
}
