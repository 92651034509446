import React, { useEffect, useState, lazy } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import { routeNames, deepDiveDelimiters } from '../../data/Data';
import { createMarkup } from '../../utils';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});
const useStyles = makeStyles((theme) => ({
    card: {
        marginTop: '32px',
        background: '#FFFFFF',
        border: '1px solid #F2F5F8',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
        borderRadius: '8px',
        padding: '18px 0 18px 18px',
        [theme.breakpoints.down('460')]: {
            padding: '12px',
        },
    },
    descriptionTitle: {
        fontFamily: 'Red Hat Display',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '36px',
        lineHeight: '48px',
        color: '#30333E',
        marginLeft: '10px',
        marginRight: '10px',
        marginTop: '36px',
        marginBottom: '16px',
    },
    descriptionText: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '28px',
        color: '#30333E',
        marginTop: '16px',
        marginLeft: '10px',
        marginRight: '10px',
    },
}));
export const IntroCard = (props) => {
    const {
		investmentAdvanced,
	} = props;
    const classes = useStyles();

	return (
        <div className={`${classes.card}`}>
            {investmentAdvanced !== null && investmentAdvanced.map((i, index) => {
                if (i['type'] === deepDiveDelimiters.intro) {
                    const content = i["Content1"];
                    const result = [];
                    content.forEach((z, index) => {
                        let val = '';
                        if (Array.isArray(z)) {
                            const tempArray = [];
                            z.forEach((i, index) => {
                                tempArray.push((
                                    <li key={`Intro_${deepDiveDelimiters.orderNone}_${index}`}>
                                        <span dangerouslySetInnerHTML={createMarkup(i)} />
                                    </li>
                                ));
                            })
                            val = (
                                <ul className={`deepDiveBullet`}>
                                    {tempArray}
                                </ul>
                            );
                        } else {
                            val = (
                                <div
                                    className={`${classes.descriptionText}`}
                                    key={`Intro_${deepDiveDelimiters.orderNone}_${index}`}
                                >
                                    <span dangerouslySetInnerHTML={createMarkup(z)} />
                                </div>
                            )
                        }
                        result.push(val);
                    });
                    return result;
                }
            })}
        </div>
	);
};
