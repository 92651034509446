import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InvestmentPlatformAvatar from "../Avatar";
import Rating from "../../Rating";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {useCommonStyles} from "./common-styles";

const useStyles = makeStyles(theme => ({
    avatar: {
        display: "inline-block",
        verticalAlign: "middle",
    },
    headingBreaker: {
        display: "none",
    },
    ratingText: {
        marginBottom: "10px",
    },
    rating: {
        marginLeft: "-3px",
    },
    isInvesting: {
        marginTop: "17px",

        "& .MuiFormControlLabel-label": {
            maxWidth: "none !important",
        },
    },
    comment: {
        width: "100%",
        marginTop: "15px",
    },
    [theme.breakpoints.only("xs")]: {
        headingBreaker: {
            display: "block"
        },
    },
}));

export default function CreateReviewFormFirstPart({ investmentPlatform, onSubmit }) {
    const classes = useStyles();
    const commonClasses = useCommonStyles();
    const [ rating, setRating ] = React.useState(0);
    const [ isInvesting, setIsInvesting ] = React.useState("true");
    const [ comment, setComment ] = React.useState("");

    return (
        <div className={commonClasses.root}>
            <div className={commonClasses.heading}>
                Submit your review on &nbsp;
                <div className={classes.headingBreaker} />
                <InvestmentPlatformAvatar
                    className={classes.avatar}
                    investmentPlatform={investmentPlatform}
                /> &nbsp;
                {investmentPlatform.name}
            </div>

            <div>
                <p className={`${commonClasses.text} ${classes.ratingText}`}>Rate the platform</p>
                <Rating
                    className={classes.rating}
                    size={30}
                    value={rating}
                    editable
                    onChange={setRating}
                />
            </div>

            <div>
                <RadioGroup
                    aria-label="is-investing"
                    name="is-investing"
                    value={isInvesting}
                    onChange={event => setIsInvesting(event.target.value)}
                    className={classes.isInvesting}
                >
                  <FormControlLabel
                      value="true"
                      control={<Radio color="primary" />}
                      label={`I invest on ${investmentPlatform.name}`}
                  />

                  <FormControlLabel
                      value="false"
                      control={<Radio color="primary" />}
                      label={`I don't invest on ${investmentPlatform.name}`}
                  />
                </RadioGroup>
            </div>

            <TextField
                multiline
                rows="4"
                variant="outlined"
                name="comment"
                className={`${commonClasses.textField} ${classes.comment}`}
                placeholder="Comment"
                value={comment}
                onChange={event => setComment(event.target.value)}
            />

            <div className={commonClasses.submitButtonContainer}>
                <Button
                    variant="contained"
                    className="homeBtn alt"
                    color="primary"
                    disabled={rating === 0 || !comment}
                    onClick={() => onSubmit({
                        rating,
                        comment,
                        isInvesting: isInvesting === "true",
                    })}
                >
                    Submit
                </Button>
            </div>
        </div>
    );
}