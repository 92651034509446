import React, { useState, useEffect } from 'react';
import LazyLoad from 'react-lazyload';
import ReactGA from 'react-ga';
import makeStyles from "@material-ui/core/styles/makeStyles";
import InvestmentPlatformCollapsed from './Collapsed';
import ExpandedPlatform from './ExpandedPlatform';
import { isDesktopView  } from '../../utils';
import { ParallelMarkets } from '../ParallelMarkets/ParallelMarkets';
import { ObjectiveList } from './ObjectiveList';
import { CollectionList } from './CollectionList';
import { TypesList } from './TypesList';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles({
    root: {
        width: '1200px',
        margin: 'auto',
        position: 'relative',
        maxWidth: '100%',
    },
    title: {
        fontFamily: 'Red Hat Display',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '36px',
        lineHeight: '48px',
        color: '#30333E',
        //marginTop: '24px',
        marginBottom: '4px',
    },
    desc: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#73757A',
        //marginBottom: '24px',
    },
    marginTop24: {
        marginTop: '24px',
    },
    marginTop44: {
        marginTop: '44px',
    },
    marginBottom24: {
        marginBottom: '24px',
    },
    marginBottom16: {
        marginBottom: '16px',
    },
});

export const DefaultViewCard = (props) => {
	const {
        expandable,
        investments,
        investmentsTotal,
        setActivePlatform,
        activePlatform,
        search,
        resetTotal,
        clusters,
        handleCollection,
        handleType,
        handleObjectives,
        handleMoveToListView,
        isListView,
    } = props;

    const classes = useStyles();
    const [premium, setPremium] = useState(null);
    useEffect(() => {
        const platforms = Object.values(investments).filter((i) => i.premium);
        setPremium(platforms);
    }, [investments]);

	return (
        <div className={`${classes.root}`}>
            <div className={`${classes.title} ${classes.marginTop24}`}>
                Featured
            </div>
            <div className={`${classes.desc} ${classes.marginBottom24}`}>
                Top platforms picked by MoneyMade
            </div>

            {premium !== null && premium.map(function (investment, index) {
            if (investment === true) {
                return null;
            }
            let investmentComponent = null;
            investmentComponent = (
                <>
                    <LazyLoad
                        height={230}
                        offset={500}
                        once
                        key={`lazy_${investment.id}`}
                    >
                        <div id={`platform_${investment.id}`} />
                        <InvestmentPlatformCollapsed
                            key={investment.id}
                            investmentPlatform={investment}
                            expandable={expandable}
                            setActivePlatform={setActivePlatform}
                        />
                    </LazyLoad>
                </>
            );
            if (activePlatform === investment &&
                !isDesktopView()) {
                const similarInvestments = activePlatform.similars.map((similar) => {
                    const similarInvestment = Object.values(investmentsTotal).find(
                        (investment) => investment.id === similar
                    );
                    return similarInvestment;
                });
                investmentComponent = (
                    <ExpandedPlatform
                        key={investment.id}
                        investmentPlatform={investment}
                        similarInvestments={similarInvestments}
                        setActivePlatform={setActivePlatform}
                        expandable={expandable}
                        resetTotal={resetTotal}
                        search={search}
                        handleMoveToListView={handleMoveToListView}
						isListView={isListView}
                    />
                );
            }
            if (index === premium.length-1) {
                return (
                    <div key={`ParallelMarkets${investment.id}_${index}`}>
                        {investmentComponent}
                        <ParallelMarkets
                            id={investment.id}
                        />
                    </div>
                );
            }
            return investmentComponent;
        })}
            <div className={`${classes.title} ${classes.marginTop44}`}>
                Investment Objective
            </div>
            <div className={`${classes.desc} ${classes.marginBottom16}`}>
                Platforms by categories based on your goals
            </div>
            <ObjectiveList
                investments={investmentsTotal}
                handleObjectives={handleObjectives}
                expandable={expandable}
            />

            <div className={`${classes.title} ${classes.marginTop44}`}>
                Investment Collections
            </div>
            <div className={`${classes.desc} ${classes.marginBottom16}`}>
                Collections of investment opportunities created by our editors
            </div>
            <CollectionList
                clusters={clusters}
                investments={investmentsTotal}
                handleCollection={handleCollection}
                expandable={expandable}
            />

            <div className={`${classes.title} ${classes.marginTop44}`}>
                Investment Type
            </div>
            <div className={`${classes.desc} ${classes.marginBottom16}`}>
                Platforms by investment types
            </div>
            <TypesList
                investments={investmentsTotal}
                handleType={handleType}
                expandable={expandable}
            />
        </div>
	);
};
