import React, { useEffect, useState, useMemo } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { getPlatformsById } from '../../utils';

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'inline-block',
        color: '#5056E7',
        cursor: 'pointer',
    }
}));

export const TagUrl = (props) => {
    const {
        text,
        platform,
        handleClick: handleClickProp,
    } = props;
    const classes = useStyles();
    const handleClick = () => {
        handleClickProp(platform);
    }
    return (
        <div
            onClick={handleClick}
            className={classes.link}
        >
            ${text}
        </div>
    )
}
