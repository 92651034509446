import React, { useState, useRef } from 'react';
import { TextField, Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import VizSensor from 'react-visibility-sensor';
import { ObserverSensor } from '../ObserverSensor/ObserverSensor';
import Grid from '@material-ui/core/Grid';
import { useSelector, shallowEqual } from 'react-redux';
import { trackEvent, eventTracker, generateSubId } from '../../utils';
import Logo from '../../img/parallelMarkets/Logo150.png';
import LogoBG from '../../img/parallelMarkets/LogoBG_clean.png';
import LogoBG2 from '../../img/parallelMarkets/LogoBG2_clean.png';
import LogoBG3 from '../../img/parallelMarkets/LogoBG3_clean.png';

const useStyles = makeStyles((theme) => ({
	main: {
		cursor: 'pointer',
		maxWidth: '1200px',
		margin: 'auto',
		width: '100%',
		marginTop: '16px',
		background: '#ffffff',
		border: '1px solid #5056E7',
		boxSizing: 'border-box',
		boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
		borderRadius: '8px',
		padding: '0px',
		marginBottom: '20px',
		backgroundRepeat: 'no-repeat',
		[theme.breakpoints.up('1100')]: {
			backgroundImage: `url(${LogoBG})`,
			backgroundPosition: 'left center',
			display: 'flex',
			height: '160px !important',
		},
		[theme.breakpoints.down('1100')]: {
			backgroundImage: `url(${LogoBG2})`,
			display: 'block',
			height: '230px !important',
		},
		[theme.breakpoints.down('850')]: {
			display: 'flex',
			flexDirection: 'column',
		},
		[theme.breakpoints.down('530')]: {
			backgroundImage: `url(${LogoBG3})`,
			backgroundPosition: 'top',
			display: 'flex',
			flexDirection: 'column',
			height: '366px !important',
		},
		[theme.breakpoints.down('470')]: {
			height: '420px !important',
		},
		[theme.breakpoints.down('340')]: {
			height: '490px !important',
		},
	},
	item1: {
		position: 'relative',
		[theme.breakpoints.down('1000')]: {
			maxWidth: '100% !important',
		},
		[theme.breakpoints.down('530')]: {
			display: 'flex',
			justifyContent: 'center',
			textAlign: 'center',
			flexBasis: 'inherit',
		},
	},
	item2: {
		position: 'relative',
		[theme.breakpoints.up('1100')]: {
			textAlign: 'right',
		},
		[theme.breakpoints.down('1100')]: {
			textAlign: 'none',
		},
		[theme.breakpoints.down('850')]: {
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center',
			maxWidth: '100% !important',
			paddingRight: '20px',
			flexBasis: '100%',
		},
		[theme.breakpoints.down('690')]: {
			paddingTop: '0px',
		},
		[theme.breakpoints.down('530')]: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexBasis: 'inherit',
			padding: 0,
			maxWidth: '100%',
			flexGrow: 1,
		},
	},
	logo: {
		position: 'absolute',
		background: '#5056E7',
		top: '45px',
		left: '62px',
		[theme.breakpoints.down('1100')]: {
			top: '55px',
			left: '32px',
		},
		[theme.breakpoints.down('850')]: {
			top: '75px',
			left: '32px',
		},
		[theme.breakpoints.down('530')]: {
			top: '20px',
			left: 'auto',
		},
	},
	img: {
		width: '130px',
		height: '70px',
	},
	headerContainer: {
		maxWidth: '460px',
		[theme.breakpoints.up('1190')]: {
			margin: '26px 0px 20px 339px',
			width: '100%',
		},
		[theme.breakpoints.down('1190')]: {
			margin: '26px 0px 20px 309px',
			width: '100%',
		},
		[theme.breakpoints.down('1100')]: {
			margin: '20px 0px 20px 309px',
			width: '100%',
		},
		[theme.breakpoints.down('1000')]: {
			margin: '20px 0px 20px 309px',
			width: '100%',
		},
		[theme.breakpoints.down('850')]: {
			margin: '20px 10px 0px 229px',
			width: 'inherit',
		},
		[theme.breakpoints.down('750')]: {
			margin: '20px 10px 0px 219px',
			width: 'inherit',
		},
		[theme.breakpoints.down('590')]: {
			margin: '20px 5px 0px 219px',
			width: 'inherit',
		},
		[theme.breakpoints.down('530')]: {
			margin: '160px 0 0 0',
			padding: '0px 10px',
		},
	},
	heading1: {
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 'bold',
		fontSize: '20px',
		lineHeight: '28px',
		color: '#5056E7',
		marginBottom: '12px',
		[theme.breakpoints.down('590')]: {
			marginBottom: '5px',
		},
		[theme.breakpoints.down('530')]: {
			marginBottom: '12px',
		},
	},
	heading2: {
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '14px',
		lineHeight: '20px',
		color: '#30333E',
	},
	pseudoborder: {
		height: '100px',
		background: '#f2f5f8',
		position: 'absolute',
		marginTop: '30px',
		//marginLeft: 'calc(100%);',
		width: '1px',
		top: '0px',
		[theme.breakpoints.down('1100')]: {
			display: 'none',
		},
	},
	lastContainer: {
		maxWidth: '800px',
		display: 'inline-block',
		[theme.breakpoints.down('1100')]: {
			margin: '0px 0px 20px 309px',
		},
		[theme.breakpoints.down('850')]: {
			//margin: '0px 0px 0px 229px',
			margin: '0px',
		},
		[theme.breakpoints.down('750')]: {
			//margin: '0px 0px 0px 219px',
			margin: '0px',
		},
		[theme.breakpoints.down('530')]: {
			textAlign: 'center',
			margin: '0',
		},
	},
	button: {
		width: '184px',
		height: '48px',
		background: '#5056E7',
		borderRadius: '8px',
		marginLeft: '32px',
		marginTop: '56px',
		marginRight: '32px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		[theme.breakpoints.down('1190')]: {
			marginLeft: '10px',
			marginRight: '10px',
		},
		[theme.breakpoints.down('1100')]: {
			margin: '0',
		},
		[theme.breakpoints.down('590')]: {
			height: '30px',
		},
		[theme.breakpoints.down('530')]: {
			height: '48px',
			width: '184px',
			margin: '0',
		},
	},
	buttonText: {
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 500,
		fontSize: '16px',
		lineHeight: '24px',
		color: '#FFFFFF',
		[theme.breakpoints.down('590')]: {
			fontSize: '14px',
		},
		[theme.breakpoints.down('530')]: {
			fontSize: '16px',
		},
	},
}));
export const ParallelMarkets = (props) => {
	const { id } = props;
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const classes = useStyles();
	const [source, setSource] = React.useState(state.itemSource || 'MoneyMade');

	const siteUrl =
		'https://parallelmarkets.com/landing-top/?utm_source=moneymade&utm_medium=paid&utm_campaign=moneymade';

	const onCardClick = () => {
		trackEvent('ExternalClick', 'platform', 'Parallel markets');
		eventTracker(
			'ExternalClick',
			'Parallel markets',
			state.itemSource,
			state.itemMedium,
			state.itemCompaign,
			state.userId
		);
		const win = window.open(
			`${siteUrl.replace(
				'%SUBID%',
				generateSubId(state.itemSource, state.itemMedium)
			)}`,
			'_blank'
		);
		if (win != null) {
			win.focus();
		}
	};

	return (
		<VizSensor
			onChange={(isVisible) => {
				trackEvent('AdImpression', 'Parallel markets', 'Parallel markets');
				eventTracker(
					'AdImpression',
					'Parallel markets',
					state.itemSource,
					state.itemMedium,
					state.itemCompaign,
					state.userId
				);
			}}
		>
			<ObserverSensor
				id={`Parallel markets_${id}`}
				onMouseOver={() => {
					trackEvent('PlatformMouseOver', 'platform', 'Parallel markets');
					eventTracker(
						'PlatformMouseOver',
						'Parallel markets',
						state.itemSource,
						state.itemMedium,
						state.itemCompaign,
						state.userId
					);
				}}
				onScroll={() => {
					trackEvent('PlatformScroll', 'platform', 'Parallel markets');
					eventTracker(
						'PlatformScroll',
						'Parallel markets',
						state.itemSource,
						state.itemMedium,
						state.itemCompaign,
						state.userId
					);
				}}
			>
				<Grid className={classes.main} onClick={onCardClick}>
					<Grid item xs={8} lg={8} className={classes.item1}>
						<div className={classes.logo}>
							<img src={Logo} className={classes.img} />
						</div>
						<div className={classes.headerContainer}>
							<div className={classes.heading1}>
								Tired of having to prove your Accreditation status every time you
								invest?
							</div>
							<div className={classes.heading2}>
								Finally, a portable identity for accredited investors that can be
								used across investment platforms. Accredit once, use everywhere.
							</div>
						</div>
					</Grid>
					<Grid item xs={4} lg={4} className={classes.item2}>
						<div className={classes.lastContainer}>
							<div className={classes.pseudoborder}></div>
							<div className={classes.button}>
								<div className={classes.buttonText}>Get yours FREE</div>
							</div>
						</div>
					</Grid>
				</Grid>
			</ObserverSensor>
		</VizSensor>
	);
};
