import React from 'react';
import { Helmet } from "react-helmet";

export const HelmetCard = (props) => {
    const { title, desc } = props;

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={`${desc}`} />
            <meta property="og:site_name" content={title} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={`${desc}`} />
        </Helmet>
    )
};
