import React, { useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import ReactGA from 'react-ga';
import CardCollapsedSkeleton from '../Skeleton/CardCollapsedSkeleton';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from 'react-router-dom';
//import { getDiscoveryPageName } from '../../utils';
import { ModalSignUp } from '../Modal/ModalSignUp';
import * as actions from "../../redux/actions";
import { getFavorite } from '../../Services/GetFavoriteAPI';
import { checkUser } from '../../Services/CheckUserAPI';
import { routeNames } from '../../data/Data';
import { CollapsedCard } from '../InvestmentPlatform/CollapsedCard';

import bg from '../../img/qa/bgFullEdited5.svg';
import bgTablet from '../../img/qa/bgFullEdited.svg';
import bgMobile from '../../img/qa/bgMobile.svg';
import bgCard from '../../img/qa/bgCard.svg';
import bgMain from '../../img/qa/bgMain4.svg';
import bgMainMobile from '../../img/qa/bgMainMobile.svg';

import bg1Tablet from '../../img/lpbgs/bg1Tablet.svg';

import bottom1web from '../../img/lpbgs/bottomsection.svg';
import bottom1tablet from '../../img/lpbgs/bgBottomTablet.svg';
import bottom1phone from '../../img/lpbgs/bgBottomPhone.svg';

import groupsvg from '../../img/lpbgs/Group 59.svg';
import groupsvgtablet from '../../img/lpbgs/Group 59tablet.svg';
import groupsvgphone from '../../img/lpbgs/Group 59phone.svg';

import phoneImg from '../../img/lpbgs/iphone 1.png';
import phoneImgMobile from '../../img/lpbgs/iphone 2.png';

import mailSvg from '../../img/lpbgs/Group 60.png';
import mailSvgMobile from '../../img/lpbgs/Group 60Mobile.png';
import reviewimg from '../../img/lpbgs/reviews illustration.png';
import reviewimgTablet from '../../img/lpbgs/reviews illustrationTablet.png';
import reviewimgMobile from '../../img/lpbgs/reviews illustrationMobile.png';
import { LogoComponent } from '../Logo/LogoComponent';
import { loginUser, checkAuth, getToken } from '../../Firebase/firebase-service';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});
const CustomButton = withStyles((theme) => ({
	root: {
		fontSize: '16px',
		fontWeight: '500',
        textTransform: 'none',
        //marginTop: '10px',
        //padding: '4px 16px',
        maxWidth: '213px',
        height: '48px',
        margin: '0 auto',
        [theme.breakpoints.down('xs')]: {
			//marginTop: '14px',
			//fontSize: '12px',
			//marginLeft: '24px',
        },
	},
}))(Button);
const useStyles = makeStyles((theme) => ({
    container: {
        overflow: 'hidden',
    },
    headerContainer: {
        overflow: 'hidden',
        marginTop: '0px !important',
    },
    header: {
        [theme.breakpoints.down('768')]: {
            margin: '0 20px',
        },
        [theme.breakpoints.down('440')]: {
            margin: '0 20px',
        },
    },
    LogoComponent: {
        marginTop: '10px',
        marginBottom: '131px',
        [theme.breakpoints.down('1146')]: {
            marginTop: '20px',
            marginBottom: '64px',
        },
        [theme.breakpoints.down('768')]: {
            marginLeft: '20px',
            paddingTop: '20px',
        },
        [theme.breakpoints.down('440')]: {
            marginLeft: '20px',
            marginBottom: '44px',
            paddingTop: '20px',
        },
    },
    title: {
        fontFamily: 'Red Hat Display',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '44px',
        lineHeight: '56px',
        color: '#FFFFFF',
        maxWidth: '400px',
        marginBottom: '10px',
        [theme.breakpoints.down('1146')]: {
            //margin: '0 auto',
        },
        [theme.breakpoints.down('768')]: {
            fontSize: '36px',
            lineHeight: '48px',
            textAlign: 'center',
            marginBottom: '44px',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    dateTimeContainer: {
        marginBottom: '48px',
        [theme.breakpoints.down('768')]: {
            marginBottom: '22px',
        },
    },
    dateTime: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '32px',
        color: '#FFFFFF',
    },
    desc: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '18px',
        lineHeight: '32px',
        color: '#FFFFFF',
        marginBottom: '30px',
        [theme.breakpoints.down('768')]: {
            fontSize: '16px',
            textAlign: 'center',
        },
    },
    highLightTitle: {
        fontFamily: 'Red Hat Display',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '36px',
        lineHeight: '48px',
        color: '#30333E',
        marginBottom: '37px',
    },
    highlightItems: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '18px',
        lineHeight: '30px',
        color: '#30333E',
    },
    questionsTitle: {
        fontFamily: 'Red Hat Display',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '26px',
        lineHeight: '48px',
        color: '#30333E',
        marginTop: '40px',
        marginBottom: '40px',
        [theme.breakpoints.down('800')]: {
			textAlign: 'center',
		},
    },
    buttonText: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
    },
    topDesc: {
        maxWidth: '150px',
    },
    blue: {
        background: '#5056e7',
        color: '#ffffff',
        '&:hover': {
			backgroundColor: 'rgb(80,86,231, 0.8)',
		},
    },
    white: {
        background: '#ffffff !important',
        color: '#5056e7',
    },
    button: {
		fontSize: '16px',
		fontWeight: '500',
        textTransform: 'none',
        maxWidth: '213px',
        height: '48px',
    },
    imgLogoContainer: {
        position: 'relative',
        //marginTop: '100px',
        marginLeft: 0,
        [theme.breakpoints.down('1146')]: {
            marginTop: '0px',
            textAlign: 'center',
        },
        [theme.breakpoints.down('1146')]: {
            marginTop: '0px',
            textAlign: 'center',
        },
    },
    imgLogo: {
        position: 'absolute',
        top: '150px',
        left: '100px',
        [theme.breakpoints.down('440')]: {
            left: '50px',
        },
    },
    imgLaptop: {
        [theme.breakpoints.down('1146')]: {
            maxWidth: '50% !important',
        },
        [theme.breakpoints.down('440')]: {
            position: 'absolute !important',
            left: '-100px',
            top: '-70px',
            maxWidth: '200% !important',
        },
    },
    desktop: {
        [theme.breakpoints.down('768')]: {
            textAlign: 'center',
        },
        [theme.breakpoints.down('440')]: {
            display: 'none',
        },
    },
    tablet: {
        [theme.breakpoints.up('440')]: {
            display: 'none',
        },
    },
    dateContainer: {
        position: 'absolute',
    },
    headerBottom: {
        [theme.breakpoints.down('440')]: {
            position: 'absolute',
            bottom: '-300px',
            left: 0,
            right: 0,
            textAlign: 'center',
        },
    },
    mainContainer: {
        display: 'flex',
        //marginTop: '50px',
        //marginLeft: '250px',
        [theme.breakpoints.down('1500')]: {
            //marginLeft: '100px',
        },
        [theme.breakpoints.down('1300')]: {
            //marginLeft: '50px',
        },
        [theme.breakpoints.down('1200')]: {
            display: 'block',
            //marginLeft: '150px',
        },
        [theme.breakpoints.down('800')]: {
            display: 'block',
            marginLeft: '0px',
        },
    },
    mainItem: {
        width: '100%',
        flex: '0 50%',
        marginLeft: '150px',
        [theme.breakpoints.down('1500')]: {
            marginLeft: '100px',
        },
        [theme.breakpoints.down('1300')]: {
            marginLeft: '50px',
        },
        [theme.breakpoints.down('1200')]: {
            marginLeft: '150px',
        },
        [theme.breakpoints.down('800')]: {
            margin: '0 20px',
            width: 'inherit',
        },
    },
    bullet: {
        maxWidth: '560px',
    },
    cardBottom: {
        [theme.breakpoints.down('1200')]: {
            marginTop: '150px',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '90%',
        },
        [theme.breakpoints.down('800')]: {
            width: '90%',
            margin: '20px auto',
        },
    },
    card: {
        maxWidth: '397px',
        background: '#FFFFFF',
        boxShadow: '0px 3px 28px rgba(6, 7, 23, 0.06)',
        borderRadius: '12px',
        padding: '40px',
        marginTop: '200px',
        [theme.breakpoints.down('1200')]: {
            marginTop: '100px',
        },
        [theme.breakpoints.down('800')]: {
            margin: '40px auto',
            padding: '20px',
        },
    },
    cardHeader: {
        display: 'flex',
    },
    linkedin: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '18px',
        lineHeight: '30px',
        color: '#3E94F9',
        marginBottom: '41px',
    },
    speakerName: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '26px',
        lineHeight: '30px',
        color: '#30333E',
        marginBottom: '10px',
        [theme.breakpoints.down('800')]: {
            fontSize: '24px',
            lineHeight: '24px',
        },
    },
    speakerDesc1: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '18px',
        lineHeight: '30px',
        color: '#30333E',
        marginBottom: '28px',
        [theme.breakpoints.down('800')]: {
            fontSize: '16px',
            lineHeight: '24px',
        },
    },
    speakerDesc2: {
        maxWidth: '357px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '18px',
        lineHeight: '30px',
        color: '#30333E',
        paddingRight: '10px',
        [theme.breakpoints.down('800')]: {
            fontSize: '16px',
            lineHeight: '24px',
        },
    },
    speakerLogo: {
        width: '180px',
        height: '180px',
        [theme.breakpoints.down('800')]: {
            width: '140px',
            height: '140px',
        },
        [theme.breakpoints.down('400')]: {
            width: '120px',
            height: '120px',
        },
    },
    buttomBottom: {
        paddingLeft: '25%',
        [theme.breakpoints.down('800')]: {
            paddingLeft: '0',
            textAlign: 'center',
        },
    },
}));
export const QASessionPage = (props) => {
    const classes = useStyles();
    const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
    const history = useHistory();
    const dispatch = useDispatch();
    const { url: currentUrl } = useRouteMatch();

    const [displaySpinner, setDisplaySpinner] = useState(true);
    const [investment, setInvestment] = useState(null);
    const [investmentCard, setInvestmentCard] = useState(null);
    const [openModalSignUp, setOpenModalSignUp] = useState(state.modalSignUpOpen);
    const platformName = "VinoVest";
    let homeStyles = {}, mainStyle = {};

    useEffect(() => {
        //setDisplaySpinner(true);
        getPlatformData();
    }, []);

    // check is user logged in
    useEffect(() => {
		checkAuth(callbackAuth);
    }, []);

    // get favorites investments
	useEffect(() => {
		if (state.loggedIn) {
			getFavorite(state.token)
			.then((data) => {
				dispatch(actions.addInvesmentsToFavorites(data.result));
			}).catch((error) => {
				console.log(error);
			});
		}
    }, [state.loggedIn]);

    // wait until all platforms are loaded
    useEffect(() => {
		if (Object.values(state.investmentsTotal).length > 0) {
            const platform = Object.values(state.investmentsTotal).filter((i) => i.name.toLowerCase().trim() === platformName.toLowerCase());
            if (platform.length > 0) {
                setInvestmentCard(platform[0]);
            }
		}
    }, [state.investmentsTotal]);

    // toggle modal
    useEffect(() => {
        if (state.modalSignUpOpen) {
            setOpenModalSignUp(true);
        } else {
            setOpenModalSignUp(false);
        }
    }, [state.modalSignUpOpen]);

	const callbackAuth = async (currentUser) => {
		if (currentUser !== null) {
			const token = await currentUser.getIdToken();
			dispatch(actions.setToken(token));
			dispatch(actions.setLoggedInStatus(true));
		} else {
			dispatch(actions.setLoggedInStatus(false));
		}
	};

    const buttonBlue = (
        <Button variant="contained" className={`${classes.button} ${classes.blue}`}
            href={`${investment !== null ? investment.calendarUrl : ''}`}
            download
        >
            Add to Calendar
        </Button>
    );
    const buttonWhite = (
        <CustomButton variant="contained" className={`${classes.white}`}
            href={`${investment !== null ? investment.calendarUrl : ''}`}
            download
        >
            Add to Calendar
        </CustomButton>
    );
    const getPlatformData = async () => {
        try {
            const { platform } = await import(`../../data/QASession/${platformName.toLowerCase()}`);
            setInvestment(platform);
        } catch (e) {
            setInvestment(null);
        }
        //setDisplaySpinner(false);
    };
    const handleCardClick = () => {
        history.push(`/${routeNames.discover}/${investmentCard !== null ? investmentCard.slug : ''}`);
    };

    if (window.innerWidth > 1200) {
        mainStyle={
            bgMain: {
				background: `url(${bgMain})`,
				height: '800px',
                backgroundRepeat: 'no-repeat',
            },
            bgBottom: {
                background: `url(${bgCard})`,
				height: '300px',
                backgroundRepeat: 'no-repeat',
            }
        }
    }
    if (window.innerWidth <= 1200 && window.innerWidth > 1146) {
        mainStyle={
            bgMain: {
				background: `url(${bgMain})`,
				height: '1100px',
                backgroundRepeat: 'no-repeat',
            },
            bgBottom: {
                background: `url(${bgCard})`,
				height: '300px',
                backgroundRepeat: 'no-repeat',
            }
        }
	}
    if (window.innerWidth > 1146) {
		homeStyles = {
			bg1: {
				background: `url(${bg})`,
				height: '830px',
				backgroundRepeat: 'no-repeat',
			},
			bg4: {
				background: `url(${bottom1web})`,
				height: '1027px',
				marginBottom: '-56px',
			},

			grid1: { height: '924px', width: '1280px', maxWidth: '90%', margin: 'auto' },
			grid1noH: {
				height: 'unset',
				width: '1080px',
				maxWidth: '90%',
				margin: 'auto',
				marginBottom: '100px',
			},
			grid2: { height: '924px', maxWidth: '90%', margin: 'auto' },
			grid3: { height: '100%', maxWidth: '90%', margin: 'auto' },
			gridnoH: {
				height: 'unset',
				maxWidth: '90%',
				margin: 'auto',
				marginTop: '0px',
				marginBottom: '0px',
			},
			SvgMail: groupsvg,
			PhoneImage: phoneImg,
			ImgReview: reviewimg,
            ImgMail: mailSvg,
        };
	}
    if (window.innerWidth <= 1146 && window.innerWidth > 768) {
		homeStyles = {
			bg1: {
				background: `url(${bg})`,
				height: '1280px',
				backgroundRepeat: 'no-repeat',
			},
			bg4: {
				background: `url(${bottom1web})`,
				height: '1027px',
				marginBottom: '-56px',
			},

			grid1: { height: '924px', width: '1280px', maxWidth: '90%', margin: 'auto' },
			grid1noH: {
				height: 'unset',
				width: '1080px',
				maxWidth: '90%',
				margin: 'auto',
				marginBottom: '100px',
			},
			grid2: { height: '924px', maxWidth: '90%', margin: 'auto' },
			grid3: { height: '100%', maxWidth: '90%', margin: 'auto' },
			gridnoH: {
				height: 'unset',
				maxWidth: '90%',
				margin: 'auto',
				marginTop: '0px',
				marginBottom: '0px',
			},
			SvgMail: groupsvg,
			PhoneImage: phoneImg,
			ImgReview: reviewimg,
            ImgMail: mailSvg,
        };
        mainStyle={
            bgMain: {
				background: `url(${bgMain})`,
				height: '1100px',
                backgroundRepeat: 'no-repeat',
            },
            bgBottom: {
                background: `url(${bgCard})`,
				height: '300px',
                backgroundRepeat: 'no-repeat',
            }
        }
	}
	if (window.innerWidth <= 768 && window.innerWidth > 440) {
		homeStyles = {
			bg1: {
				background: `url(${bg1Tablet})`,
				height: 1124,
				backgroundRepeat: 'no-repeat',
			},
			grid1: { height: '1124px' },
			bg4: {
				background: `url(${bottom1tablet})`,
				height: '1260px',
				marginBottom: '-56px',
			},
			SvgMail: groupsvgtablet,
			PhoneImage: phoneImg,
			ImgReview: reviewimgTablet,
			ImgMail: mailSvgMobile,
        };
        mainStyle={
            bgMain: {
				background: `url(${bgMain})`,
				height: '1160px',
                backgroundRepeat: 'no-repeat',
            },
            bgBottom: {
                background: `url(${bgCard})`,
				height: '300px',
                backgroundRepeat: 'no-repeat',
            }
        }
	}
	if (window.innerWidth <= 440) {
		homeStyles = {
			bg1: {
				background: `url(${bgMobile})`,
				height: '1260px',
				backgroundRepeat: 'no-repeat',
			},
			grid1: { height: '834px' },
			bg4: {
				background: `url(${bottom1phone})`,
				height: '1027px',
				marginBottom: '-96px',
			},
			SvgMail: groupsvgphone,
			PhoneImage: phoneImgMobile,
			ImgReview: reviewimgMobile,
			ImgMail: mailSvgMobile,
        };
        mainStyle={
            bgMain: {
				background: `url(${bgMainMobile})`,
				height: '1200px',
                backgroundRepeat: 'no-repeat',
            },
            bgBottom: {
                background: `url(${bgCard})`,
				height: '300px',
                backgroundRepeat: 'no-repeat',
            }
        }
    }

    const dateComponent = (
        investment !== null &&
        <div className={classes.dateTimeContainer}>
            <div className={classes.dateTime}>
                {investment.date}
            </div>
            <div className={classes.dateTime}>
                {investment.time}
            </div>
        </div>
    );

	return (
        <div className={`respCont ${classes.container}`}>
            <CssBaseline />
            {investment !== null ?
            <div>
                <header style={homeStyles.bg1} className={`${classes.headerContainer} transparentFixedHeader`}>
                    <Grid style={homeStyles.grid1} className="front" container spacing={1}>
                        <Grid xs={6} item>
                            <div className="textGrid3">
                                <div className={classes.LogoComponent}>
                                    <LogoComponent
                                        color="white"
                                    />
                                </div>
                                <div className={classes.header}>
                                    <div className={classes.title}>
                                        {investment.title}
                                    </div>
                                    <div className={classes.desktop}>
                                        {dateComponent}
                                    </div>
                                    <div className={classes.desc}>
                                        {investment.desc}
                                    </div>
                                </div>
                                <div className={classes.desktop}>
                                    {buttonWhite}
                                </div>
                            </div>
                        </Grid>
                        <Grid xs={6} className={`${classes.imgLogoContainer}`} item>
                            <img alt="" src={investment.laptopImg} className={`${classes.imgLaptop}`} />
                            <div className={`${classes.imgLogo}`}>
                                <img alt="" src={investment.logoImg} />
                            </div>
                            <div className={`${classes.tablet} ${classes.dateContainer} ${classes.headerBottom}`}>
                                {dateComponent}
                                {buttonWhite}
                            </div>
                        </Grid>
                    </Grid>
			    </header>
                <main className={`${classes.mainContainer} bgSize`} style={mainStyle.bgMain}>
                    <div className={classes.mainItem}>
                        <div className={classes.highLightTitle}>
                            Highlights
                        </div>
                        <div className={`qaBullet ${classes.bullet}`}>
                            <ul>
                            {investment.highlights.map((i, index) => {
                                return (
                                    <li key={index}>
                                        {i}
                                    </li>
                                )
                            })}
                            </ul>
                        </div>
                        <div className={classes.questionsTitle}>
                            Post questions. Get answers. It’s that simple.
                        </div>
                        <div className={classes.buttomBottom}>
                            {buttonBlue}
                        </div>
                    </div>
                    <div className={classes.mainItem}>
                        <div className={classes.card}>
                            <div className={classes.cardHeader}>
                                <div style={{width: '50%'}}>
                                    <div className={classes.linkedin}>
                                        <a
                                            href={investment.linkedin}
                                            className={`qaLink`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            LinkedIn
                                        </a>
                                    </div>
                                    <div className={`${classes.speakerName} ${classes.topDesc}`}>
                                        {investment.speakerName}
                                    </div>
                                    <div className={`${classes.speakerDesc1} ${classes.topDesc}`}>
                                        {investment.speakerTitle}
                                    </div>
                                </div>
                                <div style={{width: '50%'}}>
                                    <div>
                                        <img
                                            src={`${investment.speakerImg}`}
                                            alt={`speaker`}
                                            className={classes.speakerLogo}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={classes.speakerDesc2}>
                                {investment.speakerDesc}
                            </div>
                        </div>
                    </div>
                </main>
                <div className={`${classes.cardBottom}`} style={mainStyle.bgBottom}>
                    {investmentCard !== null ? (
                        <CollapsedCard
                            investmentPlatform={investmentCard}
                            expandable={false}
                            itemSource={state.itemSource}
                            itemMedium={state.itemMedium}
                            onCardClick={handleCardClick}
                            stopPropagation={() => {}}
                        />
                    ) : <CardCollapsedSkeleton />}
                </div>
            </div>
            : null}
            <ModalSignUp
				open={openModalSignUp}
			/>
        </div>
	);
};
