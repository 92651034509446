import { REACT_APP_GET_LATEST_STREAM_URL } from "./ConstAPI";

const makeURL = () => `${REACT_APP_GET_LATEST_STREAM_URL}`;

const makeRequestData = (data) => ({
    method: "POST",
    body: JSON.stringify(data),
    headers: {
        "Content-Type" : "application/json",
    },
});

const makeRequest = async (data) => {
    const url = makeURL();
    const requestData = makeRequestData(data);

    return await fetch(url, requestData);
};
/**
 * @param {string} lastId
 * @param {string} platformId
 */
export const getLatestStream = async (lastId = "", platformId = "") => {
    try {
		const response = await makeRequest({
            lastId,
            platformId
        });
        const responseStatus = await response.json();
        if (response.status === 200) {
            return {
                success: true,
                result: [],
                message: responseStatus,
            }
        } else {
            return {
                success: false,
                result: [],
                message: responseStatus,
            };
        }
	} catch (error) {
        console.log(error);
		return {
			success: false,
			result: [],
			message: error,
        };
	}
};
