import React, { useState, useEffect, useMemo } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import LazyLoad from 'react-lazyload';
import { makeStyles, useTheme, withStyles, fade } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import VizSensor from 'react-visibility-sensor';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Spinner } from '../../Spinner/Spinner';
import ExpandedPlatform from '../../InvestmentPlatform/ExpandedPlatform';
import { trackEvent, sortDescTwoLevels, eventTracker, catchError, getFullPlatformUrl } from '../../../utils';
import { routeNames } from '../../../data/Data';
import moment from "moment";
import { CollapsedCard } from '../Favorites/CollapsedCard';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
    },
    expandIcon: {
		position: 'absolute',
		top: '12px',
		right: '12px',
		background: '#5056E7',
		'&:hover': {
			backgroundColor: '#646AF0',
		},
		color: '#fff',
		borderRadius: '100px',
		textTransform: 'none',
		padding: '6px 16px',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
    favoritesTitle: {
        fontFamily: 'Red Hat Display',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '28px',
        lineHeight: '40px',
        color: '#30333E',
        padding: '40px 0 0 40px',
    },
    favoritesCount: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#73757A',
        padding: '4px 0 24px 40px',
    },
    date: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#73757A',
        padding: '20px 0 16px 40px',
    },
    investCard: {
		'&:hover  $expandIcon': {
			display: 'block',
        },
        position: 'relative',
        width: '96%',
        padding: '4px 0 0px 40px',
        marginRight: '70px',
        marginBottom: '12px',
        [theme.breakpoints.down('770')]: {
            width: '100%',
        },
    },
    [theme.breakpoints.down('1495')]: {
        favoritesTitle: {
            padding: '40px 0 0 0px',
        },
        favoritesCount: {
            padding: '4px 0 24px 0px',
        },
        investCard: {
            padding: '4px 0 0px 0px',
            marginRight: '0',
        },
        date: {
            padding: '20px 0 16px 0px',
        }
    },
    starContainer: {
        position: 'relative',
        float: 'right',
        [theme.breakpoints.down('770')]: {
            display: 'none',
        },
	},
	starImg: {
		position: 'absolute',
		top: '-100px',
        left: '0px',
        paddingLeft: '16px',
        cursor: 'pointer',
		[theme.breakpoints.down('445')]: {
			position: 'absolute',
			top: '-20px',
			left: '0px',
			cursor: 'pointer',
        },
    },
    moreButton: {
		textTransform: 'none',
		color: '#73757A',
		fontFamily: 'Inter',
		fontSize: '14px',
    },
    card: {
		'&:hover': { cursor: 'pointer' },
	},
	cardAction: {
		borderTop: '1px solid #F2F5F8',
		padding: '0px',
    },
    premiumCard: {
		border: '1px solid #F3B81F',
	},
}));

export const NewPlatforms = (props) => {
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const dispatch = useDispatch();
    const classes = useStyles();

    const [investments, setInvestments] = useState(state.investmentsTotal);
    const [source, setSource] = React.useState(state.itemSource || 'MoneyMade');
    const [activePlatform, setActivePlatform] = useState(null);

    const areInvestmentsPresent = Object.values(investments).length > 0;

    useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
    useEffect(() => {
		if (Object.values(state.investmentsTotal).length > 0) {
			setInvestments(state.investmentsTotal);
		}
	}, [state.investmentsTotal]);

    //scroll to active platform
    useEffect(() => {
        if (activePlatform !== null && activePlatform !== undefined) {
            try{
                let element = document.getElementById(`platform_ex_null${activePlatform.id}`);
                element.scrollIntoView({
                    behavior: 'smooth',
                });
            } catch(err) {
                catchError(err);
            }
        }
    }, [activePlatform]);

    const handleCardClick = async (id, investmentPlatform) => {
        const platformUrl = `/${routeNames.discover}/${id}`;
        ReactGA.pageview(platformUrl);
        eventTracker("pageview", platformUrl, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);

        setActivePlatform(investmentPlatform);
    };
    const stopPropagation = (event, investmentPlatform) => {
        trackEvent('ExternalClick', 'platform', investmentPlatform.name);
        eventTracker("ExternalClick", investmentPlatform.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);

		event.stopPropagation();
    };
    const dates = [];

	return (
		<div className={`respCont ${classes.root}`}>
			<CssBaseline />
			<div className={classes.toolbar} />
			<main className={`${classes.content} mainPrinc`}>
				<div className={classes.favoritesTitle}>New Platforms</div>
                <div className={classes.favoritesCount}>
                    Platforms recently added to MoneyMade
                </div>
                {areInvestmentsPresent && Object.values(investments)
                    .sort(sortDescTwoLevels("dateAdded", "_seconds"))
                    .map(function (investment, index) {
                        let investmentComponent = null;
                        const fullPlatformUrl = getFullPlatformUrl(`discover/${investment.slug}`);

                        investmentComponent = (
                            <div
                                id={`platform_${investment.id}`}
                                key={`platform_${investment.id}`}
                                className={classes.investCard}
                            >
                                <LazyLoad
                                    height={230}
                                    offset={500}
                                    once
                                    key={`lazy_${investment.id}`}
                                >
                                    <VizSensor
                                        onChange={(isVisible) => {
                                            trackEvent('PlatformImpression', 'platform', investment.name);
                                            eventTracker("PlatformImpression", investment.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
                                        }}
                                    >
                                        <CollapsedCard
											fullPlatformUrl={fullPlatformUrl}
											investment={investment}
											stopPropagation={stopPropagation}
											handleCardClick={handleCardClick}
											itemSource={state.itemSource}
											itemMedium={state.itemMedium}
										/>
                                    </VizSensor>
                                </LazyLoad>
                            </div>
                        );
                        if (activePlatform === investment) {
                            const similarInvestments = activePlatform.similars.map((similar) => {
                                const similarInvestment = Object.values(investments).find(
                                    (investment) => investment.id === similar
                                );
                                return similarInvestment;
                            });
                            investmentComponent = (
                                <div
                                    className={classes.investCard}
                                    key={`ExpandedPlatform_${investment.id}`}
                                >
                                    <ExpandedPlatform
                                        key={investment.id}
                                        investmentPlatform={investment}
                                        similarInvestments={similarInvestments}
                                        setActivePlatform={setActivePlatform}
                                        expandable={false}
                                        embed={true}
                                        favorites={true}
                                    />
                                </div>
                            );
                        }
                        const dateFormat = moment.unix(investment["dateAdded"]["_seconds"]).format('MMMM Do YYYY');
                        const date = (
                            <div className={classes.date}>
                                {dateFormat}
                            </div>);

                        let displayDate = null;
                        if (index === 0) {
                            displayDate = date;
                            dates.push(dateFormat);
                        } else {
                            if (dates.includes(dateFormat) === true) {
                                displayDate = null;
                            } else {
                                displayDate = date;
                                dates.push(dateFormat);
                            }
                        }

                        return (
                            <div key={`platformDates_${investment.id}`}>
                                {displayDate}
                                {investmentComponent}
                            </div>);
				    })
                }

                <center>
                    {<Spinner displaySpinner={state.displaySpinner} />}
                </center>
			</main>
		</div>
	);
};
