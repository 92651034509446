import React, { useEffect } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import appIosImg from '../../img/icons/appIosImg.png';
import appAndroidImg from '../../img/icons/appAndroidImg.png';
import makeStyles from '@material-ui/core/styles/makeStyles';

import ReactGA from 'react-ga';
import { eventTracker, trackEvent, generateSubId } from '../../utils';
import { useSelector, shallowEqual } from 'react-redux';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles({
	root: {
		padding: '24px !important',
	},
});

export default function Sidebar({ investmentPlatform }) {
	const classes = useStyles();
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const [source, setSource] = React.useState(state.itemSource || 'MoneyMade');

	useEffect(() => {});

	return (
		<CardContent className={classes.root}>
			<Typography style={{ marginBottom: '5px' }} paragraph>
				Visit site
			</Typography>
			<Typography style={{ color: 'black' }} paragraph>
				<a
					style={{ color: '#5056E7' }}
					href={investmentPlatform.website.replace('%SUBID%', generateSubId(state.itemSource, state.itemMedium))}
					target="_blank"
					rel="noopener noreferrer"
					onClick={() => {
						trackEvent('ExternalClick', 'platform', investmentPlatform.name);
						eventTracker("ExternalClick", investmentPlatform.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
					}}
				>
					{investmentPlatform.name}
				</a>
			</Typography>

			<Typography style={{ marginBottom: '5px' }} paragraph>
				Established
			</Typography>
			<Typography style={{ color: 'black' }} paragraph>
				{investmentPlatform.established}
			</Typography>

			<Typography style={{ marginBottom: '5px' }} paragraph>
				Assets under management
			</Typography>
			<Typography style={{ color: 'black' }} paragraph>
				{investmentPlatform.aum
					? `$ ${investmentPlatform.aum
							.toString()
							.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
					: 'Unknown'}
			</Typography>

			<Typography style={{ marginBottom: '5px' }} paragraph>
				Other Account Fees
			</Typography>
			<Typography style={{ color: 'black' }} paragraph>
				<a
					style={{ color: '#5056E7' }}
					href={investmentPlatform.feesDescription}
					target="_blank"
					rel="noopener noreferrer"
				>
					See here
				</a>
			</Typography>

			<Typography style={{ marginBottom: '5px' }} paragraph>
				Community
			</Typography>
			<Typography style={{ color: 'black' }} paragraph>
				{investmentPlatform.community ? 'Yes' : 'No'}
			</Typography>

			<Typography style={{ marginBottom: '5px' }} paragraph>
				Debit card
			</Typography>
			<Typography style={{ color: 'black' }} paragraph>
				{investmentPlatform.debitCard ? 'Yes' : 'No'}
			</Typography>

			<Typography style={{ marginBottom: '5px' }} paragraph>
				Insurance
			</Typography>
			<Typography style={{ color: 'black' }} paragraph>
				{investmentPlatform.insurance ? 'Yes' : 'No'}
			</Typography>

			<Typography style={{ marginBottom: '5px' }} paragraph>
				Cash Management
			</Typography>
			<Typography style={{ color: 'black' }} paragraph>
				{investmentPlatform.cashManagement ? 'Yes' : 'No'}
			</Typography>

			<Typography style={{ marginBottom: '5px' }} paragraph>
				APP
			</Typography>
			<Typography style={{ color: 'black' }} paragraph>
				{investmentPlatform.appIos ? (
					<img alt="appIosImg" style={{ display: 'block' }} src={appIosImg} />
				) : null}
				{investmentPlatform.appAndroid ? (
					<img
						alt="appAndroidImg"
						style={{ display: 'block', marginTop: '10px' }}
						src={appAndroidImg}
					/>
				) : null}
				{!investmentPlatform.appIos && !investmentPlatform.appAndroid ? 'No' : null}
			</Typography>
		</CardContent>
	);
}
