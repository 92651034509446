import { REACT_APP_SESSION_INFO_URL } from "./ConstAPI";
import { cookiesEnabled, setStorage, getStorage } from '../utils';


const makeURL = () => `${REACT_APP_SESSION_INFO_URL}`;

const defaultHeaders = {
  "Content-Type" : "application/json; charset=utf-8"
};

const makeRequestData = data => ({
    method: "POST",
    headers: defaultHeaders,
    body: JSON.stringify(data),
});

const makeRequest = async (data) => {
    const url = makeURL();
    const requestData = makeRequestData(data);

    return await fetch(url, requestData);
};
/**
 * @param {object} fingerPrint
 */
export const sessionInfoAPI = async (fingerPrint) => {
    try {
		const response = await makeRequest(
            fingerPrint,
        );
        const responseStatus = await response.json();
        if (response.status === 200) {
            return {
                success: true,
                result: responseStatus,
                message: '',
            }
        } else {
            return {
                success: false,
                result: [],
                message: responseStatus.error.message,
            };
        }
	} catch (error) {
        console.log(error);
		return {
			success: false,
			result: [],
			message: error,
        };
	}
};
