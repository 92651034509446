import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { hideMedia, isStringExistAndNotEmpty } from '../../utils';
import { MediaCard } from '../Media/MediaCard';
import { BestFor } from './BestFor';
import { Press } from './Press';
import InvestmentPlatformTabs from './Tabs';
import Sidebar from './Sidebar';
import { ParallelMarkets } from '../ParallelMarkets/ParallelMarkets';
import { SimilarPlatformsCard } from './SimilarPlatformsCard';

const useStyles = makeStyles((theme) => ({
	innerCards: {
		display: 'flex',
		alignItems: 'flex-start',
		padding: '20px 8px 0 62px',
	},
	innerSimilarCards: {
		display: 'flex',
		alignItems: 'flex-start',
		padding: '0 0 0 62px',
		width: '70%',
	},
	innerCard: {
		border: '1px solid #F2F5F8',
		borderRadius: '8px',
		backgroundColor: '#fff',
		padding: 0,
		marginRight: '8px',

		'& .MuiCardContent-root:last-child': {
			padding: '0 !important',
		},
	},
	mediaCard: {
		padding: 0,
		marginRight: '8px',
		'& .MuiCardContent-root:last-child': {
			padding: '0 !important',
		},
	},
	bestForCard: {
		padding: 0,
		marginRight: '8px',
		'& .MuiCardContent-root:last-child': {
			padding: '0 !important',
		},
	},
	bestForCardLeft: {
		[theme.breakpoints.up('sm')]: {
			display: 'inherit',
		},
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	bestForCardBottom: {
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
		[theme.breakpoints.down('sm')]: {
			display: 'inherit',
		},
	},
	tabsCard: {
		width: '70%',
	},
	similarHeader: {
		fontWeight: '500',
		fontSize: '16px',
		lineHeight: '24px',
		marginLeft: '1em',
		padding: '0 8px 0 62px',
	},
	sidebar: {
		width: '30%',
	},
	sidebarCard: {
		padding: '24px',
		[theme.breakpoints.down('sm')]: {
			display: 'inherit',
		},
	},
	[theme.breakpoints.down('sm')]: {
		innerCards: {
			flexDirection: 'column',
			padding: '0 16px',
		},
		innerCard: {
			margin: '0 0 20px 0',
		},
		bestForCard: {
			margin: '0 0 20px 0',
		},
		innerSimilarCards: { width: '100%', flexDirection: 'column', padding: '0 16px' },
		tabsCard: { width: '100%' },
		sidebar: { width: '100%'},
		bestForContainerCard: {padding: '0'}
	},
}));

export const BodyPlatform = (props) => {
    const {
        investmentPlatform,
        similarInvestments,
        setActivePlatform,
        embed,
        cluster,
		openCreateReviewForm,
		resetTotal,
		search,
		expandable,
		handleMoveToListView,
		isListView,
    } = props;
    const classes = useStyles();
    const hideMediaValue = useMemo(() => hideMedia(investmentPlatform.promoVideo, investmentPlatform.media), [investmentPlatform.promoVideo, investmentPlatform.media]);
    const bestForComponent = (
		<BestFor
			bestFor={investmentPlatform.bestFor}
		/>
	);
	const id = `cardBody_${investmentPlatform.id}`;

    return (
        <>
            <div id={id}>
                <div className={classes.innerCards}>
                    <div className={`${classes.tabsCard}`}>
                        <div className={`${classes.mediaCard} `}>
                            {!hideMediaValue && (
                                <MediaCard
                                    id={investmentPlatform.id}
                                    logo={investmentPlatform.logo}
                                    promoVideo={investmentPlatform.promoVideo}
                                    media={investmentPlatform.media}
                                    mediaArray={isStringExistAndNotEmpty(investmentPlatform.promoVideo) ?
                                        [investmentPlatform.promoVideo, ...investmentPlatform.media] : investmentPlatform.media }
                            />)}
                            <div className={`${classes.bestForCard} ${classes.bestForCardBottom}`}>
                                {bestForComponent}
                            </div>
                        </div>
                        <div className={`${classes.innerCard} `}>
                            <InvestmentPlatformTabs
                                investmentPlatform={investmentPlatform}
                                onCreateReview={openCreateReviewForm}
                            />
                        </div>
                    </div>

                    <div className={`${classes.sidebar}`}>
                        <div className={`${classes.innerCard} ${classes.sidebarCard}`}>
                            <Sidebar
								investmentPlatform={investmentPlatform}
								color="1"
							/>
                        </div>
                        <div className={`${classes.bestForCard} ${classes.bestForCardLeft}`}>
                            {bestForComponent}
                        </div>
                    </div>

                </div>
                {investmentPlatform.investors === 'Accredited' && (
                    <div className={classes.innerCards}>
                        <ParallelMarkets
							id={investmentPlatform.id}
						/>
                    </div>
                )}
                {!embed && similarInvestments.length > 0 ? (
                    <>
						<SimilarPlatformsCard
							setActivePlatform={setActivePlatform}
							expanded={cluster ? false : true}
							similarPlatforms={similarInvestments}
							resetTotal={resetTotal}
							search={search}
							expandable={expandable}
							handleMoveToListView={handleMoveToListView}
							isListView={isListView}
						/>
                    </>
                ) : null}
            </div>
        </>
    )
}
