import React, { useState, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import _ from 'lodash';
import { Button } from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import VizSensor from 'react-visibility-sensor';
import { useSelector, shallowEqual } from 'react-redux';
import InvestmentPlatformCollapsed from '../InvestmentPlatform/CollapsedShort';
import { ParallelMarkets } from '../ParallelMarkets/ParallelMarkets';
import { getCluster } from '../../Services/ClusterAPI';
import { trackEvent, isPaidOnly, paidOnlyInvestments, eventTracker } from '../../utils';
import { SliderCard } from '../Slider/SliderCard';
import { Spinner } from '../Spinner/Spinner';
// import { useBrowserEffect, useIsoData } from '../../shared';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles((theme) => ({
	root: {
		background: '#FBFCFD',
	},
	floatButton: {
		background: '#5056E7',
		'&:hover': {
			backgroundColor: '#646AF0',
		},
		color: '#fff',
		borderRadius: '0px',
		textTransform: 'none',
	},
	stickyButton: {
		width: '100%',
		position: 'fixed',
		bottom: '0px',
		zIndex: 999,
	},
	content: {
		flexGrow: 1,
	},
	expandableHeader: {
		fontSize: '16px',
		fontFamily: 'Red Hat Display',
		fontWeight: 'bold',
		lineHeight: '24px',
		color: '#000',
		marginTop: '0px',
		background: '#DBE0E6',
		marginBottom: '5px',
		overflow: 'hidden',
		paddingLeft: '12px',
		paddingTop: '12px',
		paddingBottom: '12px',
		[theme.breakpoints.down('xs')]: {
			fontSize: '15px',
		},
		boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04);',
	},
}));

export const ClusterView = (props) => {
	const {
		expandableBannerShow,
	} = props;
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const classes = useStyles();
	const theme = useTheme();

	const number = 2500;
	const [sliderValue, setSliderValue] = React.useState(number);
	const [paidOnly, setPaidOnly] = useState(state.itemCompaign);
	const [investments, setInvestments] = useState([]);
	const [headline, setHeadline] = useState('');
	const [displaySpinner, setDisplaySpinner] = useState(true);
	const [incriment, setIncriment] = useState(false);

	const headerId = 'widget-header';
	const headerClass = 'reveal-text';
	const config = {
		threshold: 1
	}

	useEffect(() => {
		flowAsync();
	}, []);

	useEffect(() => {
		if (headline && !displaySpinner) {
			trackEvent('ExpandableHeader', 'title', headline);
			eventTracker("ExpandableHeader", headline, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);

			const header = document.getElementById('widget-header');
			const observer = new IntersectionObserver((entries) => observerCallback(entries, observer, header), config);
			observer.observe(header);
		}
	}, [headline, displaySpinner]);

	useEffect(() => {
		if (headline && !displaySpinner) {
			const header = document.getElementById('slider');
			const observer = new IntersectionObserver((entries) => observerSliderCallback(entries, observer, header), config);
			observer.observe(header);
		}
	}, [headline, displaySpinner]);

	useEffect(() => {
		if (incriment) {
			if (sliderValue >= 5000) {
				setIncriment(false);
			} else {
				setTimeout(function(){
					setSliderValue(sliderValue + 100);
				}, 50);
			}
		}

	}, [incriment, sliderValue]);

	useEffect(() => {
		if (state.itemCompaign) {
			setPaidOnly(isPaidOnly(state.itemCompaign));
		}
	}, [state.itemCompaign]);

	/*useBrowserEffect(() => {
		if (state.itemCompaign) {
			setPaidOnly(isPaidOnly(state.itemCompaign));
		}
	}, [state.itemCompaign]);*/

	const observerCallback = (entries, observer, header) => {
		entries.forEach((entry, i) => {
			header.classList.add(headerClass);
			entry.target.style.animationPlayState = 'running';
			observer.unobserve(entry.target);
		});
	};
	const observerSliderCallback = (entries, observer, header) => {
		entries.forEach((entry, i) => {
			setIncriment(true);
			observer.unobserve(entry.target);
		});
	};

	const flowAsync = async () => {
		const response = await getCluster();
		if (response.success) {
			const clusterWithInvestments = response.result;
			const platforms = paidOnlyInvestments(clusterWithInvestments.platforms, paidOnly);
			setHeadline(clusterWithInvestments.name);
			setInvestments(_.sortBy(platforms, [`${clusterWithInvestments.id}_premium`, 'minInvestment']));
		} else {
			console.log(response.message);
		}
		setDisplaySpinner(false);
	};

	const handleExpandableBannerShow = () => {
		setTimeout(() => {
			expandableBannerShow();
		}, 100);
	};

	const handleChange = (event, newValue) => {
		setSliderValue(newValue);
	};

	return (
		<>
			<div className={`respCont ${classes.root}`}>
				{displaySpinner ?
					(<center>
						<Spinner displaySpinner={displaySpinner} />
					</center>) :
					<div>
						<CssBaseline />
						<main className={`${classes.content} `}>
							{state.userId.length > 0 && headline.length > 0 &&
								(<VizSensor
									onChange={(isVisible) => {
										trackEvent('WidgetIsInView', 'type', 'expandableDiscovery');
										eventTracker("WidgetIsInView", 'expandableDiscovery', state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
									}}
								>
									<h1 className={`${classes.expandableHeader}`} id={headerId}>{headline}</h1>
								</VizSensor>)}
							{state.userId.length > 0 && headline.length > 0 &&
								(<div
									style={{
										maxWidth: '100%',
										width: '1200px',
										padding: '0px 12px',
									}}
									id={`slider`}
								>
									<SliderCard
										sliderValue={sliderValue}
										onCardClick={handleExpandableBannerShow}
										handleChange={handleChange}
									/>
								</div>)}
							<div className="mainPrinc">
								{investments.length > 0 && state.userId.length > 0 ?
									<>
										{investments.map((investment, index) => {
											if (
												(index === 5) ||
												(investments.length < 6 && index === investments.length - 2)
											) {
												return (
													<div
														key={investment.id}
													>
														<ParallelMarkets
															id={investment.id}
														/>
														<InvestmentPlatformCollapsed
															key={investment.id}
															investmentPlatform={investment}
															embedOnclick={expandableBannerShow}
															expandable={true}
														/>
													</div>
												);
											}
											return (
												<InvestmentPlatformCollapsed
													key={investment.id}
													investmentPlatform={investment}
													embedOnclick={expandableBannerShow}
													expandable={true}
												/>
											);
										})}
									</> : null}
							</div>
						</main>
						<div className={classes.stickyButton}>
							<Button
								variant="outlined"
								color="primary"
								fullWidth={true}
								className={classes.floatButton}
								onClick={expandableBannerShow}
							>
								See All {paidOnly ? '25+' : '100+'} <KeyboardArrowDown />
							</Button>
						</div>
					</div>}
			</div>
		</>
	);
};
