import React, { useState, useEffect, useRef } from 'react';
import AppBar from '@material-ui/core/AppBar';
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import { getMore, getSearch, getFilterResult } from '../../Services/BaseAPI.js';
import { Spinner } from '../Spinner/Spinner.js';
import Tooltip from '@material-ui/core/Tooltip';
import Radio from '@material-ui/core/Radio';
import _ from 'lodash';
import { getAds } from '../../Services/AdsAPI.js';
import { useHistory, useRouteMatch, Route } from 'react-router-dom';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import { TextField, Button, MenuItem, RadioGroup } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import LowRiskActiveIcon from '../../img/icons/lowRisk_selected.png';
import LowRiskIcon from '../../img/icons/lowRisk.png';

import HighRiskActiveIcon from '../../img/icons/highRisk_selected.png';
import HighRiskIcon from '../../img/icons/highRisk.png';

import MediumRiskActiveIcon from '../../img/icons/mediumRisk_selected.png';
import MediumRiskIcon from '../../img/icons/mediumRisk.png';

import oneYearActiveIcon from '../../img/icons/1yearSelected.png';
import oneYearIcon from '../../img/icons/1year.png';

import fiveYearActiveIcon from '../../img/icons/5yearsSelected.png';
import fiveYearIcon from '../../img/icons/5years.png';

import fivePlusYearActiveIcon from '../../img/icons/5plusYearsSelected.png';
import fivePlusYearIcon from '../../img/icons/5plusYears.png';
import AdUnit from '../InvestmentPlatform/AdUnit';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBarRes from '../AppBar/AppBar.jsx';

import ReactGA from 'react-ga';
import InvestmentPlatformCollapsed from '../InvestmentPlatform/Collapsed';
import InvestmentPlatformModal from '../InvestmentPlatform/Modal';
import ExpandedPlatform from '../InvestmentPlatform/ExpandedPlatform';

import { isPaidOnly, GASendEvent, FBSendEvents, trackEvent, eventTracker, generateQuizAnswersObject } from '../../utils';
import { PseudoMailCard } from '../MailCard/PseudoMailCard';
import { MailCard } from '../MailCard/MailCard';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { LogoComponent } from '../Logo/LogoComponent';
import { InlineSlider } from '../Slider/InlineSlider';
import * as actions from '../../redux/actions';
import { ParallelMarkets } from '../ParallelMarkets/ParallelMarkets';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const drawerWidth = 247;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		$checked: {
			backgroundColor: '#ECF5FF',
		},
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	form: {
		width: '100%',
		position: 'relative',
		flexGrow: 1,
	},
	appBar: {
		[theme.breakpoints.up('sm')]: {
			zIndex: 9999,
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	backButton: {
		fontSize: '14px',
		lineHeight: '20px',
		color: '#73757A',
		textTransform: 'none',
	},
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		width: drawerWidth,
	},
	staticDrawerPaper: {
		position: 'relative',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		marginTop: '5px',
	},

	iconLow: {
		borderRadius: '8px',
		width: 52,
		height: 52,
		backgroundImage: `url(${LowRiskIcon})`,
	},
	checkedIconLow: {
		borderRadius: '8px',
		width: 52,
		height: 52,
		backgroundImage: `url(${LowRiskActiveIcon})`,
	},

	iconMedium: {
		borderRadius: '8px',
		width: 52,
		height: 52,
		backgroundImage: `url(${MediumRiskIcon})`,
	},
	checkedIconMedium: {
		borderRadius: '8px',
		width: 52,
		height: 52,
		backgroundImage: `url(${MediumRiskActiveIcon})`,
	},
	iconHigh: {
		borderRadius: '8px',
		width: 52,
		height: 52,
		backgroundImage: `url(${HighRiskIcon})`,
	},
	checkedIconHigh: {
		borderRadius: '8px',
		width: 52,
		height: 52,
		backgroundImage: `url(${HighRiskActiveIcon})`,
	},
	icon1Year: {
		borderRadius: '8px',
		width: 52,
		height: 52,
		backgroundImage: `url(${oneYearIcon})`,
	},
	checkedIcon1Year: {
		borderRadius: '8px',
		width: 52,
		height: 52,
		backgroundImage: `url(${oneYearActiveIcon})`,
	},

	icon5Years: {
		borderRadius: '8px',
		width: 52,
		height: 52,
		backgroundImage: `url(${fiveYearIcon})`,
	},
	checkedIcon5Years: {
		borderRadius: '8px',
		width: 52,
		height: 52,
		backgroundImage: `url(${fiveYearActiveIcon})`,
	},
	icon5PlusYears: {
		borderRadius: '8px',
		width: 52,
		height: 52,
		backgroundImage: `url(${fivePlusYearIcon})`,
	},
	checkedIcon5PlusYears: {
		borderRadius: '8px',
		width: 52,
		height: 52,
		backgroundImage: `url(${fivePlusYearActiveIcon})`,
	},
	regularIcon: {
		borderRadius: '50%',
		width: 16,
		height: 16,
		boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
		backgroundColor: '#f5f8fa',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		'$root.Mui-focusVisible &': {
			outline: '2px auto rgba(19,124,189,.6)',
			outlineOffset: 2,
		},
		'input:hover ~ &': {
			backgroundColor: '#ebf1f5',
		},
		'input:disabled ~ &': {
			boxShadow: 'none',
			background: 'rgba(206,217,224,.5)',
		},
	},
	checkedIcon: {
		backgroundColor: '#5056E7',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
		'&:before': {
			display: 'block',
			width: 16,
			height: 16,
			backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
			content: '""',
		},
		'input:hover ~ &': {
			backgroundColor: '#106ba3',
		},
	},
	radioLabel: {
		width: '322px',

		border: '1px solid #F2F5F8',
		borderRadius: '8px',
		boxSizing: 'border-box',
		boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
		'&:hover': {
			backgroundColor: '#ECF5FF',
		},
		'&:active': {
			backgroundColor: '#ECF5FF',
		},
	},
	radioLabel2: {
		maxWidth: '437px',
		minHeight: '48px',
		border: '1px solid #F2F5F8',
		borderRadius: '8px',
		boxSizing: 'border-box',
		boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
	},
	extendedLabel: {
		maxWidth: '400px !important',
	},
	h4: {
		fontSize: '12px',
		lineHeight: '20px',
		letterSpacing: '0.04em',
		textTransform: 'uppercase',
		color: '#5056E7',
		fontWeight: 'normal',
		marginTop: '50px',
		marginBottom: '2px',
	},
	h3: {
		fontSize: '28px',
		lineHeight: '40px',
		fontFamily: 'Red Hat Display',
		color: '#30333E',
		fontWeight: 'normal',
		marginTop: '5px',
		marginBottom: '5px',
	},
	h2: {
		fontSize: '28px',
		lineHeight: '40px',
		fontFamily: 'Red Hat Display',
		color: '#30333E',
		fontWeight: 'normal',
		marginTop: '5px',
		marginBottom: '5px',
	},
	h5: {
		fontSize: '14px',
		lineHeight: '20px',
		fontFamily: 'Red Hat Display',
		color: '#73757A',
		fontWeight: 'normal',
	},
}));

const LeftNavbarWrapper = (props) => {
	const { container, embed, paidOnly: paidOnlyProp } = props;
	const { url: currentUrl } = useRouteMatch();
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const dispatch = useDispatch();
	const history = useHistory();
	const classes = useStyles();
	const theme = useTheme();

	const [medium, setMedium] = useState(state.itemMedium);
	const [paidOnly, setPaidOnly] = useState(state.itemCompaign);
	const [displaySpinner, setDisplaySpinner] = useState(false);
	const [investments, setInvestments] = useState({});
	const [investmentsMoreArray, setInvestmentsMoreArray] = useState([]);
	const [search, setSearch] = useState('');
	const [disabledButton, setDisabledButton] = useState('');
	const [searchResultsCount, setSearchResultsCount] = useState('');
	const [ads, setAds] = useState([]);
	const [amount, setAmount] = useState(3000);
	const [value, setValue] = React.useState('None');
	const [liquidity, setLiquidity] = React.useState('');
	const [type, setType] = React.useState('');
	const [payoutfreq, setPayoutfreq] = React.useState('');
	const [ratingFilter, setRatingFilter] = React.useState({});
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const [nextUrl, setNextUrl] = useState(null);
	const [showSearch, setshowSearch] = useState(false);
	const [showMenu, setshowMenu] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [filter, setfilter] = React.useState({});
	const [filterCategories, setfilterCategories] = React.useState({});
	const [activePlatform, setActivePlatform] = useState(null);

	const risk = useRef();
	const cash = useRef();
	const timeframe = useRef();
	const cashout = useRef();
	const accredited = useRef();
	const form = useRef();

	const areInvestmentsPresent = Object.values(investments).length !== 0;

	let merged = { ...filter };

	const categoriesValues = Object.keys(filterCategories).filter(
		(k) => filterCategories[k]
	);
	if (categoriesValues.length) {
		merged.trades = categoriesValues;
	}

	const ratingValues = Object.keys(ratingFilter).filter((k) => ratingFilter[k]);
	if (ratingValues.length > 0) {
		merged.rating = ratingValues;
	}

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [areInvestmentsPresent]);
	useEffect(() => {
		if (state.itemCompaign) {
			setPaidOnly(isPaidOnly(state.itemCompaign));
		}
		if (state.itemMedium) {
			setMedium(state.itemMedium);
		}
	}, [state.itemMedium, state.itemCompaign]);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const handleSliderChange = (value) => {
        setAmount(value);
	}

	const getResults = () => {
		window.scrollTo(0, 0);

		trackEvent('QuizSubmit', 'platform', 'Calculator');
		GASendEvent('QuizSubmit', 'Calculator');
		GASendEvent('QuizData_amount', String(amount));
		GASendEvent('QuizData_risk', form.current.risk.value);
		GASendEvent('QuizData_cashpayments', form.current.cash.value);
		GASendEvent('QuizData_timeframe', form.current.timeframe.value);
		GASendEvent('QuizData_liquidity', form.current.cashout.value);
		GASendEvent('QuizData_accredited', form.current.accredited.value);
		FBSendEvents('QuizData', {
			amount: String(amount),
			risk: form.current.risk.value,
			cashpayments: form.current.cash.value,
			timeframe: form.current.timeframe.value,
			liquidity: form.current.cashout.value,
			accredited: form.current.accredited.value,
		});
		eventTracker("QuizSubmit", 'Calculator', state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
		eventTracker("QuizData_amount", String(amount), state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
		eventTracker("QuizData_risk", form.current.risk.value, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
		eventTracker("QuizData_cashpayments", form.current.cash.value, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
		eventTracker("QuizData_timeframe",  form.current.timeframe.value, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
		eventTracker("QuizData_liquidity", form.current.cashout.value, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
		eventTracker("QuizData_accredited", form.current.accredited.value, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);

		dispatch(actions.setQuizAnswers(
			generateQuizAnswersObject(
				String(amount),
				form.current.risk.value,
				form.current.cash.value,
				form.current.timeframe.value,
				form.current.cashout.value,
				form.current.accredited.value
		)));

		setDisplaySpinner(true);
		setAds([]);
		getAds().then((data) => {
			setAds(data);
		});
		setInvestments([]);
		getFilterResult()
		.then((data) => {
			let investmentsData = data;
			if (paidOnly || paidOnlyProp) {
				investmentsData = _.filter(investmentsData, (o) => o.paid);
			}

			//	console.log(form);
			let filterredList = _.filter(investmentsData, (investment) => {
				return investment.minInvestment <= amount;
			});
			filterredList = _.filter(filterredList, (investment) => {
				return investment.newRisks.includes(form.current.risk.value);
			});

            switch(Number(form.current.cash.value)) {
                case 1:
                    filterredList = _.filter(filterredList, (investment) => {
                        return (
                            investment.howMoney === 'Dividends' ||
                            investment.howMoney === 'Interest'
                        );
                    });
                    break;
                case 2:
                    filterredList = _.filter(filterredList, (investment) => {
                        return (
                            investment.howMoney === 'Value'
                        );
                    });
                    break;
                case 3:
                    filterredList = _.filter(filterredList, (investment) => {
                        return (
                            investment.howMoney === 'Dividends + Value'
                        );
                    });
                    break;
                default:
                    filterredList = _.filter(filterredList, (investment) => {
                        return (
                            investment.howMoney === 'Dividends' ||
                            investment.howMoney === 'Interest'
                        );
                    });
            }
			filterredList = _.filter(filterredList, (investment) => {
				return investment.newRisks.includes(form.current.risk.value);
			});

			filterredList = _.filter(filterredList, (investment) => {
				return investment.timeframeNumber <= Number(form.current.timeframe.value);
			});
			if (Number(form.current.cashout.value) === 1) {
				filterredList = _.filter(filterredList, (investment) => {
					return (
						investment.liquidity === 'Easy' || investment.liquidity === 'Moderate'
					);
				});
			}

			if (Number(form.current.accredited.value) !== 1) {
				filterredList = _.filter(filterredList, (investment) => {
					return investment.investors === 'All';
				});
			}

			setInvestments(filterredList);

			setDisplaySpinner(false);
		});
	};

	function StyledRadio(props) {
		const classes = useStyles();

		return (
			<Radio
				classes={{ root: classes.root, checked: classes.checked }}
				disableRipple
				color="default"
				checkedIcon={
					<span className={clsx(props.iconstyle, props.checkediconstyle)} />
				}
				icon={<span className={props.iconstyle} />}
				{...props}
			/>
		);
	}

	const allData = () => {
		if (form.current) {
			if (
				amount &&
				form.current.risk.value &&
				form.current.cash.value &&
				form.current.cashout.value &&
				form.current.timeframe.value &&
				form.current.accredited.value
			) {
				return false;
			} else return true;
		}
		return true;
	};

	return (
		<div className={`respCont ${classes.root}`}>
			<CssBaseline />

			{!embed ? (
				<AppBar position="fixed" className={classes.appBar}>
					<Toolbar>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							edge="start"
							onClick={handleDrawerToggle}
							className={`${classes.menuButton} hideMob`}
						>
							<MenuIcon />
						</IconButton>
						{medium !== 'embed' && medium !== 'embed_single' ? (
							<LogoComponent />
						) : null}
						<AppBarRes showMenu={showMenu} />
						{/*}	<div style={{ width: '100%', textAlign: 'right' }}>
						<img
							className="logo hideMob"
							style={{ marginRight: '20px' }}
							src={avatar}
							alt="Avatar"
						/>
						<img
							className="logo hideWeb"
							style={{ marginRight: '20px' }}
							onClick={() => setshowSearch(!showSearch)}
							src={searchIcon}
							alt="SearchIcon"
						/>
						<img
							className="logo hideWeb"
							onClick={() => setshowMenu(!showMenu)}
							src={showMenu ? openedIcon : cmenuIcon}
							alt="HamburgerMenu"
						/>
					</div> */}
					</Toolbar>
				</AppBar>
			) : null}
			{areInvestmentsPresent && (
				<Route
					path={`${currentUrl}/:platformId`}
					render={({ match }) => {
						//console.log(investments);
						const selectedId = match.params.platformId;
						const selectedInvestment = Object.values(investments).find(
							(investment) => investment.slug === selectedId
						);
						//console.log(match.params.platformId);

						const similarInvestments = selectedInvestment.similars.map((similar) => {
							//	console.log(similar);
							const similarInvestment = Object.values(investments).find(
								(investment) => investment.id === similar
							);

							//	console.log(similarInvestment);
							return similarInvestment;
						});

						if (!selectedInvestment) {
							history.push(currentUrl);
							return null;
						}
						trackEvent('PlatformExpandedImpression', selectedInvestment.name, selectedInvestment.name);

						return (
							<InvestmentPlatformModal
								similarInvestments={similarInvestments}
								investmentPlatform={selectedInvestment}
								onClose={() => history.push(currentUrl)}
							/>
						);
					}}
				/>
			)}
			<main className={`${classes.content} mainPrinc`}>
				{!embed ? <div className={classes.toolbar} /> : null}
				<center>
					{displaySpinner && <Spinner displaySpinner={displaySpinner} />}
					{areInvestmentsPresent ? (
						<>
							<h2 className={classes.h2}>Your personal investment opportunities</h2>
							<h5 className={classes.h5}>
								Here is what we found based on your answers
							</h5>
							<MailCard
								investments={investments}
							 />
						</>
					) : null}
				</center>
				{areInvestmentsPresent &&
					Object.values(investments).map(function (investment, index) {
						if (investment === true) {
							return null;
						}

						let investmentComponent = null;

						const name = (investment.name || '').toLowerCase();
						const description = (investment.description || '').toLowerCase();
						const query = search.toLowerCase();

						if (
							query === '' ||
							description.includes(query) ||
							name.includes(query)
						) {
							investmentComponent = (
								<InvestmentPlatformCollapsed
									key={investment.id}
									investmentPlatform={investment}
									calculator={true}
									setActivePlatform={setActivePlatform}
								/>
							);
						}

						if (index === 3 && window.innerWidth <= 768) {
							return (
								<div key={investment.id}>
									<PseudoMailCard />
									{investmentComponent}
								</div>
							);
						}

						if (activePlatform === investment) {
							const similarInvestments = activePlatform.similars.map((similar) => {
								//	console.log(similar);
								const similarInvestment = Object.values(investments).find(
									(investment) => investment.id === similar
								);

								//	console.log(similarInvestment);
								return similarInvestment;
							});

							investmentComponent = (
								<ExpandedPlatform
									key={investment.id}
									investmentPlatform={investment}
									similarInvestments={[]}
									setActivePlatform={setActivePlatform}
								/>
							);
						}

						if (
							(index === 5 && ads.length > 0) ||
							(Object.keys(investments).length < 6 &&
								index === Object.keys(investments).length - 2)
						) {
							//console.log(ads);
							return (
								<div key={investment.id}>
									<ParallelMarkets
										id={investment.id}
									/>
									{investmentComponent}
								</div>
							);
						}

						return investmentComponent;
					})}
				{areInvestmentsPresent &&
					Object.values(investments) ? (
						<>
							<center>
								<br />
								<br />
								<Button
									variant="outlined"
									color="primary"
									onClick={() => setInvestments([])}
								>
									Edit your answers
								</Button>
								<br />
								<br />
								<br />
								<br />
							</center>
						</>
					) : null
				}
				{!areInvestmentsPresent ? (
					<div style={displaySpinner ? { display: 'none' } : null}>
						{!embed ? (
							<Button
								className={classes.backButton}
								onClick={() => history.push('/discover')}
							>
								<ArrowBackIosIcon style={{ fontSize: 14 }} /> Back to Investing page
							</Button>
						) : null}
						<form style={{ margin: 'auto', maxWidth: '700px' }} ref={form}>
							<div>
								<h4 className={classes.h4}>1. Money</h4>
								<Tooltip title="Enter your value in US dollars" arrow>
									<h3 className={classes.h3}>
										How much money do you want to invest?
									</h3>
								</Tooltip>

								<InlineSlider
									sliderValue={amount}
									handleSliderChange={handleSliderChange}
								/>
							</div>
							<div>
								<h4 className={classes.h4}>2. Risk</h4>
								<Tooltip
									title="Typically, the higher the risk the higher the return but also a
									higher chance for loss."
									arrow
								>
									<h3 className={classes.h3}>
										What level of risk are you comfortable investing at?
									</h3>
								</Tooltip>

								<RadioGroup aria-label="risk" name="risk">
									<FormControlLabel
										value="1"
										inputRef={risk}
										classes={{ root: classes.radioLabel, checked: classes.checked }}
										control={
											<StyledRadio
												iconstyle={classes.iconLow}
												checkediconstyle={classes.checkedIconLow}
											/>
										}
										label="Low risk"
									/>
									<FormControlLabel
										value="2"
										inputRef={risk}
										className={classes.radioLabel}
										control={
											<StyledRadio
												iconstyle={classes.iconMedium}
												checkediconstyle={classes.checkedIconMedium}
											/>
										}
										label="Moderate risk"
									/>
									<FormControlLabel
										value="3"
										inputRef={risk}
										className={classes.radioLabel}
										control={
											<StyledRadio
												iconstyle={classes.iconHigh}
												checkediconstyle={classes.checkedIconHigh}
											/>
										}
										label="High risk"
									/>
								</RadioGroup>
							</div>

							<div>
								<h4 className={classes.h4}>3. Cash Payments</h4>
								<Tooltip
									title=""
									arrow
								>
									<h3 className={classes.h3}>
										What is your primary investment objective?
									</h3>
								</Tooltip>

								<RadioGroup aria-label="cash" name="cash">
									<FormControlLabel
										value="1"
										inputRef={cash}
										classes={{
											root: classes.radioLabel2,
											label: classes.extendedLabel,
										}}
										control={<Radio color="primary" />}
										label="Passive income"
									/>
									<FormControlLabel
										value="2"
										inputRef={cash}
										classes={{
											root: classes.radioLabel2,
											label: classes.extendedLabel,
										}}
										control={<Radio color="primary" />}
										label="Long term growth"
									/>
									<FormControlLabel
										value="3"
										inputRef={cash}
										classes={{
											root: classes.radioLabel2,
											label: classes.extendedLabel,
										}}
										control={<Radio color="primary" />}
										label="Balanced"
									/>
								</RadioGroup>
							</div>
							<div>
								<h4 className={classes.h4}>4. Timeframe</h4>
								<Tooltip
									title="Many investments take time to see returns. The length of time
									doesn’t guarantee you’ll see returns, however the longer you’re
									willing to invest the more options you will have to invest in."
									arrow
								>
									<h3 className={classes.h3}>
										How long are you comfortable investing this money for?
									</h3>
								</Tooltip>

								<RadioGroup aria-label="timeframe" name="timeframe">
									<FormControlLabel
										value="12"
										inputRef={timeframe}
										className={classes.radioLabel}
										control={
											<StyledRadio
												iconstyle={classes.icon1Year}
												checkediconstyle={classes.checkedIcon1Year}
											/>
										}
										label="Less than 1 year"
									/>
									<FormControlLabel
										value="60"
										inputRef={timeframe}
										className={classes.radioLabel}
										control={
											<StyledRadio
												iconstyle={classes.icon5Years}
												checkediconstyle={classes.checkedIcon5Years}
											/>
										}
										label="1-5 years"
									/>
									<FormControlLabel
										value="72"
										inputRef={timeframe}
										className={classes.radioLabel}
										control={
											<StyledRadio
												iconstyle={classes.icon5PlusYears}
												checkediconstyle={classes.checkedIcon5PlusYears}
											/>
										}
										label="5+ years"
									/>
								</RadioGroup>
							</div>

							<div>
								<h4 className={classes.h4}>5. Cash Out / Sell</h4>
								<Tooltip
									title="This is known as liquidity. For some, it may be important that you
									can access the cash you put towards your investment quickly. While
									for others, the ability to quickly sell for cash is less important."
									arrow
								>
									<h3 className={classes.h3}>
										Is it important that you can sell your investment quickly if you
										need to?
									</h3>
								</Tooltip>

								<RadioGroup aria-label="cashout" name="cashout">
									<FormControlLabel
										value="1"
										inputRef={cashout}
										classes={{
											root: classes.radioLabel2,
											label: classes.extendedLabel,
										}}
										control={<Radio color="primary" />}
										label="Yes, I need to be able to sell my investment quickly"
									/>
									<FormControlLabel
										value="2"
										inputRef={cashout}
										classes={{
											root: classes.radioLabel2,
											label: classes.extendedLabel,
										}}
										control={<Radio color="primary" />}
										label="No, I won’t need to sell my investment"
									/>
								</RadioGroup>
							</div>

							<div>
								<h4 className={classes.h4}>6. Accredited</h4>
								<Tooltip
									title="To be considered an accredited investor, one must have a net worth
									of at least $1,000,000, excluding the value of one's primary
									residence, or have income at least $200,000 each year for the last
									two years (or $300,000 combined income if married)"
									arrow
								>
									<h3 className={classes.h3}>Are you an accredited investor?</h3>
								</Tooltip>

								<RadioGroup aria-label="accredited" name="accredited">
									<FormControlLabel
										value="1"
										inputRef={accredited}
										className={classes.radioLabel2}
										control={<Radio color="primary" />}
										label="Yes"
									/>
									<FormControlLabel
										value="2"
										inputRef={accredited}
										className={classes.radioLabel2}
										control={<Radio color="primary" />}
										label="No"
									/>
									<FormControlLabel
										value="3"
										inputRef={accredited}
										className={classes.radioLabel2}
										control={<Radio color="primary" />}
										label="I don't know"
									/>
								</RadioGroup>
							</div>
							<div style={{ marginTop: '25px' }}>
								<Button variant="contained" color="primary" onClick={getResults}>
									Get results
								</Button>
							</div>
						</form>
					</div>
				) : null}
			</main>
		</div>
	);
};

export default LeftNavbarWrapper;
