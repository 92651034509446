import firebase from './firebase';

export const loginUser = (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
}

export const checkAuth = (callback) => {
    firebase.auth().onAuthStateChanged((user) => {
        if (user) {
            callback(user);
        } else {
            callback(null);
        }
    });
}
export const getToken = async (currentUser) => {
    const token = await currentUser.getIdToken();
    return token;
}
