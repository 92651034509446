import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Avatar from '@material-ui/core/Avatar';
import TextTruncate from 'react-text-truncate';
import { Button } from '@material-ui/core';
import _ from 'lodash';
import { mobileWidth, mobileWidth2 } from '../../data/Data';

const limit = mobileWidth;
const limit2 = mobileWidth2;

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles((theme) => ({
    root: {
        width: '1200px',
        margin: 'auto',
        position: 'relative',
        maxWidth: '100%',
    },
    title: {
        fontFamily: 'Red Hat Display',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '36px',
        lineHeight: '48px',
        color: '#30333E',
        marginTop: '24px',
        marginBottom: '4px',
    },
    desc: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#73757A',
        marginBottom: '24px',
    },
    container: {
        display: 'flex',
		flexWrap: 'wrap',
        //gap: '20px',
        [theme.breakpoints.down(`${limit}`)]: {
            justifyContent: 'center',
		},
    },
	tile: {
        cursor: 'pointer',
		width: '386px !important',
        height: '376px !important',
        background: '#FFFFFF',
        border: '1px solid #F2F5F8',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
        borderRadius: '8px',
        marginRight: '14px',
        marginBottom: '14px',
        [theme.breakpoints.down(`${limit}`)]: {
            width: '300px !important',
            //width: '220px !important',
            maxWidth: '100%',
            marginRight: '12px',
            marginBottom: '12px',
        },
        [theme.breakpoints.down(`${limit2}`)]: {
            width: '220px !important',
        },
        [theme.breakpoints.down('488')]: {
            width: '351px !important',
		},
        /*[theme.breakpoints.down('400')]: {
            width: '351px !important',
            maxWidth: '100%',
            marginRight: '0px',
            marginBottom: '12px',
		},*/
    },
    tileContainer: {
        textAlign: 'center',
        position: 'relative',
        //display: 'flex',
        //justifyContent: 'center',
        //alignItems: 'center',
    },
    avatar: {
        width: '80px',
        height: '80px',
        marginTop: '40px',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down(`${limit}`)]: {
            marginTop: '20px',
        },
        [theme.breakpoints.down('488')]: {
            marginTop: '40px',
		},
    },
    tileTitle: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '32px',
        color: '#30333E',
        marginTop: '20px',
        maxWidth: '260px',
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down(`${limit}`)]: {
            padding: '0 5px 0 5px',
        },
        [theme.breakpoints.down('488')]: {
            padding: '0',
		},
    },
    tileDesc: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#73757A',
        marginTop: '8px',
        maxWidth: '260px',
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down(`${limit}`)]: {
            padding: '0 5px 0 5px',
        },
        [theme.breakpoints.down('488')]: {
            padding: '0',
		},
    },
    button: {
        width: '192px',
        height: '48px',
		textTransform: 'none',
		//marginBottom: '10px',
		//marginTop: '20px',
		maxWidth: '1200px',
		//borderRadius: '8px',
		//borderRadius: '100px',
        color: '#ffffff',
        //border: '1px solid #5056E7',
        background: '#5056E7',
		'&:hover': {
			backgroundColor: 'rgb(80,86,231, 0.8)',
        },
        position: 'absolute',
        top: '260px;',
        marginLeft: 'auto',
        marginRight: 'auto',
        left: 0,
        right: 0,
        textAlign: 'center',
        [theme.breakpoints.down(`${limit}`)]: {
            top: '280px',
        },
        [theme.breakpoints.down('488')]: {
            top: '260px',
		},
    },
}));

export const CollectionViewCard = (props) => {
	const {
        investments,
        clusters,
        handleCollection,
    } = props;

	const classes = useStyles();
    const clusterDiv = React.useRef();

    const [clustersAdvanced, setClustersAdvanced] = useState([]);

    useEffect(() => {
        if (Object.values(investments).length > 0 && clusters) {
            getClusters(clusters);
        }
    }, [clusters, investments]);

    const getClusters = () => {
        const clustersArray = [];
        clusters.map(function (cluster, index) {
            let clusterInvestments = _.chain(investments)
                .pickBy((value, key) => {
                    if (value.clusters) {
                        return value.clusters.includes(cluster.id);
                    }
                    return false;
                })
                .sortBy([`${cluster.id}_premium`, 'minInvestment'])
                .value();
            clusterInvestments = _.values(clusterInvestments);
            clustersArray.push({
                ...cluster,
                ...{
                    platforms: clusterInvestments
                }
            });
        });
        setClustersAdvanced(clustersArray);
    };

	const handleClick = (cluster) => {
        handleCollection(cluster);
    };

	return (
        <div className={`${classes.root}`}>
            <div className={`${classes.title}`}>
                Collections
            </div>
            <div className={`${classes.desc}`}>
                Collections of investment opportunities created by our editors
            </div>

            <div className={classes.container} id="clusterContainer">
			{clustersAdvanced.length > 0 &&
                clustersAdvanced.map((cluster) => (
                    <div key={cluster.id} className={classes.tile} onClick={() => handleClick(cluster)}>
                        <div className={classes.tileContainer}>
                            <Avatar
                                src={cluster.icon}
                                className={classes.avatar}
                            >
                            </Avatar>
                            <div className={`${classes.tileTitle}`}>
                                {cluster.name}
                            </div>
                            <div className={`${classes.tileDesc}`}>
                                {cluster.description}
                            </div>
                            <Button
                                className={`${classes.button} ${classes.color1}`}
                                onClick={() => handleClick(cluster)}
                            >
                                Explore {cluster.platforms.length} options
                            </Button>
                        </div>
                    </div>
                ))}
		    </div>
        </div>
	);
};
