import negative from '../../img/icons/negative.png';
import neutral from '../../img/icons/neutral.png';
import positive from '../../img/icons/positive.png';
import paid from '../../img/icons/money.png';

const sentiments = {
	negative: { icon: negative, text: 'Negative' },
	neutral: { icon: neutral, text: 'Neutral' },
	positive: { icon: positive, text: 'Positive' },
	paid: { icon: paid, text: 'Sponsored' },
};

export const getSentiment = (sentimentScore) => {
	if (sentimentScore === 100) {
		return sentiments.paid;
	}
	if (sentimentScore < 0) {
		return sentiments.negative;
	}

	if ((sentimentScore >= 0 && sentimentScore < 0.1) || !sentimentScore) {
		return sentiments.neutral;
	}

	if (sentimentScore >= 0.1) {
		return sentiments.positive;
	}
};

export const getSentimentIcon = (sentimentScore) =>
	getSentiment(sentimentScore).icon;
export const getSentimentText = (sentimentScore) =>
	getSentiment(sentimentScore).text;
