import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Avatar from '@material-ui/core/Avatar';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { mobileWidth, widgetWidth } from '../../data/Data';

export const TypesList = (props) => {
	const {
        investments,
        handleType,
        expandable,
    } = props;

    const limit = !expandable ? mobileWidth : widgetWidth;

    const useStyles = makeStyles((theme) => ({
        root: {
            position: 'relative',
        },
        tile: {
            cursor: 'pointer',
            width: '285px !important',
            height: '320px !important',
            background: '#FFFFFF',
            border: '1px solid #F2F5F8',
            boxSizing: 'border-box',
            boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
            borderRadius: '8px',
            marginRight: '15px',
            marginBottom: '15px',
            [theme.breakpoints.down(`${limit}`)]: {
                width: '220px !important',
            },
        },
        tileContainer: {
            textAlign: 'center',
        },
        avatar: {
            width: '80px',
            height: '80px',
            marginTop: '40px',
            marginLeft: 'auto',
            marginRight: 'auto',
            [theme.breakpoints.down('400')]: {
            // margin: 0,
            },
        },
        tileTitle: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '20px',
            lineHeight: '32px',
            color: '#30333E',
            marginTop: '20px',
            maxWidth: '260px',
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            [theme.breakpoints.down('400')]: {
                //margin: 0,
                //textAlign: 'left',
            },
        },
        tileDesc: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#73757A',
            marginTop: '8px',
            maxWidth: '260px',
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            [theme.breakpoints.down('400')]: {
                //textAlign: 'left',
            },
        },
        button: {
            width: '106px',
            height: '48px',
            textTransform: 'none',
            marginTop: '20px',
            maxWidth: '1200px',
            color: '#ffffff',
            background: '#5056E7',
            '&:hover': {
                backgroundColor: 'rgb(80,86,231, 0.8)',
            },
        },
        backButton: {
            position: 'absolute',
            zIndex: '9999',
            left: '0px',
            right: 'inherit',
            top: '145px',
            marginTop: '20px',
            //width: '55px !important',
            [theme.breakpoints.up(`${limit}`)]: {
                display: 'none',
            },
        },
        forwardButton: {
            position: 'absolute',
            marginTop: '20px',
            right: '0px',
            top: '145px',
            zIndex: '9999',
            //width: '55px !important',
            [theme.breakpoints.up(`${limit}`)]: {
                display: 'none',
            },
        },
        desktopView: {
            display: 'flex',
            flexWrap: 'wrap',
            [theme.breakpoints.down(`${limit}`)]: {
                display: 'none !important',
            },
        },
        mobileView: {
            position: 'relative',
            flexWrap: 'nowrap',
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: 'translateZ(0)',
            [theme.breakpoints.up(`${limit}`)]: {
                display: 'none !important',
            },
        },
        tileContainerMobile: {
            padding: '20px',
            display: 'flex',
            alignItems: 'center',
        },
        descContainerMobile: {
            marginLeft: '20px',
        },
        iconMobile: {
            marginLeft: 'auto',
        },
    }));

    const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const classes = useStyles();
    const [typesAdvanced, setTypesAdvanced] = useState(null);
    const clusterDiv = React.useRef();

    useEffect(() => {
        if (Object.values(investments).length > 0 && state.types.length > 0) {
            const types = state.types;
            getAdvancedTypes(investments, types);
        }
    }, [investments, state.types]);

    const getAdvancedTypes = (investments, types) => {
        const typeArray = [];
        types.forEach((type, index) => {
           const count = investments.filter((platform) => platform.industry === type.name);
           if (count.length > 0) {
            typeArray.push({
                ...type,
                ...{
                    count: count.length
                },
                ...{
                    description: type.description
                }
            });
           }
        });
        setTypesAdvanced(typeArray);
    };

    function handleForwardClick(e) {
		if (clusterDiv.current) {
			clusterDiv.current.scrollLeft += 200;
		}
	}
	function handleBackClick(e) {
		if (clusterDiv.current) {
			clusterDiv.current.scrollLeft -= 200;
		}
	}
	const handleClick = (type) => {
        handleType(type);
    };

	return (
		<div className={classes.root}>
             <IconButton
                aria-label="back"
                color="primary"
                variant="outlined"
                onClick={handleBackClick}
                className={classes.backButton}
            >
                <ArrowBackIcon />
            </IconButton>
            <div className={`${classes.desktopView}`} ref={clusterDiv}>
                {typesAdvanced !== null && (

                        typesAdvanced.map((type) => (
                            <div key={type.id} className={classes.tile} onClick={() => handleClick(type)}>
                                <div className={`${classes.tileContainer}`}>
                                    <Avatar
                                        src={type.icon}
                                        className={classes.avatar}
                                    >
                                    </Avatar>
                                    <div className={`${classes.tileTitle}`}>
                                        {type.name}
                                    </div>
                                    <div className={`${classes.tileDesc}`}>
                                        {type.count} platforms
                                    </div>
                                    <Button
                                        className={`${classes.button} ${classes.color1}`}
                                        onClick={() => handleClick(type)}
                                    >
                                        Explore
                                    </Button>
                                </div>
                            </div>
                        ))
                )}
            </div>
            <GridList className={`${classes.mobileView}`} ref={clusterDiv} cols={1}>
                {typesAdvanced !== null && typesAdvanced.map((type) => (
                    <GridListTile key={type.id} className={classes.tile} onClick={() => handleClick(type)}>
                        <div className={`${classes.tileContainer}`}>
                            <Avatar
                                src={type.icon}
                                className={classes.avatar}
                            >
                            </Avatar>
                            <div className={`${classes.tileTitle}`}>
                                {type.name}
                            </div>
                            <div className={`${classes.tileDesc}`}>
                                {type.count} platforms
                            </div>
                            <Button
                                className={`${classes.button} ${classes.color1}`}
                                onClick={() => handleClick(type)}
                            >
                                Explore
                            </Button>
                        </div>
                    </GridListTile>
                ))}
            </GridList>
            <IconButton
                aria-label="forward"
                color="primary"
                variant="outlined"
                onClick={handleForwardClick}
                className={classes.forwardButton}
            >
                <ArrowForwardIcon />
            </IconButton>
		</div>
	);
}
