import React, { useState, useEffect, useMemo } from 'react';
import VizSensor from 'react-visibility-sensor';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import { isMobile } from 'react-device-detect';
import Slider from '@material-ui/core/Slider';
import chevronLeftRight from '../../img/chevron-left-right.svg';
import { trackEvent, eventTracker } from '../../utils';
import { useSelector, shallowEqual } from 'react-redux';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

function ThumbComponent(props) {
	return (
		<span {...props}>
			<img src={chevronLeftRight} />
		</span>
	);
}
const CustomSliderElement = withStyles({
	root: {
		color: '#5056E7',
		height: 4,
	},
	thumb: {
		height: 32,
		width: 32,
		backgroundColor: '#5056E7',
		border: '1px solid currentColor',
		marginTop: -14,
		marginLeft: -12,
		'&:focus, &:hover, &$active': {
			boxShadow: 'inherit',
		},
	},
	active: {},
	valueLabel: {
		left: 'calc(-50% + 4px)',
	},
	track: {
		height: 4,
		borderRadius: 4,
		color: '#5056E7',
	},
	rail: {
		height: 4,
		borderRadius: 4,
		color: '#EEEFFD',
		opacity: 1,
	},
})(Slider);

export const CustomSlider = (props) => {
	const { sliderValue, onCardClick, handleChange, sliderClass } = props;
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const [value, setValue] = React.useState(sliderValue);
	const onChange = (e, value) => {
		setValue(value);
		handleChange(value);
	};

	useEffect(() => {
		if (sliderValue) {
			setValue(sliderValue);
		}
	}, [sliderValue]);

	return (
		<>
			<VizSensor
				onChange={(isVisible) => {
					trackEvent('WidgetIsInView', 'type', 'adCard');
					eventTracker("WidgetIsInView", 'adCard', state.itemSource, state.itemMedium, state.itemCompaign, state.userId);
				}}
			>
				<CustomSliderElement
					value={value}
					onChange={isMobile ? onCardClick : onChange}
					step={100}
					min={500}
					max={25000}
					aria-labelledby="slider"
					className={`${sliderClass} CustomSliderElement`}
					ThumbComponent={ThumbComponent}
					onChangeCommitted={onCardClick}
					onTouchEnd={onCardClick}
				/>
			</VizSensor>
		</>
	);
};
