import React from 'react';
import { useHistory, useRouteMatch, Route } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { detectPlatformBySlug } from '../../utils';
import { StreamPostModal } from './StreamPostModal';

export const OnePostModalRoute = (props) => {
    const {
        handleCloseModal,
        handlePostedComment,
        handlePostLoaded,
    } = props;
    const { url: currentUrl } = useRouteMatch();
    const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
    const history = useHistory();

    const handleClose = (url) => {
        history.push(`${url}`);
    }

    return (
        <>
            <Route
                path={`${currentUrl}/:postId`}
                render={({ match }) => {
                    const postId = match.params.postId;
                    if (detectPlatformBySlug(Object.values(state.investmentsTotal), postId).length > 0) {
                        return null;
                    }
                    return (
                        <StreamPostModal
                            embed={false}
                            onClose={handleClose}
                            postId={postId}
                            currentUrl={currentUrl}
                            handleCloseModal={handleCloseModal}
                            handlePostedComment={handlePostedComment}
                            handlePostLoaded={handlePostLoaded}
                        />
                    );
                }}
            />
            <Route
                path={`${currentUrl}/:platformId/:postId`}
                render={({ match }) => {
                    const postId = match.params.postId;
                    const platformId = match.params.platformId;
                    if (!postId) {
                        return null;
                    }
                    return (
                        <StreamPostModal
                            embed={false}
                            onClose={handleClose}
                            postId={postId}
                            currentUrl={`${currentUrl}/${platformId}`}
                            handleCloseModal={handleCloseModal}
                            handlePostedComment={handlePostedComment}
                            handlePostLoaded={handlePostLoaded}
                        />
                    );
                }}
            />
        </>
    )
}
