import React from 'react';
import { useRouteMatch, Switch, Route, Link } from 'react-router-dom';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import InvestmentPlatformOverview from './Overview';
import InvestmentPlatformReviews from './Reviews';
import InvestmentPlatformMentions from './Mentions';
import { CardContent } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

const StylizedTabs = withStyles({
	root: {
		borderBottom: '1px solid #DBE0E6',
		marginBottom: '24px'
	},
	indicator: {
		height: '2px',
		backgroundColor: '#5056E7'
	}
})(Tabs);

const StylizedTab = withStyles({
	root: {
		textTransform: 'none',
		fontSize: '16px',
		color: '#73757A',
		'&$selected': {
			color: '#30333E'
		}
	}
})(Tab);

const tabs = {
	overview: { index: 0, label: 'Overview', path: '' },
	reviews: { index: 1, label: 'Reviews', path: '/reviews' },
	mentions: { index: 2, label: 'Social Mentions', path: '/mentions' }
};

const ReplaceLink = props => <Link replace {...props} />;

export default function InvestmentPlatformTabs({
	investmentPlatform,
	onCreateReview
}) {
	const { url: currentUrl } = useRouteMatch();

	return (
		<Route
			exact
			path={`${currentUrl}/:tab?`}
			render={({ match }) => {
				const tab = match.params.tab || 'overview';
				const tabIndex = tabs[tab].index;

				return (
					<CardContent>
						<StylizedTabs value={tabIndex}>
							{Object.values(tabs).map(tab => (
								<StylizedTab
									label={tab.label}
									key={tab.index}
									component={ReplaceLink}
									to={currentUrl + tab.path}
								/>
							))}
						</StylizedTabs>

						<Switch>
							<Route exact path={`${currentUrl}`}>
								<InvestmentPlatformOverview
									investmentPlatform={investmentPlatform}
								/>
							</Route>

							<Route exact path={`${currentUrl}/reviews`}>
								<InvestmentPlatformReviews
									investmentPlatform={investmentPlatform}
									onCreateReview={onCreateReview}
								/>
							</Route>

							<Route exact path={`${currentUrl}/mentions`}>
								<InvestmentPlatformMentions
									investmentPlatform={investmentPlatform}
								/>
							</Route>
						</Switch>
					</CardContent>
				);
			}}
		/>
	);
}
