import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import expandIcon from '../../img/icons/external-link.svg';
import Tooltip from '@material-ui/core/Tooltip';
import TextTruncate from 'react-text-truncate';
import ShareIcon from '@material-ui/icons/Share';
import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import InvestmentPlatformAvatar from '../InvestmentPlatform/Avatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Rating from '../Rating';
import { getSentimentText } from '../InvestmentPlatform/helpers';

import ReactGA from 'react-ga';
import { eventTracker, trackEvent, generateSubId } from '../../utils';
import { useSelector, shallowEqual } from 'react-redux';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles((theme) => ({
	borderedColumn: {
		position: 'relative',

		'& .pseudoborder': {
			height: 'calc(100% - 40px) !important',
			top: '28px',
			left: '-24px',
			margin: '0 !important',
		},
	},
	secondColumn: {
		paddingRight: '32px',
	},
	thirdColumn: {
		paddingRight: '32px',
	},
	subheader: {
		maxWidth: '220px',
		[theme.breakpoints.down('369')]: {
			maxWidth: '190px',
		},
		alignItems: 'center',
	},
	rating: {
		marginLeft: '8px',
		[theme.breakpoints.down('xs')]: {
			marginLeft: '0px',
		},
	},
	tooltip: {
		zIndex: 10001, // to be visible inside the modal
	},
}));

export const HeaderInvestCollapsed = (props) => {
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const { investmentPlatform, expanded } = props;
	const [copySuccess, setCopySuccess] = React.useState(false);
	const [source, setSource] = React.useState(state.itemSource || 'MoneyMade');
	const classes = useStyles();
	useEffect(() => {});

	function clickCopy(url) {
		//console.log(url);
		copyToClipboard(url);
		setCopySuccess(true);
		setTimeout(() => setCopySuccess(false), 1200);
	}

	function copyToClipboard(copyText) {
		var textField = document.createElement('textarea');
		textField.innerText = copyText;
		document.body.appendChild(textField);
		textField.select();
		document.execCommand('copy');
		textField.remove();
	}

	function stopPropagation(event) {
		trackEvent('ExternalClick', 'platform', investmentPlatform.name);
		eventTracker("ExternalClick", investmentPlatform.name, state.itemSource, state.itemMedium, state.itemCompaign, state.userId);

		event.stopPropagation();
	}

	return (
		<>
			<div>
				<Grid container alignItems="flex-start">
					<Grid className="firstgrid" item sm={5} xs={5}>
						<CardHeader
							id="Investopedia_CardHeader"
							avatar={
								<Tooltip
									disableFocusListener
									disableTouchListener
									classes={{ popper: classes.tooltip }}
									placement="top"
									title={`${getSentimentText(
										investmentPlatform.sentiment
									)} sentiment`}
									arrow
								>
									<span>
										<InvestmentPlatformAvatar
											investmentPlatform={investmentPlatform}
										/>
									</span>
								</Tooltip>
							}
							title={
								<div>
									<Tooltip
										disableFocusListener
										disableTouchListener
										classes={{ popper: classes.tooltip }}
										placement="top"
										title={`Visit site`}
										arrow
									>
										<a
											target="_blank"
											rel="noopener noreferrer"
											style={{ cursor: 'pointer' }}
											href={
												investmentPlatform.website
													? investmentPlatform.website.replace('%SUBID%', generateSubId(state.itemSource, state.itemMedium))
													: '/discover'
											}
											onClick={stopPropagation}
										>
											{investmentPlatform.name}
										</a>
									</Tooltip>
								</div>
							}
						/>

						<CardContent
							style={{
								paddingTop: '0px',
								paddingRight: '24px',
								paddingBottom: '10px',
							}}
						>
							<Typography
								variant="body2"
								color="textSecondary"
								component="p"
								style={{ maxWidth: '450px', marginLeft: '16px', color: '#73757A' }}
							>
								{!expanded ? (
									<TextTruncate
										line={3}
										element="span"
										truncateText="…"
										text={investmentPlatform.description}
										textTruncateChild={<a style={{ color: '#5056e7' }}>more</a>}
									/>
								) : (
									investmentPlatform.description
								)}
							</Typography>
						</CardContent>
					</Grid>

					<Grid
						className={`${classes.borderedColumn} ${classes.secondColumn}`}
						item
						sm={3}
						xs={3}
						id="InvestopediaItem"
					>
						<p
							style={{
								fontSize: '14px',
								margin: '0',
								marginTop: '28px',
								color: '#73757A',
							}}
						>
							Fees
						</p>
						<h4
							style={{
								fontWeight: 'normal',
								marginTop: '0',
								marginBottom: '10px',
								fontSize: '20px',
							}}
						>
							{`${investmentPlatform.fees} %`}
						</h4>
						<div className="pseudoborder"></div>
					</Grid>

					<Grid
						className={`thirdgrid ${classes.borderedColumn} ${classes.thirdColumn}`}
						item
						sm={3}
						xs={3}
						id="InvestopediaItem"
					>
						<div>
							<p
								style={{
									fontSize: '14px',
									margin: '0',
									marginTop: '28px',
									color: '#73757A',
								}}
							>
								Min investment
							</p>
							<h4
								style={{
									fontWeight: 'normal',
									marginTop: '0',
									marginBottom: '10px',
									fontSize: '20px',
								}}
							>
								$
								{investmentPlatform.minInvestment
									.toString()
									.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
							</h4>
							<div className="pseudoborder"></div>
						</div>
					</Grid>
				</Grid>
			</div>
		</>
	);
};
