import React, { useState, useEffect } from 'react';
import SimilarPlatform from './SimilarPlatform';
import makeStyles from '@material-ui/core/styles/makeStyles';

export default function SimilarPlatforms({
	similarPlatforms,
	expanded,
	setActivePlatform,
	resetTotal,
	search,
	expandable,
	deepDive,
	handleMoveToListView,
	isListView,
}) {
	return (
		<React.Fragment>
			{similarPlatforms.map((platform) =>
				platform ? (
					<SimilarPlatform
						investmentPlatform={platform}
						key={platform.id}
						expanded={expanded}
						setActivePlatform={setActivePlatform}
						resetTotal={resetTotal}
						search={search}
						expandable={expandable}
						deepDive={deepDive}
						handleMoveToListView={handleMoveToListView}
						isListView={isListView}
					/>
				) : null
			)}
		</React.Fragment>
	);
}
