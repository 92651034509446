import React from 'react';
import queryString from 'query-string';
import LeftNavbarWrapper from '../components/LeftNavbarWrapperTrading';
import Footer from '../components/Footer/Footer.jsx';

import ReactGA from 'react-ga';
import { recordUserId } from '../utils';
import { useDispatch } from 'react-redux';
import * as actions from '../redux/actions';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const Home = (props) => {
	const { utm_campaign, utm_medium, utm_source } = queryString.parse(
		props.location.search
	);
	const dispatch = useDispatch();
	React.useEffect(() => {
		ReactGA.pageview('/trading');
		if (utm_source) {
			dispatch(actions.setItemSource(utm_source));
		}
		if (utm_campaign) {
			dispatch(actions.setItemCompaign(utm_campaign));
		}
		if (utm_medium) {
			dispatch(actions.setItemMedium(utm_medium));
		}
	}, [utm_campaign, utm_medium, utm_source]);

	//recordUserId();
	return (
		<div>
			<LeftNavbarWrapper utmParams={{ utm_campaign, utm_medium, utm_source }} />

			<div>
				<Footer utmParams={{ utm_campaign, utm_medium, utm_source }} />
			</div>
			{/*<div className="sortWrapper footer__disclaimer">
			<p>
				The Content is for informational purposes only, you should not construe any
				such information or other material as legal, tax, investment, financial, or
				other advice. Nothing contained on our Site constitutes a solicitation,
				recommendation, endorsement, or offer by MoneyMade or any third party service
				provider to buy or sell any securities or other financial instruments in this
				or in in any other jurisdiction in which such solicitation or offer would be
				unlawful under the securities laws of such jurisdiction. All Content on this
				site is information of a general nature and does not address the
				circumstances of any particular individual or entity. Nothing in the Site
				constitutes professional and/or financial advice, nor does any information on
				the Site constitute a comprehensive or complete statement of the matters
				discussed or the law relating thereto. MoneyMade is not a fiduciary by virtue
				of any person’s use of or access to the Site or Content. You alone assume the
				sole responsibility of evaluating the merits and risks associated with the
				use of any information or other Content on the Site before making any
				decisions based on such information or other Content. In exchange for using
				the Site, you agree not to hold MoneyMade, its affiliates or any third party
				service provider liable for any possible claim for damages arising from any
				decision you make based on information or other Content made available to you
				through the Site.
				<br />
				<br />
				MoneyMade strives to keep its information accurate and up to date. This
				information may be different than what you see when you visit a financial
				institution, service provider or specific product’s site. All financial
				products, services are presented without warranty. When evaluating offers,
				please review the financial institution’s Terms and Conditions.
				<br />
				<br />
				Advertiser disclosure: We strive to provide users with the most transparent
				and true information about the platforms listed. Some of the platforms listed
				may contain Affiliate links. We may receive payment if you apply for and / or
				receive one of the products listed on MoneyMade.
			</p>
</div> */}
		</div>
	);
};
export default Home;
