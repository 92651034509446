import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Header from './Header';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import ClosableModal from '../ClosableModal';
import CreateReviewForm from './CreateReviewForm';
import { createReview } from '../../Services/ReviewsAPI';
import ReactGA from 'react-ga';
import { trackEvent, eventTracker, getDiscoveryPageName, getFullPlatformUrl } from '../../utils';
import { useSelector, shallowEqual } from 'react-redux';
import { BodyPlatform } from './BodyPlatform';
import { HeaderBodyMobile } from '../InvestmentPlatform/HeaderBodyMobile';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles((theme) => ({
	mainWidth: {
		width: 1200,
		maxWidth: '100%',
	},
	mainModal: {
		margin: 'auto',
		marginBottom: '12px',
		paddingBottom: '10px',
		boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
		position: 'relative',
		borderRadius: '8px',
	},
	anchor: {
		display: 'block',
		position: 'relative',
		top: '-65px',
		visibility: 'hidden',
	},
	expandIcon: {
		position: 'absolute',
		top: '12px',
		right: '12px',
	},
	websiteIcon: {
		position: 'absolute',
		top: '12px',
		right: '50px',
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	premium: {
		border: '1px solid #F3B81F',
	},
	createReviewModal: {
		maxWidth: '553px',
		width: '100%',
		backgroundColor: '#FFF',
	},
	cardContainerDesktop: {
		[theme.breakpoints.up('750')]: {
			display: 'inherit',
		},
		[theme.breakpoints.down('750')]: {
			display: 'none',
		},
	},
	cardContainerMobile: {
		[theme.breakpoints.up('750')]: {
			display: 'none',
		},
		[theme.breakpoints.down('750')]: {
			display: 'inherit',
		},
	},
}));

const modals = {
	MAIN: 'main',
	CREATE_REVIEW: 'create_review',
};

export default function ExpandedPlatform({
	investmentPlatform,
	similarInvestments,
	setActivePlatform,
	embed,
	cluster,
	expandable,
	favorites,
	resetTotal,
	search,
	handleMoveToListView,
	isListView,
}) {
	const classes = useStyles();
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const [visibleModal, setVisibleModal] = React.useState(modals.MAIN);
	const [isWaitingForReview, setIsWaitingForReview] = React.useState(false);

	const openCreateReviewForm = () => setVisibleModal(modals.CREATE_REVIEW);
	const openMainModal = () => setVisibleModal(modals.MAIN);

	const platformUrl = getDiscoveryPageName(expandable, investmentPlatform.slug);
	const fullPlatformUrl = getFullPlatformUrl(`${platformUrl}`);

	useEffect(() => {
		trackEvent('PlatformExpandedImpression', 'platform', investmentPlatform.name);
		eventTracker(
			'PlatformExpandedImpression',
			investmentPlatform.name,
			state.itemSource,
			state.itemMedium,
			state.itemCompaign,
			state.userId
		);
	});

	const onCreateReviewFormSubmit = async (data) => {
		setIsWaitingForReview(true);

		await createReview(investmentPlatform.id, {
			reviewerName: data.name,
			reviewerEmail: data.email,
			isVerified: data.isInvesting,
			comment: data.comment,
			rating: data.rating,
		});

		setIsWaitingForReview(false);
		setVisibleModal(modals.MAIN);
	};
	return (
		<>
			<div
				className={`mainCard ${classes.mainModal} ${
					investmentPlatform.premium > 0 ? classes.premium : null
				} ${favorites ? `` : classes.mainWidth}`}
			>
				<a
					id={`platform_ex_${cluster ? `${cluster}_` : null}${
						investmentPlatform.id
					}`}
					className={classes.anchor}
				/>
				<IconButton
					className={classes.expandIcon}
					onClick={() => setActivePlatform(null)}
					aria-label="Expand"
					color="primary"
				>
					<KeyboardArrowUpIcon />
				</IconButton>
				<div className={classes.cardContainerDesktop}>
					<Header
						investmentPlatform={investmentPlatform}
						platformUrl={fullPlatformUrl}
						expandable={expandable}
						expanded={true}
					/>
					<BodyPlatform
						investmentPlatform={investmentPlatform}
						similarInvestments={similarInvestments}
						setActivePlatform={setActivePlatform}
						embed={embed}
						cluster={cluster}
						openCreateReviewForm={openCreateReviewForm}
						resetTotal={resetTotal}
						search={search}
						handleMoveToListView={handleMoveToListView}
						isListView={isListView}
					/>
				</div>
				<div className={classes.cardContainerMobile}>
					<HeaderBodyMobile
						investmentPlatform={investmentPlatform}
						platformUrl={fullPlatformUrl}
						expandable={expandable}
						expanded={true}
						similarInvestments={similarInvestments}
						setActivePlatform={setActivePlatform}
						embed={embed}
						cluster={cluster}
						openCreateReviewForm={openCreateReviewForm}
						modal={false}
						resetTotal={resetTotal}
						search={search}
						handleMoveToListView={handleMoveToListView}
						isListView={isListView}
					/>
				</div>
				<ClosableModal
					className={classes.createReviewModal}
					open={visibleModal === modals.CREATE_REVIEW}
					onClose={openMainModal}
				>
					<CreateReviewForm
						isLoading={isWaitingForReview}
						investmentPlatform={investmentPlatform}
						onSubmit={onCreateReviewFormSubmit}
					/>
				</ClosableModal>
			</div>
		</>
	);
}
