import firebase from 'firebase/app';
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyBzygyr_xkx_LaRVSllJ8scNz-5se3Tzmo",
    authDomain: "benchmark-media.firebaseapp.com",
    databaseURL: "https://benchmark-media.firebaseio.com",
    projectId: "benchmark-media",
    storageBucket: "benchmark-media.appspot.com",
    messagingSenderId: "686208352233",
    appId: "1:686208352233:web:90352117c9473d15"
};

firebase.initializeApp(config);

//export const auth = firebase.auth;

export default firebase;
