import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Rating from '../Rating';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});
const useStyles = makeStyles((theme) => ({
    card: {
        background: '#FFFFFF',
        border: '1px solid #F2F5F8',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
        borderRadius: '8px',
        padding: '28px',
        [theme.breakpoints.down('460')]: {
            padding: '8px',
        },
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    topRow: {
        display: 'flex',
        alignItems: 'center',
    },
    logo: {
        width: '52px',
        height: '52px',
        background: '#F2F5F8',
        [theme.breakpoints.down('460')]: {
            width: '40px',
        },
    },
    title: {
        fontFamily: 'Red Hat Display',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '44px',
        lineHeight: '56px',
        color: '#30333E',
        marginLeft: '12px',
        [theme.breakpoints.down('700')]: {
            fontSize: '24px',
            lineHeight: '32px',
        },
    },
    button: {
        width: '160px',
        [theme.breakpoints.down('700')]: {
            width: '100%',
        },
    },
    rating: {
		marginLeft: '8px',
		[theme.breakpoints.down('xs')]: {
			marginLeft: '0px',
		},
	},
    website: {
        fontFamily: 'Red Hat Display',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '18px',
        lineHeight: '40px',
        color: '#30333E',
        marginLeft: '20px',
        textDecoration: 'underline',
        [theme.breakpoints.down('460')]: {
            display: 'none',
        },
    },
    favoritesContainer: {
        marginLeft: 'auto',
    },
    favorites: {
        display: 'flex',
        alignItems: 'center',
        background: '#F2F5F8',
        border: '1px solid #DBE0E6',
        boxSizing: 'border-box',
        borderRadius: '100px',
        height: '32px',
        cursor: 'pointer',
        paddingRight: '10px',
    },
    favoritesText: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '20px',
        color: '#30333E',
        paddingRight: '4px',
        paddingLeft: '4px',
    },
    tagsRow: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '12px',
        paddingBottom: '16px',
    },
    tagGrey: {
        background: '#ECF5FF',
        borderRadius: '4px',
        padding: '0px 8px',
        marginRight: '12px',
    },
    tagBlue: {
        background: '#ECF5FF',
        borderRadius: '4px',
        padding: '0px 8px',
        marginRight: '12px',
    },
    tagGreen: {
        background: '#EBFAF1',
        borderRadius: '4px',
        padding: '0px 8px',
        marginRight: '12px',
    },
    tagText: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '20px',
        color: '#30333E',
        letterSpacing: '0.04em',
        textTransform: 'uppercase',
    },
    grey: {
        color: '#73757A',
    },
    blue: {
        color: '#3E94F9',
    },
    green: {
        color: '#33CD71',
    },
    statisticsRow: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('460')]: {
            display: 'none',
        },
    },
    statisticsContainer: {
        marginRight: '60px',
    },
    countText: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '32px',
        color: '#30333E',
    },
    countDescription: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '20px',
        color: '#73757A',
    },
    descriptionRow: {
        marginTop: '16px',
    },
    description: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#73757A',
        [theme.breakpoints.down('700')]: {
            fontSize: '14px',
            lineHeight: '20px',
        },
    },
    cardTitle: {
        cursor: 'pointer',
    },
    borderedColumn: {
		position: 'relative',
		'& .pseudoborder': {
			height: 'calc(100% - 40px) !important',
			top: '28px',
			left: '-24px',
			margin: '0 !important',
		},
	},
	secondColumn: {
		paddingRight: '32px',
    },
    row: {
        //display: 'flex',
        marginTop: '40px',
    },
    rowCol: {
        //width: '50%',
    },
    rowTitle: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#73757A',
    },
    hl: {
        width: '356px',
        maxWidth: '100%',
        height: '1px',
        background: '#F2F5F8',
        marginTop: '15px',
        marginBottom: '15px',
    },
    assetDescription: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#30333E',
        paddingRight: '14px',
    },
    assetTitle: {
        width: '134px',
    },
    right: {
        [theme.breakpoints.up('700')]: {
           // display: 'inherit',
        },
        [theme.breakpoints.down('700')]: {
            display: 'none',
        },
    },
    bottom: {
        [theme.breakpoints.up('700')]: {
            display: 'none',
        },
        [theme.breakpoints.down('700')]: {
            display: 'inherit',
            marginTop: '16px',
        },
    },
    hlBottom: {
        [theme.breakpoints.up('xs')]: {
            display: 'none',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'inherit',
        },
    },
    headers: {},
}));
export const NoResultCard = (props) => {
    const {
        investment,
	} = props;
    const classes = useStyles();

	return (
            <div>
                <div className={`${classes.row}`}>
                    <Grid container alignItems="flex-start" className={`${classes.row}`}>
                        <Grid className={`${classes.rowTitle}`} item sm={3} xs={6}>
                            Investment type
                        </Grid>
                        <Grid item sm={3} xs={6}>
                            <Chip
                                style={{ marginTop: '0px', marginRight: '0px' }}
                                label={investment.industry}
                                className="ctag gray"
                            />
                        </Grid>

                        <Grid item xs={6} className={`${classes.hlBottom}`}>
                            <div className={`${classes.hl}`}></div>
                        </Grid>
                        <Grid item xs={6} className={`${classes.hlBottom}`}>
                            <div className={`${classes.hl}`}></div>
                        </Grid>

                        <Grid className={`${classes.rowTitle}`} item sm={3} xs={6}>
                            User rating
                        </Grid>
                        <Grid className={` `} item sm={3} xs={6}>
                            {(investment.averageRating || null) && (
                                <Rating
                                    className={classes.rating}
                                    value={investment.averageRating}
                                />
                            )}
                        </Grid>
                    </Grid>

                    <Grid container alignItems="flex-start">
                        <Grid item sm={6} xs={6}>
                        <div className={`${classes.hl}`}></div>
                        </Grid>
                        <Grid item sm={6} xs={6}>
                            <div className={`${classes.hl}`}></div>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <Grid container alignItems="flex-start">
                        <Grid className={`${classes.rowTitle}`} item sm={3} xs={6}>
                            <div className={`${classes.assetTitle}`}>
                                Assets under management
                            </div>
                        </Grid>
                        <Grid item sm={3} xs={6}>
                            <div className={`${classes.assetDescription}`}>
                                {investment.assetsUnderManagement
                                ? `$ ${investment.assetsUnderManagement
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                                : 'Unknown'}
                            </div>
                        </Grid>

                        <Grid item xs={6} className={`${classes.hlBottom}`}>
                            <div className={`${classes.hl}`}></div>
                        </Grid>
                        <Grid item xs={6} className={`${classes.hlBottom}`}>
                            <div className={`${classes.hl}`}></div>
                        </Grid>

                        <Grid className={`${classes.rowTitle}`} item sm={3} xs={6}>
                            Insurance
                        </Grid>
                        <Grid className={` `} item sm={3} xs={6}>
                            <div className={`${classes.assetDescription}`}>
                                {investment.insurance ? 'Yes' : 'No'}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
	);
};
