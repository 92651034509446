import React, { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';

function capitalize(string) {
    if (typeof string !== 'string') {
        return;
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
}
export const styles = (theme) => ({
  /* Styles applied to the root element. */
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: "#fff",
    border: '1px solid #F2F5F8',
    borderLeft: '0',
    borderRight: '0',
  },
  /* Styles applied to the root element if `position="bottom"`. */
  positionBottom: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.mobileStepper,
  },
  /* Styles applied to the root element if `position="top"`. */
  positionTop: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.mobileStepper,
  },
  /* Styles applied to the root element if `position="static"`. */
  positionStatic: {},
  /* Styles applied to the dots container if `variant="dots"`. */
  dots: {
    display: 'flex',
    flexDirection: 'row',
  },
  /* Styles applied to each dot if `variant="dots"`. */
  dot: {
    backgroundColor: theme.palette.action.disabled,
    borderRadius: '50%',
    width: 8,
    height: 8,
    margin: '0 2px',
  },
  /* Styles applied to a dot if `variant="dots"` and this is the active step. */
  dotActive: {
    backgroundColor: theme.palette.primary.main,
  },
  /* Styles applied to the Linear Progress component if `variant="progress"`. */
  progress: {
    width: '100%',
    borderRadius: '100px',
  },
  colorPrimary: {
    backgroundColor: '#DBE0E6',
    borderRadius: '100px',
  },
  barColorPrimary: {
    backgroundColor: '#33CD71',
    borderRadius: '100px',
  },
    title: {
        margin: '20px auto',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#73757A',
    },
    stepper: {
        border: '1px solid #F2F5F8',
        paddingTop: '14px',
        paddingBottom: '20px',
        borderLeft: '0px',
        paddingRight: '20px',
        [theme.breakpoints.up('830')]: {
            width: '594px',
            paddingLeft: '95px',
		},
        [theme.breakpoints.down('830')]: {
            width: '594px',
            paddingLeft: '95px',
        },
        [theme.breakpoints.down('780')]: {
            width: '550px',
            paddingLeft: '44px',
        },
        [theme.breakpoints.down('730')]: {
            width: '500px',
            paddingLeft: '44px',
        },
        [theme.breakpoints.down('680')]: {
            width: '450px',
            paddingLeft: '44px',
        },
        [theme.breakpoints.down('630')]: {
            width: '400px',
            paddingLeft: '44px',
        },
        [theme.breakpoints.down('600')]: {
            width: '430px',
            paddingLeft: '20px',
        },
        [theme.breakpoints.down('550')]: {
            width: '380px',
            paddingLeft: '20px',
        },
        [theme.breakpoints.down('500')]: {
            width: '330px',
            paddingLeft: '22px',
        },
        [theme.breakpoints.down('450')]: {
            width: '280px',
            paddingLeft: '22px',
        },
        [theme.breakpoints.down('400')]: {
            width: '230px',
            paddingLeft: '22px',
        },
        [theme.breakpoints.down('350')]: {
            width: '180px',
            paddingLeft: '22px',
        },
        [theme.breakpoints.down('300')]: {
            paddingLeft: '6px',
		},
    },
    arrows: {
        display: 'flex',
    },
    backButton: {
        border: '1px solid #F2F5F8',
        borderLeft: '0px',
        paddingTop: '10px',
    },
    nextButton: {
        border: '1px solid #F2F5F8',
        borderLeft: '0px',
        borderRight: '0px',
        paddingTop: '10px',
    },
});
const MobileStepper = forwardRef(function MobileStepper(props, ref) {
    const {
        onFinish,
        activeStep = 0,
        backButton,
        classes,
        className,
        LinearProgressProps,
        nextButton,
        position = 'bottom',
        steps,
        variant = 'dots',
        ...other
    } = props;
    useEffect(() => {
        if (activeStep === steps) {
            onFinish();
        }
    }, [activeStep, steps])
  return (
    <Paper
        square
        elevation={0}
        className={clsx(classes.root, classes[`position${capitalize(position)}`], className)}
        ref={ref}
        {...other}
    >
        <div style={{
            display: 'flex',
        }}>
            <div className={classes.stepper} >
                <span className={classes.title}>
                    {`${activeStep}/${steps} completed`}
                </span>
                <span>
                    {variant === 'progress' && (
                        <LinearProgress
                            className={classes.progress}
                            variant="determinate"
                            value={Math.ceil((activeStep / (steps - 1)) * 100)}
                            classes={{
                                colorPrimary: classes.colorPrimary,
                                barColorPrimary: classes.barColorPrimary,
                            }}
                            {...LinearProgressProps}
                        />
                    )}
                </span>
            </div>
            <div className={classes.arrows}>
                <div className={classes.backButton}>
                    {backButton}
                </div>
                <div className={classes.nextButton}>
                    {nextButton}
                </div>
            </div>
        </div>
    </Paper>
  );
});

MobileStepper.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Set the active step (zero based index).
   * Defines which dot is highlighted when the variant is 'dots'.
   */
  activeStep: PropTypes.number,
  /**
   * A back button element. For instance, it can be a `Button` or an `IconButton`.
   */
  backButton: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * Props applied to the `LinearProgress` element.
   */
  LinearProgressProps: PropTypes.object,
  /**
   * A next button element. For instance, it can be a `Button` or an `IconButton`.
   */
  nextButton: PropTypes.node,
  /**
   * Set the positioning type.
   */
  position: PropTypes.oneOf(['bottom', 'static', 'top']),
  /**
   * The total steps.
   */
  steps: PropTypes.number.isRequired,
  /**
   * The variant to use.
   */
  variant: PropTypes.oneOf(['dots', 'progress', 'text']),
};

export default withStyles(styles, { name: 'MuiMobileStepper' })(MobileStepper);
