import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Skeleton from '@material-ui/lab/Skeleton';
import Avatar from '@material-ui/core/Avatar';
import TextTruncate from 'react-text-truncate';
import _ from 'lodash';
import { mobileWidth, widgetWidth } from '../../data/Data';

export const CollectionList = (props) => {
	const {
        clusters,
        investments,
        handleCollection,
        expandable,
    } = props;

    const limit = !expandable ? mobileWidth : widgetWidth;

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            //justifyContent: 'space-around',
            overflow: 'hidden',
            paddingTop: '0px',
            //width: '100%',
            //maxWidth: '1200px',
            position: 'relative',
            padding: '2px',
        },
        gridList: {
            flexWrap: 'nowrap',
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: 'translateZ(0)',
        },
        tile: {
            cursor: 'pointer',
            width: '386px !important',
            height: '376px !important',
            background: '#FFFFFF',
            border: '1px solid #F2F5F8',
            boxSizing: 'border-box',
            boxShadow: '0px 4px 16px rgba(6, 7, 23, 0.04)',
            borderRadius: '8px',
            marginRight: '14px',
            [theme.breakpoints.down(`${limit}`)]: {
                width: '220px !important',
                maxWidth: '100%',
                marginRight: '12px',
                marginBottom: '12px',
            },
        },
        tileContainer: {
            textAlign: 'center',
            position: 'relative',
        },
        avatar: {
            width: '80px',
            height: '80px',
            marginTop: '40px',
            marginLeft: 'auto',
            marginRight: 'auto',
            [theme.breakpoints.down(`${limit}`)]: {
                marginTop: '20px',
            },
            [theme.breakpoints.down('488')]: {
                marginTop: '40px',
            },
        },
        tileTitle: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '20px',
            lineHeight: '32px',
            color: '#30333E',
            marginTop: '20px',
            maxWidth: '260px',
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            [theme.breakpoints.down(`${limit}`)]: {
                padding: '0 5px 0 5px',
            },
            [theme.breakpoints.down('488')]: {
                padding: '0',
            },
        },
        tileDesc: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#73757A',
            marginTop: '8px',
            maxWidth: '260px',
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            [theme.breakpoints.down(`${limit}`)]: {
                padding: '0 5px 0 5px',
            },
            [theme.breakpoints.down('488')]: {
                padding: '0',
            },
        },
        button: {
            width: '192px',
            height: '48px',
            textTransform: 'none',
            maxWidth: '1200px',
            color: '#ffffff',
            background: '#5056E7',
            '&:hover': {
                backgroundColor: 'rgb(80,86,231, 0.8)',
            },
            position: 'absolute',
            top: '260px;',
            marginLeft: 'auto',
            marginRight: 'auto',
            left: 0,
            right: 0,
            textAlign: 'center',
            [theme.breakpoints.down(`${limit}`)]: {
                top: '280px',
            },
            [theme.breakpoints.down('488')]: {
                top: '260px',
            },
        },
        backButton: {
            position: 'absolute',
            zIndex: '9999',
            left: '0px',
            right: 'inherit',
            top: '145px',
            marginTop: '20px',
            //width: '55px !important',
            [theme.breakpoints.down(`${limit}`)]: {
                //display: 'none',
            },
        },
        forwardButton: {
            position: 'absolute',
            marginTop: '20px',
            right: '0px',
            top: '145px',
            zIndex: '9999',
            //width: '55px !important',
            [theme.breakpoints.down(`${limit}`)]: {
                //display: 'none',
            },
        },
        skeleton: {
            width: '100%',
            maxWidth: '1200px',
            borderRadius: '8px',
            height: '100px',
            [theme.breakpoints.down('1536')]: {
                height: '84px',
            },
            [theme.breakpoints.down('600')]: {
                height: '76px',
            },
        },
        desktopView: {
            [theme.breakpoints.down(`${limit}`)]: {
                //display: 'none !important',
            },
        },
        mobileView: {
            [theme.breakpoints.up(`${limit}`)]: {
                display: 'none !important',
            },
            [theme.breakpoints.down(`${limit}`)]: {
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
            },
        },
    }));

	const classes = useStyles();
    const clusterDiv = React.useRef();
    const [clustersAdvanced, setClustersAdvanced] = useState([]);

    const skeletonElement = (
		<Skeleton animation="wave" variant="rect" className={`${classes.button}`} />
	);
    const skeletonArray = Array(8).fill(skeletonElement);

    const scrollItems = `scrollItems`;
    const slider = document.querySelector(`.${scrollItems}`);
    let isDown = false;
    let startX;
    let scrollLeft;

    useEffect(() => {
        if (slider) {
            slider.addEventListener('mousedown', onMouseDown);
            slider.addEventListener('mouseleave', onMouseLeave);
            slider.addEventListener('mouseup', onMouseUp);
            slider.addEventListener('mousemove', onMouseMove);
        }
		return function cleanup () {
            if (slider) {
                slider.removeEventListener('mousedown', onMouseDown);
                slider.removeEventListener('mouseleave', onMouseLeave);
                slider.removeEventListener('mouseup', onMouseUp);
                slider.removeEventListener('mousemove', onMouseMove);
            }
		};
	});
    useEffect(() => {
        if (clusters) {
            getClusters(clusters);
        }
    }, [clusters]);

    const onMouseDown = (e) => {
        isDown = true;
        slider.classList.add('active');
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
    };
    const onMouseLeave = () => {
        isDown = false;
        slider.classList.remove('active');
    };
    const onMouseUp = () => {
        isDown = false;
        slider.classList.remove('active');
    };
    const onMouseMove = (e) => {
        if(!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 3; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
    };
    const getClusters = () => {
        const clustersArray = [];
        clusters.map(function (cluster, index) {
            let clusterInvestments = _.chain(investments)
                .pickBy((value, key) => {
                    if (value.clusters) {
                        return value.clusters.includes(cluster.id);
                    }
                    return false;
                })
                .sortBy([`${cluster.id}_premium`, 'minInvestment'])
                .value();
            clusterInvestments = _.values(clusterInvestments);
            clustersArray.push({
                ...cluster,
                ...{
                    platforms: clusterInvestments
                }
            });
        });
        setClustersAdvanced(clustersArray);
    };
	const handleClick = (cluster) => {
        handleCollection(cluster);
    };
	function handleForwardClick(e) {
		if (clusterDiv.current) {
			clusterDiv.current.scrollLeft += 200;
		}
	}
	function handleBackClick(e) {
		if (clusterDiv.current) {
			clusterDiv.current.scrollLeft -= 200;
		}
	}
	return (
		<div className={classes.root} id="clusterContainer">
			{clustersAdvanced.length === 0 ? (
				<div>
					<GridList className={classes.gridList} cols={1}>
						{skeletonArray.map((element, index) => {
							return (
								<GridListTile key={index} className={classes.tile}>
									{element}
								</GridListTile>
							)
						})}
					</GridList>
				</div>
			) : (
				<>
					<IconButton
						aria-label="back"
						color="primary"
						variant="outlined"
						onClick={handleBackClick}
						className={classes.backButton}
					>
						<ArrowBackIcon />
					</IconButton>

					<GridList className={`${classes.gridList} ${classes.desktopView} ${scrollItems}`} ref={clusterDiv} cols={1}>
						{clustersAdvanced.map((cluster) => (
							<GridListTile key={cluster.id} className={classes.tile} onClick={() => handleClick(cluster)}>
                                <div className={classes.tileContainer}>
                                    <Avatar
                                        src={cluster.icon}
                                        className={classes.avatar}
                                    >
                                    </Avatar>
                                    <div className={`${classes.tileTitle}`}>
                                        <TextTruncate
                                            line={2}
                                            element="span"
                                            truncateText="…"
                                            text={cluster.name}
                                        />
                                    </div>
                                    {<div className={`${classes.tileDesc}`}>
                                        {cluster.description}
                                    </div>}
                                    <Button
                                        className={`${classes.button} ${classes.color1}`}
                                        onClick={() => handleClick(cluster)}
                                    >
                                       Explore {cluster.platforms.length} options
                                    </Button>
                                </div>
							</GridListTile>
						))}
					</GridList>

					<IconButton
						aria-label="forward"
						color="primary"
						variant="outlined"
						onClick={handleForwardClick}
						className={classes.forwardButton}
					>
						<ArrowForwardIcon />
					</IconButton>
				</>
			)}
		</div>
	);
}
