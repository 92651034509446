import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { getSentimentIcon } from './helpers';

const useStyles = makeStyles({
	avatar: {
		backgroundColor: '#f3f5f8',
	},
	avatarWithStar: {
		backgroundColor: '#f3f5f8',
		width: '48px',
		height: '48px',
	},
	sentimentImage: {
		maxWidth: '16px',
	},
});

export default function InvestmentPlatformAvatar({ className, investmentPlatform, starVisibility  }) {
	const classes = useStyles();

	const SmallAvatar = withStyles((theme) => ({
		root: {
			width: 22,
			height: 22,
			border: `2px solid ${theme.palette.background.paper}`,
			backgroundColor: '#fff',
		},
		img: {
			backgroundColor: '#fff',
		},
	}))(Avatar);

	const imgStyle = starVisibility ? { maxWidth: '48px' } : { maxWidth: '40px' };
	const avatarStyle = starVisibility ? classes.avatarWithStar : classes.avatar;

	return (
		<Badge
			overlap="circle"
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			/*badgeContent={<SmallAvatar src={getSentimentIcon(investmentPlatform.sentiment)} />}*/
		>
			<Avatar aria-label="avatar" className={`${avatarStyle} ${className}`}>
				<img
					alt={investmentPlatform.name}
					style={imgStyle}
					src={investmentPlatform.logo}
				/>
			</Avatar>
		</Badge>
	);
}
