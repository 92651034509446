import React from "react";

import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { makeStyles } from "@material-ui/core/styles";

import { useReviewStyles } from "./review-styles";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: "24px",
        paddingBottom: "24px",
    },
    filtersMenu: {
        zIndex: "10001 !important",
    },
    [theme.breakpoints.down("xs")]: {
        root: {
            flexWrap: "wrap",
            paddingTop: "20px",
            paddingBottom: "20px",
        },
        writeReviewButton: {
            marginBottom: "0 !important",
            marginTop: "20px",
        },
    },
}));

export default function ReviewsActions({ onCreateReview, orderings, selectedOrdering, onOrderingUpdate }) {
    const classes = useStyles();
    const reviewClasses = useReviewStyles();
    const [ sortMenuAnchorEl, setSortMenuAnchorEl ] = React.useState(null);
    console.log(sortMenuAnchorEl);

    const openSortMenu = event => setSortMenuAnchorEl(event.currentTarget);
    const closeSortMenu = () => setSortMenuAnchorEl(null);
    const onSelectOrdering = ordering => {
        closeSortMenu();
        onOrderingUpdate(ordering);
    };

    return (
        <div className={`${classes.root} ${reviewClasses.reviewsBlockRow}`}>
            <span>
                Sort by: &nbsp;
                <Button size="small" endIcon={<KeyboardArrowDownIcon/>} onClick={openSortMenu}>
                    {selectedOrdering.label}
                </Button>
            </span>

            <Menu
                anchorEl={sortMenuAnchorEl}
                open={Boolean(sortMenuAnchorEl)}
                onClose={closeSortMenu}
                className={classes.filtersMenu}
            >
                {orderings.map(ordering =>
                    <MenuItem
                        key={ordering.name}
                        onClick={() => onSelectOrdering(ordering)}
                    >
                        {ordering.label}
                    </MenuItem>
                )}
            </Menu>

            <Button
                variant="contained"
                className={`homeBtn one ${classes.writeReviewButton}`}
                onClick={onCreateReview}
                color="primary"
            >
                Write a review
            </Button>
        </div>
    )
}
