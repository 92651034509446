import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import queryString from 'query-string';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CalculatorBanner from '../components/Calculator/CalculatorBanner';
import CalculatorFlow from '../components/Calculator/CalculatorFlow';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import AppBarRes from '../components/AppBar/AppBar.jsx';

import ReactGA from 'react-ga';
import { eventTracker } from '../utils';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as actions from '../redux/actions';

ReactGA.initialize('UA-148517382-4', {
	gaOptions: {
		cookieFlags: 'max-age=7200;secure;samesite=none',
	},
});

const useStyles = makeStyles((theme) => ({
	expandIcon: {
		position: 'absolute',
		top: '12px',
		right: '12px',
	},
}));

const EmbedEntry = (props) => {
	//console.log(props);
	const [investments, setInvestments] = useState([]);
	const [paidOnly, setPaidOnly] = useState(false);
	const embededEnryRef = useRef();
	const classes = useStyles();

	const [height, setHeight] = useState(0);

	const [bannerShow, setBannerShow] = useState(true);
	//const componentRef = React.();

	const [displaySpinner, setDisplaySpinner] = useState(false);

	useEffect(() => {}, []);

	useEffect(() => {
		setHeight(embededEnryRef.current.clientHeight);
		let dynamicHeight;
		//console.log(embededEnryRef.current.clientHeight);
		if (bannerShow) {
			dynamicHeight = embededEnryRef.current.clientHeight;
		} else {
			dynamicHeight = 800;
		}
		if (window.parent) {
			window.parent.postMessage(
				{
					frameId: frameId,
					action: 'setHeight',
					height: dynamicHeight,
				},
				'*'
			);
		}
	}, [bannerShow]);

	const { utm_campaign, utm_medium, utm_source, frameId } = queryString.parse(
		props.location.search
	);
	const state = useSelector((stateInternal) => stateInternal.Item, shallowEqual);
	const utmParams = { utm_campaign, utm_medium, utm_source };
	const dispatch = useDispatch();

	React.useEffect(() => {
		if (state.userId) {
			ReactGA.pageview(`/embedCalculator`);
			eventTracker(
				'pageview',
				`/embedCalculator`,
				utm_source,
				utm_medium,
				utm_campaign,
				state.userId
			);
		}
		if (utm_campaign === 'paidOnly') {
			setPaidOnly(true);
		}
		if (utm_source) {
			dispatch(actions.setItemSource(utm_source));
		}
		if (utm_campaign) {
			dispatch(actions.setItemCompaign(utm_campaign));
		}
		if (utm_medium) {
			dispatch(actions.setItemMedium(utm_medium));
		}
	}, [utm_campaign, utm_medium, utm_source, state.userId]);

	function openCalculator() {
		setBannerShow(!bannerShow);
	}
	//console.log(bannerShow);
	//console.log(investments.length);
	return (
		<div ref={embededEnryRef}>
			{bannerShow ? (
				<CalculatorBanner embed={true} openCalculator={openCalculator} />
			) : (
				<>
					<IconButton
						className={classes.expandIcon}
						aria-label="Collapse"
						onClick={openCalculator}
					>
						<KeyboardArrowUpIcon />
					</IconButton>
					{utm_campaign === 'calculatorNav' ? (
						<AppBarRes showMenu={true} utmParams={utmParams} />
					) : null}
					<CalculatorFlow embed={true} paidOnly={paidOnly} />
				</>
			)}
		</div>
	);
};
export default EmbedEntry;
